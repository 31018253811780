<template>
  <div id="cart">
    <div v-if="redirect">
      <h6 style="font-size: 28px; white-space: nowrap">
        <b>Cargando...</b>
      </h6>
      <hr class="mt-2" />
    </div>
    <div v-else>
      <div class="fade-in">
        <div v-if="service_active && $route.name != 'Resumen'" class="container-gif-cart-v">
          <img src="/images/dis.gif" alt="imagen-carga" style="max-width: 100%" />
        </div>
        <div
          v-if="service_active && $route.name != 'Resumen'"
          class="alert alert-light alert-payment-v"
          role="alert"
        >
          Tienes una nota de cobro pendiente{{ notaid != null ? `, nota con el id: ${notaid}` : '' }}. Para pagarla haz click
          <a href="/pago-rapido">aquí.</a>
        </div>
      </div>
      <div class="ordersummary_">
        <div>
          <div v-if="cart.length != 0" id="order-standard_cart" style="line-height: 1.4 !important">
            <div class="col-md-12 px-0">
              <div class="mb-3">
                <div class="view-cart-items-header">
                  <div class="row" style="flex-flow: row nowrap">
                    <div class="col-lg-7 col-sm-7 col-xs-7">Servicio/Opciones</div>
                    <div class="col-lg-5 col-sm-5 text-right">Precio/Ciclo</div>
                  </div>
                </div>

                <div class="view-cart-items">
                  <div v-for="(item, index) in returnAllServices(cart)" :key="index" class="item box_service">
                    <div class="row">
                      <div class="col-md-12">
                        <span style="display: block; width: 90%;">
                          <div style="font-size: 1.6em">
                            <div v-if="resolucion()">
                              <b v-if="item.configuracion.cantidad" class="count-dynamic">{{
                                returnQuantityService(item.configuracion)
                              }}</b>
                              {{ item.nombre }}

                              <button
                                alt="Editar producto del carrito" aria-label="Editar producto del carrito"
                                v-if="item.nivel == 1 && !item.producto"
                                @click="editItem(item.pos_cart)"
                                class="btn btn-link btn-xs fix-btn-table-c-v ml-1 btn-color-edit-v"
                              >
                                <i class="fa fa-pencil-square-o"></i>
                                Editar
                              </button>

                              <button
                                alt="eliminar producto del carrito" aria-label="eliminar producto del carrito"
                                v-if="item.nivel == 1"
                                @click="setSelected(item.pos_cart)"
                                data-toggle="modal"
                                data-target="#myModal"
                                class="btn btn-link btn-xs fix-btn-table-c-v btn-color-delete-v"
                              >
                                <i class="fas fa-times"></i>
                                Eliminar
                              </button>
                            </div>
                            <div v-else>
                              <b v-if="item.configuracion.cantidad" class="count-dynamic">{{
                                returnQuantityService(item.configuracion)
                              }}</b>
                              {{ item.nombre }}

                              <button
                                v-if="item.nivel == 1 && !item.producto"
                                alt="editar producto del carrito" aria-label="editar producto del carrito"
                                @click="editItem(item.pos_cart)"
                                class="btn btn-link btn-xs fix-btn-table-c-v ml-1 btn-color-edit-v"
                              >
                                <i class="fa fa-pencil-square-o"></i>
                              </button>

                              <button
                                v-if="item.nivel == 1"
                                alt="eliminar producto del carrito" aria-label="eliminar producto del carrito"
                                @click="setSelected(item.pos_cart)"
                                data-toggle="modal"
                                data-target="#myModal"
                                class="btn btn-link btn-xs fix-btn-table-c-v btn-color-delete-v box_service_btn_deleteService"
                              >
                                <i class="fas fa-times"></i>
                              </button>
                            </div>
                          </div>
                        </span>

                        <span v-if="item.subcategory_" class="item-group">{{
                          item.subcategory_.nombre
                        }}</span>
                        <span v-else class="item-group"> Complemento </span>

                        <div v-if="!item.configuracion.cantidad && !item.producto" class="mt-1">
                          <div v-for="(configuracion, name) in item.configuracion" :key="name">
                            <span class="item-domain" style="color: var(--primary-color)">
                              {{ configuracion.nombre }}: {{ configuracion.value }}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-12 col-lg-12 px-0 pt-pricebox-info price-card-new price-cont pr-3">
                        <h6 class="price" style="text-align: right">
                          <span
                            >${{
                              formatNumber(
                                setPriceService(item.moneda, item.precio * returnCantidadService(item))
                              )
                            }}
                          </span>
                        </h6>
                        <p class="pt-price-duration p-price-duration-v">
                          CLP / {{ item.periodicidad.nombre }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="empty-cart">
                  <button
                    v-if="cart.length != 0"
                    alt="eliminar todos los productos del carrito" aria-label="eliminar todos los productos del carrito"
                    type="button"
                    class="btn btn-link btn-xs btn-empty-v"
                    style="line-height: 25px !important"
                    id="btnEmptyCart"
                    data-toggle="modal"
                    data-target="#deleteAllModal"
                  >
                    <i class="fas fa-trash-alt icon-empty-v"></i>
                    <span>Vaciar Carro</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div v-else>
            <hr />
            <h5 style="font-size: 14px; letter-spacing: 1px; text-transform: uppercase; text-align: center">
              Configure al menos un servicio para continuar
            </h5>
            <a
              v-if="type == 2"
              href="/cart"
              class="btn btn-default-grad-purple-fill white-font btn-default-grad-purple-fill-black mt-3"
              style="width: 100%; height: 52px; padding-top: 13px"
            >
              Servicios <i class="fas fa-arrow-alt-circle-right"></i>
            </a>
          </div>
        </div>

        <div
          v-if="cart.length > 0"
          class="col-md-12 price-total-table px-0"
          style="padding-top: 5px !important"
        >
          <h6 style="font-size: 16px; white-space: nowrap">
            <b
              >NETO: ${{
                formatNumber(
                  Math.round(setMasterTotalPriceService(cart) - (setMasterTotalPriceService(cart) / 100) * 19)
                )
              }}</b
            >
          </h6>
          <h6 style="font-size: 16px; white-space: nowrap">
            <b>IVA: ${{ formatNumber(Math.round((setMasterTotalPriceService(cart) / 100) * 19)) }}</b>
          </h6>
        </div>
        <div
          v-if="cart.length > 0"
          class="col-md-12 price-total-table px-0"
          style="padding-top: 15px !important"
        >
          <h6 style="font-size: 28px; white-space: nowrap">
            <b>${{ formatNumber(setMasterTotalPriceService(cart)) }}</b>
          </h6>
        </div>

        <button
          v-if="cart.length != 0"
          alt="Activar Servicios" aria-label="Activar Servicios"
          @click="next()"
          class="btn btn-default-grad-purple-fill white-font btn-default-grad-purple-fill-black mt-3"
          style="width: 100%; height: 52px"
        >
          <span v-if="cart.length > 1">Activar Servicios</span> <span v-else>Activar Servicio</span>
          <i class="fas fa-arrow-alt-circle-right ml-2" style="top: 2px; position: relative"></i>
        </button>

        <div v-if="$route.name != 'Cart' && cart.length != 0" class="col-md-12 px-0 container-sc-v">
          <a href="/cart" class="seguir-comprando-btn mt-2 pt-2">Nuestros servicios</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import {
  formatNumber,
  toArray,
  setPriceService,
  setTotalPriceService,
  setMasterTotalPriceService,
  returnQuantityService,
  returnAllServices,
  returnCantidadService,
  resolucion,
} from "@/helpers";
export default {
  props: ["cart", "redirect", "type"],
  data: () => ({
    service_active: false,
    notaid: null
  }),
  computed: {
    ...mapGetters(["getResume", "getStatus"]),
  },
  created() {
    this.notaid = null;
    if (this.getResume.length > 0) {
      this.checkNotaCobros(this.getResume).then((res) => {
        if (res.data && res.data.data == true) {
          this.service_active = true;
          if(this.getResume.length == 1) this.notaid = this.getResume[0].notacobro.id
        }
      });
    }
  },
  methods: {
    ...mapMutations(["setSelected"]),
    ...mapActions(["checkNotaCobros"]),
    formatNumber,
    toArray,
    setPriceService,
    setTotalPriceService,
    setMasterTotalPriceService,
    returnQuantityService,
    returnAllServices,
    returnCantidadService,
    resolucion,
    next() {
      this.$emit("next");
    },
    editItem(index) {
      this.$emit("edit", index);
    },
    async checkResume(ncobros) {
      return this.checkNotaCobros(ncobros);
    },
  },
};
</script>

<style scoped>
.icon-category {
  top: 4px !important;
  position: relative !important;
  height: 30px !important;
  cursor: pointer !important;
}

.pos-icon-cart {
  right: 0px;
  position: absolute !important;
}
.box_service{
  position: relative;
}

.box_service_btn_deleteService{
    position: absolute;
    top: 0;
    right: 0;
    margin: 1px 10px;
    z-index: 1000;

}
</style>
