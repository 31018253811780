<template>
    <main class="huaweiCloud">
        <section class="huaweiCloud_banner">
            <BannerMain :contentBanner="contentBanner"/>
            <div class="nubesComponent">
                <nubes/>
            </div>
        </section>

        <section class="huaweiCloud_contact">
            <div class="huaweiCloud_contact_container">
                <div class="titleBx">
                    <h2 class="titleBx_text">Solicita la llamada de un <span class="titleBx_text_colorAltern">Especialista en Nubes</span></h2>
                </div>
    
                <form @submit.prevent="validNoRobot()" class="huaweiCloud_contact_form">
                    <div>
                        <input v-model="message.nombre" class="huaweiCloud_contact_form_input" type="text" placeholder="Tú nombre">
                        <input v-model="message.telefono" class="huaweiCloud_contact_form_input" type="number" placeholder="Teléfono">
                    </div>
                    <div class="huaweiCloud_contact_turnstile">
                        <!-- Turnstile cloud -->
                        <div id="contactShortTurnstile"></div>
                    </div>
                    <button type="submit" class="huaweiCloud_contact_form_btn">Enviar</button>
                </form>

            </div>
        </section>

        <section class="huaweiCloud_infoService">
            <div class="huaweiCloud_infoService_container">
                <div class="huaweiCloud_infoService_container_imgBx">
                    <img class="huaweiCloud_infoService_container_imgBx_img" src="images/services/huawei_cloud/huaweicloud.webp" alt="">
                </div>

                <div class="huaweiCloud_infoService_container_title">
                    <p class="huaweiCloud_infoService_container_title_text"> 
                        En ihosting, estamos comprometidos con ofrecerte las soluciones más avanzadas y eficientes en tecnología y alojamiento TI. Ahora, con nuestro servicio de Huawei Cloud, te brindamos la potencia y flexibilidad de una de las plataformas de nube más innovadoras del mundo, junto con nuestro soporte experto y atención personalizada.
                    </p>
                </div>
            </div>
            <div class="nubesComponent">
                <nubes :styleNubes="{color: '#C80016'}"/>
            </div>
        </section>

        <section class="huaweiCloud_benefits">
            <div class="huaweiCloud_benefits_container">
                <div class="titleBx">
                    <h2 class="titleBx_text" style="color: white;">Beneficios de Huawei Cloud</h2>
                </div>

                <div class="huaweiCloud_benefits_container_boxes">

                    <div class="huaweiCloud_benefits_container_boxes_card">
                        <div class="huaweiCloud_benefits_container_boxes_card_head">
                            <img class="huaweiCloud_benefits_container_boxes_card_head_img" src="images/services/huawei_cloud/benefit/icono1.webp" alt="">
                            <h4 class="huaweiCloud_benefits_container_boxes_card_head_title">Infraestructura de Clase Mundial</h4>
                        </div>
                        <div class="huaweiCloud_benefits_container_boxes_card_body">
                            <p class="huaweiCloud_benefits_container_boxes_card_body_p">Huawei Cloud ofrece una
                                infraestructura robusta y segura, diseñada para soportar las aplicaciones y cargas de trabajo más exigentes.</p>
                        </div>
                    </div>
                    <div class="huaweiCloud_benefits_container_boxes_card">
                        <div class="huaweiCloud_benefits_container_boxes_card_head">
                            <img class="huaweiCloud_benefits_container_boxes_card_head_img" src="images/services/huawei_cloud/benefit/icono2.webp" alt="">
                            <h4 class="huaweiCloud_benefits_container_boxes_card_head_title">Escalabilidad y
                                Flexibilidad</h4>
                        </div>
                        <div class="huaweiCloud_benefits_container_boxes_card_body">
                            <p class="huaweiCloud_benefits_container_boxes_card_body_p"> Adapta los recursos de la nube a las necesidades de tu negocio, con la capacidad de escalar hacia arriba o hacia abajo según lo requieras.</p>
                        </div>
                    </div>
                    <div class="huaweiCloud_benefits_container_boxes_card">
                        <div class="huaweiCloud_benefits_container_boxes_card_head">
                            <img class="huaweiCloud_benefits_container_boxes_card_head_img" src="images/services/huawei_cloud/benefit/icono3.webp" alt="">
                            <h4 class="huaweiCloud_benefits_container_boxes_card_head_title">Innovación Continua</h4>
                        </div>
                        <div class="huaweiCloud_benefits_container_boxes_card_body">
                            <p class="huaweiCloud_benefits_container_boxes_card_body_p"> Aprovecha las últimas tecnologías en inteligencia artificial, big data y más, con actualizaciones y mejoras constantes.</p>
                        </div>
                    </div>
                    <div class="huaweiCloud_benefits_container_boxes_card">
                        <div class="huaweiCloud_benefits_container_boxes_card_head">
                            <img class="huaweiCloud_benefits_container_boxes_card_head_img" src="images/services/huawei_cloud/benefit/icono4.webp" alt="">
                            <h4 class="huaweiCloud_benefits_container_boxes_card_head_title">Costo x Efectividad</h4>
                        </div>
                        <div class="huaweiCloud_benefits_container_boxes_card_body">
                            <p class="huaweiCloud_benefits_container_boxes_card_body_p"> Optimiza tus costos operativos con una plataforma que ofrece un rendimiento superior a
                                precios competitivos.</p>
                        </div>
                    </div>
                    <div class="huaweiCloud_benefits_container_boxes_card">
                        <div class="huaweiCloud_benefits_container_boxes_card_head">
                            <img class="huaweiCloud_benefits_container_boxes_card_head_img" src="images/services/huawei_cloud/benefit/icono5.webp" alt="">
                            <h4 class="huaweiCloud_benefits_container_boxes_card_head_title">
                                Conectividad Global
                            </h4>
                        </div>
                        <div class="huaweiCloud_benefits_container_boxes_card_body">
                            <p class="huaweiCloud_benefits_container_boxes_card_body_p"> Beneficiate de la global de Huawei, con centros de datos distribuidos estratégicamente para asegurar la mejor atencia y disponibilidad.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="huaweiCloud_why">
            <div class="huaweiCloud_why_container">
                <div class="titleBx">
                    <h2 class="titleBx_text"> ¿Por qué elegir <span class="titleBx_text_colorAltern">ihosting</span> como tu proveedor Cloud?  </h2>
                </div>
                <div class="huaweiCloud_why_container_cardsBx">
                    <div v-for="(contentCard, index) in contentCardWhy" :key="index">
                        <CardFullWidth :contentCard="contentCard"/>
                    </div>
                </div>
            </div>
            <div class="nubesComponent">
                <nubes :styleNubes="{color: '#C80016'}"/>
            </div>
        </section>

        <section class="huaweiCloud_services">
            <div class="huaweiCloud_services_container">
                <div class="titleBx">
                    <h2 class="titleBx_text" style="color: white;">Servicios</h2>
                    <p class="titleBx_paragraph" style="color: white; margin-top: 20px;">
                        Si te encuentras en transición hacia la transformación digital o si necesitas recibir una gran cantidad de visitas en un corto periodo de tiempo, la infraestructura de Huawei Cloud te ayuda a crear de manera rápida, escalable y segura.
                    </p>
                </div>

                <div class="huaweiCloud_services_container_cards">
                    <article class="huaweiCloud_services_container_cards_cardBx">
                        <div class="huaweiCloud_services_container_cards_cardBx_imgBx">
                            <img class="huaweiCloud_services_container_cards_cardBx_imgBx_img" src="images/services/huawei_cloud/services/icono1.webp" alt="">
                        </div>
                        <div class="huaweiCloud_services_container_cards_cardBx_textBx">
                            <h4 class="huaweiCloud_services_container_cards_cardBx_textBx_title">Asesoría</h4>
                            <p class="huaweiCloud_services_container_cards_cardBx_textBx_p">Desarrollo de estructuras.</p>
                        </div>
                    </article>
                    <article class="huaweiCloud_services_container_cards_cardBx">
                        <div class="huaweiCloud_services_container_cards_cardBx_imgBx">
                            <img class="huaweiCloud_services_container_cards_cardBx_imgBx_img" src="images/services/huawei_cloud/services/icono2.webp" alt="">
                        </div>
                        <div class="huaweiCloud_services_container_cards_cardBx_textBx">
                            <h4 class="huaweiCloud_services_container_cards_cardBx_textBx_title">Migración</h4>
                            <p class="huaweiCloud_services_container_cards_cardBx_textBx_p">Llevamos tu plataforma a la nube sin perjudicar la productividad de tu negocio.</p>
                        </div>
                    </article>
                    <article class="huaweiCloud_services_container_cards_cardBx">
                        <div class="huaweiCloud_services_container_cards_cardBx_imgBx">
                            <img class="huaweiCloud_services_container_cards_cardBx_imgBx_img" src="images/services/huawei_cloud/services/icono3.webp" alt="">
                        </div>
                        <div class="huaweiCloud_services_container_cards_cardBx_textBx">
                            <h4 class="huaweiCloud_services_container_cards_cardBx_textBx_title">Administración</h4>
                            <p class="huaweiCloud_services_container_cards_cardBx_textBx_p">Desplegamos tu plataforma y servicios en Huawei Cloud.</p>
                        </div>
                    </article>
                    <article class="huaweiCloud_services_container_cards_cardBx">
                        <div class="huaweiCloud_services_container_cards_cardBx_imgBx">
                            <img class="huaweiCloud_services_container_cards_cardBx_imgBx_img" src="images/services/huawei_cloud/services/icono4.webp" alt="">
                        </div>
                        <div class="huaweiCloud_services_container_cards_cardBx_textBx">
                            <h4 class="huaweiCloud_services_container_cards_cardBx_textBx_title">Soporte</h4>
                            <p class="huaweiCloud_services_container_cards_cardBx_textBx_p">Servicio de soporte directo y exclusivo.</p>
                        </div>
                    </article>
                </div>

            
            </div>
        </section>

        <!-- <section class="huaweiCloud_promo">
            <div class="huaweiCloud_promo_container">
                <div class="huaweiCloud_promo_container_textBx">
                    <h2 class="huaweiCloud_promo_container_textBx_title" >Empieza hoy con <span class="huaweiCloud_promo_container_textBx_title_color">Huawei Cloud</span> e ihosting</h2>
                    <p class="huaweiCloud_promo_container_textBx_p">No esperes más para transformar tu negocio con la nube de Huawei y el soporte experto de ihosting. Contáctanos para una consulta gratuita y descubre cómo podemos ayudarte a alcanzar tus objetivos tecnológicos.</p>
                </div>
                
                <div class="huaweiCloud_promo_container_imgBx">
                    <img class="huaweiCloud_promo_container_imgBx_img" src="images/services/huawei_cloud/logos/logosx.webp" alt="">
                </div>

            </div>
        </section> -->

        <section id="huaweiForm" class="huaweiCloud_form">
            <!-- <div class="bannerMain_bg">
                <img class="bannerMain_bg_img" src="images/services/huawei_cloud/banner/bannerbg.png"  alt="">
            </div> -->
            <div class="huaweiCloud_form_container" >
                <ContactSimply :idForm="formContact.idForm"  :reverse="false" :dataText="formContact.dataTextForm" :ViewDataForm="formContact.viewDataForm" />
            </div>
        </section>

        <section class="huawei_proceso">
            <div class="huawei_proceso_container">
                <div class="huawei_proceso_listBx">
                    <listDynamic :dataTitleBox="dataTitleList" :dataList="dataList" :styleList="{direction: 'column', background_special_list: '#C80016'}"/>
                </div>
            </div>
        </section>

        <!-- <section class="huawei_footer">
            <div class="huawei_footer_container">
                <div class="titleBx">
                    <h2 class="titleBx_text">Desarrolla tu Proyecto Cloud junto a <span class="titleBx_text_colorAltern">ihosting</span> </h2>
                </div>
                <div class="huawei_footer_container_imgBx">
                    <img class="huawei_footer_container_imgBx_img" src="images/services/huawei_cloud/logosxgrande.png" alt="huaweiCloud_ihosting">
                </div>
            </div>
        </section> -->

    </main>
</template>

<script>
import BannerMain from '@/components/web/banners/BannerMain.vue';
import CardFullWidth from '@/components/web/cards/CardFullWidth.vue';
import nubes from '../../../components/web/nubes.vue';
import ContactSimply from "../../../components/web/forms/contactSimply.vue";
import listDynamic from "@/components/web/listDynamic.vue";
import TurnstileCloud from "@/hooks/segurity/turnstile";
import { mapActions } from "vuex";

    export default {
        components: {
            BannerMain,
            nubes,
            CardFullWidth,
            ContactSimply,
            listDynamic
        },
        data: ()=>({
            contentBanner: {
                title: 'Más de 20 años entregando tranquilidad y seguridad a nuestros clientes',
                btnText: '¡Cotiza con nosotros!',
                btnUrl: '#huaweiForm',
                head_logo: 'images/services/huawei_cloud/banner/icono2.webp',
                image_main: 'images/services/huawei_cloud/banner/icono1.webp',
                bg: 'images/services/huawei_cloud/banner/bannerbg.png'

            },
            contentCardWhy: [
                {
                    title: 'Soporte y Atención en Español',
                    paragraph: 'Nuestro equipo de expertos está disponible para ofrecerte soporte en tu idioma, asegurando una comunicación clara y efectiva.',
                    icon: 'images/services/huawei_cloud/steep/icono1.webp',
                    color_title: "#C80016"
                },
                {
                    title: 'Facturación en Moneda Local',
                    paragraph: 'Factura en tu moneda local y recupera el IVA de la operación, simplificando tu contabilidad y reduciendo costos fiscales.',
                    icon: 'images/services/huawei_cloud/steep/icono2.webp',
                    color_title: "#C80016"
                },
                {
                    title: 'Más de 20 Años de Experiencia',
                    paragraph: 'Con más de dos décadas liderando proyectos TI en Chile, Perú y Colombia, ihosting tiene la experiencia y conocimiento para ofrecerte un servicio de calidad superior, además de la tranquilidad y seguridad que estás buscando.',
                    icon: 'images/services/huawei_cloud/steep/icono3.webp',
                    color_title: "#C80016"
                },
                {
                    title: 'Acompañamiento',
                    paragraph: '¡No estarás solo! Te acompañaremos en el proceso de contratación, migración y optimización de tu Nube.',
                    icon: 'images/services/huawei_cloud/steep/icono4.webp',
                    color_title: "#C80016"
                },

            ],

            formContact:{
                dataTextForm:{
                    title: 'Empieza hoy con Huawei Cloud e ihosting',
                    subtitle: 'No esperes más para transformar tu negocio con la nube de Huawei y el soporte experto de ihosting. Contáctanos para una consulta gratuita y descubre cómo podemos ayudarte a alcanzar tus objetivos tecnológicos.'
                },
                viewDataForm:{
                    stateInputs:{
                            nombre: true,
                            email: true,
                            telefono: true,
                            asunto: false,
                            motivo: false,
                            mensaje: true,
                        },
                    asunto: 'Consulta ihosting X Huawei', 
                    motivo: 'Consulta comercial',
                    colorMain: '#C80016',
                    colorTextTitle: 'white'
                },
                idForm: 'huaweiCloud'
            },
            dataTitleList: {
                title: 'Proceso de Implementación',
                description: '¡La flexibilidad es parte del crecimiento! Queremos acompañarte en cada paso, es por eso que contamos con plataformas flexible y planes.',
                color: '#C80016',
            }, 
            dataList:{
                dataListServiceInfo:[
                    {title: 'Reunión inicial', 'description': 'Reunión inicial para el levantamiento de necesidades En esta primera etapa, nos reunimos contigo para comprender tus necesidades específicas y objetivos empresariales. Escuchamos atentamente para identificar los desafíos que enfrenta tu organización y cómo una solución en la nube puede ayudarte a superarlos. Este paso es crucial para garantizar que diseñemos una solución que se ajuste perfectamente a tus requisitos.', img: '/images/services/procesoImplementacion/ReuniónInicial.webp' },
                    {title: 'Desarrollo de Estructura ', description: 'Desarrollo de estructura. Hacer cálculos y poder optimizar costos. Una vez que comprendemos tus necesidades, nuestro equipo de expertos en Nubes se pone manos a la obra para diseñar la estructura de la solución en la nube. Realizamos cálculos detallados para asegurar que la propuesta sea eficiente y rentable. Analizamos diferentes opciones para optimizar costos, garantizando que obtengas el máximo valor por tu inversión.', img: '/images/services/procesoImplementacion/DesarrollodeEstructura.webp' },
                    {title: 'Cotización Optimizada', description: 'Cotización optimizada y presentación de solución. Con la estructura y los costos optimizados, preparamos una cotización detallada que refleja la mejor solución para tu negocio. Te presentamos esta propuesta de manera clara y transparente, explicando cada componente y su beneficio. Nos aseguramos de que comprendas completamente cómo nuestra solución de Nubes resolverá tus desafíos y mejorará tus operaciones.', img: '/images/services/procesoImplementacion/CotizaciónOptimizada.webp' },
                    {title: 'Onboarding ', description: 'Onboarding y configuración de propuesta. Una vez aceptada la propuesta, iniciamos el proceso de onboarding, guiándote a través de cada paso para configurar y desplegar la solución en Nubes. Nuestro equipo te asistirá en la migración de datos, la configuración de aplicaciones y la integración con tus sistemas existentes. Nos aseguramos de que la transición sea fluida y sin interrupciones para tu negocio.', img: '/images/services/procesoImplementacion/Onboarding.webp'},
                    {title: 'Administración de la Nube ', description: 'Administración de la Nube. Después del onboarding, no te dejamos solo. Ofrecemos un servicio continuo de administración de la nube para garantizar que tu solución en la Nube funcione de manera óptima. Monitoreamos el rendimiento, gestionamos actualizaciones y te asistimos con cualquier problema que pueda surgir. Nuestro objetivo es que puedas concentrarte en tu negocio mientras nosotros nos encargamos de la tecnología.', img: '/images/services/procesoImplementacion/AdministracióndelaNube.webp'},
                ],
                expande: true
            },
            message: {
                nombre: '',
                email: 'No disponible',
                telefono: '',
                asunto: 'Solicitú de la llamada de un Especialista en Nubes',
                motivo: 'Consulta ihosting X Huawei',
                mensaje: 'Hola, por favor solicito la ayuda de un especialista.',
                robot: false,

            },
        }),
        methods:{
            ...mapActions(["sendMessage"]),
            validNoRobot() {
            // estado que verifica si ya se recibió el token de cloudflare
            if (TurnstileCloud.getToken == null) {
                this.$toast.error("Terminando de verificar que no eres un robot, intenta nuevamente.");
                this.$Progress.fail();

            } else {
                console.log("user valido: 000, " , TurnstileCloud.getToken )
                this.message.robot = true;
                this.submitContact();
            }
            },

            submitContact(){
                try {
                    if (process.env.VUE_APP_ACTIVE_TURNSTILE == 'true') this.message.tokenTurnstile = TurnstileCloud.getToken() // se agrega token para que lo validen
                    this.$Progress.start();
                    this.sendMessage(this.message).then((res) => {
                        if (res.data?.status == "success") {
                            this.$toast.success("Mensaje enviado con exito! te contactaremos a la brevedad.");
                            this.$Progress.finish();
                            this.clearForm();
                        } else if (res.data?.status == "error") {
                            this.$toast.error(res.data.message);
                            this.$Progress.fail();
                        } else {
                            this.$toast.error("Error en los campos ingresados");
                            this.$Progress.fail();
                        }
                        TurnstileCloud.resetTurnstile();


                    }).catch(error => {
                        console.log("sendMsg: response: ", response);
                        TurnstileCloud.resetTurnstile();

                    });
                } catch (error) {
                    
                }
                }
        },
        mounted() {
            setTimeout(() => {
                if (process.env.VUE_APP_ACTIVE_TURNSTILE == 'true') {
                    TurnstileCloud.activeTurnstileCloud("contactShortTurnstile");
                }
            }, 1000);
        },
        beforeDestroy() {
            // console.log("se ejecuta destroy en huaweiCloud");
            // si el usuario se redirecciona a otra pagina, se borra todo elemento relacionado al turnstile
            TurnstileCloud.removeTurnstileCloud("contactShortTurnstile");
        },

    }

</script>

<style scoped>
/* banner ---- */
.huaweiCloud_banner{
    position: relative;
}

.nubesComponent{
  position: absolute;
  left: 0;
  bottom: -75px;
}


/* title bx ---*/
.titleBx{
    width: 100%;
    max-width: 1000px;
    text-align: center;
    margin: 20px 0px;

}

.titleBx_text{
    font-size: 42px;
}

.titleBx_text_colorAltern{
    color: #C80016;
}



/*  contact ---- */
.huaweiCloud_contact{
    height: 50vh;
    max-height: 500px;
    padding: 50px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.huaweiCloud_contact_container{
    width: 100%;
    max-width: 1400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.huaweiCloud_contact_form{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.huaweiCloud_contact_form_input{
    border: 2px solid #C80016;
    border-radius: 4px;
    width: 100%;
    height: 42px;
    max-width: 180px;
    padding: 10px 20px;
    margin: 0px 8px;
    font-size: 14px;
    font-weight: 500;
}

.huaweiCloud_contact_form_btn{
    min-width: 120px;
    height: 42px;
    border: 0px;
    padding: 10px 15px;
    background: #C80016;
    color: white;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0px 8px;

}

.huaweiCloud_contact_turnstile{
    border: 0px;
    margin: 0px;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0px;
}


/* INFO HUAWEI --- */
.huaweiCloud_infoService{
    position: relative;
    height: 60vh;
    min-height: 400px;
    max-height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 0px 200px;
}

.huaweiCloud_infoService_container{
    max-width: 1400px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.huaweiCloud_infoService_container_imgBx{
    margin: 0 20px;
    width: 50%;
    max-width: 400px;
    display: flex;
    align-items: center;

}

.huaweiCloud_infoService_container_imgBx_img{
    height: 300px;
}

.huaweiCloud_infoService_container_title{
    width: 50%;
}

.huaweiCloud_infoService_container_title_text{
    font-size: 15px;
    font-weight: 500;
}

/* benefit */
.huaweiCloud_benefits{
    min-height: 70vh;
    background: #C80016;
    display: flex;
    align-items: center;
    justify-content: center;
}


.huaweiCloud_benefits_container{
    max-width: 1400px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.huaweiCloud_benefits_container_boxes{
    display: flex;
}

.huaweiCloud_benefits_container_boxes_card{
    max-width: 250px;
    padding: 20px 25px;
    background: white;
    border-radius: 8px;
    margin: 0px 10px;
    max-height: 350px;
    box-shadow: 4px 11px 15px 0px #0000000f;
}

.huaweiCloud_benefits_container_boxes_card_head{
    /* max-height: ; */
}

.huaweiCloud_benefits_container_boxes_card_head_img{
    height: 55px;
    margin-bottom: 20px;
}

.huaweiCloud_benefits_container_boxes_card_head_title{
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 20px;
    font-weight: 700;
    min-height: 52px;
    color: #C80016;
}

.huaweiCloud_benefits_container_boxes_card_body_p{
    font-size: 14px;
    font-weight: 500;
}


/*  section why --------------- */

.huaweiCloud_why{
    position: relative;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 80px 0px 150px;
}

.huaweiCloud_why_container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* max-width: 1400px; */
}


/*  section services --------------------- */

.huaweiCloud_services{
    position: relative;
    min-height: 65vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #C80016;
}
.huaweiCloud_services_container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}
.huaweiCloud_services_container_cards{
    width: 100%;
    max-width: 1400px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.huaweiCloud_services_container_cards_cardBx{
    max-width: 340px;
    min-width: 200px;
    width: 28%;
    height: auto;
    min-height: 155px;
    background: white;
    padding: 25px 20px 20px;
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    border-radius: 8px;
    box-shadow: -1px 3px 8px 4px #0000000f;
}
.huaweiCloud_services_container_cards_cardBx_imgBx{
    display: flex;
    /* align-items: center; */
    height: 100%;
}
.huaweiCloud_services_container_cards_cardBx_imgBx_img{
    height: 50px;
}
.huaweiCloud_services_container_cards_cardBx_textBx{
    margin-left: 10px;
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    min-width: 70%;
}

.huaweiCloud_services_container_cards_cardBx_textBx_title{
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 5px;
}

.huaweiCloud_services_container_cards_cardBx_textBx_p{
    font-size: 15px;
    font-weight: 500;
    margin: 0px;
    line-height: 25px;
}


/* promo section ------------------ */
.huaweiCloud_promo{
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.huaweiCloud_promo_container{
    display: flex;
    width: 100%;
    max-width: 1100px;
    justify-content: space-between;
}

.huaweiCloud_promo_container_imgBx{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.huaweiCloud_promo_container_imgBx_img{
    max-width: 350px;
}

.huaweiCloud_promo_container_textBx{
    max-width: 500px;
}

.huaweiCloud_promo_container_textBx_title{
    font-size: 35px;
}

.huaweiCloud_promo_container_textBx_title_color{
    color: #C80016;
}
.huaweiCloud_promo_container_textBx_p{
    font-size: 14px;
    font-weight: 500;
    line-height: 25px;
}


/* form section -------- */

.huaweiCloud_form{
    position: relative;
    background: #C80016
}
.huaweiCloud_form_container{
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-image: url('../../../../public/images/services/huawei_cloud/banner/bannerbg.png'); */
}


/* .bannerMain_bg{
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        width: 100%;
        height: 100%;
    }

    .bannerMain_bg_img{
        width: 100%;
        height: 100%;
        opacity: 0.5;
        background-repeat: no-repeat;
    } */


/* section proceso --------------------- */

.huawei_proceso{
    min-height: 100vh;
    display: flex;
    justify-content: center;
    padding: 100px 40px;
}


/*  section footer  --------------------*/
/* .huawei_footer{
    min-height: 45vh;
    display: flex;
    justify-content: center;
}

.huawei_footer_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    max-width: 80%;
}

.huawei_footer_container_imgBx{
    max-width: 900px;
}

.huawei_footer_container_imgBx_img{
    width: 100%;
    min-height: 220px;
    display: flex;
    justify-content: center;
} */
@media (max-width: 1400px){
    /* title bx ---*/
.titleBx{
    max-width: 800px;
}

.titleBx_text{
    font-size: 32px;
}

/*  contact ---- */

.huaweiCloud_contact_container{
    max-width: 1100px;
}

/* INFO HUAWEI --- */
.huaweiCloud_infoService_container{
    max-width: 1100px;
}

.huaweiCloud_infoService_container_imgBx_img{
    height: 260px;
}

/* benefit section ---- */
.huaweiCloud_benefits{
    min-height: 92vh;
}
.huaweiCloud_benefits_container_boxes_card{
    max-width: 220px;
    max-height: 310px;
    margin: 0px 5px;
}
.huaweiCloud_benefits_container_boxes_card_head_title{
    font-size: 18px;
    margin-bottom: 12px;
}

.huaweiCloud_benefits_container_boxes_card_body_p{
    font-size: 12px;
    font-weight:500;
}

.huaweiCloud_contact{
    height: 70vh;
}


/* services section ----- */

.huaweiCloud_services{
    min-height: 80vh;
}
.huaweiCloud_services_container_cards{
    width: 100%;
    max-width: 1050px;
}
.huaweiCloud_services_container_cards_cardBx{
    width: 25%;
    max-width: 255px;
    min-height: 125px;
    height: auto;
    padding: 20px 20px 10px;
}
.huaweiCloud_services_container_cards_cardBx_textBx_title{
    font-size: 18px;
}
.huaweiCloud_services_container_cards_cardBx_textBx_p{
    font-size: 12px;
    /* font-weight: 700; */
    line-height: 20px;
}

/* promo section */
.huaweiCloud_promo_container_textBx_title{
    font-size: 28px;
}

.huaweiCloud_promo_container{
    max-width: 900px;
}
.huaweiCloud_promo_container_textBx{
    max-width: 400px;
}
/* huawei proceso  ---------- */
.huawei_proceso{
    padding: 100px 40px 100px;
}

/* footer huawei */
/* .huawei_footer_container_imgBx_img{
    min-height: 180px;
} */
}

@media (max-width: 600px){
    /*  contact ---- */
    .huaweiCloud_contact{
        padding: 50px 0px 0px;
    }
    .huaweiCloud_contact_container{
        max-width: 90%;
    }

    .huaweiCloud_contact_form{
        flex-direction: column;
        width: 90%;
    }
    .huaweiCloud_contact_form_input{
        max-width: 90%;
        margin: 5px 0px;
    }
    .huaweiCloud_contact_form_btn{
        width: 90%;
        margin: 10px 0px;
    }

    /* title bx ----------- */

    .titleBx_text{
        font-size: 25px;
    }

    /* info section ---------- */
    .huaweiCloud_infoService{
        max-height: 100vh;
        height: auto;
        min-height: 80vh;
        padding: 70px 0px 200px;
    }

    .huaweiCloud_infoService_container{
        flex-direction: column;
    }
    .huaweiCloud_infoService_container_imgBx{
        width: 95%;
        justify-content: center;
        margin-bottom: 20px;
    }
    .huaweiCloud_infoService_container_imgBx_img{
        height: 160px;
    }
    .huaweiCloud_infoService_container_title{
        width: 85%;
        text-align: center;
    }

    /* benefit section -------- */
    .huaweiCloud_benefits{
        height: auto;
        min-height: 100vh;
        padding: 40px 0px;
    }
    .huaweiCloud_benefits_container_boxes{
        flex-direction: column;
        gap: 20px;
    }

    /*  why section ------------ */
    .huaweiCloud_why_container{
        max-width: 90%;
    }

    /* services section ----------- */
    .huaweiCloud_services_container{
        max-width: 90%;
        padding: 35px 0px;
    }
    .huaweiCloud_services_container_cards{
        justify-content: center;
    }
    .huaweiCloud_services_container_cards_cardBx{
        max-width: 90%;
        width: 100%;
        margin: 10px 0px;
    }
    .huaweiCloud_promo_container{
        max-width: 90%;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    /* huawei proceso  ---------- */
    .huawei_proceso{
        padding: 100px 40px 20px;
    }
    /* footer huawei */
    /* .huawei_footer_container_imgBx_img{
        min-height: auto;
    } */
}
</style>