<template>
  <section class="ssdMain_services" :class="{'bannerColumn': dataBannerSlider.directionElement}" :style="`padding: ${styleSection.padding} !important`">
    <div class="ssdMain_services_backgroundOverlay" style="background-image: url(images/backgrounds/backgroundDecoratino.jpg)"></div>
      
    <!-- child TEXT -->
      <div class="ssdMain_services_text wow animate__animated animate__fadeInLeft" :class="{'ssdMain_services_text_center': !dataBannerSlider.directionElement}">
        <h2 class="ssdMain_services_text_title">
          {{ dataBannerSlider.title }} <span class="ssdMain_services_text_title_bold">{{ dataBannerSlider.titleBold }}</span>
        </h2>
        <p class="ssdMain_services_text_description">
          {{ dataBannerSlider.subtitle }}
        </p>

        <button v-if="dataBannerSlider.btn" class="btnShodow" >
          <a class="btnShodow_link" :href="dataBannerSlider.btn.url">
            {{ dataBannerSlider.btn.title }}
          </a>
        </button>

        <ul class="ssdMain_services_text_list">
          <li v-for="(item,index) in dataBannerSlider.listText" :key="index" class="ssdMain_services_text_list_item">
            <i aria-hidden="true" class="fa fa-check ssdMain_services_text_list_item_icon"></i>
            <span class="ssdMain_services_text_list_item_text">
              {{ item }}
            </span>
          </li>
        </ul>
      </div>

    <!-- child SLOT -->
      <div class="ssdMain_services_contentInfo" :class="{'ssdMain_services_contentInfo_full': !dataBannerSlider.directionElement}">
        <slot>
          <!--  componente a agregar -->
        </slot>
      </div>
      <div class="ripple-background">
              <div class="circle xxlarge shade1"></div>
              <div class="circle xlarge shade2"></div>
              <div class="circle large shade3"></div>
              <div class="circle mediun shade4"></div>
              <div class="circle small shade5"></div>
      </div>


    <div class="nubesComponent">
        <nubes/>
    </div>
  </section>
</template>

<script>
import nubes from "@/components/web/nubes.vue";

export default {
  components: {
    nubes
  },
  props:{
    dataBannerSlider:{
      default: ()=>({})
    },
    styleSection:{
      default: ()=>({
        padding: 'padding: 100px 90px 30px'
      })
    }
  },

};
</script>

<style scoped>


.ssdMain_services {
    min-height: 100vh;
    height: auto;
    display: flex;
    position: relative;
    overflow: hidden;
    background: var(--grey-color);
    align-items: center;
    justify-content: center;
    padding-top: 100px;
}

.bannerColumn {
  flex-direction: column;

}


.nubesComponent{
  position: absolute;
  left: 0;
  bottom: -72px;
}

.ssdMain_services_backgroundOverlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-attachment: fixed;
  opacity: 0.2;
  z-index: 0;
}

.ssdMain_services_text {
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40%;
    max-width: 750px;
    padding: 10px 60px;
    z-index:2;
    text-align: center;
}

.ssdMain_services_text_center{
  text-align: start;
  align-items: flex-start;
  width: 50%;

}
.ssdMain_services_text_title {
  font-size: 55px;
  font-weight: 600;
  margin-bottom: 20px;
  white-space: pre-line;
    line-height: 1em;
}
.ssdMain_services_text_description,
.ssdMain_services_text_list_item_text {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 20px;
}
.ssdMain_services_text_title_bold {
  color: var(--primary-color);
}
.ssdMain_services_text_list_item {
  margin-bottom: 10px;
}

.ssdMain_services_text_list_item_icon {
  margin-right: 5px;
  color: var(--primary-color);
}
.ssdMain_services_contentInfo {
  width: 100%;
  z-index: 1;
}

.ssdMain_services_contentInfo_full{
  width: 50%;
}

/* button */
.btnShodow{
    background: var(--primary-color);
    color:  var(--white-color);
    font-family: var(--title-fonts);
    font-weight: 700;
    font-size: 16px;
    text-decoration: none;
    padding: 5px 35px;
    line-height: 2;
    border: none;
    border-radius: 5px;
    transition: .3s;
    cursor: pointer;
    box-shadow: 0px 5px 20px 0px rgba(0, 81, 250, 0.3);
    transform: scale(1);

}
.btnShodow:hover{
    transform: scale(1.05) !important;
    color: var(--white-color) !important;

}

.btnShodow_link{
  color:  var(--white-color);

}


/* circle */
.circle{
  position: absolute;
  border-radius: 50%;
  background: linear-gradient(90deg, var(--blue-color) 0%, var(--primary-color) 100%);
  animation: ripple 12s infinite;
  box-shadow: 0px 0px 1px 0px var(--blue-color); 
}

.small{
  width: 200px;
  height: 200px;
  right: -100px;
  bottom: -100px;
}

.medium{
  width: 400px;
  height: 400px;
  right: -200px;
  bottom: -200px;
}

.large{
  width: 600px;
  height: 600px;
  right: -300px;
  bottom: -300px;
}

.xlarge{
  width: 800px;
  height: 800px;
  right: -400px;
  bottom: -400px;
}

.xxlarge{
  width: 1000px;
  height: 1000px;
  right: -500px;
  bottom: -500px;
}

.shade1{
  opacity: 0.2;
}
.shade2{
  opacity: 0.5;
}

.shade3{
  opacity: 0.7;
}

.shade4{
  opacity: 0.8;
}

.shade5{
  opacity: 0.9;
}

@keyframes ripple{
  0%{
    transform: scale(0.8);
  }
  
  50%{
    transform: scale(1.2);
  }
  
  100%{
    transform: scale(0.8);
  }
}


/* circle animation end--- */

@media (max-width: 1655px){
  .ssdMain_services_text_description{
    font-size: 14px;
    font-weight: 500;

  }
}
@media(max-width:1300px){
  .ssdMain_services_text_title {
    font-size: 42px;
  }
}
/* media Med width */
@media (max-width: 1255px){
  .ssdMain_services{
    /* min-height: 110vh; */
    flex-direction: column;
    padding: 80px 0px 100px;

  }
  .ssdMain_services_text {
    width: 90%;
    margin-bottom: 0px;
    margin-top: 30px;
  }

  .ssdMain_services_text{
    text-align: center;
    justify-content: center;
    align-items: center;
    max-width: 700px;
    padding: 0px 30px;
  }
  .ssdMain_services_text_list{
    display: none;
  }



}
@media (max-width: 1300px){
  .ssdMain_services_contentInfo_full{
    width: 70%;
  }
}
/* Media min width */
@media (max-width: 900px){
  .ssdMain_services_text_title{
    font-size: 40px;
  }
  .ssdMain_services_contentInfo{
    width: 100%;
  }
  
}

@media (max-width: 700px){
  .ssdMain_services {
    padding: 20px 0px 60px;
  }
  .ripple-background{
    position: absolute;
    left: -355px;
    bottom: -200px;
  }
}

@media (max-width: 600px){
  .nubesComponent{
  position: absolute;
  left: 0;
  bottom: -102px;
}
.ssdMain_services{
  padding-top: 0px;
}
  .ssdMain_services_text_description, .ssdMain_services_text_list_item_text {
    font-size: 14px;
  }

.cardScale .text{
  font-size: 12px;
}

}
/* .section_paddingBottom{
    padding-bottom: 130px;
} */

</style>
