<template>
  <div id="conectividad" style="overflow: hidden">
    <!-- START home 3 REVOLUTION SLIDER 6.3.2 -->

    <p class="rs-p-wp-fix"></p>
    <rs-module-wrap
      id="rev_slider_2_1_wrapper"
      data-alias="home-2-1"
      data-source="gallery"
      style="background: transparent; padding: 0; margin: 0px auto; margin-top: 0; margin-bottom: 0; min-height: 740px; padding: 5px 0px;"
    >
      <rs-module
        id="rev_slider_conectividad"
        class=""
        data-version="6.3.2"
        style="overflow: visible !important"
      >
        <rs-slides style="overflow: visible !important">
          <rs-slide data-key="rs-2" data-title="Slide" data-anim="ei:d;eo:d;s:600;r:0;t:fade;sl:0;">
            <img
              src="rev-slider/assets/transparent.png"
              alt="Slide"
              data-bg="c:#f5f9ff;"
              data-parallax="off"
              class="rev-slidebg"
              data-no-retina
            />

            <rs-layer
              id="slider-2-slide-2-layer-4"
              data-type="image"
              data-rsp_ch="on"
              class="rs-pxl-2"
              data-xy="x:l,c,c,c;xo:-170px,1px,-1px,0;y:m,b,b,b;yo:-70px,-130px,-90px,-130px;"
              data-text="w:normal;s:20,15,11,6;l:0,19,14,8;"
              data-dim="w:986px,500px,381px,293px;h:1080px,548px,420px,315px;"
              data-frame_0="x:50,39,29,17;"
              data-frame_1="st:1600;sp:1000;"
              data-frame_999="o:0;st:w;"
              style="z-index: 9"
              ><img
                src="rev-slider/assets/1214.png"
                width="986"
                height="1080"
                alt="slider-image-2"
                data-no-retina
              />
            </rs-layer>

            <rs-layer
              id="slider-1-slide-1-layer-1"
              data-type="text"
              data-color="#606c7a"
              data-rsp_ch="on"
              data-xy="x:r,c,c,c;xo:15px,0,0,0;y:m,t,t,t;yo:30px,145px,113px,69px;"
              data-text="w:normal;s:18,16,0,4;l:34,32,0,6;a:right,center,center,center;"
              data-vbility="t,t,t,f"
              data-frame_0="x:50,39,29,17;"
              data-frame_1="st:900;sp:1000;"
              data-frame_999="o:0;st:w;"
              style="z-index: 9; font-family: Jost"
              >Queremos ayudarte a mejorar la productividad entregandote estabilidad<br />por medio de
              nuestras soluciones de conectividad
            </rs-layer>

            <rs-layer
              id="slider-1-slide-1-layer-2"
              class="banner-text"
              data-type="text"
              data-color="#002450"
              data-rsp_ch="on"
              data-xy="x:r,c,c,c;xo:15px,0,0,0;y:m,t,t,t;yo:-82px,40px,40px,30px;"
              data-text="w:normal;s:60,47,42,34;l:68,53,50,37;fw:600;a:right,center,center,center;"
              data-dim="w:633.406px,498px,509px,440px;"
              data-frame_0="x:50,39,29,17;"
              data-frame_1="st:900;sp:1000;"
              data-frame_999="o:0;st:w;"
              style="z-index: 10; font-family: Jost"
            >
              Conectividad para tu negocio<br />
            </rs-layer>

            <rs-layer
              id="slider-2-slide-2-layer-3"
              data-type="image"
              data-rsp_ch="on"
              data-xy="x:c;y:b;"
              data-text="w:normal;s:20,15,11,6;l:0,19,14,8;"
              data-dim="w:2200px,1732px,1315px,811px;h:1238px,975px,740px,456px;"
              data-frame_0="sX:0.9;sY:0.9;"
              data-frame_1="st:600;sp:1000;"
              data-frame_999="o:0;st:w;"
              style="z-index: 8"
              ><img src="rev-slider/assets/Polygon-Luminary-1.svg" alt="slider-image" data-no-retina />
            </rs-layer>

            <router-link class="menu-item" to="/nosotros#contacto">
              <a
                id="slider-1-slide-1-layer-7"
                class="rs-layer rev-btn"
                target="_self"
                rel="nofollow"
                data-type="button"
                data-rsp_ch="on"
                data-xy="x:r,c,c,c;xo:15px,-80px,-80px,-70px;y:m,t,m,m;yo:110px,225px,-90px,-55px;"
                data-text="w:normal;s:18,16,16,14;l:32,32,32,30;"
                data-dim="minh:0px,none,none,none;"
                data-padding="t:12,9,10,8;r:30,24,30,25;b:12,9,10,8;l:30,24,30,25;"
                data-border="bor:5px,5px,5px,5px;"
                data-frame_0="x:50,39,29,17;"
                data-frame_1="st:900;sp:1000;"
                data-frame_999="o:0;st:w;"
                data-vbility="t,f,f,f"
                data-frame_hover="c:#fff;bgc:var(--primary-color);bor:5px,5px,5px,5px;"
                style="z-index: 15; background-color: var(--dark-color); font-family: Jost"
                >Contáctanos
              </a>
            </router-link>
          </rs-slide>
        </rs-slides>
      </rs-module>
    </rs-module-wrap>

    <!-- END REVOLUTION SLIDER -->

    <!-- Service Details start (HOSTING BÁSICO) -->
    <section class="section_service p-5 ">
      <div id="vpn" class="enclaje_section"></div>

      <div class="container">
        <div class="row">
          <div class="col-xl-6 col-md-12 mb-5 mb-xl-0">
            <div class="pt-img-main wow animate__animated animate__fadeInLeft">
              <img src="images/patterns/voip.svg" class="svg img-fluid" alt="image6" />
            </div>
          </div>
          <div class="col-xl-6 col-md-12">
            <div class="pt-section-title-1">
              <h2 class="pt-section-title"><span class="imp-word">VPN Empresarial</span></h2>
              <p class="pt-section-description">
                <b>Estabilidad y redundancia</b> <br />
                El creciente aumento de ataques informáticos hace que se deban proteger los activos
                tecnológicos de amenazas inesperadas. A través de nuestras soluciones puedes unir múltiples
                oficinas, acceder a tus servidores o ingresar de manera confiable y estable a tus aplicaciones
                de negocio en ihosting, Azure, AWS, GDP, etc. de forma simple y con completa seguridad. <br />
              </p>
            </div>
            <div class="pt-icon-list">
              <ul class="pt-icon-list-ul">
                <li class="pt-icon-list-item">
                  <span class="pt-icon-list-icon">
                    <i aria-hidden="true" class="ion ion-android-done-all"></i
                  ></span>
                  <span class="pt-icon-list-text"
                    >Asesoría y soporte técnico en tu idioma especializado en resolver tus inquietudes.</span
                  >
                </li>
                <li class="pt-icon-list-item">
                  <span class="pt-icon-list-icon">
                    <i aria-hidden="true" class="ion ion-android-done-all"></i
                  ></span>
                  <span class="pt-icon-list-text"
                    >Nuestras soluciones se encuentran diseñadas en alta disponibilidad para que no pierdas
                    conectividad ante eventos inesperados.</span
                  >
                </li>
                <li class="pt-icon-list-item">
                  <span class="pt-icon-list-icon">
                    <i aria-hidden="true" class="ion ion-android-done-all"></i
                  ></span>
                  <span class="pt-icon-list-text"
                    >Entregamos soluciones IPSec o SSL según el caso de uso.</span
                  >
                </li>
                <li class="pt-icon-list-item">
                  <span class="pt-icon-list-icon">
                    <i aria-hidden="true" class="ion ion-android-done-all"></i
                  ></span>
                  <span class="pt-icon-list-text"
                    >Podemos soportar grandes cargas de trabajo y escalar sin inconvenientes.
                  </span>
                </li>
              </ul>
            </div>
            <div class="pt-button-block mt-4">
              <a href="/nosotros#contacto" class="pt-button"><span class="text">Contáctanos</span></a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Service Details end -->

    <!-- Service Details start (HOSTING BÁSICO) -->
    <section class="section_service p-5" style="background: var(--grey-color)">
      <div id="punto-punto" class="enclaje_section"></div>
      <div class="container">
        <div class="row">
          <div class="col-xl-6 col-md-12">
            <div class="pt-section-title-1">
              <h2 class="pt-section-title">Punto-<span class="imp-word">Punto</span></h2>
              <b>Estabilidad y confianza</b> <br />
              <p class="pt-section-description">
                Conectividad (punto a punto) entre instalaciones, sucursales u oficinas, brindando la
                posibilidad de interconectar múltiples locaciones o llegar a diferentes datacenters, con
                conexión directa a través de MPLS o Fibra Oscura.<br />
              </p>
            </div>
            <div class="pt-button-block mt-4">
              <a href="/nosotros#contacto" class="pt-button"><span class="text">Contáctanos</span></a>
            </div>
          </div>
          <div class="col-xl-6 col-md-12 mt-5 mt-xl-0 p-box-c-30">
            <div class="pt-img-main wow animate__animated animate__fadeInRight">
              <img src="images/patterns/domainmanage.svg" class="svg img-fluid pt-img-main" alt="image2" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Service Details end -->

    <!-- Service Details start (HOSTING BÁSICO) -->
    <section class="section_service p-5">
      <div id="byoip" class="enclaje_section"></div>

      <div class="container">
        <div class="row">
          <div class="col-xl-6 col-md-12 mb-5 mb-xl-0 p-box-c-30">
            <div class="pt-img-main wow animate__animated animate__fadeInLeft">
              <img src="images/patterns/domainrenewal.svg" class="svg img-fluid" alt="image3" />
            </div>
          </div>
          <div class="col-xl-6 col-md-12">
            <div class="pt-section-title-1">
              <h2 class="pt-section-title"><span class="imp-word">BYOIP</span></h2>
              <p class="pt-section-description">
                Si cuentas con tus propios prefijos o sistema autónomo (AS) puedes en cualquiera de nuestros
                datacenter anunciar parte o todas tus redes en IPv4 y IPv6 o incluso formar una red anycast.
                <br />
              </p>
            </div>
            <div class="pt-button-block mt-4">
              <a href="/nosotros#contacto" class="pt-button"><span class="text">Contáctanos</span></a>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-4 pt-5">
        <div class="col-xl-3 col-md-6">
          <div class="pt-fancy-box pt-fancybox-2">
            <div class="pt-fancy-media">
              <i class="cont-img-icon-tab"
                ><img
                  src="images/icons/cloud.svg"
                  class="img-icon-tab"
                  style="margin: auto; max-width: fit-content"
              /></i>
            </div>
            <div class="pt-fancybox-info">
              <h4 class="pt-fancy-box-title">Asesoría</h4>
              <p class="pt-fancybox-description">
                Asesoría y soporte técnico en tu idioma especializado en resolver tus inquietudes.
              </p>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6 mt-4 mt-md-0">
          <div class="pt-fancy-box pt-fancybox-2">
            <div class="pt-fancy-media">
              <i class="cont-img-icon-tab"
                ><img
                  src="images/icons/infrastructure.svg"
                  class="img-icon-tab"
                  style="margin: auto; max-width: fit-content"
              /></i>
            </div>
            <div class="pt-fancybox-info">
              <h4 class="pt-fancy-box-title">Disponibilidad</h4>
              <p class="pt-fancybox-description">
                Nuestras soluciones se encuentran diseñadas en alta disponibilidad para que no pierdas
                conectividad ante eventos inesperados.
              </p>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-12 mt-4 mt-xl-0">
          <div class="pt-fancy-box pt-fancybox-2">
            <div class="pt-fancy-media">
              <i class="cont-img-icon-tab"
                ><img
                  src="images/icons/global.svg"
                  class="img-icon-tab"
                  style="margin: auto; max-width: fit-content"
              /></i>
            </div>
            <div class="pt-fancybox-info">
              <h4 class="pt-fancy-box-title">VPN</h4>
              <p class="pt-fancybox-description">Entregamos soluciones IPSec o SSL según el caso de uso.</p>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-12 mt-4 mt-xl-0">
          <div class="pt-fancy-box pt-fancybox-2">
            <div class="pt-fancy-media"><i class="flaticon-003-monitor-screen"></i></div>
            <div class="pt-fancybox-info">
              <h4 class="pt-fancy-box-title">Escalamiento</h4>
              <p class="pt-fancybox-description">
                Podemos soportar grandes cargas de trabajo y escalar sin inconvenientes.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Service Details end -->

    <!-- Offer start -->
    <section class="pt-blue-background">
      <div
        class="pt-background-overley pt-opacity-1"
        style="background-image: url(images/polygon/Polygon-Luminary.svg)"
      ></div>
      <div class="container">
        <div class="row mx-0 my-0">
          <div class="col-xl-8">
            <div class="pt-section-title-1 pt-section-title-1-color-white mb-0">
              <h2 class="pt-section-title text-white">¿Estás buscando otra solución de conectividad?</h2>
              <p class="pt-section-description text-white mb-0">
                ¡Cuéntanos qué es lo que buscas y te asesoraremos para que encuentres la mejor solución!
              </p>
            </div>
          </div>
          <div class="col-xl-4">
            <div class="pt-btn-container text-right pt-btn-position">
              <div class="pt-button-block">
                <router-link class="menu-item" to="/nosotros#contacto">
                  <a class="pt-button pt-button-change-color" style="cursor: pointer">
                    <span class="text">Contáctanos</span>
                  </a>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- offer end -->

    <!-- Service Details start (HOSTING BÁSICO) -->
    <section class="section_service p-5"  style="background: var(--grey-color)">
      <div id="peering" class="enclaje_section"></div>

      <div class="container">
        <div class="row">
          <div class="col-xl-6 col-md-12 mb-5 mb-xl-0 p-box-c-30">
            <div class="pt-img-main wow animate__animated animate__fadeInLeft">
              <img src="images/patterns/dns.svg" class="svg img-fluid pt-img-main" alt="image4" />
            </div>
          </div>
          <div class="col-xl-6 col-md-12">
            <div class="pt-section-title-1">
              <h2 class="pt-section-title"><span class="imp-word">Peering</span></h2>
              <p class="pt-section-description">
                Si eres proveedor de servicio te invitamos a hacer peering con nosotros ya sea a través de los
                puntos de intercambios de tráfico (PIT) a los cuales estamos conectados o en nuestros puntos
                de presencia (PoP). <br />
              </p>
            </div>
            <div class="pt-icon-list">
              <ul class="pt-icon-list-ul">
                <li class="pt-icon-list-item">
                  <span class="pt-icon-list-icon">
                    <i aria-hidden="true" class="ion ion-android-done-all"></i
                  ></span>
                  <span class="pt-icon-list-text">Menores latencias.</span>
                </li>
                <li class="pt-icon-list-item">
                  <span class="pt-icon-list-icon">
                    <i aria-hidden="true" class="ion ion-android-done-all"></i
                  ></span>
                  <span class="pt-icon-list-text">Internet más resiliente.</span>
                </li>
                <li class="pt-icon-list-item">
                  <span class="pt-icon-list-icon">
                    <i aria-hidden="true" class="ion ion-android-done-all"></i
                  ></span>
                  <span class="pt-icon-list-text">Ingeniería de tráfico.</span>
                </li>
                <li class="pt-icon-list-item">
                  <span class="pt-icon-list-icon">
                    <i aria-hidden="true" class="ion ion-android-done-all"></i
                  ></span>
                  <span class="pt-icon-list-text">Menores costos de tránsito.</span>
                </li>
              </ul>
            </div>
            <div class="pt-button-block mt-4">
              <a href="/nosotros#contacto" class="pt-button"><span class="text">Contáctanos</span></a>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-4 pt-5">
        <div class="col-xl-3 col-md-6">
          <div class="pt-fancy-box pt-fancybox-2">
            <div class="pt-fancy-media"><i class="flaticon-003-monitor-screen"></i></div>
            <div class="pt-fancybox-info">
              <h4 class="pt-fancy-box-title">Redundancia</h4>
              <p class="pt-fancybox-description">
                Minimiza la dependencia con proveedores de tránsito generando una conexión directa aumentando
                la redundancia y resiliencia de internet.
              </p>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6 mt-4 mt-md-0">
          <div class="pt-fancy-box pt-fancybox-2">
            <div class="pt-fancy-media">
              <i class="cont-img-icon-tab"
                ><img
                  src="images/icons/low-cost.svg"
                  class="img-icon-tab"
                  style="margin: auto; max-width: fit-content"
              /></i>
            </div>
            <div class="pt-fancybox-info">
              <h4 class="pt-fancy-box-title">Ahorro</h4>
              <p class="pt-fancybox-description">
                Genera ahorros significativos al disminuir la dependencia de los proveedores de tránsito
                nacionales e internacionales.
              </p>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-12 mt-4 mt-xl-0">
          <div class="pt-fancy-box pt-fancybox-2">
            <div class="pt-fancy-media">
              <i class="cont-img-icon-tab"
                ><img
                  src="images/icons/group.svg"
                  class="img-icon-tab"
                  style="margin: auto; max-width: fit-content"
              /></i>
            </div>
            <div class="pt-fancybox-info">
              <h4 class="pt-fancy-box-title">Usuarios</h4>
              <p class="pt-fancybox-description">
                Mejora significativamente la experiencia del usuario final pudiendo obtener menores latencias
                al acercar el contenido al usuario final.
              </p>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-12 mt-4 mt-xl-0">
          <div class="pt-fancy-box pt-fancybox-2">
            <div class="pt-fancy-media"><i class="flaticon-011-cloud-computing"></i></div>
            <div class="pt-fancybox-info">
              <h4 class="pt-fancy-box-title">PIT</h4>
              <p class="pt-fancybox-description">
                Los puntos de intercambio de tráfico de Internet permite a los proveedores de servicio de
                Internet (ISP) conectarse e intercambiar tráfico localmente.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Service Details end -->

    <!-- Award Slider start -->
    <section style="padding: 40px 0px 80px 0px" class="boxshadowcustom">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="pt-section-title-1 text-center">
              <h2 class="pt-section-title">Estamos <span class="imp-word">conectados </span>con</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="pt-award">
              <!-- Slider main container -->
              <div
                class="swiper-container swiper-container-fast-static swiper-container-initialized swiper-container-horizontal"
                data-nav="false"
                data-pagination_type="bullets"
                data-desk_num="5"
                data-lap_num="3"
                data-tab_num="2"
                data-mob_num="1"
                data-mob_sm="1"
                data-autoplay="true"
                data-loop="true"
                data-margin="60"
              >
                <!-- Additional required wrapper -->
                <div class="swiper-wrapper">
                  <!-- Slides -->
                  <div class="swiper-slide">
                    <div class="pt-awardbox-1">
                      <a title="Cloudflare" target="_blank"
                        ><img src="img/logos/cloudflare.svg" alt="Cloudflare"
                      /></a>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="pt-awardbox-1">
                      <a title="Akamai" target="_blank"
                        ><img src="images/partners/partner15.png" alt="Akamai"
                      /></a>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="pt-awardbox-1">
                      <a title="Microsoft" target="_blank"
                        ><img src="images/partners/partner16.png" alt="Microsoft"
                      /></a>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="pt-awardbox-1">
                      <a title="Pit Chile" target="_blank"
                        ><img src="images/partners/partner17.png" alt="Pit Chile"
                      /></a>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="pt-awardbox-1">
                      <a title="Google" target="_blank"
                        ><img src="images/partners/partner18.png" alt="Google"
                      /></a>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="pt-awardbox-1">
                      <a title="Huawei Cloud" target="_blank"
                        ><img src="images/partners/partner19.png" alt="Huawei Cloud"
                      /></a>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="pt-awardbox-1">
                      <a title=" AT&T" target="_blank"
                        ><img src="images/partners/partner20.png" alt=" AT&T"
                      /></a>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="pt-awardbox-1">
                      <a title="Telefonica" target="_blank"
                        ><img src="images/partners/partner21.png" alt="Telefonica"
                      /></a>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="pt-awardbox-1">
                      <a title="GTD" target="_blank"><img src="images/partners/partner22.png" alt="GTD" /></a>
                    </div>
                  </div>
                </div>
                <!-- If we need pagination -->
                <div class="swiper-pagination swiper-pagination-static"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Award Slider end -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {
  formatNumber,
  changeSeparator,
  swiper_component,
  swiper_component_fast,
  revinit_revslider21,
  changeColorSvg,
  resolucion,
  swiper_component_fast_static,
} from "@/helpers";

export default {
  metaInfo: {
    title: "ihosting | Conectividad",
    htmlAttrs: {
      lang: "es",
      amp: true,
    },
    meta: [
      {
        name: "description",
        content: "Queremos ayudarte a mejorar la productividad entregandote estabilidad por medio de nuestras soluciones de conectividad."
      },
      {
        name: "keywords",
        content: "VPN Empresarial, Punto-Punto, BYOIP",
      },
    ]
  },

  data: () => ({
    infra_01: false,
    type: 1,
    form: {
      cantidad: 1,
    },
  }),

  computed: {
    ...mapGetters(["getServices_"]),
    resolucion,
  },
  created() {},
  mounted() {
    this.swiper_component_fast();

    this.changeColorSvg();
    this.revinit_revslider21("#rev_slider_conectividad");
    this.getServices(1).then(() => {
      this.swiper_component();
      this.swiper_component_fast_static();
    });
  },

  methods: {
    ...mapActions(["getServices"]),
    formatNumber,
    changeSeparator,
    swiper_component,
    revinit_revslider21,
    swiper_component_fast,
    changeColorSvg,
    swiper_component_fast_static,
  },
};
</script>
