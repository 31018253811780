var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main"},[_c('section',{staticClass:"main_banner"},[_c('BannerSlider',{attrs:{"dataBannerSlider":_vm.dataBannerSlider}},[_c('div',{staticClass:"azureMain_portada_content"},[_c('ContactSimply',{attrs:{"ViewDataForm":{
                     stateInputs:{
                     nombre: true,
                     email: true,
                     telefono: true,
                     asunto: false,
                     motivo: false,
                     mensaje: true,
                    },
                withBLur: true,
                 asunto: 'Consulta ihosting VPS CLOUD: solicito la llamada de un Ejecutivo Comercial',
                 motivo: 'Consulta comercial VPS CLOUD'
               },"idForm":"cloudpVps1"}})],1)])],1),_c('section',{staticClass:"main_section"},[_c('titleBox',{attrs:{"dataTitleBox":_vm.DataSectionServices['VPS_linux']}}),(_vm.dataServicios)?_c('div',{staticClass:"main_section_sliderBx"},[_c('SliderCardPlans',{attrs:{"categorie":_vm.categories['Cloud VPS Linux Mensual'],"styleBoxCard":{width: '380px'},"infoCategory":{category: _vm.dataServicios[2].categoria, subCategory: _vm.dataServicios[2].subcategoria },"imageCard":{src: 'images/linux-logo.png', state:true},"cardContact":false,"keySlide":"CloudVPSLinuxMensual"}})],1):_vm._e(),_c('div',{staticClass:"nubesComponent"},[_c('nubes')],1)],1),_c('section',{staticClass:"main_section",staticStyle:{"background-color":"var(--grey-color)"}},[_c('div',{staticClass:"nubesComponent_top"},[_c('nubes')],1),_c('titleBox',{attrs:{"dataTitleBox":_vm.DataSectionServices['VPS_windows']}}),(_vm.dataServicios)?_c('div',{staticClass:"main_section_sliderBx"},[_c('SliderCardPlans',{attrs:{"categorie":_vm.categories['Cloud VPS Windows Mensual'],"styleBoxCard":{width: '380px'},"infoCategory":{category: _vm.dataServicios[2].categoria, subCategory: _vm.dataServicios[2].subcategoria },"imageCard":{src: 'images/windows-logo.png', state:true},"cardContact":false,"keySlide":"CloudWindowMensual"}})],1):_vm._e(),_c('div',{staticClass:"nubesComponent"},[_c('nubes')],1)],1),_c('section',{staticClass:"main_cards "},[_c('titleBox',{staticClass:"wow animate__animated  animate__fadeInUp",attrs:{"dataTitleBox":_vm.dataTitleMore,"configTitleBox":{'positionTitle':'center' }}}),_c('div',{staticClass:"main_cards_box wow animate__animated  animate__fadeInUp"},_vm._l((_vm.dataSectionMore),function(item,index){return _c('cardWaveInside',{key:index,attrs:{"dataInfo":item}})}),1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }