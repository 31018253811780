<template>
  <div class="minCards">
    <titleBox :dataTitleBox="dataTitleBestServer"/>

    <div class="minCards_wrapper">
         <div v-for="(item, index) in dataCards" :key="index" class="minCards_wrapper_box">
            <div class="minCards_wrapper_box_front">
               <div class="minCards_wrapper_box_front_icon">
                  <i v-if="item.icon" :class="item.icon"></i>
                  <i v-else class="fas fa-code"></i>
               </div>
               <span class="minCards_wrapper_box_front_title">{{ item.title }}</span>
            </div>
            <div class="minCards_wrapper_box_back">
               <span class="minCards_wrapper_box_back_title">{{ item.title }}</span>
               <p v-if="item.description">
                  {{ item.description }}
               </p>
            </div>
         </div>

      </div>
  </div>
</template>

<script>
import titleBox from '@/components/web/titleBox.vue';
export default{
    components:{
        titleBox,
    },
    props:{
      dataCards:{
        default: []
      },
      dataTitleBestServer: {
        default: ()=>({})
      }
    },
    data: ()=>({

    }),
}
</script>

<style scoped>

/* cards */
.minCards{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 70px 0px 40px;
    margin: 0 auto;
}
.minCards_wrapper{
  width: 95%;
  max-width: 1200px;
  display: grid;
  margin: 20px 10px auto;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  text-align: center;
}

.minCards_wrapper_box{
  width: 350px;
  margin: 0 auto;
  position: relative;
  perspective: 1000px;
}
.minCards_wrapper_box_front{
  background: #fff;
  height: 250px;
  width: 100%;
  border-radius: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 5px 20px 0px rgba(0, 81, 250, 0.1);
  transition: all 0.5s ease;
  margin-bottom: 30px;
}
 .minCards_wrapper_box_front.minCards_wrapper_box_front_icon{
  height: 80px;
}
 .minCards_wrapper_box_front .minCards_wrapper_box_front_icon i{
  font-size: 65px;
  color: var(--primary-color);
  margin-bottom: 10px;
}
.minCards_wrapper_box_front .minCards_wrapper_box_front_title,
.minCards_wrapper_box_back .minCards_wrapper_box_back_title{
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  width: 94%;
}

.minCards_wrapper_box_front_title{
  color: var(--dark-color);
}

.minCards_wrapper_box .minCards_wrapper_box_back{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  height: 250px;
  width: 100%;
  padding: 30px;
  border-radius: 1.25rem;
  color: #fff;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  background:var(--primary-color);
  transform: translateY(110px) rotateX(-90deg);
  box-shadow: 0px 5px 20px 0px rgba(0, 81, 250, 0.1);
  transition: all 0.5s ease;
}
.minCards_wrapper_box .minCards_wrapper_box_back p{
  margin-top: 10px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
}


.minCards_wrapper_box:nth-child(2) .minCards_wrapper_box_back,
.minCards_wrapper_box:nth-child(4) .minCards_wrapper_box_back,
.minCards_wrapper_box:nth-child(6) .minCards_wrapper_box_back
{
  opacity: 1;
  transform: rotateX(0deg);
}
.minCards_wrapper_box:nth-child(2) .minCards_wrapper_box_front,
.minCards_wrapper_box:nth-child(4) .minCards_wrapper_box_front,
.minCards_wrapper_box:nth-child(6) .minCards_wrapper_box_front
{
  opacity: 0;
  transform: translateY(-110px) rotateX(90deg);
}


.minCards_wrapper_box:hover .minCards_wrapper_box_back{
  opacity: 1;
  transform: rotateX(0deg);
}
.minCards_wrapper_box:hover .minCards_wrapper_box_front{
  opacity: 0;
  transform: translateY(-110px) rotateX(90deg);
}

@media (max-width: 700px) {
  .minCards{
    padding: 20px 0px 10px;
    width: 100%;
  }
  .minCards_wrapper{

    margin: 20px 0px;
    width: 100%;
  }
}
</style>
