<template>
    <div>
      <!-- Contenido de tu página -->
      <!-- <div v-if="showButton" class="scroll-to-top-button" @click="scrollToTop">Subir al inicio</div> -->
<!-- 
        <button  @click="scrollToTop"  class="button">
            <svg class="svgIcon" viewBox="0 0 384 512">
                <path d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z"></path>
            </svg>
        </button> -->

        <button v-if="showButton" @click="scrollToTop" class="button">
            <div class="button-box">
                <span class="button-elem">
                <svg viewBox="0 0 46 40" xmlns="http://www.w3.org/2000/svg">
                    <path
                    d="M46 20.038c0-.7-.3-1.5-.8-2.1l-16-17c-1.1-1-3.2-1.4-4.4-.3-1.2 1.1-1.2 3.3 0 4.4l11.3 11.9H3c-1.7 0-3 1.3-3 3s1.3 3 3 3h33.1l-11.3 11.9c-1 1-1.2 3.3 0 4.4 1.2 1.1 3.3.8 4.4-.3l16-17c.5-.5.8-1.1.8-1.9z"
                    ></path>
                </svg>
                </span>
                <span class="button-elem">
                <svg viewBox="0 0 46 40">
                    <path
                    d="M46 20.038c0-.7-.3-1.5-.8-2.1l-16-17c-1.1-1-3.2-1.4-4.4-.3-1.2 1.1-1.2 3.3 0 4.4l11.3 11.9H3c-1.7 0-3 1.3-3 3s1.3 3 3 3h33.1l-11.3 11.9c-1 1-1.2 3.3 0 4.4 1.2 1.1 3.3.8 4.4-.3l16-17c.5-.5.8-1.1.8-1.9z"
                    ></path>
                </svg>
                </span>
            </div>
        </button>

    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        showButton: false
      };
    },
    mounted() {
        window.addEventListener('scroll', ()=> {

            // Verifica si los elementos existen antes de intentar acceder a sus propiedades
                if(window.scrollY >= 190){
                  this.showButton = true;
                }
                else{
                  this.showButton = false;
                }
        });  
    },
    // destroyed() {
    //   window.removeEventListener('scroll', this.handleScroll);
    // },
    methods: {
    //   handleScroll() {
    //     // Verificar si el usuario ha hecho suficiente scroll para mostrar el botón
    //     this.showButton = window.pageYOffset > 100; // Puedes ajustar el valor según tu preferencia
    //   },
      scrollToTop() {
        // Hacer scroll hacia arriba
        window.scrollTo({
          top: 0,
          behavior: 'smooth' // Hacer el scroll de forma suave
        });
      }
    }
  };
  </script>
  
  <style scoped>
  .button {
  display: block;
  position: relative;
  width: 56px;
  height: 56px;
  margin: 0;
  overflow: hidden;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  border: 0;
  transform: rotate(90deg);
  position: fixed;
  right: 22px;
  bottom: 100px;
  z-index: 999;
}

.button:before,
.button:after {
  content: "";
  position: absolute;
  border-radius: 50%;
  inset: 7px;
}

.button:before {
  border: 2px solid var(--blue-color);
  background-color: rgba(251,251,253,0.8);
    backdrop-filter: saturate(180%) blur(20px);
  transition: opacity 0.4s cubic-bezier(0.77, 0, 0.175, 1) 80ms,
    transform 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) 80ms;
}

.button:after {
  border: 2px solid var(--blue-color);;
  transform: scale(1.3);
  transition: opacity 0.4s cubic-bezier(0.165, 0.84, 0.44, 1),
    transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 0;
}

.button:hover:before,
.button:focus:before {
  opacity: 0;
  transform: scale(0.7);
  transition: opacity 0.4s cubic-bezier(0.165, 0.84, 0.44, 1),
    transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.button:hover:after,
.button:focus:after {
  opacity: 1;
  transform: scale(1);
  transition: opacity 0.4s cubic-bezier(0.77, 0, 0.175, 1) 80ms,
    transform 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) 80ms;
}

.button-box {
  display: flex;
  position: absolute;
  top: 8px;
  left: 0;
}

.button-elem {
  display: block;
  width: 20px;
  height: 20px;
  margin: 17px 18px 0 18px;
  transform: rotate(180deg);
  fill: var(--blue-color);
}

.button:hover .button-box,
.button:focus .button-box {
  transition: 0.4s;
  transform: translateX(-56px);
}
  </style>
  