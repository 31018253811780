export function toArray(rows) {
  return Object.keys(rows).map(key => rows[key])
}

export function contentTooltip(content) {
  let des = null
  if (content) {
    des = content
  } else {
    des = 'descripción campo ayuda toltip, content-campo.texto_ayuda'
  }
  return {
    content: des,
    placement: 'top',
    classes: ['info'],
    targetClasses: ['it-has-a-tooltip'],
    offset: 10,
    delay: {
      show: 300,
      hide: 300,
    },
  }
}

export function checkNumber(event, value){
  if((event.keyCode < 48 || event.keyCode > 57) && event.keyCode != 8) {
      event.preventDefault();
  } 
   if(value.toString().length > 1 && event.keyCode != 8){
       event.preventDefault();
  }
  return false
}

/* ----------------------------- HELPERS TOTALES/NUMEROS ---------------------------- */
export function formatNumber(number) {
  number += ""
  var x = number.split(".")
  var x1 = x[0]
  var x2 = x.length > 1 ? "." + x[1] : ""
  var rgx = /(\d+)(\d{3})/
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, "$1" + "." + "$2")
  }

  return x1 + x2
}

export function changeSeparator(number) {
  number += ""
  var x = number.split(".")
  var x1 = x[0]
  var x2 = x[1]

  return x1 + "," + x2
}

export function setPriceService(moneda, precio, config) {
  let value = "";
  let cantidad = 1;
  if (config?.cantidad) {
    cantidad = config.cantidad;
  }
  if (moneda?.id == 3 || moneda?.id == 2) {
    value = moneda.valor * precio * cantidad;
    return value.toFixed();
  } else {
    return precio * cantidad;
  }
}

export function getCalculoAhorro(precioServicio, valorCupon, tipoCupon){
  let ahorro = 0;
  if (tipoCupon == 2) {
      ahorro = (precioServicio * valorCupon) / 100;
  } else if (tipoCupon == 1) {
      ahorro = valorCupon;
  }
  return ahorro;
}
// funcion que toma el precio del servicio y lo resta por el descuento, para luego retornar el nuevo monto
export function precioConDescuento(service){
  // console.log("precioconDescuento: ", service);
  return getCalculo_price_with_cupon(service.cupon, service.precio )
}

export function getCalculo_price_with_cupon(cupon, service_price ){
  // console.log("getCalculo_price_with_cupon: cupon: ", cupon, " service_price: ", service_price)

  // si es un cupon de periodicidades 
  let valor_cupon = cupon.valor;
  if(cupon.periodicidad_state == 1 && cupon.cupon_periodicidad_selected){
    return getcalculo_price_with_cupon_periodicidad(service_price, cupon);
  }
  if(cupon.id_tipocupon == 2){
    return Math.round(service_price * (1 - valor_cupon / 100));
  }else if(cupon.id_tipocupon == 1){
    return service_price - valor_cupon;
  }else{
    return service_price;
  }
}

export function get_valor_por_nombre_periodicidad(nombrePeriodicidad){

  let multiplicadorPeriodicidad;

    switch (nombrePeriodicidad.toLowerCase()) {
      case 'anual':
          multiplicadorPeriodicidad = 1;
          break;
      case 'bienal':
          multiplicadorPeriodicidad = 2;
          break;
      case 'trienal':
          multiplicadorPeriodicidad = 3;
          break;
      default:
          // Si no se especifica una periodicidad válida, asumimos anual
          multiplicadorPeriodicidad = 1;
    }
  return multiplicadorPeriodicidad;
}


export function getcalculo_price_with_cupon_periodicidad(service_price, cupon) {
    // Paso 1: Determinar la multiplicación según la periodicidad
    // console.log("getcalculo_price_with_cupon_periodicidad, service_price: ", service_price, " cupon: ", cupon);
    // return 1;
    let multiplicadorPeriodicidad;
    let nombrePeriodicidad = cupon.cupon_periodicidad_selected.nombre.toLowerCase();
    let valor_cupon_periodicidad = cupon.cupon_periodicidad_selected.valor;

    multiplicadorPeriodicidad = get_valor_por_nombre_periodicidad(nombrePeriodicidad);
    
     let service_price_periodicidad = Math.round(service_price * multiplicadorPeriodicidad);
    //  console.log("service_price_periodicidad multipliacado: ", service_price_periodicidad);
  
    // Paso 2: Calcular el descuento según el tipo de cupón
    let precioConDescuento;
    if (cupon.id_tipocupon == 1) {
        // Cupón por monto exacto
        precioConDescuento = Math.round(service_price_periodicidad - valor_cupon_periodicidad);
    } else if (cupon.id_tipocupon == 2) {
        // Cupón por porcentaje
        precioConDescuento = Math.round(service_price_periodicidad * (1 - valor_cupon_periodicidad / 100));
        // console.log("-------cupon.id_tipocupon == 2 precio con descuento: ", precioConDescuento);
    } else {
        // Si no se especifica un tipo de cupón válido, no aplicamos descuento
        precioConDescuento = service_price_periodicidad;
    }
  
    // Paso 3: Multiplicar por la periodicidad
    // const precioFinal = Math.round(precioConDescuento * multiplicadorPeriodicidad);
  
    return precioConDescuento;
  }

export function setTotalPriceService(element) {
  // console.log("setTotalPriceService: ", element)
  let total = 0;
  element.servicios_adicionales.forEach((value) => {
    total = total + parseInt(this.setPriceService(value.moneda, value.precio, value.configuracion));
  });

  let cantidad = 1;
  if (element.configuracion?.cantidad) {
    cantidad = element.configuracion.cantidad
  }

  let total_ = 0;
  let final = 0;
  let servicePrecio = element.precio;
  // agregar cupon fantasma, (sin validar)
  if(element.cupon_service){
    servicePrecio = getCalculo_price_with_cupon(element.cupon_service, element.precio  )
  }
  if (element.moneda?.id == 3 || element.moneda?.id == 2) {
    total_ = Math.ceil(element.moneda.valor * servicePrecio);
    final = (Number.parseInt(total_) + total) * cantidad;
    return Math.round(final);
  } else if(servicePrecio){
    // condicional usada por el carrito pra comprar un nuevo servicio (cupon validado)
    // verifica si existe cupon para sumar el precio con cupon en vez del precio real
    if(element.cupon){
      total_ = precioConDescuento(element);
    }else{
      total_ = servicePrecio;
    }
    final = (Number.parseInt(total_) + total) * cantidad;
    return Math.round(final);
  }else{
    total_ = element.notacobro.neto;
    final = (Number.parseInt(total_) + total) * cantidad;
    return Math.round(final);
  }
}

// return monto total de servicios del carrito
export function setMasterTotalPriceService(elements) {
  let total_final = 0;
  elements.forEach((value) => {
    total_final = total_final + parseInt(this.setTotalPriceService(value));
  });

  return total_final;
}

export function setMasterTotalPriceNotasCobro(elements, notasc) {
  let total_final = 0;
  elements.forEach((value) => {
    let nc = notasc.find(item => item.notacobro.id == value.notacobro.id)
    if(nc) {
      total_final = total_final + parseInt(this.setTotalPriceService(value));
    }
    
  });

  return total_final;
}


export function returnQuantityService(configuracion) {
  let cantidad = "(1)"
  for (const [key, value] of Object.entries(configuracion)) {
    if (key == 'cantidad') {
      cantidad = "(" + value + ")"
    }
  }

  return cantidad
}

export function returnQuantityServicePrincipal(item) {
  let cantidad = "(1)"

    if (item.cantidad) {
      cantidad = "(" + item.cantidad + ")"
    }


  return cantidad
}

// Toma items del carrito y coloca los item en nivel 1 y los servicios adicionales en nivel 2 para luego agregarlos junto a los servicios principales dentro del mismo array
export function returnAllServices(cart) {
  // console.log("se ejecuta returnAllServices: cart: ", cart);
  let services = []
  cart.forEach((ser, index) => {
    ser['nivel'] = 1
    ser['pos_cart'] = index
    services.push(ser)

      ser.servicios_adicionales.forEach((ser1) => {
        ser1['nivel'] = 2
        services.push(ser1)
      })
  // console.log("se ejecuta returnAllServices: services: ", services);

    
  })
  return services
}


export function returnCantidadService(item) {
  let cantidad = 1
  if (item.configuracion?.cantidad) {
    cantidad = item.configuracion.cantidad
  }
  return cantidad
}

export function resolucion() {
  if (window.innerWidth < 991) {
    return true
  } else {
    return false
  }
}

export function resolucion2() {
  if (window.innerWidth < 750) {
    return true
  } else {
    return false
  }
}
/* ----------------------------- HELPERS JQUERY ----------------------------- */

// CAMBIAR COLOR SVG (TEMPLATE ANTLER)
export function changeColorSvg() {
  $("img.svg").each(function () {
    var $img = jQuery(this);
    var attributes = $img.prop("attributes");
    var imgURL = $img.attr("src");
    $.get(imgURL, function (data) {

      var $svg = $(data).find("svg");
      $svg = $svg.removeAttr("xmlns:a");
      $.each(attributes, function () {
        $svg.attr(this.name, this.value);
        $svg.data('data-node', true);
      });
      $img.replaceWith($svg);

    });
  });

}
// rev-slider home-2 //

export function revinit_revslider11() {

  // function revinit_revslider111() {
  jQuery(function () {

    var tpj = jQuery;
    var revapi11 = tpj("#rev_slider_11_1");
    if (revapi11 == undefined || revapi11.revolution == undefined) {
      revslider_showDoubleJqueryError("rev_slider_11_1");
    } else {
      revapi11.revolution({
        sliderLayout: "fullwidth",
        visibilityLevels: "1240,1024,778,480",
        gridwidth: "1300,1024,778,480",
        gridheight: "850,700,550,449",
        spinner: "spinner0",
        perspective: 600,
        perspectiveType: "global",
        editorheight: "850,700,550,449",
        responsiveLevels: "1240,1024,778,480",
        progressBar: { disableProgressBar: true },
        navigation: {
          onHoverStop: false
        },
        parallax: {
          levels: [1, 3, 5, 20, 25, 30, 35, 40, 45, 46, 47, 48, 49, 50, 51, 30],
          type: "mouse",
          origo: "slidercenter",
          speed: 0
        },
        fallbacks: {
          allowHTML5AutoPlayOnAndroid: true
        },
      });
    }

  });
  // } // End of RevInitScript

  // var revapi1,
  //   tpj;
  // jQuery(function () {
  //   tpj = jQuery;
  //   revapi1 = tpj("#rev_slider_1_1");
  //   if (revapi1 == undefined || revapi1.revolution == undefined) {
  //     revslider_showDoubleJqueryError("rev_slider_1_1");
  //   } else {
  //     revapi1.revolution({
  //       sliderLayout: "fullwidth",
  //       visibilityLevels: "1240,1024,778,480",
  //       gridwidth: "1300,1024,778,480",
  //       gridheight: "850,700,550,449",
  //       spinner: "spinner0",
  //       perspective: 600,
  //       perspectiveType: "global",
  //       editorheight: "850,700,550,449",
  //       responsiveLevels: "1240,1024,778,480",
  //       progressBar: { disableProgressBar: true },
  //       navigation: {
  //         onHoverStop: false
  //       },
  //       parallax: {
  //         levels: [1, 3, 5, 20, 25, 30, 35, 40, 45, 46, 47, 48, 49, 50, 51, 30],
  //         type: "mouse",
  //         origo: "slidercenter",
  //         speed: 0
  //       },
  //       fallbacks: {
  //         allowHTML5AutoPlayOnAndroid: true
  //       },
  //     });
  //   }

  // });
} // End of RevInitScript



export function revinit_revslider21(element) {
  jQuery(function () {
    var tpj = jQuery;
    var revapi2 = tpj(element);
    if (revapi2 == undefined || revapi2.revolution == undefined) {
      revslider_showDoubleJqueryError("rev_slider_2_1");
    } else {
      revapi2.revolution({
        sliderLayout: "fullwidth",
        visibilityLevels: "1240,1024,778,480",
        gridwidth: "1300,1024,778,480",
        gridheight: "930,525,450,255",
        spinner: "spinner0",
        perspective: 600,
        perspectiveType: "global",
        editorheight: "930,525,450,255",
        responsiveLevels: "1240,1024,778,480",
        progressBar: { disableProgressBar: true },
        navigation: {
          onHoverStop: false
        },
        parallax: {
          levels: [1, 3, 5, 20, 25, 30, 35, 40, 45, 46, 47, 48, 49, 50, 51, 30],
          type: "mouse",
          origo: "slidercenter",
          speed: 0
        },
        fallbacks: {
          allowHTML5AutoPlayOnAndroid: true
        },
      });
    }

  });
} // End of RevInitScript

export function swiper_component_target(clase) {
  // console.log("se ejecuta swiper_component_target: ", clase);
  let allSwiper = document.querySelectorAll("swiperContent");
  // console.log("swiper_component_target, allswiper: ", allSwiper);

  var sliders = [];
  var slider;
  jQuery(clase).each(function (index, element) {

    var ele = jQuery(this);

    ele.addClass('s-target' + index);

    slider = new Swiper('.s-target' + index, {
      slidesPerView: ele.data('desk_num'),
      spaceBetween: ele.data('margin'),
      loop: ele.data('loop'),
      autoplay: ele.data('autoplay'),
      // speed: 2500,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination-dynamic',
        type: ele.data('pagination_type'),
        renderFraction: function (currentClass, totalClass) {
          return '<span class="' + currentClass + '"></span>' +
            ' <span class="pt-page-seprate">/</span> ' +
            '<span class="' + totalClass + '"></span>';
        },

      },

      breakpoints: {
        // when window width is >= 320px
        0: {
          slidesPerView: ele.data('mob_sm'),

        },
        480: {
          slidesPerView: ele.data('mob_num'),

        },
        // when window width is >= 480px
        767: {
          slidesPerView: ele.data('tab_num'),

        },
        // when window width is >= 640px
        1023: {
          slidesPerView: ele.data('lap_num'),

        },
        1199: {
          slidesPerView: ele.data('desk_num'),

        }
      }
    });

    sliders.push(slider);
  });
}

export function swiper_component() {
  var sliders = [];
  var slider;
  jQuery('.swiper-container').each(function (index, element) {

    var ele = jQuery(this);

    ele.addClass('s-component' + index);

    slider = new Swiper('.s-component' + index, {
      slidesPerView: ele.data('desk_num'),
      spaceBetween: ele.data('margin'),
      loop: ele.data('loop'),
      autoplay: ele.data('autoplay'),
      // speed: 2000,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination-dynamic',
        type: ele.data('pagination_type'),
        renderFraction: function (currentClass, totalClass) {
          return '<span class="' + currentClass + '"></span>' +
            ' <span class="pt-page-seprate">/</span> ' +
            '<span class="' + totalClass + '"></span>';
        },

      },

      breakpoints: {
        // when window width is >= 320px
        0: {
          slidesPerView: ele.data('mob_sm'),

        },
        480: {
          slidesPerView: ele.data('mob_num'),

        },
        // when window width is >= 480px
        767: {
          slidesPerView: ele.data('tab_num'),

        },
        // when window width is >= 640px
        1023: {
          slidesPerView: ele.data('lap_num'),

        },
        1199: {
          slidesPerView: ele.data('desk_num'),

        }
      }
    });

    sliders.push(slider);
  });
}

export function swiper_component_fast() {
  var sliders = [];
  var slider;
  jQuery('.swiper-container-fast').each(function (index, element) {

    var ele = jQuery(this);

    ele.addClass('s-fast' + index);

    slider = new Swiper('.s-fast' + index, {
      slidesPerView: ele.data('desk_num'),
      spaceBetween: ele.data('margin'),
      loop: ele.data('loop'),
      autoplay: ele.data('autoplay'),
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination-dynamic',
        type: ele.data('pagination_type'),
        renderFraction: function (currentClass, totalClass) {
          return '<span class="' + currentClass + '"></span>' +
            ' <span class="pt-page-seprate">/</span> ' +
            '<span class="' + totalClass + '"></span>';
        },

      },

      breakpoints: {
        // when window width is >= 320px
        0: {
          slidesPerView: ele.data('mob_sm'),

        },
        480: {
          slidesPerView: ele.data('mob_num'),

        },
        // when window width is >= 480px
        767: {
          slidesPerView: ele.data('tab_num'),

        },
        // when window width is >= 640px
        1023: {
          slidesPerView: ele.data('lap_num'),

        },
        1199: {
          slidesPerView: ele.data('desk_num'),

        }
      }
    });

    sliders.push(slider);
  });
}

export function swiper_component_fast_static() {
  var sliders = [];
  var slider;
  jQuery('.swiper-container-fast-static').each(function (index, element) {

    var ele = jQuery(this);

    ele.addClass('s-static' + index);

    slider = new Swiper('.s-static' + index, {
      slidesPerView: ele.data('desk_num'),
      spaceBetween: ele.data('margin'),
      loop: ele.data('loop'),
      // autoplay: {
      //   autoplay: true,
      //   delay: 5000,
      // },
      autoplay: ele.data('autoplay'),
      speed: 3000,
      
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination-static',
        type: ele.data('pagination_type'),
        renderFraction: function (currentClass, totalClass) {
          return '<span class="' + currentClass + '"></span>' +
            ' <span class="pt-page-seprate">/</span> ' +
            '<span class="' + totalClass + '"></span>';
        },

      },

      breakpoints: {
        // when window width is >= 320px
        0: {
          slidesPerView: ele.data('mob_sm'),

        },
        480: {
          slidesPerView: ele.data('mob_num'),

        },
        // when window width is >= 480px
        767: {
          slidesPerView: ele.data('tab_num'),

        },
        // when window width is >= 640px
        1023: {
          slidesPerView: ele.data('lap_num'),

        },
        1199: {
          slidesPerView: ele.data('desk_num'),

        }
      }
    });

    sliders.push(slider);
  });
}

export function swiper_simple(idElements){
  // console.log("se ejecuta 1swiper_simple desde el helper ", idElements);
      let allSwiper = document.querySelectorAll(`#${idElements}`);
      // let allSwiper = document.queryselectorAll(`[id^="${idElements}"]`);
      // console.log("all 1.5swiper start: ", allSwiper);
      setTimeout(()=>{
        // console.log("se ejecuta 2swiper_simple setTimeout", allSwiper);
        allSwiper.forEach((element, index) => {
          let autoplay = element.dataset.autoplay ? 'true' : 'false' ;
          let loop = element.dataset.loop ? true : false;
          if(autoplay === 'true'){
            // console.log("un elemento tiene autoplay: ", element);
            autoplay = {autoplay: true, delay: 5000}
          }
            var swiper = new Swiper(`.swiper-container-${index}`, {
              slidesPerView: 3,
              spaceBetween: 30,
              slidesPerGroup: 3,
              loop: loop,
              // centeredSlides: 'true',
              fade: 'true',
              grabCursor: 'true',
              autoplay: autoplay,
              pagination: {
                el: `.swiper-pagination-${index}`,
                clickable: true,
                dynamicBullets: true,
              },
              navigation: {
                nextEl: `.swiper-button-next-${index}`,
                prevEl: `.swiper-button-prev-${index}`,
              },
          
              breakpoints:{
                // Cuando el ancho de la pantalla sea >= NUMEMRO, se veran cierta cantidad de cards en pantalla
                  0: {
                      slidesPerView: 1,
                  },
                  900: {
                      slidesPerView: 2,
                  },
                  1280: {
                      slidesPerView: 3,
                  },
              },
            });
        });
        // console.log("3 swiper_simpleallswiper: ",allSwiper);
      },2000);

}

export function swiperCreate(key, loop = false, autoplay = false, time = 800, perView = {'slidesPerViewTwo': 2, 'slidesPerViewThree': 3, viewDynamic: true}){
  setTimeout(() => {
    let swiperCards = new Swiper(`.card__content_${key}`, {
      loop: false,
      spaceBetween: 32,
      grabCursor: true,
      loop: loop,
      autoplay: autoplay,
      pagination: {
        el: `.swiper-pagination_${key}`,
        clickable: true,
        dynamicBullets: true,
      },
    
      navigation: {
        nextEl: `.swiper-button-next_${key}`,
        prevEl: `.swiper-button-prev_${key}`,
      },
      
      breakpoints: (()=>{
        if(perView.viewDynamic == true){
          // console.log("es dinamico")
          return {

          600: {
            slidesPerView: perView.slidesPerViewTwo,
            // spaceBetween: 20
          },
          1260: {
            slidesPerView: perView.slidesPerViewThree,
            // spaceBetween: 40
          },
        };
      }else{

        return {}
      }
      })()
    });
    
  }, time);
}


export function selectBtn() {
  $(".custom-select").each(function () {
    var classes = $(this).attr("class"),
      id = $(this).attr("id"),
      name = $(this).attr("name");
    var template = '<div class="' + classes + '">';
    template += '<span class="custom-select-trigger">' + $(this).attr("placeholder") + '</span>';
    template += '<div class="custom-options">';
    $(this).find("option").each(function () {
      template += '<span class="custom-option ' + $(this).attr("class") + '" data-value="' + $(this).attr("value") + '">' + $(this).html() + '</span>';
    });
    template += '</div></div>';

    $(this).wrap('<div class="custom-select-wrapper"></div>');
    $(this).hide();
    $(this).after(template);
  });
  $(".custom-option:first-of-type").hover(function () {
    $(this).parents(".custom-options").addClass("option-hover");
  }, function () {
    $(this).parents(".custom-options").removeClass("option-hover");
  });
  $(".custom-select-trigger").on("click", function () {
    $('html').one('click', function () {
      $(".custom-select").removeClass("opened");
    });
    $(this).parents(".custom-select").toggleClass("opened");
    event.stopPropagation();
  });
  $(".custom-option").on("click", function () {
    $(this).parents(".custom-select-wrapper").find("select").val($(this).data("value"));
    $(this).parents(".custom-options").find(".custom-option").removeClass("selection");
    $(this).addClass("selection");
    $(this).parents(".custom-select").removeClass("opened");
    $(this).parents(".custom-select").find(".custom-select-trigger").text($(this).text());
  });
}


export function accordion() {
  jQuery('.pt-accordion-block .pt-accordion-box .pt-accordion-details').hide();
  jQuery('.pt-accordion-block .pt-accordion-box:first').addClass('pt-active').children().slideDown('slow');
  jQuery('.pt-accordion-block .pt-accordion-box').on("click", function () {
    if (jQuery(this).children('div.pt-accordion-details').is(':hidden')) {
      jQuery('.pt-accordion-block .pt-accordion-box').removeClass('pt-active').children('div.pt-accordion-details').slideUp('slow');
      jQuery(this).toggleClass('pt-active').children('div.pt-accordion-details').slideDown('slow');
    }
  });
}

export function masonry() {
  jQuery('.pt-masonry').isotope({
    itemSelector: '.pt-masonry-item',
    masonry: {
      columnWidth: '.grid-sizer',
      gutter: 0

    }

  });

  jQuery('.pt-grid').isotope({
    itemSelector: '.pt-grid-item',
  });

  jQuery('.pt-filter-button-group').on('click', '.pt-filter-btn', function() {

    var filterValue = jQuery(this).attr('data-filter');
    jQuery('.pt-masonry').isotope({
      filter: filterValue
    });
    jQuery('.pt-grid').isotope({
      filter: filterValue
    });
    jQuery('.pt-filter-button-group .pt-filter-btn').removeClass('active');
    jQuery(this).addClass('active');

  });

  var initial_items = 5;
  var next_items = 3;

  if (jQuery('.pt-masonry').length > 0) {
    var initial_items = jQuery('.pt-masonry').data('initial_items');
    var next_items = jQuery('.pt-masonry').data('next_items');
  }

  if (jQuery('.pt-grid').length > 0) {
    var initial_items = jQuery('.pt-grid').data('initial_items');
    var next_items = jQuery('.pt-grid').data('next_items');
  }
}

export function selectp() {
  $('#select_subcategory').selectpicker('refresh');
}



/* --------------------------- FIN HELPERS JQUERY --------------------------- */

export function onlyNumber($event) {
  let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if (keyCode < 48 || keyCode > 57) { // && keyCode !== 46 / 46 is dot
                $event.preventDefault();
            }
}
export function onlyNumberAndDash($event) {
  let keyCode = $event.keyCode ? $event.keyCode : $event.which
  if (
    (keyCode < 48 || keyCode > 57) &&
    keyCode !== 45 &&
    keyCode !== 107 &&
    keyCode !== 75
  ) {
    // && keyCode !== 46 / 46 is dot
    $event.preventDefault()
  }
}
// (keyCode >= 65 && keyCode <= 90 )
export function onlyLettersAndDot($event) {
  let keyCode = $event.keyCode ? $event.keyCode : $event.which
  if (
    ((keyCode < 65 || keyCode > 90) && (keyCode < 97 || keyCode > 122))
    && keyCode !== 46
  ) {
    // && keyCode !== 46 / 46 is dot
    $event.preventDefault()
  }
}
