<template>
  <main id="vpsSSD" class="ssdMain" style="min-height: 100vh;">
    <section class="ssdMain_portada">
      <BannerSlider :dataBannerSlider="dataBannerSlider" :styleSection = "{ padding: '100px 0px 0px' }" style="padding: 0px;" class="bannerSlider">
        <div class="BannerSlider_sliderCardPlans">
          <SliderCardPlans
              v-if="listServices.length > 0"
              :categorie="listServices"
              :styleBoxCard="{ width: widthCardsServices.width, min_width: widthCardsServices.min_width }"
              :infoCategory="{
                  category: dataServicios[0].categoria,
                  subCategory: dataServicios[0].subcategoria,
              }"
              :cardContact="false"
              keySlide="sliderAllServices"
              :configSwiper="{
                loop: false,
                autoplay: false,
                time: 800,
                perView: {'slidesPerViewTwo': 2, 'slidesPerViewThree': 4, viewDynamic: true}
              }"
          />
        </div>
        </BannerSlider>
    </section>

    <!-- info vps -->
    <section class="ssdMain_listResources ">
      <cardsHoverGradient class="wow animate__animated  animate__fadeInUp" :dataCards="dataMaxFlex" :dataTitleBox="dataTitleMaxFlex" position="center"/>
    </section>

    <!-- banner promo wave animated -->
    <section class="ssdMain_bannerAnimated">
      <bannerAnimated :dataBannerAnimated="dataBannerAnimated"/>
    </section>

    <!-- Ventajas vps -->
    <section class="ssdMain_listResources wow animate__animated animate__fadeInUp">
      <cardsHoverGradient class="wow animate__animated  animate__fadeInUp" :dataCards="dataAdvantages" :dataTitleBox="dataTitleAdvantages" position="center"/>
    </section>

    <section class="contactSimply">
      <div class="nubesComponent_top">
          <nubes/>
      </div>
      <ContactSimply idForm="vpsSSDForm1" :dataText="dataTextForm1"/>

      <div class="nubesComponent">
        <nubes/>
      </div>
    </section>

    <!-- <div class="BannerSlider_sliderCardPlans ssdMain_sliderCardPlans wow animate__animated animate__fadeInUp " id="viewAll" >
      <titleBox :dataTitleBox="dataTitleServicesAll" :configTitleBox="{'positionTitle': 'center' }"/>
          <SliderCardPlans
              v-if="listServices.length > 0"
              :categorie="listServices"
              :styleBoxCard="{ width: widthCardsServices.width, min_width: widthCardsServices.min_width }"
              :infoCategory="{
                  category: dataServicios[0].categoria,
                  subCategory: dataServicios[0].subcategoria,
              }"
              :cardContact="false"
              keySlide="sliderAllServices"
              :configSwiper="{
                loop: false,
                autoplay: false,
                time: 800,
                perView: {'slidesPerViewTwo': 2, 'slidesPerViewThree': 4, viewDynamic: true}
              }"
          />
    </div> -->

    <!-- better server -->
    <section class="ssdMain_minCards">
      <minCards class="wow animate__animated animate__fadeInUp" :dataCards="dataBestServer" :dataTitleBestServer="dataTitleBestServer"/>
    </section>

    <!-- servicios adicionales -->
    <section class="ssdMain_cardsHoverAnimated">
      <div class="nubesComponent_top"><nubes/></div>
      <cardHoverAnimated class="wow animate__animated animate__fadeInUp" :dataCards="dataAdditional" :dataTitleBox="dataTitleAdditional"/>
      <!-- <div class="nubesComponent"><nubes/></div> -->

    </section>

    <!-- Nuestros planes tambien incluyen -->
    <section class="ssdMain_list">
      <sectionGrid class="wow animate__animated animate__fadeInUp"/>
    </section>

    <!-- why -->
    <section class="ssdMain_minCards ssdMain_minCards_why">
      <div class="nubesComponent_top"><nubes/></div>

      <minCards class="wow animate__animated animate__fadeInUp" :dataCards="dataWhy" :dataTitleBestServer="dataTitleWhy"/>
      <div class="nubesComponent"><nubes/></div>

    </section>

    <!-- <section class="ssdMain_minBanner">
      <minBanner :dataBanner="dataBanner" />
    </section> -->

    <!-- Nuestros planes tambien incluyen -->
    <section class="ssdMain_list wow animate__animated animate__fadeInUp">
      <listDynamic :dataTitleBox="dataTitleList" :dataList="dataList"/>
    </section>

    <section class="contactSimply">
      <div class="nubesComponent_top">
          <nubes/>
      </div>
      <ContactSimply idForm="vpsSSDForm2" :reverse="true" :dataText="dataTextForm2"/>

      <div class="nubesComponent">
        <nubes/>
      </div>
    </section>

    <section class="ssdMain_boxOptions">
      <div class="nubesComponent_top"><nubes/></div>
      <boxOptions />

    </section>
  </main>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { swiper_simple } from "@/helpers";
import SliderCardPlans from "@/components/web/sliders/SliderCardPlans.vue";

import BannerSlider from  "@/components/web/sliders/BannerSlider.vue";
import cardsHoverGradient from "@/components/web/cards/cardsHoverGradient.vue";
import cardHoverAnimated from "@/components/web/cards/cardHoverAnimated.vue";
import minCards from "@/components/web/cards/minCards.vue";
import bannerAnimated from "@/components/web/bannerAnimated.vue";
import boxOptions from "@/components/web/boxOptions.vue";
// import minBanner from "@/components/web/minBanner.vue";
import sectionGrid from "@/components/web/sectionGrid.vue";
import listDynamic from "@/components/web/listDynamic.vue";
// import titleBox from "../../../components/web/titleBox.vue";
import nubes from "../../../components/web/nubes.vue";
import ContactSimply from "../../../components/web/forms/contactSimply.vue";

export default {
  components: {
    SliderCardPlans,
    BannerSlider,
    cardHoverAnimated,
    cardsHoverGradient,
    minCards,
    bannerAnimated,
    boxOptions,
    sectionGrid,
    listDynamic,
    nubes,
    ContactSimply
},
  data: ()=>({

    listServices: [], //guarda los servicios a mostrar

    // data para la seccion de inicio, el banner slider ---
    dataBannerSlider:{
      title: 'VPS',
      titleBold: 'SSD',
      subtitle: ' La libertad que buscas con máxima velocidad, nuestras VPS SSD son la mejor alternativa en cuanto a desempeño y precio.',
      // btn: {'title': 'Ver todos', 'type': 'link', 'url': '/vpsSSD#viewAll'},
      nameServiceForView: 'VPS SSD Menusal',
      keySlide: 'vpsSsdMensual',
      directionElement: true,
    },
  
    // dataTitleServicesAll: {
    //         title: 'Servicios VPS SSD',
    //         description: 'Que la rapidez no sea un problema con los discos SSD tendrás un 300% más velocidad que con discos mecánicos (HDD). Puedes solicitar cPanel&WHM, plesk o trabajar por línea de comando.',
    //         color: 'var(--primary-color)',
    // },

    // data para la seccion Estabilidad y continuidad operacional garantizada ---
    dataTitleMaxFlex: {
            title: 'Estabilidad y continuidad operacional garantizada',
            description: 'Máxima Velocidad y alto rendimiento para tu VPS SSD y Storage hibrido para soluciones de mayor redundancia. Conectividad directa a prácticamente todos los países de LATAM a través de PIT Chile.',
            color: 'var(--dark-color)',
    },
    dataMaxFlex:[
        {title: 'Conectividad TIER 1', img:'images/services/estabilidad_continuidad/Conectividad.png'},
        // {title: 'Storage Híbrido SSD', img: 'images/services/estabilidad_continuidad/Storage.png'},
        {title: 'Respaldo Energético - UPS en N+1', img: 'images/services/estabilidad_continuidad/Respaldo_energetico.png'},
        // {title: '', img: 'images/services/estabilidad_continuidad/UPS.png'},
        {title: 'Conectados a PIT Chile', img: 'images/services/estabilidad_continuidad/Conectados.png'},
        {title: 'Interconexión entre Data Center ', img: 'images/services/estabilidad_continuidad/Interconexion.png'},
    ],

    // banner animated ---
    dataBannerAnimated:{
      title: 'Invierta en un Servicio VPS Premium de primer nivel alojadas en Chile',
      paragraph: ' Contamos con una moderna infraestructura, capaz de albergar toda la información de nuestros clientes de forma segura y eficiente. Nuestro centro de procesamiento de datos es capaz de alojar miles de sitios, sin perder confianza y seguridad. Además, nos mantenemos en constante crecimiento.'
    },

    // data para ventajas ---
    dataTitleAdvantages: {
      title: 'Ventajas',
      description: '¡La flexibilidad es parte del crecimiento! Queremos acompañarte en cada paso, es por eso que contamos con plataformas flexibles.',
      color: 'var(--dark-color)',
    },
    dataAdvantages:[
      {title: 'Tráfico ilimitado ', img: 'images/services/ventajas/Trafico_limitado.png'},
      // {title: 'Soporte 24/7 ', img: 'images/services/ventajas/Soporte_24_7.png'},
      {title: 'Recursos Dedicados.', img: 'images/services/ventajas/Recursos_dedicados.png'},
      {title: 'Acceso Root ', img: 'images/services/ventajas/Acceso_root.png'},
      {title: 'I/O de 20x Mayor', img: 'images/services/ventajas/Rendimiento.png' },
      {title: '99.8% Uptime', img: 'images/services/ventajas/UPtime.png' },
    ],


    // data para la seccion BEST SERVER --- 
    dataTitleBestServer: {
            title: 'El mejor servidor VPS SSD',
            description: 'Disfrute de un espacio personalizado, especialmente diseñado para la gestión de sus sitios y aplicaciones web. ',
            color: 'var(--dark-color)',
    }, 
    dataBestServer:[
        {title: 'IP Dedicada', 'description': 'Dirección IP exclusiva y registro PTR personalizable para identificar su servidor virtual.', icon: 'fa fa-globe'},
        {title: 'Flexibilidad', 'description': 'Ofrecemos servidores virtuales adaptados a los sistemas operativos más populares, como nuestros VPS Linux o VPS Windows.', icon: 'fa fa-server'},
        {title: 'Transferencia Ilimitada', 'description': 'Tráfico de datos sin limitaciones para servidores virtuales.', icon: 'fa fa-exchange'},
        {title: 'Múltiples métodos de pagos', 'description': 'Paga con tus tarjetas de crédito y débito mediante WebPay Plus en 3 cuotas sin interés.'},
        {title: 'Soporte 24/7', 'description': 'Siempre que requieras asistencia estaremos disponibles para ayudarte a resolver tus inquietudes en el menor tiempo posible.', icon:'fa fa-phone'},
        {title: 'Entorno Escalable', 'description': ' Su VPS SSD se transforma para adaptarse a su evolución. Así pues, podrá aumentar sus recursos desde el área de cliente y podrá solicitar con un solo click.', icon: ''},
    ],


    // data para la seccion servicios adicionales ---
    dataTitleAdditional: {
            title: 'Servicios adicionales',
            description: 'Servicios disponibles con recargo. ¡Contáctanos para acceder a ellos!',
            color: 'var(--dark-color)',
    },
    dataAdditional:[
        {title: 'IP adicionales. ', img: 'images/services/servicios_adicionales/IP.png', url: '/cart'},
        {title: 'Certificados SSL.  ', img: 'images/services/servicios_adicionales/SSL.png', url: '/cart'},
        {title: 'Gestión Completa: Delega la administración de tu servidor a nuestros especialistas.', img: 'images/services/servicios_adicionales/Delegar.png', url: '/cart'},
        {title: 'Backups. ', img: 'images/services/servicios_adicionales/Backups.png', url: '/cart'},
        {title: 'Licencia Cpanel', 'img': 'images/services/ventajas/Recursos_dedicados.png'},
        {title: 'VLAN PRIVADA', 'img': 'images/services/estabilidad_continuidad/Conectados.png'},


    ],


    // data para la seccion WHY? ---
    dataTitleWhy: {
      title: '¿Por qué contratar un Servidor VPS?',
      color: 'var(--dark-color)',
    }, 
    dataWhy:[
      {title: 'Soporte de alto tráfico', 'description': 'Soportan sitios con alto tráfico que necesitan mayor rendimiento, mejor ancho de banda y más flexibilidad. ', icon: 'fa fa-bolt'},
      {title: 'Recursos Garantizados', 'description': 'Los VPS disponen de su propia RAM, CPU y espacio de almacenamiento. Esto favorece un óptimo procesamiento de datos y gestión de multitareas.'},
      {title: 'Escalable', 'description': 'Puedes comenzar con el servidor más pequeño y aumentar su capacidad cuando lo necesites, además de ofrecer almacenamiento RAID 10 con SSD.', icon: 'fa fa-level-up'},
    ],


    // data min Banner
    dataBanner: {
    title: 'Migración gratuita con Asistencia Técnica incluida en todos los planes',
    img: 'images/ilustraciones/001.png',
    infoAction: {
      up: 'Sin costos',
      text: 'Gratis',
      down: 'Ver servicios',
      url: '/cart'
    }
    },

    // data para la seccion Complementos potentes ---
    dataTitleList: {
      title: 'Complementos potentes',
      description: 'Los recursos de nuestros VPS SSD están totalmente asignados a usted, por lo que podrá administrar su servicio con total libertad para adaptarlo a las necesidades de su actividad.',
      color: 'var(--dark-color)',
    }, 
    dataList:{
      dataListServiceInfo:[
        {title: 'Alto Rendimiento y Performance', 'description': 'Procesador Intel Xeon CPU E7-4880 v2 @ 2.50GHz creado para el alto rendimiento y la mayor capacidad de memoria por socket de la industria.', icon: 'fa fa-check'},
        {title: 'Puertos de Red a 1 Gbps ',  icon: 'fa fa-check'},
        {title: 'VLAN privada a través de red pública y privada ',  icon: 'fa fa-check'},
        // {title: 'DNS geográficamente redundante ',  icon: 'fa fa-check'},
        // {title: 'Compatible con IPv4 e IPv6 ',  icon: 'fa fa-check'},
        // {title: 'Ancho de banda público de entrada ilimitado ',  icon: 'fa fa-check'},
        // {title: 'Ancho de banda de red privada ilimitado ',  icon: 'fa fa-check'},
        {title: 'Virtualización con Vmware calidad empresarial  ', 'description': 'Virtualización con Vmware calidad empresarial con visualización de alto rendimiento.',  icon: 'fa fa-check'},
      ],
      expande: false,
    },



    // formularios data;
    dataTextForm1:{
      title: '¡Solicita ayuda de un especialista!',
      subtitle: ' Deja tus datos y nuestro equipo técnico VPS se comunicará contigo'
    },
    dataTextForm2:{
      title: '¿Necesitas asistencia técnica con VPS?',
      subtitle: ' Escríbenos y uno de nuestros especialistas se comunicará contigo. ¿Cual es tu inquietud?'
    },
  }),
  computed: {
    // getters vuex start --
    ...mapGetters(["getServices_", "getData"]),
    // getters vuex end --

    dataServicios() {
      return this.getData?.web.servicios;
    },

    widthCardsServices(){

      if(window.innerWidth > 1300 || window.innerWidth <= 450){
        return {
          width: '380px',
          min_width: '340px',
          max_width: '385px'
        }
      }
      else{
        return {
          width: '290px',
          min_width: '285px',
          max_width: '300px'
        }
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.initComponent();
    }, 300);
  },
  methods: {
    // method vuex start --
    ...mapActions(["getServices"]),
    // method vuex end --

    // methods helper start --
    swiper_simple,
    // methods helper end --
    initComponent() {
      if (this.getData != null && this.getData.web) {
        // this.loading = true;
        // filtrar el arreglo servicios por el servicio a buscar
        let servicesSSD = this.getData.web.servicios.filter( (e) => e.nombre == this.dataBannerSlider.nameServiceForView);

        // obtener la data de los servicios a buscar
        this.getServices(servicesSSD[0].subcategoria).then((res) => {

          // Funcion que ordena los servicios de su categoria de menor a mayor por su precio
          this.listServices = this.categoriesSort(res);
          
          // activar el swiper
          this.swiper_simple("swiperContent");
        });
        // setTimeout(() => (this.loading = false), 500);
      } else {
        this.initComponent();
      }
    },

    // Ordena los servicios/categorias por el precio de menor a mayor
    categoriesSort(service) {
      return service.sort((a, b) => a.precio - b.precio);
    },
  }
};
</script>

<style scoped>
.ssdMain {
  min-height: 100vh;
  /* padding: 55px 0px 20px; */
}
.bannerSlider{
  padding: 100px 0px !important;
}
.BannerSlider_sliderCardPlans{
  min-height: 735px;
}

.ssdMain_portada,
.ssdMain_minCards,
.ssdMain_bannerAnimated,
.ssdMain_boxOptions,
.ssdMain_list,
.ssdMain_cardsHoverAnimated,
.ssdMain_minBanner, .contactSimply, .ssdMain_listResources {
  position: relative;
  padding: 0px;
  overflow: hidden;
}

.ssdMain_cardsHoverAnimated{
  padding: 80px 0px 70px;
}

.ssdMain_list{
  padding: 40px 0px;
}

.contactSimply{
  background: var(--grey-color);
  padding: 40px 0;
  
}

.ssdMain_sliderCardPlans{
  padding: 100px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.ssdMain_minCards_why{
  padding: 100px 0px;
}
.ssdMain_minCards_why, .ssdMain_minBanner{
  background: var(--grey-color);
}

.ssdMain_cardsHoverAnimated{
  /* padding: 90px 0px 20px; */
}
.nubesComponent{
  position: absolute;
  left: 0;
  bottom: -72px;
}
.nubesComponent_top{
  position: absolute;
    top: 65px;
    left: 0;
    z-index: 5;

}

@media (max-width: 1300px) {
    .bannerSlider {
        flex-direction: column;
        padding: 50px 0px 100px !important;
    }
}
@media (max-width: 1160px) {
  .ssdMain {
  padding: 0px 0px 20px;
}

}

@media (max-width: 900px) {
  .ssdMain {
    /* padding: 10px 0px; */
  }
.ssdMain_minCards,
.ssdMain_bannerAnimated,
.ssdMain_boxOptions,
.ssdMain_list,
.ssdMain_cardsHoverAnimated,
.ssdMain_minBanner, .contactSimply, .ssdMain_listResources {
  padding: 40px 0px;
}
.ssdMain_portada{
  padding: 0px;
}
.contactSimply{
  padding: 40px 10px;
}
}


@media (max-width: 600px){
  .bannerSlider{
  padding: 20px 0px 0px !important;
}
}
</style>
