<template>
    <div class="ResumenPay_data">
        <div class="">

            <div v-if="response == 2 || response == 0">
                <p class="alert alert-danger" role="alert" style="min-height: 53px">
                    <span>Ha ocurrido un error al procesar el pago.</span>
                </p>

                <div>

                    <h4 style="font-size: 19px">RESUMEN</h4>
                    <p>Estado: <span style="color: red">Pago rechazado</span></p>

                    <p v-if="!esPagoRapido && listpay.length > 0 " class="mb-4">
                        Recordamos a usted que se ha emitido la nota de cobro
                        <span v-for="nota in listpay" :key="nota.id"> "{{ nota.id }}", </span> por un monto de ${{
                            formatNumber(notasCobroNeto) }}, la cual puede ser cancelada hasta el día
                        {{
                            sumarDias(new Date(), 5).toLocaleDateString("es-ES", {
                                weekday: "long",
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                            })
                        }}.
                    </p>

                    <div v-if="listpay.length > 0 && !pdfView" class="col-md-12 px-0" style="margin-top: -20px">
                        <h5 class="price" style="font-size: 17px; margin: 5px 0px"> REINTENTAR PAGO POR: ${{
                            formatNumber(notasCobroNeto) }} </h5>

                        <transition name="fade">
                            <span v-show="error_" class="error-nc-v-resume">
                                Error al reintentar el pago.
                            </span>
                        </transition>

                        <button @click="finishOrder" alt="Reintentar Pago" aria-label="Reintentar Pago"
                            class="btn btn-default-grad-purple-fill white-font btn-default-grad-purple-fill-black mt-3"
                            style="width: 100%; height: 52px" :disabled="!getUser && !this.esPagoRapido"
                            :class="[loading ? 'verify-class' : 'normal-class']">
                            <span v-if="!loading" class="spinner-span" style="top: -4px; position: relative">
                                Reintentar Pago
                                <i class="fas fa-arrow-alt-circle-right spinner-i"></i>
                            </span>
                            <div v-else class="spinner-border text-info" style="max-height: 30px" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </button>
                    </div>

                    <a @click="$emit('clearPago')" :href="urlIsm"
                        class="btn btn-default-grad-purple-fill white-font btn-default-grad-purple-fill-black mt-3"
                        style="width: 100%; height: 52px; margin-top: 10px;">
                        <span style="top: 8px; position: relative">Ir al portal</span>
                    </a>

                    <div class="col-md-12 px-0 container-sc-v">
                        <a href="/cart" class="seguir-comprando-btn mt-2 pt-2">Nuestros Servicios</a>
                    </div>

                </div>

            </div>

            <div v-else-if="response == 1">
                <div class="alert alert-success" role="alert" style="min-height: 53px">
                    <span>Hemos recibido tu pago exitosamente y procederá a ser facturado a la brevedad.
                    </span>
                </div>
                <h4 style="font-size: 19px">RESUMEN</h4>

                Estado: <span style="color: green"> Pago aceptado </span> <br />
                <span>
                    Fecha:{{ listpay[0].fecha_pago }}</span><br />

                <h5 style="font-size: 13px">
                    TARJETA: XXXXXX XXXXXXXX{{ dataPay.card_number }} - {{ dataPay.type }}
                </h5>

                <h5 style="font-size: 13px">
                    CODIGO DE AUTORIZACIÓN: {{ dataPay.authorization_code }}
                </h5>

                <h5 v-if="dataPay.cuota_monto" style="font-size: 13px">
                    MONTO CUOTA: {{ formatNumber(parseInt(dataPay.cuota_monto)) }}
                </h5>

                <h5 v-if="dataPay.cuota_canitidad && dataPay.type == 'Credito'" style="font-size: 13px">
                    NÚMERO DE CUOTAS: {{ dataPay.cuota_canitidad }}<br />
                </h5>

                <h5 v-if="!dataPay.cuota_monto && dataPay.type == 'Credito'" style="font-size: 13px">
                    El valor de cada cuota dependerá del banco al que pertenece tu tarjeta.<br />
                </h5>

                <div style="text-align: right">
                    <h5 class="price" style="font-size: 17px">
                        NETO: ${{
                            formatNumber(
                                Math.round(
                                    notasCobroNeto -
                                    (notasCobroNeto / 100) * 19
                                )
                            )
                        }}
                    </h5>
                    <h5 class="price" style="font-size: 17px">
                        IVA: ${{ formatNumber(Math.round((notasCobroNeto / 100) * 19)) }}

                    </h5>
                    <h5 class="price" style="font-size: 17px">
                        TOTAL: ${{ formatNumber(notasCobroNeto) }}
                    </h5>
                </div>

                <div style="text-align: right">
                    <img class="mt-4" style="width: 127px" src="images/final_stamp.png" alt="ihosting_stamp" />
                </div>

                <!-- buttons -->
                <div v-if="!pdfView" class="col-md-12 px-0">
                    <a @click="$emit('clearPago')" href="/#home"
                        class="btn btn-default-grad-purple-fill white-font btn-default-grad-purple-fill-black mt-3"
                        style="width: 100%; height: 52px">
                        <span style="top: 8px; position: relative">Finalizar</span>
                    </a>
                    <a @click="$emit('clearPago')" :href="urlIsm"
                        class="btn btn-default-grad-purple-fill white-font btn-default-grad-purple-fill-black mt-3"
                        style="width: 100%; height: 52px; margin-top: 10px;">
                        <span style="top: 8px; position: relative">Ir al portal</span>
                    </a>

                    <div class="col-md-12 px-0 container-sc-v">
                        <a href="/cart" class="seguir-comprando-btn mt-2 pt-2">Nuestros Servicios</a>
                    </div>

                    <div class="col-md-12 px-0 container-sc-v">
                        <a href="#" @click="$emit('generateReport')" class="seguir-comprando-btn mt-2 pt-2"><span
                                v-if="response == 1">Descargar Comprobante</span>
                            <!-- <span v-else-if="response != 1 && getResume.length != 0">Descargar Notas de cobro pendientes</span> -->
                        </a>
                    </div>

                </div>
            </div>

            <div v-else>
                <div class="col-xl-12 col-md-12" style="padding: 2px !important;">
                    <div class="pt-icon-box-style7 mt-md-0 shadow-card" style="min-height: 160px">
                        <div class="pt-icon-box-content" style="text-align: center">
                            <h4 style="font-size: 19px">DATOS DE TRANSFERENCIA</h4>
                            <p class="pt-icon-box-description">
                                Titular Cuenta: ihosting Servicios Internet Ltda.<br />
                                Banco de Chile<br />
                                RUT: 76.351.470-6<br />
                                Número Cuenta: 058-01624-04
                            </p>

                            <h5 class="price" style="font-size: 17px">
                                NETO: ${{
                                    formatNumber(
                                        Math.round(
                                            this.dataPay -
                                            (this.dataPay  / 100) * 19
                                        )
                                    )
                                }}
                            </h5>
                            <h5 class="price" style="font-size: 17px">
                                IVA: ${{ formatNumber(Math.round((this.dataPay  / 100) * 19)) }}
                            </h5>
                            <h5 class="price" style="font-size: 17px">
                                TOTAL: ${{ formatNumber(this.dataPay ) }}
                            </h5>

                            <br />

                            <div class="col-md-12">
                                <p v-if="!esPagoRapido && listpay.length == 1" class="mb-3">
                                    Recordamos a usted que se ha emitido la nota de cobro <span v-for="id in idsNotasCobro"
                                        :key="id"> "{{ id }}", </span>por un monto
                                    de ${{ formatNumber(notasCobroNeto) }}, la cual puede ser
                                    cancelada hasta el día
                                    {{
                                        sumarDias(new Date(), 5).toLocaleDateString("es-ES", {
                                            weekday: "long",
                                            year: "numeric",
                                            month: "long",
                                            day: "numeric",
                                        })
                                    }}.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <a @click="$emit('clearPago')" :href="urlIsm"
                    class="btn btn-default-grad-purple-fill white-font btn-default-grad-purple-fill-black mt-3"
                    style="width: 100%; height: 52px; margin-top: 10px;">
                    <span style="top: 8px; position: relative; margin: 10px 0px;">Ir al portal</span>
                </a>
                <div class="col-md-12 px-0 container-sc-v">
                    <a href="/cart" class="seguir-comprando-btn mt-2 pt-2">Nuestros Servicios</a>
                </div>
            </div>

        </div>

    </div>
</template>


<script>
import { mapGetters } from "vuex";

import {
    formatNumber,
} from "@/helpers";

export default {
    props: {
        listpay: {
            default: () => [],
            type: Array
        },
        dataPay: {
            default: null,
        },
        esPagoRapido: {
            default: false,
            type: Boolean
        },
        error_:{
            default: false,
            type: Boolean
        },
        response: {
            default: 0,
        },
        notasCobroNeto: {
            default: 0,
            type: Number
        },
        pdfView: {
            default: false,
            type: Boolean
        }
    },

    data: () => ({
        loading: false,
        urlIsm: process.env.VUE_APP_PORTAL_URL,
    }),

    computed:{
        ...mapGetters(["getUser", "getResume", "getToken"]),

    },

    methods: {
        formatNumber,
        sumarDias(fecha, dias) {
            fecha.setDate(fecha.getDate() + dias);
            return fecha;
        },

        idsNotasCobro() {
            let ids = [];
            // iterar solo si hay elementos
            if(this.listpay.length > 0){
                this.listpay.forEach((value, index) => {
                    ids.push(value.id);
                });
            }
            return ids;
        },

        generateReport() {
            this.$emit("prueba");
        },
        finishOrder(){
            this.loading = true;
            this.$emit('finishOrder');
            setTimeout(() => {
                this.loading = false
            }, 2000);
        }
    },
}


</script>

<style>
/* RESUMEN DATA */
.ResumenPay_data {
    width: 35%;
    overflow: hidden;
    border-radius: 10px;
}
</style>