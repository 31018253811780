<template>
  <div class="cardHoverAnimated" >
    <titleBox :dataTitleBox="dataTitleBox"/>
    <div class="cardHoverAnimated_content">
      <div v-for="(item, index) in dataCards" :key="index" class="ag-courses_item" :class="{'cardHoverAnimated_link': item.icon}">
        <a class="ag-courses-item_link"  @click="changePage(item.url)">
        <!-- <a class="ag-courses-item_link" :href="item.url"> -->
          <div class="ag-courses-item_bg"></div>
          <div v-if="item.img" class="imageBx">
            <img  :src="item.img" class="imageBx_img" alt="">
          </div>
          <div v-if="item.icon"  class="imageBx imageBx_iconBx" :class="{'imageBx_iconBx_url': item.img}">
            <i :class="item.icon" class="imageBx_iconBx_icon"></i>
          </div>
          <div v-if="item.title" class="ag-courses-item_title">{{ item.title }}</div>
          <div class="ag-courses-item_date-box">
            <a  class="ag-courses-item_date"> Ver servicios </a>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import titleBox from '@/components/web/titleBox.vue';
export default{
  components: {
    titleBox
  },
  props:{
    dataCards: {
      default: ()=>({})
    },
    dataTitleBox: {
      default: ()=>({})
    }
  },
  methods: {
    changePage(url){
      window.location.replace(url);
    }
  },
}

</script>



<style scoped>
.cardHoverAnimated{
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding: 180px 0px 140px; */
}
.cardHoverAnimated_link{
  cursor: pointer;
}

.cardHoverAnimated_content{
    width: 100%;
    min-height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1430px;
}
.ag-courses_item {
  margin: 20px;
    overflow: hidden;
    border-radius: 28px;
    box-shadow: 0px 5px 20px 0px rgba(0, 81, 250, 0.1);

}
.ag-courses-item_link {
  display: block;
    padding: 30px 20px;
    height: 180px;
    background-color: var(--dark-color);
    overflow: hidden;
    position: relative;
    text-decoration: none;
    width: 270px;
}

.ag-courses-item_link:hover .ag-courses-item_title,
.ag-courses-item_link:hover .ag-courses-item_date {
  color: white;
}
.ag-courses-item_link:hover .ag-courses-item_bg {
  -webkit-transform: scale(10);
  -ms-transform: scale(10);
  transform: scale(10);
}

.ag-courses-item_link:hover .imageBx_img{
  width: 60px;
  height: 60px;
}
.ag-courses-item_title {
  min-height: 57px;
  margin: 0 0 30px;
  overflow: hidden;
  font-weight: bold;
  font-size: 14px;
  color: white;
  z-index: 2;
  position: relative;
}

.imageBx{
  position: absolute;
    right: 30px;
    bottom: 14px;
}

.imageBx_img{
  width: 45px;
  height: 45px;
  transition: .3s;
}

.imageBx_iconBx{
  right: 10px;
  bottom: 0px;
}

.imageBx_iconBx_icon{
  font-size: 30px;
}

.imageBx_iconBx_url{
  right: 2px;
}

.ag-courses-item_date-box {
  font-size: 18px;
  color: #fff;
  z-index: 2;
  position: absolute;
  bottom: 20px;
  display: none;
}
.ag-courses-item_date {
  font-weight: bold;
  color: var(--primary-color);
  -webkit-transition: color 0.5s ease;
  -o-transition: color 0.5s ease;
  transition: color 0.5s ease;
}
.ag-courses-item_bg {
  height: 130px;
  width: 100px;
  background-color: var(--primary-color);
  z-index: 1;
  position: absolute;
  top: -75px;
  right: -75px;
  border-radius: 50%;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

@media (max-width: 1249px){

.cardHoverAnimated{
  /* padding: 100px 0px 60px;   */
}
  .cardHoverAnimated_content{
    max-width: 830px;
  }
}

@media (max-width: 790px){
  .cardHoverAnimated_content{
    flex-direction: column;
  }
}
</style>