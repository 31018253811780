<template>
  <span class="span-header-v px-0" style="width: 100%; margin-bottom: 32px">
    <h3 class="col-9 col-md-9 title-bar-left2 mt-0" style="padding: 0">
      <b v-if="titles">
        <span v-if="step_config == 1">
          {{ titles.title1 | uppercase }}
        </span>
        <span v-else>
          {{ titles.title2 | uppercase }}
        </span>
      </b>
      <b v-else>{{ title | uppercase }} </b>
    </h3>

    <h3 class="col-3 col-md-3 title-bar-right2 mt-0" style="padding: 0">
      <a
        v-if="back_ && step"
        @click="goToCategory"
        :class="{ 'disabled-back': !loading }"
        class="link"
        style="cursor: pointer"
      >
        <i class="fa fa-angle-double-left" aria-hidden="true"></i>
      </a>
      <a
        v-if="back_ && !step"
        @click="back"
        :class="{ 'disabled-back': !loading }"
        class="link"
        style="cursor: pointer"
      >
        <i class="fa fa-angle-double-left" aria-hidden="true"></i>
      </a>
    </h3>
  </span>
</template>

<script>
export default {
  props: ["title", "back_", "loading", "step_config", "titles", "step"],
  data: () => ({}),
  methods: {
    back() {
      this.$emit("back");
    },
    goToCategory() {
      this.$emit("goToCategory");
    },
  },
  filters: {
    uppercase: function (v) {
      if (v) return v.toUpperCase();
    },
  },
};
</script>

<style scoped>
.span-header-v {
  display: flex;
  flex-flow: row nowrap;
}
@media (max-width: 576px) {
  .span-header-v {
    width: 100%;
    padding: 0;
  }
}
</style>
