<template>
    <article class="cardFull">

        <div class="cardFull_text">
            <h3 class="cardFull_text_title" :style="`color:${contentCard.color_title}`">{{ contentCard.title }}</h3>
            <p class="cardFull_text_paragraph">{{ contentCard.paragraph }}</p>
        </div>

        <div class="cardFull_iconBx">
            <img class="cardFull_iconBx_img" :src="contentCard.icon" alt="">
        </div>

    </article>
</template>

<script>
export default{
    props:{
        contentCard:{
            default: ()=>({
                title: 'Soporte y Atención en Español',
                paragraph: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum expedita enim corporis natus ad libero reprehenderit officiis porro minus mollitia accusamus blanditiis, excepturi debitis placeat perspiciatis assumenda fuga quaerat doloribus.',
                icon: 'images/services/azure/services_additionals/CLOUDVPS.png',
                color_title: '#194960'
            })
        }
    }
}
</script>

<style scoped>
    .cardFull{
        position: relative;
        width: 100%;
        max-width: 850px;
        min-height: 250px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 25px 35px;
        box-shadow: -1px 3px 8px 4px #0000000f;
        margin: 30px 0px;
    }
    .cardFull_text{
        width: 70%;
    }
    .cardFull_text_title{
        font-size: 34px;
        margin-bottom: 15px;
    }
    .cardFull_text_paragraph{
        font-size: 15px;
        font-weight: 500;
    }
    .cardFull_iconBx{
        width: 20%;
        display: flex;
        justify-content: center;
        height: 100%;
        min-height: 150px;
        position: absolute;
        right: 0px;
        top: 25px;
    }
    .cardFull_iconBx_img{
        max-height: 70px;
    }

    @media(max-width:1400px){
        .cardFull_text_paragraph{
            font-size: 14px;
        }

        .cardFull_text_title{
            font-size: 28px 
        }
    }

    @media(max-width:600px){
        .cardFull_text{
        width: 85%;
        }
        .cardFull_iconBx{
            width: 10%;
            right: 24px;
        }
        .cardFull_iconBx_img{
            max-height: 50px !important;
        }
    .cardFull_text_title{
        font-size: 20px;
        line-height: 30px;
    }
    }
</style>