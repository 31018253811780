<template>
  <div>
    <!-- ***** ELIMINAR SERVICIO CARRITO ***** -->
    <div class="modal fade-in" id="myModal" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              alt="ocultar modal" aria-label="ocultar modal"
              type="button"
              class="close"
              data-dismiss="modal"
              @click="deleteItem = null"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title"><b>Eliminar servicio</b></h4>
          </div>
          <div class="modal-body">
            <p>Seguro que quieres eliminar el servicio?</p>
          </div>
          <div class="modal-footer">
            <button
              @click="deleteItem = null"
              alt="no elimina servicio del carrito" aria-label="no elimina servicio del carrito"
              type="button"
              class="btn btn-default-purple-fill"
              data-dismiss="modal"
              style="margin-right: 10px"
            >
              No
            </button>
            <button
              @click="deleteItemConfirm"
              alt="si elimina servicio del carrito" aria-label="si elimina servicio del carrito"
              type="button"
              class="btn btn-default-grad-purple-fill white-font btn-default-grad-purple-fill-black"
              data-dismiss="modal"
            >
              Si
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- ***** ELIMINAR TODOS LOS SERVICIO CARRITO ***** -->
    <div class="modal fade-in" id="deleteAllModal" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button 
              alt="cerrar modal" aria-label="cerrar modal"
              type="button" class="close" data-dismiss="modal">
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title"><b>Eliminar servicios</b></h4>
          </div>
          <div class="modal-body">
            <p>Seguro que quieres eliminar todos los servicios del carrito?</p>
          </div>
          <div class="modal-footer">
            <button
              alt="para no eliminar todos los servicios del carrito" aria-label="para no eliminar todos los servicios del carrito"
              type="button"
              class="btn btn-default-purple-fill"
              data-dismiss="modal"
              style="margin-right: 10px"
            >
              No
            </button>
            <button
              @click="deleteAllServicesCart"
              alt="para si eliminar todos los servicios del carrito" aria-label="para si eliminar todos los servicios del carrito"
              type="button"
              class="btn btn-default-grad-purple-fill white-font btn-default-grad-purple-fill-black"
              data-dismiss="modal"
            >
              Si
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  data: () => ({
    deleteItem: null,
  }),
  computed: {
    ...mapGetters(["getSelected"]),
  },
  methods: {
    ...mapMutations(["deleteService", "clearCart"]),
    deleteItemConfirm() {
      if (this.currentStep == 3) {
        this.deleteDetail(this.getSelected);
      } else {
        this.deleteService(this.getSelected);
      }
    },
    deleteAllServicesCart() {
      this.$Progress.start();

      this.clearCart();
      this.$toast.success("Carrito limpiado.");
      this.$Progress.finish();
    },
  },
};
</script>
