import { render, staticRenderFns } from "./titleBox.vue?vue&type=template&id=40452b01&scoped=true&"
import script from "./titleBox.vue?vue&type=script&lang=js&"
export * from "./titleBox.vue?vue&type=script&lang=js&"
import style0 from "./titleBox.vue?vue&type=style&index=0&id=40452b01&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40452b01",
  null
  
)

export default component.exports