<template>
    <section class="container">
      <div class="card__container swiper">
         <div class="card__content" :class="`card__content_${keySlide}`">
            <div  class="swiper-wrapper">
               <article v-for="(item, index) in items" :key="index" class="card__article swiper-slide">
                    <div v-if="typeInfo == 'info'">
                        <div class="boxInfo">
                            <div class="boxInfo_header">
                                <div class="boxInfo_header_icon">
                                    <i v-if="item.icon" :class="item.iconClass"></i>
                                    <i v-if="!item.icon"><img :src="item.img" :alt="item.title"></i>
                                </div>
                                <h4 class="boxInfo_header_title">{{ item.title }}</h4>
                            </div>
                            <div class="boxInfo_body">
                                <p>{{ item.info }}</p>
                            </div>
                        </div>
                    </div>
                 <!-- <Card :serviceProp="item" :index="index" :styleBoxCard="{width: '380px'}" :dataServicios="dataServicios" :imageCard="imageCard"></Card> -->
               </article>      
            </div>
         </div>
         <!-- Pagination -->
         <div class="swiper-pagination" :class="`swiper-pagination_${keySlide}`"></div>
      </div>
   </section>
</template>
<script>
import {swiperCreate} from "@/helpers";
export default{
name: 'SliderCardInfo',
components:{
},
props:{
    items:{
        default: ()=>{
            return []
        }
    },
    keySlide:{
        type: String,
        default: ""
    },
    typeInfo: {
        default: 'info',
        type: String,
    }
 },
 methods: {
     swiperCreate,
     
 },
 mounted() {
    // crea una instancia de swiperjs con el nombre que recibimos como prop
    //params:  keySlide: 'nombredelSwiperUNICO', loop: false, autoplay: false, time: 800 (tiempo de espera antes de ejecutar)
   this.swiperCreate(this.keySlide, true, true, 700);
 }
}
</script>

<style scoped>

/*=============== VARIABLES CSS ===============*/
:root {
/*========== Colors ==========*/
/*Color mode HSL(hue, saturation, lightness)*/
--second-color: hsl(195, 75%, 52%);
--dark-color: hsl(212, 40%, 12%);
--white-color: hsl(212, 4%, 95%);
--body-color: hsl(212, 42%, 15%);

/*========== Font and typography ==========*/
/*.5rem = 8px | 1rem = 16px ...*/
--body-font: "Bai Jamjuree", sans-serif;
--h2-font-size: 1.25rem;
--normal-font-size: 1rem;
}

/*=============== BASE ===============*/
* {
box-sizing: border-box;
padding: 0;
margin: 0;
}

section {
font-family: var(--body-font);
font-size: var(--normal-font-size);
background-color: var(--body-color);
color: var(--white-color);
}

a {
text-decoration: none;
}

img {
display: block;
max-width: 100%;
height: auto;
}

/*=============== CARD ===============*/
.container {
display: flex;
justify-content: center;
align-items: flex-start;
height: 100%;
width: 100%;
margin: 0 auto 10px;
}

.card__container {
padding-block: 1rem;
/* background: gray; */
max-width: 1280px;
height: auto;
min-height: 90%;
padding-bottom: 35px;
}
/* @media (max-width: 1300px){
  .card__container{
    max-width: 1140px;
  }
} */
.card__content {
margin-inline: 1.75rem;
border-radius: 1.25rem;
overflow: hidden;
padding: 15px;
}

.card__article {
width: 300px; /* Remove after adding swiper js */
border-radius: 1.25rem;
overflow: hidden;
display: flex;
justify-content: center;
box-shadow: 0px 0px 15px 0px rgb(0 36 80 / 10%);
background: #fff;


}
/* Swiper class */
.swiper-button-prev:after,
.swiper-button-next:after {
content: "";
}

.swiper-button-prev,
.swiper-button-next {
width: initial;
height: initial;
font-size: 1.4rem;
color: var(--second-color);
background: var(--primary-color);
box-shadow:1px 1px 8px 0px rgb(4 70 151 / 88%);
border-radius: 40px;
height: 45px;
width:45px;
display: flex;
align-items: center;
justify-content: center;
text-align: center;
top: 275px !important;
/* padding: 2px; */
/* display: none; */
}

.swiper-button-prev {
left: 2rem;
}

.swiper-button-next {
right: 2rem;
}
.swiper-pagination{
margin-top: 10px;
transform: translateX(-50%) translateY(10px) !important;
}

.swiper-pagination-bullet {
background-color: hsl(212, 32%, 40%);
opacity: 1;
z-index: 10;
}

.swiper-pagination-bullet-active {
background-color: var(--second-color);
}

/* boxInfo START */
.boxInfo{
    min-height: 405px;
    border-radius: 1.25rem;
    padding: 30px;

}

.boxInfo_header_icon{
    margin: 0px 0px 20px;
}

.boxInfo_header_icon i {
    background: var(--grey-color);
    color: var(--primary-color);
    width: 104px;
    height: 104px;
    line-height: 104px;
    font-size: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    -webkit-border-radius: 900px;
    -moz-border-radius: 900px;
    border-radius: 900px;
    margin: auto;
}

.boxInfo_header_icon i img {
    line-height: 50px;
    display: block;
    height: 60px;
    width: 60px;
}

.boxInfo_header_title{
    color: var(--dark-color);
    font-family: var(--title-fonts);
    font-size: 28px;
    font-weight: 600;
    font-style: normal;
    line-height: 36px;
    text-align: center;
    border-bottom: 2px solid var(--grey-color);
    padding-bottom: 15px;
    /* margin-bottom: 15px; */
}


.boxInfo_body{
    margin-bottom: 1rem !important;
    font-size: 15px;
    color: #606C7A;
    text-align: center;
}

/* boxInfo END */



/*=============== BREAKPOINTS ===============*/
@media(max-width:768px){
.container {
 margin: 10px auto 10px;

}
}
/* For medium devices */
@media screen and (min-width: 768px) {

.card__content {
 margin-inline: 2rem;
}
}
</style>