var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"defender"}},[_c('SliderTextImages',{attrs:{"dataSlider":_vm.dataSliderTextImages}},[_c('div',{staticClass:"sliderText_imageBx"},[_c('img',{attrs:{"src":"/images/services/microsoft_defender/bannerImage.webp"}})])]),_c('section',{staticClass:"section"},[_c('div',{staticClass:"section_container"},[_vm._m(0),_c('div',{staticClass:"section_container_right"},[_c('titleBox',{attrs:{"dataTitleBox":_vm.titleBox_for_whySection,"configTitleBox":{'positionTitle': _vm.titleBox_for_whySection.positionTitle }}}),_vm._m(1)],1)])]),_c('section',{staticClass:"section",attrs:{"id":"contact"}},[_c('div',{staticClass:"section_container"},[_c('div',{staticClass:"section_container_left section_container_left_withPadding"},[_c('titleBox',{attrs:{"dataTitleBox":_vm.titleBox_for_contactFirst,"configTitleBox":{'positionTitle': _vm.titleBox_for_contactFirst.positionTitle }}})],1),_c('div',{staticClass:"section_container_right"},[_c('div',{staticClass:"section_container_right_ContactBx"},[_c('ContactSimply',{attrs:{"ViewDataForm":{
                            stateInputs:{
                            nombre: true,
                            email: true,
                            telefono: true,
                            asunto: false,
                            motivo: false,
                            mensaje: true,
                            
                        },
                        withBLur: false,
                        asunto: 'Consulta Microsoft Defender',
                        motivo: 'Consulta comercial Microsoft Defender'
                    },"titleForm":"Contacto","idForm":"ContactoMicrosoft1"}})],1)])])]),_c('section',{staticClass:"section section_grayColor"},[_c('div',{staticClass:"nubesComponent_top"},[_c('nubes')],1),_c('div',{staticClass:"section_container section_container_column"},[_c('div',{staticClass:"section_container_left section_container_left_widthFull"},[_c('titleBox',{attrs:{"dataTitleBox":_vm.titleBox_for_benefits,"configTitleBox":{'positionTitle': _vm.titleBox_for_benefits.positionTitle }}})],1),_c('div',{staticClass:"section_container_right section_container_right_widthFull"},_vm._l((_vm.dataBenefits),function(benefit,indexBenefit){return _c('div',{key:indexBenefit,staticClass:"cardInfo"},[_c('div',{staticClass:"cardInfo_head"},[_c('img',{staticClass:"cardInfo_head_img",attrs:{"src":benefit.image,"alt":""}}),_c('h3',{staticClass:"cardInfo_head_title"},[_vm._v(_vm._s(benefit.title))])]),_c('div',{staticClass:"cardInfo_body"},[_c('p',{staticClass:"cardInfo_body_text"},[_vm._v(_vm._s(benefit.text))])])])}),0)])]),_c('section',{staticClass:"section"},[_c('div',{staticClass:"section_container section_container_reverse"},[_c('div',{staticClass:"section_container_left section_container_left_withPadding"},[_c('titleBox',{attrs:{"dataTitleBox":_vm.titleBox_for_contactSecond,"configTitleBox":{'positionTitle': _vm.titleBox_for_contactSecond.positionTitle }}})],1),_c('div',{staticClass:"section_container_right"},[_c('div',{staticClass:"section_container_right_ContactBx"},[_c('ContactSimply',{attrs:{"ViewDataForm":{
                            stateInputs:{
                            nombre: true,
                            email: true,
                            telefono: true,
                            asunto: false,
                            motivo: false,
                            mensaje: true,
                            
                        },
                        withBLur: false,
                        asunto: 'Consulta Microsoft Defender',
                        motivo: 'Consulta comercial Microsoft Defender'
                    },"idForm":"ContactoMicrosoft2","titleForm":"Contacto"}})],1)])])]),_c('section',{staticClass:"section section_grayColor section_paddingBottom"},[_c('div',{staticClass:"nubesComponent_top"},[_c('nubes')],1),_c('div',{staticClass:"section_container section_container_reverse"},[_vm._m(2),_c('div',{staticClass:"section_container_right section_container_right_flex"},[_c('titleBox',{attrs:{"dataTitleBox":_vm.titleBox_for_segurty,"configTitleBox":{'positionTitle': _vm.titleBox_for_segurty.positionTitle }}})],1)])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section_container_left"},[_c('img',{staticClass:"section_container_left_img",attrs:{"src":"/images/services/microsoft_defender/logo.webp","alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"btnShodow",attrs:{"type":"submit"}},[_c('a',{staticClass:"btnShodow_link",attrs:{"href":"#contact"}},[_vm._v(" Solicita la llamada de un ejecutivo ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section_container_left "},[_c('img',{staticClass:"section_container_left_img",attrs:{"src":"/images/services/microsoft_defender/image1.webp","alt":""}})])}]

export { render, staticRenderFns }