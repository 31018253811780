import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import vmodal from 'vue-js-modal'
Vue.use(vmodal)

import VueProgressBar from 'vue-progressbar'
const options = {
  color: '#62ca3f',
  failedColor: '#ff0000',
  thickness: '5px',
  transition: {
      speed: '1s',
      opacity: '0.6s',
      termination: 400
  },
  autoRevert: true,
  location: 'top',
  inverse: false
}
Vue.use(VueProgressBar, options)

import VueToast from 'vue-toast-notification'
// import 'vue-toast-notification/dist/theme-default.css'
import 'vue-toast-notification/dist/theme-sugar.css';
Vue.use(VueToast, {
  duration: 2000,
  position: "bottom-left"
})

import es from 'vee-validate/dist/locale/es'
import VeeValidate, { Validator } from "vee-validate";
Vue.use(VeeValidate);
Validator.localize("es", es);

import VTooltip from 'v-tooltip'
Vue.use(VTooltip)




Vue.config.productionTip = false
Vue.config.ignoredElements = ['rs-layer', 'rs-module-wrap', 'rs-module', 'rs-slides', 'rs-slide']

new Vue({
  router,
  store,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')
