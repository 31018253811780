<template>
    <div class="main">
        <section class="main_banner">
             <BannerSlider :dataBannerSlider="dataBannerSlider" >
                 <div class="azureMain_portada_content">
                   <ContactSimply :ViewDataForm="{
                         stateInputs:{
                         nombre: true,
                         email: true,
                         telefono: true,
                         asunto: false,
                         motivo: false,
                         mensaje: true,
                        },
                    withBLur: true,
                     asunto: 'Consulta ihosting VPS CLOUD: solicito la llamada de un Ejecutivo Comercial',
                     motivo: 'Consulta comercial VPS CLOUD'
                   }"
                    idForm="cloudpVps1"
                   />
                   
                 </div>
             </BannerSlider>
        </section>  
        <section class="main_section" >
            <!-- <div class="nubesComponent_top">
                <nubes/>
            </div> -->
            <titleBox :dataTitleBox="DataSectionServices['VPS_linux']"/>
            
            <div class="main_section_sliderBx" v-if="dataServicios">
                <SliderCardPlans :categorie="categories['Cloud VPS Linux Mensual']" :styleBoxCard="{width: '380px'}" :infoCategory="{category: dataServicios[2].categoria, subCategory: dataServicios[2].subcategoria }"  :imageCard="{src: 'images/linux-logo.png', state:true}" :cardContact="false" keySlide="CloudVPSLinuxMensual"/>
            </div>

            <div class="nubesComponent">
                <nubes/>
            </div>
        </section>

        <section class="main_section" style="background-color: var(--grey-color);">
            <div class="nubesComponent_top">
                <nubes/>
            </div>
            <titleBox :dataTitleBox="DataSectionServices['VPS_windows']"/>
            
            <div class="main_section_sliderBx" v-if="dataServicios">
                <SliderCardPlans :categorie="categories['Cloud VPS Windows Mensual']" :styleBoxCard="{width: '380px'}" :infoCategory="{category: dataServicios[2].categoria, subCategory: dataServicios[2].subcategoria }"  :imageCard="{src: 'images/windows-logo.png', state:true}" :cardContact="false" keySlide="CloudWindowMensual"/>
            </div>

            <div class="nubesComponent">
                <nubes/>
            </div>
        </section>

        <section class="main_cards ">
            <titleBox class="wow animate__animated  animate__fadeInUp" :dataTitleBox="dataTitleMore" :configTitleBox="{'positionTitle':'center' }"/>
            <div class="main_cards_box wow animate__animated  animate__fadeInUp">
                <cardWaveInside v-for="(item,index) in dataSectionMore" :dataInfo="item" :key="index" />
            </div>
        </section>
    </div>
</template>
<script>
import SliderCardPlans from "@/components/web/sliders/SliderCardPlans.vue";
import BannerSlider from  "@/components/web/sliders/BannerSlider.vue";
import ContactSimply from "@/components/web/forms/contactSimply.vue";
import titleBox from "@/components/web/titleBox.vue"
import nubes from "../../../components/web/nubes.vue";
import cardWaveInside from "@/components/web/cards/cardWaveInside.vue";

import { mapGetters, mapActions } from "vuex";

export default{
    components:{
        BannerSlider,
        SliderCardPlans,
        ContactSimply,
        titleBox,
        nubes,
        cardWaveInside
    },
    data: ()=>({
        categories: {},
            loading: true,
            // data para la seccion de inicio, el banner slider ---
            dataBannerSlider:{
                title: 'VPS',
                titleBold: 'Cloud',
                subtitle: 'Si es que tu negocio no se puede detener, nuestras Cloud VPS son la mejor alternativa en cuanto a desempeño y disponibilidad.',
                directionElement: false,
            },
            DataSectionServices: {
                "VPS_linux": { 
                    title: "Planes Cloud VPS Linux",
                    description: " En nuestros planes Cloud VPS Linux encontrarás toda la estabilidad que te permitirá crecer sin dificultades, revisa nuestros planes a continuación.",
                },
                "VPS_windows": { 
                    title: "Planes Cloud VPS Windows",
                    description: "En nuestros planes Cloud VPS Windows encontrarás toda la estabilidad y redundancia que te permitirá crecer sin dificultades, revisa nuestros planes a continuación.",
                },
            },
            // cards waves:
                    // data cards
        dataTitleMore: {
            title: ' Opciones de Administración Flexible',
            description: 'Personaliza tu experiencia: administra por ti mismo o déjanos encargarnos, con escalabilidad sin complicaciones y elección de plataformas populares.',
            color: 'var(--dark-color)',
            positionTitle: 'left'
        },
            dataSectionMore:[
            {
                title: 'Autoadministración',
                text: 'Puedes elegir que sea autoadministrada por ti o despreocuparte y que nosotros nos encarguemos de toda la administración de tu Cloud VPS.',
                img: 'images/services/cloudVps/AUTOADMINISTRACION-.webp'
            },
            {
                title: 'Aumentar recursos',
                text: 'Si necesitas aumentar recursos puedes escalar rápidamente y sin complicaciones.',
                img: 'images/services/cloudVps/AUMENTAR-RECURSOS.webp'
            },
            {
                title: 'cPanel&WHM',
                text: 'Puedes solicitar cPanel&WHM, plesk o trabajar por línea de comando.',
                img: 'images/services/cloudVps/CPANEL_WHM.webp'
            },
        ],
          
    }),
    computed: {
        ...mapGetters(["getServices_", "getData"]),
        data_() {
        return this.getData?.web;
        },
        dataServicios() {
        return this.getData?.web.servicios;
        },
    },
    mounted() {
        setTimeout(() => {
            this.initComponent();
        }, 1000);
    },
    methods: {
    ...mapActions(["getServices"]),

        initComponent() {
            if (this.getData != null) {
                this.loading = true;
                //ADD service microsof adicional:
                this.data_.servicios['6']["servicios"].push(818);
                this.data_.servicios.forEach((servicio, index) => {
                    this.getServices(servicio.subcategoria).then((res) => {

                    let data = [];
                    res.forEach((item) => {
                        let i = this.data_.servicios[index]["servicios"].includes(item.id);
                        if (i) {
                        if(this.data_.servicios['6']["servicios"] && item.id == 818){
                            // console.log("lo encontree: ", item.id, "servicios: ", this.data_.servicios[index]["servicios"]);
                            data.unshift(item);
                        }else{
                            data.push(item);
                        }
                        }
                    });
                    let dataFiltered = this.filterForSpace(data);

                    // Funcion que ordena los servicios de su categoria de menor a mayor por su precio
                    let categoriasOrdenada = this.categoriesSort(dataFiltered);
                    this.$set(this.categories, servicio.nombre, categoriasOrdenada);
                    });
                });
                // console.log("this.cattegories: ", this.categories);
                // Despues de que se hayan cargado todas las siete peticiones que se hicieron en el foreach deberia ejecutarse el swiperSimple
                this.getServices(this.getData.web.categorias?.servicios).then(() => {
                // refresh
                // this.swiper_simple("swiperContent");
                });
                setTimeout(()=>{
                        this.loading = false;
                }, 3000)
            } else {
                console.log("getData es null: ", this.getData)
                this.initComponent();
            }
        },
        filterForSpace(services){
            if(services.length > 4){
                let data =  services.filter((e)=> e.atributos[0].pivot.valor != "64 GB" && e.atributos[0].pivot.valor != "128 GB" );
                // console.log("data filterspace: ", data)
                return data;
            }else{
                // console.log("services wordpress? ", services)
                return services
            }
        },
              // Ordena los servicios/categorias por el precio de menor a mayor
      categoriesSort(service){
        return service.sort( (a,b)=> a.precio - b.precio) ;
       },
    },
}
</script>

<style scoped>
.main, .main_banner{
    padding: 0px;
    overflow: hidden;
}

.main_section, .main_cards{
    padding: 80px 0px 0px; 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    /* min-height: 1011px; */
}

.main_section_sliderBx{
    /* min-height: 735px; */
    width: 100%;
    padding-bottom: 70px;
}

.main_cards{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 0px 20px;
    min-height: 75vh;
}
.main_cards_box{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

/* nubes */
.nubesComponent{
  position: absolute;
  left: 0;
  bottom: -75px;
}

.nubesComponent_top{
  position: absolute;
    top: 38px;
    left: 0;
    z-index: 5;

}
@media (max-width:1500px){
.main_cards{
    padding-top: 50px;
}
}
</style>