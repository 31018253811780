<template>
    <section class="listPLans">

        <titleBox :dataTitleBox="dataTitleBox"/>
        <!-- <span class="listPLans_btn" @click="seeMore = !seeMore">{{ seeMore ? 'Ver menos' : 'Ver más' }}</span> -->
        <!-- <button class="listPLans_btn" >seeMore</button> -->
        <div class="listPLans_container">
          <div class="wrapper">
              <div class="" v-for="(item, index) in plansIncludeFilter" :key="index" :class=" `box box-${index}` ">
                <div v-if="item.img" class="imageBx">
                  <img :src="item.img" alt="" style="max-width: 50px; height: 50px;">
                </div>
                <i v-else aria-hidden="true" class="ion ion-android-done-all listPLans_listsContent_item_icon"></i>

                  <span class="box_title">{{ item.text }}</span>
              </div>
          </div>
        </div>
    </section>

</template>

<script>
import titleBox from '@/components/web/titleBox.vue';
export default{
    components:{
        titleBox,
    },
    data: ()=>({
        seeMore: false,
        // data que se envia al componente titulo
        dataTitleBox: {
            title: 'Nuestros Planes VPS también incluyen',
            color: 'var(--dark-color)',
        }, 
        plansInclude: [
            {text: 'Performance extrema en velocidad de disco, sin latencia y con discos SSD de última generación.', img: 'images/services/nuestros_planes/Velocidad_disco.png' },
            {text: 'Rendimiento y Performance.', img: 'images/services/nuestros_planes/Rendimiento_performance.png' },
            {text: 'Recursos Garantizados, Utilizamos componentes de alto rendimiento para que tu servidor siempre funcione de manera óptima.', img: 'images/services/nuestros_planes/Rendimiento_optimo.png' },
            {text: 'DNS personalizado.', img: 'images/services/nuestros_planes/DNS_personalizado.png' },
            {text: 'Acceso a tu servidor virtual VPS con control total. Accede mediante SSH o Escritorio Remoto. ', img: 'images/services/nuestros_planes/Acceso_servidor_virtual_VPS.png' },
            {text: 'Monitoreo Permanente, monitoreamos nuestra infraestructura para conseguir los mejores porcentajes de uptime.', img: 'images/services/nuestros_planes/Monitoreo_permanente.png' },
            {text: 'Estabilidad, Servidores con tecnología de punta, asegurando un alto rendimiento y un uptime garantizado de 99.8%.', img:'images/services/nuestros_planes/ESTABILIDAD_SERVIDORES.png' },
            {text: 'Soporte Especializado y entrenado, dispuesto a atenderte por múltiples canales de comunicación. ', img:'images/services/nuestros_planes/SOPORTE_ESPECIALIZADO.png' },
            {text: 'Escalabilidad en cualquier momento.', img:'images/services/nuestros_planes/ESTABILIDAD_CUALQUIER.png' },
        ]   
    }),

    computed: {
      plansIncludeFilter(){
        // if(this.seeMore){
          return this.plansInclude;
        // }else{
          // return this.plansInclude.slice(0,6);
        // }
      }
    }
}
</script>


<style scoped>

.listPLans{
    /* min-height: 100vh; */
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 40px 0px;
    position: relative;
    margin: 0 auto;
}

.listPLans_btn{
    background: var(--primary-color);
    color:  var(--white-color);
    font-family: var(--title-fonts);
    font-weight: 700;
    font-size: 16px;
    padding: 5px 35px;
    line-height: 2;
    border: none;
    border-radius: 5px;
    transition: .3s;
    cursor: pointer;
    box-shadow: 0px 5px 20px 0px rgba(0, 81, 250, 0.3);
}
.listPLans_btn:hover{
    transform: scale(1.05);
}


.listPLans_container{
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.wrapper {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(4, [col] minmax(250px, 1fr) );

    /* grid-template-columns: repeat(4, [col] 250px ); */
    grid-template-rows: repeat(7, [row] auto );
    background-color: #fff;
    color: #444;
    max-width: 1090px;
  }

  .box {
    color: var(--secondary-color);
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    min-height: 265px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    border-radius: 1.25rem;
    box-shadow: 0px 5px 20px 0px rgba(0, 81, 250, 0.1);
    transition: .3s;
    z-index: 1;
  }

  .box:hover{
    transform: scale(1.09);
    box-shadow: 4px 6px 32px 8px 0px 5px 20px 0px rgba(0, 81, 250, 0.2);
    z-index: 2;
  }

  .box_title{
    max-width: 95%;
  }

  .box-0 {
    grid-column: col / span 1;
    grid-row: row / span 1;
  }
  .box-1{
    grid-column: col 2 / span 1;
    grid-row: row;
    background: var(--blue-color);
    color: var(--white-color);

  }
  .box-2{
    grid-column: col 3 / span 2;
    grid-row: row;
    background: var(--dark-color);
    color: var(--white-color);
  }
  .box-3 {
    grid-column: col 1 / span 1;
    grid-row: row 2;
    background: var(--blue-color);
    color: var(--white-color);
  }

  .box-4{
    grid-column: col 2 / span 1;
    grid-row: row 2;
  }

  .box-5{
    grid-column: col 3 / span 2;
    grid-row: row 2;
    background: var(--primary-color);
    color: var(--white-color);
  }


  .box-6{
    grid-column: col 1 / span 2;
    grid-row: row 3 / span 3;
    
  }


  .box-7{
    grid-column: col 3 / span 1;
    grid-row: row 3 / span 3;
  }
  .box-8{
    grid-column: col 4 / span 1;
    grid-row: row 3 / span 3;
  }

  /* .box-10{
    grid-column: col 1 / span 2;
    grid-row: row 6;
  }


  .box-11{
    grid-column: col 3 / span 2;
    grid-row: row 6;
  } */


@media (max-width: 1180px){
  .wrapper{
    width: auto;
    padding: 0 0px;
    grid-template-columns: repeat(2, [col] minmax(auto, 1fr) );
    grid-gap: 20px;
    row-gap: 30px;

  }
  .box-1{
    grid-column: col 2 / span 1;
    grid-row: row;

  }
  .box-2{
    grid-column: span 2;
    grid-row: initial;
  }
  .box-3 {
    grid-column: span 1;
    grid-row: initial;
  }

  .box-4{
    grid-column: span 1;
    grid-row: initial;
  }

  .box-5{
    grid-column:  span 2;
    grid-row: initial;
  }


  .box-6{
    grid-column: span 2;
    grid-row: span 3;
    height: 100%;
  }

  .box-7{
    grid-column:  span 1;
    grid-row:  span 3;
    height: 100%;
  }


  .box-8{
    grid-column: initial; /* Restablecer a la configuración predeterminada o al valor deseado */
    grid-row: span 3; /* Restablecer a la configuración predeterminada o al valor deseado */
  }

  .box-10{
    grid-column: span 2;
    grid-row: initial;
  }


  .box-11{
    grid-column: span 2;
    grid-row: initial;
  }
}

@media (max-width: 600px){
  .listPLans{
    padding: 10px 10px 80px;
    width: 92%;
  }
  .box {
    padding: 5px 10px;
    font-size: 12px;
  }
}
</style>