<template>
        <div :class="titleBoxStyle" :style="styleConfig">
            <h2 v-if="dataTitleBox.title" class="titleBox_title" :style="`color: ${dataTitleBox.color}`">{{ dataTitleBox.title }} <span class="titleBox_title_bold" v-if="dataTitleBox.title_bold"> {{ dataTitleBox.title_bold }}</span></h2>
            <h3 v-if="dataTitleBox.subtitle" class="titleBox_subtitle">{{ dataTitleBox.subtitle }}</h3>
            <p v-if="dataTitleBox.description" class="titleBox_description"> {{ dataTitleBox.description }} </p>
        </div>
</template>

<script>
export default{
    props:{
        dataTitleBox: {
            default: ()=>({})
        },
        configTitleBox:{
            positionTitle: '',
            style: ' ',
        }
    },
    computed:{
        titleBoxStyle(){
            let classStyle = 'titleBox';
            if(this.configTitleBox){
                this.configTitleBox.positionTitle == 'left' ? classStyle ='titleBox titleBoxLeft' : (this.configTitleBox.positionTitle == 'right' ? classStyle = 'titleBox titleBoxRight' : classStyle ='titleBox') ;
            }
            return classStyle;
        },
        styleConfig(){
            if(this.configTitleBox){
               return this.configTitleBox.style
            }else{
                return ''
            }
        }
    }
}

</script>


<style scoped>
/* title box start --- */
.titleBox{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 85%;
    max-width: 850px;
    min-width: 200px;
    text-align: center;
    /* padding: 10px; */
}
.titleBoxLeft{
    text-align: start !important;
    align-items: flex-start !important;
    /* padding: 0px 0px 20px 65px !important; */
}

.titleBoxRight{
    text-align: end !important;
    align-items: flex-end !important;
    /* padding: 0px 65px 20px 0px !important; */
}
.titleBox_title{
    font-size: 33px;
    line-height: 39px;
    font-weight: 700;
    margin-bottom: 10px;
}

.titleBox_title_bold{
    color: var(--primary-color);
}

.titleBox_subtitle{
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 5px;
}

.titleBox_description{
    font-size: 18px;
    font-family: var(--body-fonts);
    /* font-size: 1rem; */
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 15px;
}

@media (max-width: 1300px){
    .titleBox_title{
        width: 90%;
    }
    .titleBox_description{
        font-size: 14px;
        font-weight: 500;
    }
}
@media (max-width:1220px){


    .titleBoxLeft, .titleBoxRight{
        text-align: center !important;
        padding: 10px !important;
        align-items: center !important;
    }
    .titleBox_title{
        font-size: 30px;
    }
    .titleBox_description{
        font-size: 15px;
    }
}

@media (max-width:555px){

    .titleBox{
        width: 90%;
    }
    .titleBox_title{
        font-size: 25px;
        line-height: 45px;
    }
    .titleBoxLeft, .titleBoxRight{
        width: 98%;

    }
}
/* title box end --- */

</style>