<template>
  <div>
    <div class="pt-section-title-1" style="height: 54px">
      <h5 class="pt-section-title">Nuestros <span class="imp-word">Servicios</span></h5>
    </div>
    <section class="services help pb-80 ser-cont">
      <div class="service-wrap">
        <transition name="slide-fade">
          <div v-show="getCategories_.length != 0 && finish == true" class="row rows-card">
            <div v-for="item in getCategories_" :key="item.id" class="col-sm-12 col-md-6 col-lg-6 row-card">
              <div @click="nextStep(item)" class="courses-container" style="cursor: pointer">
                <div class="pt-fancybox-box-info pt-fancybox2-active fancyitem" style="text-align: center">
                  <h4 class="pt-fancy-title">{{ item.nombre }}</h4>
                  <div class="pt-fancy-media">
                    <i v-if="item.nombre == 'Hosting'" class="flaticon-018-cloud-network"></i>
                    <i v-else-if="item.nombre == 'CloudVPS'" class="flaticon-002-cloud-computing"></i>
                    <i v-else-if="item.nombre == 'Certificados SSL'" class="flaticon-016-paper"></i>
                    <i v-else-if="item.nombre == 'Productos'" class="cont-img-icon-tab"
                      ><img src="images/icons/box.svg" class="img-icon-tab icon-products-cart"
                    /></i>

                    <i v-else class="flaticon-003-monitor-screen"></i>
                  </div>

                  <div class="pt-btn-container">
                    <div class="pt-button-block">
                      <a class="pt-button pt-btn-link">
                        <span class="text" style="color: var(--primary-color); font-size: 22px"
                          >Ver servicios</span
                        >
                        <span class="pt-button-line-left"></span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>

        <div v-if="finish == false" class="svg-icon-v">
          <img src="@/assets/loading.svg" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["finish"],
  data: () => ({}),
  computed: {
    ...mapGetters(["getCategories_"]),
  },
  methods: {
    back() {
      this.$emit("back");
    },
    nextStep(category) {
      this.$emit("next", category);
    },
  },
};
</script>

<style scoped>
.ser-cont {
  padding-top: 110px !important;
}

.ser-cont {
  padding-top: 83px !important;
}

@import url("https://fonts.googleapis.com/css?family=Muli&display=swap");

.courses-container:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
  -webkit-transition: all 300ms linear 0ms;
  -moz-transition: all 300ms linear 0ms;
  -ms-transition: all 300ms linear 0ms;
  transition: all 300ms linear 0ms;
}
.course {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  max-width: 100%;
  margin-bottom: 40px;
  overflow: hidden;
}

.course h6 {
  opacity: 0.6;
  margin: 0;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.course h2 {
  letter-spacing: 1px;
  margin: 10px 0;
}

.course-preview {
  background-color: #3696ce;
  color: #fff;
  padding: 30px;
  width: 380px;
}

.course-preview a {
  color: #fff;
  display: inline-block;
  font-size: 12px;
  opacity: 0.6;
  margin-top: 30px;
  text-decoration: none;
}

.course-info {
  padding: 30px;
  position: relative;
  width: 100%;
  display: block;
}

.progress-container {
  position: absolute;
  top: 30px;
  right: 30px;
  text-align: right;
  width: 150px;
}

.btn_ {
  background-color: #3696ce !important;
  border: 0;
  border-radius: 50px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
  color: #fff;
  font-size: 16px;
  padding: 12px 25px;
  position: absolute;
  bottom: 30px;
  right: 30px;
  letter-spacing: 1px;
}

@media (max-width: 576px) {
  .course-info {
    display: none;
  }

  .course-preview {
    width: 100%;
  }
}
</style>
