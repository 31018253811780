<template>
  <div class="error-container" :class="error ? 'errorShowStyle' : ''">
    <transition name="slide-fade">
      <span v-show="error" class="invalid-feedback">{{ error }}</span>
    </transition>
  </div>
</template>

<script>
export default {
  props: ["error"],
};
</script>

<style scoped>
.invalid-feedback {
  text-align: left;
  display: block;
  width: 100%;
  font-size: 0.875em;
  color: #dc3545;
}

.errorShowStyle{
  margin-bottom: 24px;
}
</style>
