<template>
  <div style="position: relative; overflow: hidden;">
    <!-- LOADER  -->
    <div id="pt-loading">
      <div id="pt-loading-center">

        <img rel="preload" alt="loading" src="images/loading.gif" />
      </div>
    </div>

    <!-- HEADER COMPONENT -->
    <AppHeader/>

    <!-- VIEW ROUTER -->
    <transition name="fade">
      <div class="viewPage">
        <router-view />
      </div>
    </transition>

    <!-- btn Scroll top -->
    <btnScrollTop/>

    <!-- FOOTER COMPONENT -->
    <footer-component />

    <!-- PROGRESS BAR COMPONENT -->
    <vue-progress-bar />

    <!-- DIALOGS COMPONENT -->
    <dialogs-component />

    <!-- chat -->
    <chatWootComponent/>
  </div>
</template>

<script>
// import JsonLd from 'vue-jsonld'
import { mapActions } from "vuex";
import AppHeader from "./components/web/header/AppHeader.vue";
import btnScrollTop from "./components/web/buttons/btnScrollTop.vue";
// import headerComponent from "@/components/web/HeaderComponent";
import footerComponent from "@/components/web/FooterComponent";
import dialogsComponent from "@/components/cart/DialogsComponent";
import chatWootComponent from "./components/web/chatWootComponent.vue";
export default {
  components: {
    AppHeader,
    btnScrollTop,
    // headerComponent,
    footerComponent,
    dialogsComponent,
    chatWootComponent
  },
  data: () => ({}),
  created() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let new_content = urlParams.get("new_content");
    if (new_content) {
      this.$toast.success("Nuevo contentido descargado y actualizado", {
        position: "bottom-left",
        duration: 7000,
      });
    }
    this.getData();
  },
  methods: {
    ...mapActions(["getData"]),
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

.router-link-exact-active {
  color: #42b983;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}


/* START SWIPER GLOBAL STYLE */
.swiper-container{
  overflow: hidden;
}

/* END SWIPER GLOBAL STYLE */

/* START ENCLAJE SECTION */
.section_service{
  position: relative;
}

.enclaje_section{
  position: absolute;
  top: 50px;
  left: 0px;
  right: 0px;
  width: 100%;
  height: 1px;
}

@media(max-width:1199px){
  .enclaje_section{
    top: 20px;
  }
}

@media(max-width:1260px){
  .viewPage{
    padding-top: 55px;
  }
}
/* END ENCLAJE SECTION */
</style>