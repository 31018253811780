var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"main",staticStyle:{"min-height":"100vh"},attrs:{"id":"microsoft"}},[_c('section',{staticClass:"main_banner"},[_c('BannerSlider',{attrs:{"dataBannerSlider":_vm.dataBannerSlider}},[_c('div',{staticClass:"azureMain_portada_content"},[_c('ContactSimply',{attrs:{"idForm":"microsofForm","ViewDataForm":{
                     stateInputs:{
                     nombre: true,
                     email: true,
                     telefono: true,
                     asunto: false,
                     motivo: false,
                     mensaje: true,
                     
                    },
                 withBLur: true,
                 asunto: 'Consulta ihosting Microsoft 365: solicito la llamada de un Ejecutivo Comercial',
                 motivo: 'Consulta comercial Microsoft 365'
                 
               },"titleForm":"Solicita asistencia de un Experto en Microsoft 365"}})],1)])],1),_c('section',{staticClass:"main_cards wow animate__animated  animate__fadeInUp"},[_c('titleBox',{attrs:{"dataTitleBox":_vm.dataTitleMore,"configTitleBox":{'positionTitle':'center' }}}),_c('div',{staticClass:"main_cards_box"},_vm._l((_vm.dataSectionMore),function(item,index){return _c('cardWaveInside',{key:index,attrs:{"dataInfo":item}})}),1)],1),_c('section',{staticClass:"main_cards_slider"},[_c('titleBox',{attrs:{"dataTitleBox":_vm.dataTitleCardsMicrosoft,"configTitleBox":{'positionTitle':'center' }}}),_c('cardsMicrosoft')],1),_c('section',{staticClass:"main_list"},[_c('listDynamic',{attrs:{"dataTitleBox":_vm.dataList.dataListTitle,"dataList":_vm.dataList,"keyList":"cuestion"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }