<template>
    <div class="azureMain_imageList_content ">
              <div v-if="dataListBeneficios.img" class="azureMain_imageList_content_imgBx wow animate__animated  animate__fadeInUp">
                  <img class="azureMain_imageList_content_imgBx_img" :src="dataListBeneficios.img" alt="beneficios image">
              </div>
              <div class="azureMain_imageList_content_infoBx" :class="{'fullWidth': !dataListBeneficios.img}">
                  <div class="azureMain_imageList_content_infoBx_info">
                      <titleBox :dataTitleBox="dataTitleBox" :configTitleBox="{'positionTitle':dataTitleBox.positionTitle }"/>

                      <!-- <h3 class="azureMain_imageList_content_infoBx_info_title">Más que una suscripción, administramos tu Nube de manera Integral.</h3> -->
                      <ul>
                          <li v-for="(item, index) in dataListBeneficios.listText" :key="index"><span class="fa fa-check"></span> {{item.text}} </li>
                      </ul>
                      <button v-if="dataListBeneficios.btn" class="btnShodow" >
                        <router-link class="btnShodow_link" to="/nosotros#contacto">
                          Contáctanos
                        </router-link>
                      </button>

                  </div>
              </div>
    </div>

</template>

<script>
import titleBox from '@/components/web/titleBox.vue';

export default{
  components:{
      titleBox
  },
  props:{
      dataListBeneficios:{
          default: null
      },
      dataTitleBox:{
          default: ()=>({})
      },
  }
}
</script>

<style scoped>
.azureMain_imageList_content{
display: flex;
flex-wrap: wrap;
justify-content: space-evenly;
width: 100%;
}

.azureMain_imageList_content_imgBx{
width: 45%;
max-width: 860px;
display: flex;
align-items: center;
justify-content: center;
padding: 0 60px;
}

.azureMain_imageList_content_imgBx_img{
width: 100%;
}

.azureMain_imageList_content_infoBx{
width: 50%;
max-width: 860px;
}
.azureMain_imageList.pricing-page {
  background: #f5f5f5;
}

.azureMain_imageList_content_infoBx_info {
  padding-left: 20px;
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  max-width: 620px;
}

.azureMain_imageList_content_infoBx_info_title {
  color: var(--primary-color);
  font-weight: 700;
  line-height: 39px;
  margin-bottom: 18px;
}

.azureMain_imageList_content_infoBx_info ul {
  margin-bottom: 35px;
  margin-top: 20px;
  max-width: 500px;
}

.azureMain_imageList_content_infoBx_info ul li {
  list-style: none;
  max-width: 600px;
  font-size: 14px;
  font-family: var(--body-fonts);
  font-style: normal;
  font-weight: 400;
  line-height: 2;
  margin-bottom: 15px;
}

.azureMain_imageList_content_infoBx_info ul li span {
  margin-right: 5px;
  color: var(--blue-color);
}


/* button  shadow*/
.btnShodow{
  background: var(--primary-color);
  color:  var(--white-color);
  font-family: var(--title-fonts);
  font-weight: 700;
  font-size: 16px;
  text-decoration: none;
  padding: 5px 35px;
  line-height: 2;
  border: none;
  border-radius: 5px;
  transition: .3s;
  cursor: pointer;
  box-shadow: 0px 5px 20px 0px rgba(0, 81, 250, 0.3);
  transform: scale(1);

}
.btnShodow:hover{
  transform: scale(1.05) !important;
  color: var(--white-color) !important;

}

.btnShodow_link{
color:  var(--white-color);

}

.fullWidth{
  width: 100%;
}
@media (max-width:1580px){
  .azureMain_imageList_content_imgBx{
      padding: 0 10px;
  }
  /* .azureMain_imageList_content_infoBx_info ul li {
      font-size: 12px;
  } */
}

@media(max-width:1300px){
  .azureMain_imageList_content_infoBx_info{
      padding-left: 0px;
      /* flex-direction: row; */
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 900px;
  }
}

@media(max-width:650px){
  .azureMain_imageList_content_infoBx{
      width: 100%;
      display: flex;
      justify-content: center;
  }

  .azureMain_imageList_content_infoBx_info{
      padding: 0 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
  }

  .azureMain_imageList_content_imgBx{
      width: 90%;
  }
  .azureMain_imageList_content_imgBx_img{
      width: 100%;
  }
  
.azureMain_imageList_content_infoBx_info ul {
  padding-left: 0px;
}
}




</style>