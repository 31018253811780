<template>
  <div
    class="azureMain_beneficios_content "
    :class="{'reverse': dataSection.reverse}"
  >
    <div class="azureMain_beneficios_content_text wow animate__animated  animate__fadeInUp">
      <div class="azureMain_beneficios_content_text_sectionTitle " v-if="dataSection.title">
        <titleBox
          :dataTitleBox="dataSection"
          :configTitleBox="{
            positionTitle: dataSection.positionTitle,
            style: 'width: 100%',
          }"
        />
      </div>
      <div v-if="dataSection.icons" class="azureMain_beneficios_content_text_row">
        <div v-for="(itemIcon, index) in dataSection.icons" :key="index" class=" wow azureMain_beneficios_content_text_row_bx">
          <div class="rounded text-center p-4">
            <i
              class=" fa-2x text-primary mb-2 azureMain_beneficios_content_text_row_bx_icon"
              :class="itemIcon.icon"
            ></i>
            <p class="mb-0">{{ itemIcon.text }}</p>
          </div>
        </div>
      </div>

      <div v-if="dataSection.list" class="azureMain_beneficios_content_text_list">
        <div v-for="(itemIcon, indexList) in dataSection.list" :key="indexList">
          <div class="rounded d-flex p-2">
            <i
              class=" fa-2x mb-2 mr-2"
              :class="itemIcon.icon"
              style="color: var(--primary-color); font-size: 20px;"
            ></i>
            <p class="mb-0">{{ itemIcon.text }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-5 azureMain_beneficios_content_image">
      <img
        class="img-fluid wow zoomIn azureMain_beneficios_content_image_img"
        data-wow-delay="0.5s"
        :src="dataSection.img"
      />
    </div>
  </div>
</template>

<script>
import titleBox from "@/components/web/titleBox.vue";
export default {
  props: {
    dataSection: {
      default: () => ({}),
      required: true,
    },
  },
  components: {
    titleBox,
  },
};
</script>

<style scoped>
.azureMain_beneficios_content {
  width: 100%;
  max-width: 1600px;
  padding: 0px 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.reverse{
    flex-direction: row-reverse;
}

.azureMain_beneficios_content_text {
  width: 50%;
  max-width: 50%;
  padding: 20px;
}
.azureMain_beneficios_content_text_sectionTitle {
  margin-bottom: 30px;
}

.azureMain_beneficios_content_text_row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.azureMain_beneficios_content_text_row_bx {
  /* margin-bottom: 1.5rem; */
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  height: 152px;
  width: 219px;
  max-width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--grey-color);
  margin: 0 0.5rem 1.5rem;
}

.azureMain_beneficios_content_text_row_bx_icon {
  color: var(--primary-color) !important;
}

.azureMain_beneficios_content_image {
  width: 50%;
  display: flex;
  justify-content: center;
}

.azureMain_beneficios_content_image_img {
  max-width: 700px;
  height: 450px;
}

.azureMain_beneficios_content_text_list{
  font-size: 14px;
}

@media (max-width: 1580px) {
  .azureMain_beneficios_content {
    justify-content: space-around;
    align-items: center;
    padding: 0 25px;
  }
  .azureMain_beneficios_content_text {
    width: 45%;
    max-width: 50%;
    padding: 20px;
  }
  .azureMain_beneficios_content_image_img {
    max-width: 500px;
    height: 330px;
  }
  .azureMain_beneficios_content_text_row_bx {
    height: 120px;
    width: 219px;
    font-size: 14px !important;
  }
}
@media (max-width: 1140px) {
  .azureMain_beneficios_content {
    flex-direction: column;
  }
  .azureMain_beneficios_content_text {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .azureMain_beneficios_content_text_row {
    justify-content: center;
  }
  .azureMain_beneficios_content_image {
    width: 100%;
    max-width: 100%;
  }
}


@media (max-width:650px){
    .azureMain_beneficios_content {
        max-width: 500px;
        width: 100%;
        padding: 0 0 !important;
    }
    .azureMain_beneficios_content_text{
        padding: 0px 0px !important;
    }
    .azureMain_beneficios_content_text_row{
        justify-content: center;
    }
    .azureMain_beneficios_content_text_row_bx {
        margin: 0 0 0.4rem 0.4rem;
    }
    .azureMain_beneficios_content_image{
        padding: 0px ;
        margin: 15px 0px;
    }

    .azureMain_beneficios_content_image_img{
        max-width: 500px;
        width: 100%;
        height: 330px;
    }
}

</style>
