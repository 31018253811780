<template>
    <section class="boxOptionsMain">
        <titleBox :dataTitleBox="dataTitleBox"/>

        <div class="boxOptionsMain_container wow animate__animated animate__fadeInUp">
            <div class="boxOptionsMain_container_menus">
                <span class="boxOptionsMain_container_menus_item" :class="{activeMenu: menuOptions.ERP.state}" @click="changeMenu('ERP')">Aloja tu ERP</span>
                <span class="boxOptionsMain_container_menus_item" :class="{activeMenu: menuOptions.systems.state}" @click="changeMenu('systems')">Sistemas operativos</span>
                <span class="boxOptionsMain_container_menus_item" :class="{activeMenu: menuOptions.technologies.state}" @click="changeMenu('technologies')">Tecnologías</span>
            </div>

            <div class="boxOptionsMain_container_box">
                <div v-for=" (item, index) in dataSelected" :key="index" class="boxOptionsMain_container_box_item" :class="{active: animationItems_state}">
                    <img class="boxOptionsMain_container_box_item_icon flaticon-001-server" :src="pathIcon + item.img" alt="title" title="title" :class="menuOptions.ERP.state ? 'iconBig' : '' ">
                    <span class="boxOptionsMain_container_box_item_title">{{ item.title }}</span>
                </div>
            </div>
        </div>
    </section>
</template>


<script>
import titleBox from '@/components/web/titleBox.vue';
export default{
    components: {
        titleBox
    },
    data: ()=>({
        // data que se envia al componente titulo
        dataTitleBox: {
            title: 'VPS SSD',
            description: 'Escoge las opciones que mejor de adecuen a tu proyecto. ',
            color: 'var(--dark-color)',
        },
        // path de los iconos
        pathIcon: 'img/logos/icon/',

        // estado que se pasa a true para ver una animation
        animationItems_state: false,

        // data que contiene los iconos a iterar
        menuOptions:{
            systems: {
                state: true,
                data: [
                    { title: 'Ubuntu LTS y Latest', img: 'systemsOperating/ubuntuIcon.svg'},
                    { title: 'CentOS Stream', img: 'systemsOperating/centosIcon.svg'},
                    { title: 'Almalinux', img: 'systemsOperating/AlmaLinuxIcon.svg'},
                    { title: 'Rockylinux', img: 'systemsOperating/RockyLinuxIcon.svg'},
                    { title: 'Debian', img: 'systemsOperating/debianIcon.svg'},
                    { title: 'Fedora', img: 'systemsOperating/FedoraIcon.svg'},
                    { title: 'Windows', img: 'systemsOperating/window.svg'},
                    { title: 'Otros', img: 'plus.svg'},

                ]
            },
            ERP: {
                state: false,
                data: [
                    { title: 'Softland', img: 'ERP/Softland.png'},
                    { title: 'Random', img: 'ERP/Random.png'},
                    { title: 'Sap', img: 'ERP/Sap.png'},
                    { title: 'Q-biz', img: 'ERP/Q-biz.png'},
                    { title: 'Manager', img: 'ERP/Manager.png'},
                    { title: 'Flexline', img: 'ERP/Flexline.png'},
                ]
            },
            technologies:{
                state: false,
                data: [
                    { title: 'NodeJS', img: 'Technology/nodejsIcon.svg'},
                    { title: 'Javascript', img: 'Technology/javascriptIcon.svg'},
                    { title: 'Python', img: 'Technology/pythonIcon.svg'},
                    { title: 'Django', img: 'Technology/djangoIcon.svg'},
                    { title: 'FastAPI', img: 'Technology/fastapiIcon.svg'},
                    { title: 'Laravel', img: 'Technology/laravelIcon.svg'},
                    { title: 'Symfony', img: 'Technology/symfonyIcon.svg'},
                    { title: 'PHP', img: 'Technology/phpIcon.svg'},
                    { title: 'Ruby on Rails', img: 'Technology/railsIcon.svg'},
                    { title: 'ASP.NET', img: 'Technology/netIcon.svg'},
                    { title: 'Otros', img:'plus.svg'},

                ]
            } 
        },
    
    }),
    computed: {
        // data selecionada para iterar y mostrar en la caja
        dataSelected(){
            let dataSelectedFiltered = [];
            Object.keys(this.menuOptions).forEach(propiedad => (this.menuOptions[propiedad].state == true) ? dataSelectedFiltered = this.menuOptions[propiedad].data : false );
            return dataSelectedFiltered;
        }
    },
    methods: {
        // cambiar vista
        changeMenu(option){
            this.animationItems();
            Object.keys(this.menuOptions).forEach(propiedad => this.menuOptions[propiedad].state = false);
            this.menuOptions[option].state = !this.menuOptions[option].state;
        },
        // funcion que activa una animacion en los iconos cuando se cambia de vista
        animationItems(){
            this.animationItems_state = true;
            setTimeout(()=>{
            this.animationItems_state = false;
            },150);
        }
    },
}

</script>

<style scoped>

.boxOptionsMain{
    position: relative;
    min-height: 90vh;
    height: 100%;
    padding: 60px 20px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    z-index: 6;
    /* background: var(--grey-color); */

}



/* boxOptionsMain_container start --- */
.boxOptionsMain_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    width: 100%;
    margin: 30px 0px;
    z-index: 1;
    /* background: green; */
}

.boxOptionsMain_container_menus{
    width: auto;
    max-width: 500px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 2px;
    margin-bottom: 14px;
}

.boxOptionsMain_container_menus_item{
    position: relative;
    margin-right: 35px;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
}

.boxOptionsMain_container_menus_item:hover{
    color: var(--primary-color);
}


.boxOptionsMain_container_menus_item::before{
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin: 0 auto;
    height: 3px;
    width: 0px;
    border-radius: 1px;
    background: var(--primary-color);
    transition: .1s ease-in;
}

.boxOptionsMain_container_menus_item.activeMenu::before{
    width: 20px;
    background: var(--primary-color);
    z-index: 1;
    transition: .2s ease-in;
}

/* boxOptionsMain_container end --- */


/* boxOptionsMain_container_box start */

.boxOptionsMain_container_box{
    display: flex;
    flex-wrap: wrap;
    background: white;
    width: 70%;
    max-width: 800px;
    min-width: 350px;
    min-height: 400px;
    padding: 20px;
    border-radius: 1.25rem;
    box-shadow: 0 4px 32px 0 rgba(10,14,29,.02), 0 8px 64px 0 rgba(10,14,29,.08);
}

.boxOptionsMain_container_box_item{
    flex: 0 0 25%;
    max-width: 25%;
    height: 220px;
    padding: 24px 16px;
    margin-bottom: 15px;
    border-radius: 1.25rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: .1s ease-in-out;
    transform: scale(1);
    opacity: 1;
}

.boxOptionsMain_container_box_item.active{
    transition: .1s ease-in;
    opacity: 0.1;
    transform: scale(0.3);
}

.boxOptionsMain_container_box_item_icon{
    height: 50%;
    width: 50%;
}

.iconBig{
    height: 60%;
    width: 65%;
}

.boxOptionsMain_container_box_item_title{
    height: 30%;
    font-size: 18px;
    font-weight: 400;
    margin-top: 10px;
    text-align: center;
}
/* boxOptionsMain_container_box end */


@media (max-width: 850px){
    .boxOptionsMain{
        padding: 0 0px;
    }
    .boxOptionsMain_container_box{
        justify-content: center;
        width: 90%;
        min-width: 330px;
    }
    .boxOptionsMain_container_box_item{
        min-width: 138px;
        height: 200px;
        padding: 24px 5px;
    }
    .boxOptionsMain_container_box_item_title{
        font-size: 14px;
        font-weight: 500;
    }
    .boxOptionsMain_container_menus{
        justify-content: center;
        flex-direction: column;
        gap: 15px;
    }
    .boxOptionsMain_container_menus_item{
        font-size: 15px;
        margin-right: 12px;
    }

}
</style>