<template>
  <section class="about_section layout_padding">
    <div class="about_section_container ">
    <titleBox :dataTitleBox="dataSection.titleBox" />
    
      <div class="about_section_container_heading_row">
        <div class="about_section_container_heading_row_bx ">
          <div class="about_section_container_heading_row_bx_imgBx">
            <img class="about_section_container_heading_row_bx_imgBx_img" :src="dataSection.data.img" alt="">
          </div>
        </div>
        <div class="about_section_container_heading_row_bx">
          <div class="about_section_container_heading_row_bx_detail">
            <h3 class="about_section_container_heading_row_bx_detail_title">
                {{  dataSection.data.title }}
            </h3>
            <p>
            {{  dataSection.data.subtitle }}  
            <b v-if=" dataSection.data.subtitle_decoration"> {{ dataSection.data.subtitle_decoration }} </b> 
            </p>
            <ul v-if="dataSection.data.list">
                <li v-for="(item,index) in dataSection.data.list" :key="index"><i class="fa fa-check"></i> {{ item  }}</li>
            </ul>
            <button v-if="dataSection.data.btn" type="submit" :alt="dataSection.data.btn.text" :aria-label="dataSection.data.btn.text" class="btnShodow"  >
                <a :href="dataSection.data.btn.link" class="btnShodow_link">
                    {{dataSection.data.btn.text}}
                </a>
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- end about section -->
</template>
<script>
import titleBox from '@/components/web/titleBox.vue';
// import nubes from './nubes.vue';

export default{
    components: {
        titleBox,
        // nubes,

    },
    props:{
        dataSection: {
            default: () => ({}),
        },
    }
}

</script>


<style scoped>
    .about_section{
        /* background: var(--grey-color); */
        padding: 70px 0px 100px;
        position: relative;
        overflow: hidden;
    }
    .nubesComponent_top{
        position: absolute;
        top: 65px;
        left: 0;
        z-index: 5;
    }

    .about_section_container{
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
        max-width: 1500px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .about_section_container_heading{
        margin-bottom: 45px;
        text-align: center;

        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .about_section_container_heading p {
    margin-top: 10px;
    margin-bottom: 0;
}

.about_section_container_heading_row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    margin-top: 65px;
}
.about_section_container_heading_row_bx{
    position: relative;
    width: 50%;
    padding-right: 15px;
    padding-left: 15px;
}
.about_section_container_heading_row_bx_detail{
    font-size: 16px;
}
.about_section_container_heading_row_bx_imgBx{
    max-width: 80%;
    /* margin: 0 auto; */

}
.about_section_container_heading_row_bx_imgBx_img {
    width: 100%;
    max-width: 500px;
}
.about_section_container_heading_row_bx_detail_title{
    font-size: 32px;
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 15px;
    color: var(--dark-color);
}

i{
    color: var(--blue-color);
}

/* button */


.btnShodow{
    background: var(--primary-color);
    color:  var(--white-color);
    font-family: var(--title-fonts);
    font-weight: 700;
    font-size: 16px;
    text-decoration: none;
    padding: 5px 35px;
    line-height: 2;
    border: none;
    border-radius: 5px;
    transition: .3s;
    cursor: pointer;
    box-shadow: 0px 5px 20px 0px rgba(0, 81, 250, 0.3);
    transform: scale(1);

}
.btnShodow:hover{
    transform: scale(1.05) !important;
    color: var(--white-color) !important;

}

.btnShodow_link{
  color:  var(--white-color);

}
li{
    margin-bottom: 8px;
}
@media (max-width: 1300px){
    .about_section_container_heading_row_bx_imgBx{
        margin: 0 auto !important;
    }
    .about_section_container_heading_row_bx_imgBx_img {
        width: 100%;
        max-width: 400px;
}
}
@media (max-width:768px){
    .about_section_container_heading_row_bx{
        flex: 0 0 50%;
        max-width: 50%;
        display: flex;
        justify-content: center;
    }
}

@media (max-width:650px){
    .about_section{
        padding: 10px 0px;
    }
    .about_section_container_heading_row_bx_detail_title{
        margin-top: 20px;
        font-size: 25px;
    }
    .about_section_container_heading_row{
        flex-direction: column;
    }
    .about_section_container_heading_row_bx{
        max-width: 95%;
        width: 90%;
    }
    .about_section_container_heading_row{
        margin-top: 10px;
    }
    .about_section_container_heading_row_bx_imgBx_img{
        height: 200px;
    }
}
</style>