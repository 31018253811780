<template>
  <div class="header_container_buttons" :class="{'header_container_buttons_active': headerActive || $route.name != 'Home' && $route.name != 'Nosotros'}" >
                <div class="pt-btn-container" style="align-self: center; display: flex">
                  <!-- CARRITO -->

                  <div v-if="$route.name != 'Cart'" class="dropdown dropdown-res">
                    <button
                      class="btn-cart-header"
                      alt="ver carrito del usuario" aria-label="ver carrito del usuario"
                      style="padding: 3px 12px; font-size;: 12px 1!important; margin: 0 2px"
                      type="button"
 
                      @click="togglesChangeState('showCart')"
                    >
                      <i
                        class="fas fa-shopping-cart"
                        :class="[
                          ($route.name != 'Home' && $route.name != 'Nosotros') || resolucionComputed
                            ? ''
                            : 'a-header',
                        ]"
                      ></i>
                      <span class="badge badage-primary ml-1 badage-pos-c">{{ getCart.length }}</span>
                    </button>

                    <!-- box float cart-->
                    <div
                      class="dropdown-menu dropdown-menu-right container-form fade-in cart-dropdown dropdown-menu-resp dropdown-noti"
                      :class="{'show': showCart}"
                    >
                      <!-- CART COMPOENTENT -->
                      <cart-component
                        :cart="getCart"
                        :type="2"
                        @edit="editItem"
                        @deleteItem="deleteItem_"
                        @next="next_checkout"
                      />
                    </div>

                  </div>
                  <!-- LOGIN -->
                  <div class="dropdown dropdown-res">
                    <button
                    alt="iniciar session" aria-label="iniciar session"
                      class="btn-cart-header pt-btn-container-item"
                      :class="[
                        ($route.name != 'Home' && $route.name != 'Nosotros') || resolucionComputed
                          ? ''
                          : 'a-header',
                      ]"
                      style="padding: 8px 5px"
                      type="button"

                      @click="togglesChangeState('showAuth')"
                    >
                      <span
                        class="d-xl-block badge badage-primary ml-1 badage-pos-c"
                        
                      >
                        <i
                          class="fas fa-user pr-1"
                          style="margin-right: 15px; margin-left: 5px; color: white !important"
                          :class="[
                            ($route.name != 'Home' && $route.name != 'Nosotros') || resolucionComputed
                              ? ''
                              : 'a-header',
                          ]"
                        ></i>

                        <span
                          class=""
                          style="padding-right: 11px; position: relative; left: -12px"
                          v-if="!resolucionComputed"
                        >
                          <!-- Mi cuenta -->
                        </span>

                        <div
                          v-if="getUser"
                          class="badage-status-user d-xl-block"
                          style="font-size: 10px; position: absolute; color: #42ff6b; right: 8px; top: 7px"
                        >
                          <i class="fa fa-circle" aria-hidden="true"></i>
                        </div>

                        <div
                          v-else
                          class="badage-status-user d-xl-block"
                          style="font-size: 10px; position: absolute; color: #95aeb6; right: 8px; top: 7px"
                        >
                          <i class="fa fa-circle" aria-hidden="true"></i>
                        </div>
                      </span>
                    </button>

                    <!-- box float login -->
                    <div
                      class="dropdown-menu dropdown-menu-right container-form fade-in cart-dropdown dropdown-menu-resp dropdown-noti"
                      :class="{'show':showAuth}"
                    >
                      <transition name="slide-fade">
                        <form
                          v-show="!getUser"
                          @submit.prevent="login_"
                          class="columns column is-multiline is-12"
                          data-vv-scope="form1"
                        >
                          <div class="contbadage-portal">
                            <!-- <a href="https://clientes.ihosting.cl"
                              ><div class="badge plans bg-blue">Portal Clientes</div></a
                            > -->
                          </div>
                          <div class="form-group" style="height: 100px">
                            <label for="exampleDropdownFormEmail2">Username</label>
                            <input
                              v-model="form1.rut_username"
                              v-validate="'required'"
                              type="text"
                              name="rut_username"
                              data-vv-as="username"
                              placeholder="Username o RUT (sin puntos y con guión)"
                              :class="{ 'vue-error': errors.first('form1.rut_username') }"
                              key="1_"
                              maxlength="30"
                            />
                            <FormError :error="errors.first('form1.rut_username')"> </FormError>
                          </div>
                          <div class="form-group" style="height: 90px">
                            <label for="exampleDropdownFormPassword2">Contraseña</label>
                            <input
                              v-model="form1.contrasena"
                              v-validate="'required'"
                              type="password"
                              name="contrasena"
                              data-vv-as="contraseña"
                              placeholder="Contraseña"
                              :class="{ 'vue-error': errors.first('form1.contrasena') }"
                              key="2_"
                              maxlength="30"
                            />
                            <FormError :error="errors.first('form1.contrasena')"> </FormError>
                          </div>

                          <a
                            :href="enlace+'/portal/index.php?module=auth&controller=ReestablecerContrasena'"
                            style="float: right;"
                            small
                          >
                            ¿Olvidó su contraseña?
                          </a>

                          <button
                            type="submit"
                            alt="iniciar session" aria-label="iniciar session"
                            class="btn btn-default-grad-purple-fill white-font disable-on-click spinner-on-click"
                            style="width: 100%; padding: 9px 0px !important; margin-top: 20px"
                            :class="[verify ? 'verify-class' : 'normal-class']"
                          >
                            <span v-if="!verify" class="spinner-span">
                              Ingresar
                              <i class="fas fa-arrow-alt-circle-right spinner-i" style="top: 9px"></i>
                            </span>
                            <div
                              v-else
                              class="spinner-border text-info"
                              style="max-height: 30px"
                              role="status"
                            >
                              <span class="sr-only">Loading...</span>
                            </div>
                          </button>
                        </form>
                      </transition>

                      <a
                        href="/registro"
                        class="btn btn-default-grad-purple-fill white-font mt-3"
                        style="width: 100%; height: 52px; padding-top: 13px; font-size: 1.2em;"
                        v-show="!getUser"
                      >
                        Registrarse 
                        <i class="fas fa-arrow-alt-circle-right"></i>
                      </a>

                      <a :href="enlace">
                        <button
                          alt="ingresar al portal clientes ihosting" aria-label="ingresar al portal clientes ihosting"

                          class="btn button-payment white-font"
                          :class="[getUser ? 'sin-margin-top' : '']"
                        >
                          <span class="button__text">
                            <span class="spinner-span">Portal clientes</span>
                          </span>
                          <div class="icon-portal">
                            <lord-icon
                              src="https://cdn.lordicon.com/udwhdpod.json"
                              trigger="loop"
                              colors="primary:#ffffff,secondary:#30c9e8"
                              style="width: 68px; height: 68px"
                            >
                            </lord-icon>
                          </div>
                        </button>
                      </a>

                      <a href="/pago-rapido" class="">
                        <button class="btn button-payment white-font"
                        alt="pagar rapido" aria-label="pagar rapido">
                          <span class="button__text">
                            <span class="spinner-span">Pago rápido</span>
                          </span>
                          <svg class="button__svg" role="presentational" viewBox="0 0 600 600">
                            <defs>
                              <clipPath id="myClip">
                                <rect x="0" y="0" width="100%" height="50%" />
                              </clipPath>
                            </defs>
                            <g clip-path="url(#myClip)">
                              <g id="money">
                                <path
                                  d="M441.9,116.54h-162c-4.66,0-8.49,4.34-8.62,9.83l.85,278.17,178.37,2V126.37C450.38,120.89,446.56,116.52,441.9,116.54Z"
                                  fill="#699e64"
                                  stroke="#323c44"
                                  stroke-miterlimit="10"
                                  stroke-width="14"
                                />
                                <path
                                  d="M424.73,165.49c-10-2.53-17.38-12-17.68-24H316.44c-.09,11.58-7,21.53-16.62,23.94-3.24.92-5.54,4.29-5.62,8.21V376.54H430.1V173.71C430.15,169.83,427.93,166.43,424.73,165.49Z"
                                  fill="#699e64"
                                  stroke="#323c44"
                                  stroke-miterlimit="10"
                                  stroke-width="14"
                                />
                              </g>
                              <g id="creditcard">
                                <path
                                  d="M372.12,181.59H210.9c-4.64,0-8.45,4.34-8.58,9.83l.85,278.17,177.49,2V191.42C380.55,185.94,376.75,181.57,372.12,181.59Z"
                                  fill="#a76fe2"
                                  stroke="#323c44"
                                  stroke-miterlimit="10"
                                  stroke-width="14"
                                />
                                <path
                                  d="M347.55,261.85H332.22c-3.73,0-6.76-3.58-6.76-8v-35.2c0-4.42,3-8,6.76-8h15.33c3.73,0,6.76,3.58,6.76,8v35.2C354.31,258.27,351.28,261.85,347.55,261.85Z"
                                  fill="#ffdc67"
                                />
                                <path d="M249.73,183.76h28.85v274.8H249.73Z" fill="#323c44" />
                              </g>
                            </g>
                            <g id="wallet">
                              <path
                                d="M478,288.23h-337A28.93,28.93,0,0,0,112,317.14V546.2a29,29,0,0,0,28.94,28.95H478a29,29,0,0,0,28.95-28.94h0v-229A29,29,0,0,0,478,288.23Z"
                                fill="#a4bdc1"
                                stroke="#323c44"
                                stroke-miterlimit="10"
                                stroke-width="14"
                              />
                              <path
                                d="M512.83,382.71H416.71a28.93,28.93,0,0,0-28.95,28.94h0V467.8a29,29,0,0,0,28.95,28.95h96.12a19.31,19.31,0,0,0,19.3-19.3V402a19.3,19.3,0,0,0-19.3-19.3Z"
                                fill="#a4bdc1"
                                stroke="#323c44"
                                stroke-miterlimit="10"
                                stroke-width="14"
                              />
                              <path
                                d="M451.46,435.79v7.88a14.48,14.48,0,1,1-29,0v-7.9a14.48,14.48,0,0,1,29,0Z"
                                fill="#a4bdc1"
                                stroke="#323c44"
                                stroke-miterlimit="10"
                                stroke-width="14"
                              />
                              <path
                                d="M147.87,541.93V320.84c-.05-13.2,8.25-21.51,21.62-24.27a42.71,42.71,0,0,1,7.14-1.32l-29.36-.63a67.77,67.77,0,0,0-9.13.45c-13.37,2.75-20.32,12.57-20.27,25.77l.38,221.24c-1.57,15.44,8.15,27.08,25.34,26.1l33-.19c-15.9,0-28.78-10.58-28.76-25.93Z"
                                fill="#7b8f91"
                              />
                              <path
                                d="M148.16,343.22a6,6,0,0,0-6,6v92a6,6,0,0,0,12,0v-92A6,6,0,0,0,148.16,343.22Z"
                                fill="#323c44"
                              />
                            </g>
                          </svg>
                        </button>
                      </a>

                      <div v-show="getUser" class="row">
                        <div class="col-md-12">
                          <button
                            @click="logout_"
                            alt="cerrar session" aria-label="cerrar session"
                            type="submit"
                            class="btn btn-default-grad-purple-fill white-font disable-on-click spinner-on-click"
                            style="
                              width: 100%;
                              padding: 11px 0px !important;
                              background: #e45e5ed1 !important;
                              margin-bottom: 2px;
                              margin-top: 16px;
                            "
                            :class="{ 'disabled-btn-css': verify }"
                          >
                            <span v-if="!verify" class="spinner-span">Cerrar sesion</span>
                            <div
                              v-else
                              class="spinner-border text-info"
                              style="max-height: 30px"
                              role="status"
                            >
                              <span class="sr-only">Loading...</span>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <button
                  alt="navegacion de la pagina" aria-label="navegacion de la pagina"
                  class="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  style="color: white"
                >
                  <i class="fas fa-bars"></i>
                </button> -->
  </div>
</template>

<script>
import cartComponent from "@/components/cart/CartComponent";
import { mapActions, mapGetters, mapMutations } from "vuex";
import FormError from "@/components/cart/FormError";

export default{
components: {
  cartComponent,
  FormError
},
  props:{
    headerActive: {
      default: false
    }
  },
  data: ()=>({
      toggle: false,
      deleteItem: null,
      verify: false,
      form1: {
        rut_username: "",
        contrasena: "",
      },
      enlace: process.env.VUE_APP_PORTAL_URL,
      widthPantalla: window.innerWidth,
      showAuth: false,
      showCart: false


  }),
  computed:{
  ...mapGetters(["getCart", "getUser"]),

    resolucionComputed(){
    if (this.widthPantalla <= 1161) {
      // console.log("resolucionComputed es true");
        return true
      } else {
      // console.log("resolucionComputed es false")
        return false
    }
  },
  },
  methods: {
    ...mapMutations(["deleteService", "logout"]),
    ...mapActions(["login"]),
    registrar_cuenta() {
      this.$router.push('cuenta');
    },

    togglesChangeState(state){
      if(state == 'showCart'){
        this.showCart = !this.showCart;
        this.showAuth = false;
      }else{
        this.showAuth = !this.showAuth;
        this.showCart = false
      }
      this.$forceUpdate();
    },

    editItem(index) {
      window.location.replace("/cart?id=" + index);
    },
    deleteItem_(index) {
      this.deleteItem = index;
    },

    deleteItemConfirm() {},
    next_checkout() {
      window.location.replace("/cart?step=true");
    },

    //LOGIN
    login_() {
      this.$Progress.start();
      this.$validator.validateAll("form1").then((esValido) => {
        if (esValido) {
          this.verify = true;
          this.login(this.form1)
            .then((res) => {
              if (res?.type == "success") {
                this.$toast.success(res?.cliente?.nombre + " Logeado correctamente.");
                this.$Progress.finish();
                if(res.cliente != null){
                  setTimeout(() => {
                    console.log("cliente no es null por lo que se recarga la pagina");
                    location.reload();
                  }, 700);
              }
              } else {
                console.log("[001]errorLogin: ", res.type);
                this.$validator.errors.add({
                  field: "rut_username",
                  msg: res.message,
                  scope: "form1",
                });

                this.$toast.error("Error en los campos ingresados");
                this.$Progress.fail();
              }
              this.verify = false;
            })
            .catch((error) => {
              console.log(error.response);
            });
        } else {
          this.$toast.error("Error de login");
          this.$Progress.fail();
          this.verify = false;
        }
        //
      });
    },
    logout_() {
      this.logout();
    },
  },
}
</script>


<style scoped>


[type="button"], [type="reset"], [type="submit"] {

font-size: 16px !important;
}


.main_container_header_subMenu_list{
position: absolute;
top: 4rem;
right: 1.2rem;
display: flex;
flex-direction: column;
min-width: 240px;
margin-top: 0.25rem;
padding: 1.4rem;
border-radius: 8px;
background: #000;
border: 1px solid #80808057;
display: none;

}


.main_container_header_subMenu_list_btn{
margin: 10px 0px;
padding: 0.25rem 0;
cursor: pointer;

}

.main_container_header_subMenu_list_btn:hover{
color: #ffffff;
}


.main_container_header_subMenu_list_btn_icon{
margin: 0 5px;
}




/* active */

.headerToggles_headerActive .line{
stroke: #000  !important;
}

/* active end */

/* styel button cart / login */

.a-link-header {
cursor: pointer;
color: black;
}
.container-form {
padding: 25px 20px;
width: 400px;
left: auto !important;
}

.btn-cart-header {
padding: 8px 15px;

background: transparent;
color: var(--dark-color);
outline: none;
}

.btn-cart-header:focus {
outline: none;
}

.pt-btn-container {
display: flex;
align-items: center;
}

.badage-primary {
color: white !important;
background: var(--primary-color) !important;
padding: 5px;
}

.a-link-header:hover,
.a-link-header:active,
.a-link-header:focus {
color: white !important;
}

.only-movile {
height: 64px;
display: none;
}

@media(max-width:650px){

.btn-cart-header {
padding: 3px 5px !important;
margin: 0px 0px !important;
}

.dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: 0;
  bottom: initial;
  max-width: 300px;
}
}
@media (min-width: 1160px) {
.nav-bar-v {
  padding: 6px 35px 3px 35px !important;
}
}

.dropdown-resp {
position: relative;
}


@media (max-width: 1160px) {
.menu-item a{
  font-size: 15px;
}
.navbar-expand-lg .navbar-nav{
  margin-left: 120px!important;
}
.pt-menu-contain{
  display: flex !important;
}
.dropdown-res {
  position: inherit;
}
.container-form {
  width: 100% !important;
  max-width: 400px !important;
  right: 0 !important;
  top: 45px !important;
  transform: none !important;
}

.nav-bar-v {
  padding: 10px 0;
}

.only-movile {
  height: 64px;
  display: block;
}

.only-movile-header {
  position: fixed !important;
}
/* .header_container_buttons{
  position: absolute;
  right: 0;
} */
}
/* .no_style{
content: none !important;
} */
@media(max-width: 1023px){
.navbar-expand-lg .navbar-nav{
  margin-left: auto !important;
}
}

header#pt-header .logo_blanco.normal-logo {
display: none;
}

header#pt-header .logo_normal.normal-logo {
display: block;
}

header#pt-header .logo_blanco.home-logo {
display: block;
}
header#pt-header .logo_normal.home-logo {
display: none;
}

header#pt-header.pt-header-sticky .logo_normal.home-logo {
display: block !important;
}
header#pt-header.pt-header-sticky .logo_blanco.home-logo {
display: none !important;
}

.a-header {
color: white !important;
}

.header_container_buttons_active .a-header {
color: black !important;
}

@media (hover: hover) {
#creditcard {
  /*  set start position */
  transform: translateY(110px);
  transition: transform 0.1s ease-in-out;
  /*  set transition for mouse enter & exit */
}

#money {
  /*  set start position */
  transform: translateY(180px);
  transition: transform 0.1s ease-in-out;
  /*  set transition for mouse enter & exit */
}

button:hover #creditcard {
  transform: translateY(0px);
  transition: transform 0.2s ease-in-out;
  /*  overide transition for mouse enter */
}

button:hover #money {
  transform: translateY(0px);
  transition: transform 0.3s ease-in-out;
  /*  overide transition for mouse enter */
}
}

@keyframes bounce {
0% {
  transform: translateY(0);
}
50% {
  transform: translateY(-0.25rem);
}
100% {
  transform: translateY(0);
}
}

.button:hover .button__text span {
transform: translateY(-0.25rem);
transition: transform 0.2s ease-in-out;
}

.button-payment {
margin-top: 16px;
width: 100%;
border: none;
outline: none;
padding: 15px 0px !important;
position: relative;
border-radius: 5px;
background-color: var(--dark-color);
font-size: 18px;
box-shadow: rgba(0, 9, 61, 0.2) 0px 4px 8px 0px;
}

.button-payment:active {
transform: translateY(1px);
}

.button__svg {
position: absolute;
overflow: visible;
bottom: 6px;
right: 0.2rem;
height: 140%;
}

.badage-status-user {
font-size: 10px;
position: absolute;
color: green;
top: 17px;
margin-left: 44px;
}


span{
text-shadow: 0.5px 0.5px #0000005e;
font-weight: 600;
}

@media(max-width: 650px){


}
</style>