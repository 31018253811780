<template>
    <section class="bannerMain">
        <div class="bannerMain_bg">
            <img class="bannerMain_bg_img" :src="contentBanner.bg"  alt="">
        </div>
        <div class="bannerMain_container">
            <div class="bannerMain_container_title">
                <div class="bannerMain_container_title_head">
                    <img class="bannerMain_container_title_head_img" :src="contentBanner.head_logo" alt="">
                </div>

                <div class="bannerMain_container_body" style="color: #C80016;">
                    <h2 class="bannerMain_container_body_title" style="color: white;">
                        {{ contentBanner.title }}
                    </h2>

                    <a :href="contentBanner.btnUrl" class="bannerMain_container_body_btn">
                      <span>
                        {{  contentBanner.btnText }}
                      </span>  
                    </a>
                </div>

            </div>

            <div class="bannerMain_container_image">
                <img class="bannerMain_container_image_bg" :src="contentBanner.image_main" alt="">
            </div>
        </div>
    </section>
</template>

<script>
export default{
    props: {
        contentBanner:{
            require: true
        }
    }
}
</script>

<style scoped>
    .bannerMain{
        height: 100%;
        min-height: 92vh;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #C80016;
        padding-top: 0px;

    }

    .bannerMain_bg{
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        width: 100%;
        height: 100%;
    }

    .bannerMain_bg_img{
        width: 100%;
        height: 100%;
        opacity: 0.5;
    }
    .bannerMain_container{
        width: 80%;
        max-width: 1400px;
        display: flex;
        justify-content: space-around;
        z-index: 1;
    }

    .bannerMain_container_title{
        width: 50%;
        max-width: 900px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .bannerMain_container_title_head{
        margin-bottom: 20px;
    }
    .bannerMain_container_title_head_img {
        height: 32px;
    }


    .bannerMain_container_body_title{
        font-size: 50px;
        line-height: 55px;
        margin-bottom: 25px;
    }
    
    .bannerMain_container_body_btn{
        position: relative;
        width: 240px;
        height: 65px;
        background: white;
        padding: 20px 20px;
        border-radius: 4px;
        text-align: center;
        font-size: 20px;
        line-height: normal;
        font-weight: 500;
        display: block;
        cursor: pointer;
        color: #C80016;
    }

    .bannerMain_container_image{
        width: 50%;
        min-height: 500px;
        display: flex;
        justify-content: center;
    }

    .bannerMain_container_image_bg{
        height: 400px;
    }

    @media(max-width:1400px){
        .bannerMain{
        min-height: 94vh;
        }
        .bannerMain_container{
            max-width: 1100px;
        }

        .bannerMain_container_title_head_img{
            height: 25px;
        }
        .bannerMain_container_body_title{
            font-size: 34px;
            line-height: 42px;
        }

        .bannerMain_container_body_btn{
            min-width: 100px;
            max-width: 170px;
            width: auto;
            height: auto;
            padding: 10px 18px;
            border-radius: 2px;
            font-size: 14px;
        }
        .bannerMain_container_image{
            align-items: center;
        }
        .bannerMain_container_image_bg{
            height: 300px;
        }
    }

    @media(max-width:1100px){
        .bannerMain_container_title{
            width: 100%;
            text-align: center;
            align-items: center;
            justify-content: center;
        }

        .bannerMain_container_image{
            display: none;
        }

        .bannerMain_container_body{
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    @media(max-width:600px){
        .bannerMain_container_body_title{
            font-size: 25px;
        }
        .bannerMain_container_title_head_img{
            height: 18px;
        }

        .bannerMain_bg_img{
            opacity: 0.3;
        }
    }
</style>