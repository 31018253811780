<template>
  <div :class="positionCards">
    <titleBox :dataTitleBox="dataTitleBox" :configTitleBox="{'positionTitle':position }"/>
    <div class="sectionCards_content" :class="position == 'right' ? 'sectionCards_content_right' : ''">
      <div v-for="(item, index) in dataCards" :key="index" class="card">
        <div v-if="item.img" class="card_imageBx">
          <img :src="item.img" :alt="`iconIhosting-${item.title}`" class="card_imageBx_img" loading="lazy">
        </div>
        <p v-if="item.title" class="card_heading">{{ item.title }}</p>
        <!-- <p></p> -->
      </div>
    </div>
  </div>
</template>

<script>
import titleBox from '@/components/web/titleBox.vue';
export default{
    components:{
        titleBox
    },
    props:{
        dataCards:{
            default: []
        },
        dataTitleBox:{
            default: ()=>({})
        },
        position:{
          default: ''
        }
    },
    data:()=>({
    }),
    computed:{
      positionCards(){
        if(this.position == 'right'){
          return 'sectionCards sectionCards_right'
        }else if(this.position == 'left'){
          return 'sectionCards sectionCards_left'

        }
        else{
          return 'sectionCards'

        }
      }
    }
}

</script>

<style scoped>

.sectionCards{
    width: 100%;
    min-height: 50vh;
    padding: 100px 0px 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    background: white;
}

.sectionCards_right {
    align-items: flex-end !important;
    padding-right: 65px;

}

.sectionCards_left{
  padding-left: 65px;
  align-items: flex-start !important;

}

.sectionCards_content{
    max-width: 1600px;
    width: auto;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 30px;
    z-index: 1;
    margin-top: 20px;
}

.sectionCards_content_right{
  justify-content: flex-end;

}
.card {
  position: relative;
  width: 190px;
  height: 214px;
  background-color: var(--dark-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  padding: 40px 12px 10px;
  gap: 12px;
  border-radius: 8px;
  /* cursor: pointer; */
  color: white;
  /* margin: 20px; */
  /* margin: 0px 20px 0 0; */
}

.card::before {
  content: '';
  position: absolute;
  inset: 0;
  left: -6px;
  margin: auto;
  width: 200px;
  height: 224px;
  border-radius: 10px;
  background: linear-gradient(-45deg, var(--primary-color) 0%, #40c9ff 100% );

  /* background: linear-gradient(-45deg, #e81cff 0%, #40c9ff 100% ); */
  z-index: -10;
  pointer-events: none;
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.card::after {
  content: "";
  z-index: -1;
  position: absolute;
  inset: 0;
  background: linear-gradient(-45deg, var(--blue-color) 0%, #00dbde 100% );
  transform: translate3d(0, 0, 0) scale(0.95);
  filter: blur(20px);
}

.card_imageBx{
  height: 40%;
}
.card_imageBx_img{
  max-width: 50px; 
  height: 50px;
}

.card_heading {
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 600;
  min-height: 45%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.card p:not(.card_heading) {
  font-size: 14px;
  position: absolute;
  bottom: 0px;
}

/* .card p:last-child {
  color: var(--primary-color);
  font-weight: 600;
} */

/* .card:hover::after {
  filter: blur(30px);
} */

/* .card:hover::before {
  transform: rotate(-90deg) scaleX(1.34) scaleY(0.77);
} */


@media(max-width: 1555px){

.sectionCards{
    align-items: center;
    padding: 60px 0px 70px;
  }

  .sectionCards_content{
    justify-content: center;
    align-items: center;
    width: 80%;
    padding-left: 0px;
  }

  .sectionCards_right, .sectionCards_left{
    align-items: center !important;
    padding-right: 0px;
  }

  .card {
    margin:20px;
  }
}

@media (max-width: 980px){
  .sectionCards{
    padding: 40px 0px;
  }
  .sectionCards_content{
    width: 90%;
  }
  .card{
    margin: 20px;
  }
}
</style>