<template>
    <a class="button" :class="{'btn_primary': !btnConfig.colorPrimary, 'button_periodicidad': btnConfig.cupon_service.new_client}">
      <div class="tooltiptext">{{ btnConfig.description }}</div>
        <div class="dots_border"></div>
        <svg xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            class="sparkle"
        >
            <path
            class="path"
            stroke-linejoin="round"
            stroke-linecap="round"
            stroke="black"
            fill="black"
            d="M14.187 8.096L15 5.25L15.813 8.096C16.0231 8.83114 16.4171 9.50062 16.9577 10.0413C17.4984 10.5819 18.1679 10.9759 18.903 11.186L21.75 12L18.904 12.813C18.1689 13.0231 17.4994 13.4171 16.9587 13.9577C16.4181 14.4984 16.0241 15.1679 15.814 15.903L15 18.75L14.187 15.904C13.9769 15.1689 13.5829 14.4994 13.0423 13.9587C12.5016 13.4181 11.8321 13.0241 11.097 12.814L8.25 12L11.096 11.187C11.8311 10.9769 12.5006 10.5829 13.0413 10.0423C13.5819 9.50162 13.9759 8.83214 14.186 8.097L14.187 8.096Z"
            ></path>
            <path
            class="path"
            stroke-linejoin="round"
            stroke-linecap="round"
            stroke="black"
            fill="black"
            d="M6 14.25L5.741 15.285C5.59267 15.8785 5.28579 16.4206 4.85319 16.8532C4.42059 17.2858 3.87853 17.5927 3.285 17.741L2.25 18L3.285 18.259C3.87853 18.4073 4.42059 18.7142 4.85319 19.1468C5.28579 19.5794 5.59267 20.1215 5.741 20.715L6 21.75L6.259 20.715C6.40725 20.1216 6.71398 19.5796 7.14639 19.147C7.5788 18.7144 8.12065 18.4075 8.714 18.259L9.75 18L8.714 17.741C8.12065 17.5925 7.5788 17.2856 7.14639 16.853C6.71398 16.4204 6.40725 15.8784 6.259 15.285L6 14.25Z"
            ></path>
            <path
            class="path"
            stroke-linejoin="round"
            stroke-linecap="round"
            stroke="black"
            fill="black"
            d="M6.5 4L6.303 4.5915C6.24777 4.75718 6.15472 4.90774 6.03123 5.03123C5.90774 5.15472 5.75718 5.24777 5.5915 5.303L5 5.5L5.5915 5.697C5.75718 5.75223 5.90774 5.84528 6.03123 5.96877C6.15472 6.09226 6.24777 6.24282 6.303 6.4085L6.5 7L6.697 6.4085C6.75223 6.24282 6.84528 6.09226 6.96877 5.96877C7.09226 5.84528 7.24282 5.75223 7.4085 5.697L8 5.5L7.4085 5.303C7.24282 5.24777 7.09226 5.15472 6.96877 5.03123C6.84528 4.90774 6.75223 4.75718 6.697 4.5915L6.5 4Z"
            ></path>
        </svg>

        <!-- texto si el cupon es con periodicidad  -->
        <a v-if="btnConfig.cupon_service.periodicidad_state && btnConfig.cupon_service.all_periodicidades" class="text_button">
         Ahorra {{ getNamePeriodicidad( cupon_selected.cupon_periodicidad_selected.nombre ) }}
        </a>
        <!-- texto si el cupon es normal  -->
        <a v-else class="text_button"> {{ btnConfig.text }}</a>

        <!-- btn arrow -->
        <span v-if="btnConfig.cupon_service.periodicidad_state && btnConfig.cupon_service.all_periodicidades && btnConfig.cupon_service.all_periodicidades.length > 1"  class="btn_arrow_periodicidades" :class="{'btn_arrow_periodicidades_active': viewListBtn}">
          <i class="fa fa-arrow-down " aria-hidden="true" style="font-size: 12px; color: gray;"></i>
        </span>

        <div v-if="btnConfig.cupon_service.periodicidad_state == 1"  class="button_list_periodicidades">
          <a class="button_list_periodicidades_bx" >
            <span class="">Pagas por: </span>
          </a>
          <a 
          class="button_list_periodicidades_bx" 
          v-for="(periodicidad, index) in btnConfig.cupon_service.all_periodicidades " 
          :key="index"
          @click="changePeriodicidad_selected(periodicidad)"
          >
            <span class="">{{ getNamePeriodicidad(periodicidad.nombre) }}</span> <span> {{ btnConfig.cupon_service.id_tipocupon == 2 ? `${periodicidad.valor}% descuento` : `$${periodicidad.valor} descuento` }}</span>
          </a>
        </div>
      </a>
</template>

<script>
export default{
    props:{
        btnConfig: {
            default: ()=>({
                colorPrimary: false,
                text: "promo",
                description: "Ingresar",
                cupon_service: null
        }) ,
        } 
    },
    data: ()=>({
      viewListBtn: false,
      cupon_selected: {},
    }),
   created(){
    this.changePeriodicidad_selected();
   },
    computed:{
    },
    methods: {
      changeViewListBtn(){
        console.log("changeViewListBtn: ", this.viewListBtn);
        this.viewListBtn = !this.viewListBtn;
      },
      changePeriodicidad_selected(periodicidad = null){
        this.cupon_selected = {...this.btnConfig.cupon_service}
        if(periodicidad){
          this.cupon_selected.cupon_periodicidad_selected = periodicidad;
        }else{
          if(this.btnConfig.cupon_service.periodicidad_state == 1){
            if(this.btnConfig.cupon_service.all_periodicidades && this.btnConfig.cupon_service.all_periodicidades.length > 0){
              this.cupon_selected.cupon_periodicidad_selected = this.btnConfig.cupon_service.all_periodicidades[0];
              this.btnConfig.cupon_service.all_periodicidades.forEach(element => {
                if(element.valor < this.cupon_selected.cupon_periodicidad_selected.valor){
                  this.cupon_selected.cupon_periodicidad_selected = element;
                }
              });
            }else{
              this.cupon_selected.cupon_periodicidad_selected =  btnConfig.cupon_service.all_periodicidades[0];
            }
          }
        }
        this.$emit("get_cupon_selected",  this.cupon_selected);
      },
      getNamePeriodicidad(name){
        // console.log("name: " , name)
        let years = {
          anual: '1 año',
          bienal: '2 años',
          trienal: '3 años',
        }
        return years[name.toLocaleLowerCase()] ?? years.anual
      }
    }
}
</script>


<style scoped>
.button {
  --black-700: hsla(0 0% 12% / 1);
  --border_radius: 5px;
  --transtion: 0.3s ease-in-out;
  --offset: 2px;

  cursor: pointer;
  position: relative;

  display: flex;
  align-items: center;
  gap: 0.5rem;
  transform-origin: center;
  padding: 0.45rem 1.5rem;
  background-color: transparent;
  border: none;
  border-radius: var(--border_radius);
  transform: scale(calc(1 + (var(--active, 0) * 0.1)));
  box-shadow: rgb(0 124 255 / 6%) 2px 2px 15px 5px;
  transition: transform var(--transtion);
  position: relative;
  z-index: 1;
}

.button_periodicidad{
  padding-right: 50px;
}

.btn_primary {
    box-shadow: rgba(234, 244, 255, 0.06) 2px 2px 15px 5px;

}
.btn_primary .btn_arrow_periodicidades{
  border-left: 1px solid white !important;
}

.btn_primary .btn_arrow_periodicidades i{
  /* background-color: #3696ce; */
  color: white !important;
}

.button_list_periodicidades{
  display: none;
  position: absolute;
  top: 104%;
  left: 0px;
  right: 0px;
  background: white;
  border: 1px solid rgba(19, 132, 238, 0.116);
  min-height: 200px;
  min-width: 200px;
  border-radius: 5px;
  z-index: 1;
  box-shadow: rgba(101, 101, 101, 0.041) 2px 2px 11px 8px;
}

.button:hover .button_list_periodicidades{
  display: block;
}

.button_list_periodicidades_bx{
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 12px 12px;
  border-bottom: 1px solid rgba(23, 156, 189, 0.096);
  color: rgb(150, 150, 150);
  font-size: 14px;
}

.button_list_periodicidades_bx:hover{
  color: var(--primary-color) !important;
}

.button::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 100%;
  height: 100%;
  background-color: #ffffff;

  border-radius: var(--border_radius);
  /* box-shadow: ; */

  transition: all var(--transtion);
  z-index: 0;
}

.btn_primary::before{
    background-color: #3696ce;

}

.button::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 100%;
  height: 100%;
  /* background-color: hsla(260 97% 61% / 0.75); */
  /* background-image: linear-gradient(-45deg, #3696ce, #40c9ff); */
  background-position: top;

  opacity: var(--active, 0);
  border-radius: var(--border_radius);
  transition: opacity var(--transtion);
  z-index: 2;
}

/* .button:is(:hover, :focus-visible) {
  --active: 1;
} */

.button:active {
  transform: scale(1);
  
}

.button .dots_border {
  --size_border: calc(100% + 2px);

  overflow: hidden;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: var(--size_border);
  height: var(--size_border);
  background-color: transparent;

  border-radius: var(--border_radius);
  z-index: -10;
}

.button .dots_border::before {
  content: "";
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: left;
  transform: rotate(0deg);

  width: 100%;
  height: 2rem;
  background-color: rgb(182, 234, 255);

  mask: linear-gradient(transparent 0%, white 120%);
  animation: rotate 2s linear infinite;
}

.btn_primary .dots_border::before{
  background-color: rgb(145, 224, 255);


}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

.button .sparkle {
  position: relative;
  z-index: 10;

  width: 1.75rem;
}

.button .sparkle .path {
  fill: currentColor;
  stroke: currentColor;

  transform-origin: center;

  color: #3696ce;
}

.btn_primary .sparkle .path {
  color: hsl(0, 0%, 100%);

}

.button:is(:hover, :focus) .sparkle .path {
  animation: path 1.5s linear 0.5s infinite;
}

.button .sparkle .path:nth-child(1) {
  --scale_path_1: 1.2;
}
.button .sparkle .path:nth-child(2) {
  --scale_path_2: 1.2;
}
.button .sparkle .path:nth-child(3) {
  --scale_path_3: 1.2;
}

@keyframes path {
  0%,
  34%,
  71%,
  100% {
    transform: scale(1);
  }
  17% {
    transform: scale(var(--scale_path_1, 1));
  }
  49% {
    transform: scale(var(--scale_path_2, 1));
  }
  83% {
    transform: scale(var(--scale_path_3, 1));
  }
}

.button .text_button {
  position: relative;
  z-index: 10;
  background-image: linear-gradient(90deg, hsl(0deg 0% 34.01%) 0%, hsla(0 0% 100% / var(--active, 0)) 120%);

  background-clip: text;

  font-size: 1rem;
  /* color: gray; */

}

/* .button .text_button:hover{
  color: gray;
} */

.btn_primary .text_button {
    background-image: linear-gradient(
    90deg,
    hsla(0 0% 100% / 1) 0%,
    hsla(0 0% 100% / var(--active, 0)) 120%
  );
  background-clip: text;
  color: white;
}

.button:hover .text_button{
  color: var(--primary-color);
    /* background-image: linear-gradient(90deg, hsl(0deg 0% 34.01%) 0%, hsl(0deg 0% 34.01%) 120%); */
}

.btn_primary:hover .text_button {
  color: white;
    /* background-image: linear-gradient(
    90deg,
    hsla(0 0% 100% / 1) 0%,
    hsla(0 0% 100% / var(--active, 0)) 120%
  ); */
}



/* tootip */
/* .tooltip button:hover {
  background-color: #b92929;
  transform: scale(1.05);
} */

.button:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.tooltiptext {
  visibility: hidden;
  width: 160px;
  background-color: #000000cf;
  color: #fff;
  text-align: center;
  border-radius: 8px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -80px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 11px;

  /* visibility: visible;
  opacity: 1; */
}

.tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
}


.btn_arrow_periodicidades{
  position: absolute;
  top: 0px;
  /* bottom: -1px; */
  right: 0px;
  width: 40px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 40px;
  border-radius: 0px 5px 5px 0px;
  z-index: 10;
  border-left: 1px solid var(--primary-color);
  }


.button:hover .btn_arrow_periodicidades i{
  transform: rotate(180deg);
}

.btn_arrow_periodicidades i{
  transition: .2s;
  color: var(--primary-color) !important;
}

.btn_arrow_periodicidades_active i{
  transform: rotate(180deg);
}
</style>