<template>

        <div class="nubes">
            <div data-v-f027afe4="" data-v-c58b6816="" class="nubes_content" style="position: relative;"><svg data-v-f027afe4="" data-v-c58b6816="" version="1.0" xmlns="http://www.w3.org/2000/svg" width="320px" height="150px" viewBox="0 0 1280.000000 855.000000" style="position: absolute; left: -159px; bottom: 7px;"><g data-v-f027afe4="" data-v-c58b6816="" transform="translate(0.000000,855.000000) scale(0.100000,-0.100000)" :fill="styleNubes.color" stroke="none"><path data-v-f027afe4="" data-v-c58b6816="" d="M8130 8543 c-19 -1 -84 -8 -145 -13 -873 -83 -1730 -711 -2261 -1654
-42 -76 -106 -201 -141 -279 -35 -78 -69 -141 -76 -141 -6 1 -87 32 -179 68
-299 118 -473 148 -736 128 -427 -33 -806 -163 -1167 -400 -320 -209 -616
-513 -820 -842 -94 -152 -201 -383 -260 -564 -30 -91 -55 -172 -55 -181 0 -19
-8 -19 -126 6 -346 72 -827 -7 -1181 -196 -282 -150 -528 -380 -691 -647 -311
-508 -377 -1188 -176 -1788 194 -577 623 -1042 1161 -1259 256 -104 422 -135
703 -135 185 0 217 2 333 27 155 33 359 99 518 168 l119 51 87 -80 c302 -274
627 -471 981 -593 237 -81 440 -121 711 -139 589 -40 1171 123 1739 487 l83
53 97 -86 c303 -268 683 -442 1117 -511 153 -24 492 -24 625 0 471 86 872 311
1237 690 105 110 252 294 317 398 l29 46 171 -82 c307 -148 455 -194 648 -202
576 -24 1148 277 1523 802 448 627 600 1547 394 2380 -211 854 -779 1491
-1494 1675 -64 17 -127 30 -140 30 -13 0 -26 4 -29 9 -4 5 -15 60 -26 123
-221 1269 -1084 2289 -2175 2573 -257 67 -527 96 -715 78z"></path></g></svg><svg data-v-f027afe4="" data-v-c58b6816="" version="1.0" xmlns="http://www.w3.org/2000/svg" width="320px" height="150px" viewBox="0 0 1280.000000 855.000000" style="position: absolute;left: -17px;bottom: 19px;"><g data-v-f027afe4="" data-v-c58b6816="" transform="translate(0.000000,855.000000) scale(0.100000,-0.100000)" :fill="styleNubes.color" stroke="none"><path data-v-f027afe4="" data-v-c58b6816="" d="M8130 8543 c-19 -1 -84 -8 -145 -13 -873 -83 -1730 -711 -2261 -1654
-42 -76 -106 -201 -141 -279 -35 -78 -69 -141 -76 -141 -6 1 -87 32 -179 68
-299 118 -473 148 -736 128 -427 -33 -806 -163 -1167 -400 -320 -209 -616
-513 -820 -842 -94 -152 -201 -383 -260 -564 -30 -91 -55 -172 -55 -181 0 -19
-8 -19 -126 6 -346 72 -827 -7 -1181 -196 -282 -150 -528 -380 -691 -647 -311
-508 -377 -1188 -176 -1788 194 -577 623 -1042 1161 -1259 256 -104 422 -135
703 -135 185 0 217 2 333 27 155 33 359 99 518 168 l119 51 87 -80 c302 -274
627 -471 981 -593 237 -81 440 -121 711 -139 589 -40 1171 123 1739 487 l83
53 97 -86 c303 -268 683 -442 1117 -511 153 -24 492 -24 625 0 471 86 872 311
1237 690 105 110 252 294 317 398 l29 46 171 -82 c307 -148 455 -194 648 -202
576 -24 1148 277 1523 802 448 627 600 1547 394 2380 -211 854 -779 1491
-1494 1675 -64 17 -127 30 -140 30 -13 0 -26 4 -29 9 -4 5 -15 60 -26 123
-221 1269 -1084 2289 -2175 2573 -257 67 -527 96 -715 78z"></path></g></svg><svg data-v-f027afe4="" data-v-c58b6816="" version="1.0" xmlns="http://www.w3.org/2000/svg" width="320px" height="150px" viewBox="0 0 1280.000000 855.000000" style="position: absolute; left: 121px; bottom: 23px;"><g data-v-f027afe4="" data-v-c58b6816="" transform="translate(0.000000,855.000000) scale(0.100000,-0.100000)" :fill="styleNubes.color" stroke="none"><path data-v-f027afe4="" data-v-c58b6816="" d="M8130 8543 c-19 -1 -84 -8 -145 -13 -873 -83 -1730 -711 -2261 -1654
-42 -76 -106 -201 -141 -279 -35 -78 -69 -141 -76 -141 -6 1 -87 32 -179 68
-299 118 -473 148 -736 128 -427 -33 -806 -163 -1167 -400 -320 -209 -616
-513 -820 -842 -94 -152 -201 -383 -260 -564 -30 -91 -55 -172 -55 -181 0 -19
-8 -19 -126 6 -346 72 -827 -7 -1181 -196 -282 -150 -528 -380 -691 -647 -311
-508 -377 -1188 -176 -1788 194 -577 623 -1042 1161 -1259 256 -104 422 -135
703 -135 185 0 217 2 333 27 155 33 359 99 518 168 l119 51 87 -80 c302 -274
627 -471 981 -593 237 -81 440 -121 711 -139 589 -40 1171 123 1739 487 l83
53 97 -86 c303 -268 683 -442 1117 -511 153 -24 492 -24 625 0 471 86 872 311
1237 690 105 110 252 294 317 398 l29 46 171 -82 c307 -148 455 -194 648 -202
576 -24 1148 277 1523 802 448 627 600 1547 394 2380 -211 854 -779 1491
-1494 1675 -64 17 -127 30 -140 30 -13 0 -26 4 -29 9 -4 5 -15 60 -26 123
-221 1269 -1084 2289 -2175 2573 -257 67 -527 96 -715 78z"></path></g></svg><svg data-v-f027afe4="" data-v-c58b6816="" version="1.0" xmlns="http://www.w3.org/2000/svg" width="320px" height="150px" viewBox="0 0 1280.000000 855.000000" style="position: absolute; left: 187px; bottom: 4px;"><g data-v-f027afe4="" data-v-c58b6816="" transform="translate(0.000000,855.000000) scale(0.100000,-0.100000)" :fill="styleNubes.color" stroke="none"><path data-v-f027afe4="" data-v-c58b6816="" d="M8130 8543 c-19 -1 -84 -8 -145 -13 -873 -83 -1730 -711 -2261 -1654
-42 -76 -106 -201 -141 -279 -35 -78 -69 -141 -76 -141 -6 1 -87 32 -179 68
-299 118 -473 148 -736 128 -427 -33 -806 -163 -1167 -400 -320 -209 -616
-513 -820 -842 -94 -152 -201 -383 -260 -564 -30 -91 -55 -172 -55 -181 0 -19
-8 -19 -126 6 -346 72 -827 -7 -1181 -196 -282 -150 -528 -380 -691 -647 -311
-508 -377 -1188 -176 -1788 194 -577 623 -1042 1161 -1259 256 -104 422 -135
703 -135 185 0 217 2 333 27 155 33 359 99 518 168 l119 51 87 -80 c302 -274
627 -471 981 -593 237 -81 440 -121 711 -139 589 -40 1171 123 1739 487 l83
53 97 -86 c303 -268 683 -442 1117 -511 153 -24 492 -24 625 0 471 86 872 311
1237 690 105 110 252 294 317 398 l29 46 171 -82 c307 -148 455 -194 648 -202
576 -24 1148 277 1523 802 448 627 600 1547 394 2380 -211 854 -779 1491
-1494 1675 -64 17 -127 30 -140 30 -13 0 -26 4 -29 9 -4 5 -15 60 -26 123
-221 1269 -1084 2289 -2175 2573 -257 67 -527 96 -715 78z"></path></g></svg><svg data-v-f027afe4="" data-v-c58b6816="" version="1.0" xmlns="http://www.w3.org/2000/svg" width="320px" height="150px" viewBox="0 0 1280.000000 855.000000" style="position: absolute; left: 271px; bottom: -7px;"><g data-v-f027afe4="" data-v-c58b6816="" transform="translate(0.000000,855.000000) scale(0.100000,-0.100000)" :fill="styleNubes.color" stroke="none"><path data-v-f027afe4="" data-v-c58b6816="" d="M8130 8543 c-19 -1 -84 -8 -145 -13 -873 -83 -1730 -711 -2261 -1654
-42 -76 -106 -201 -141 -279 -35 -78 -69 -141 -76 -141 -6 1 -87 32 -179 68
-299 118 -473 148 -736 128 -427 -33 -806 -163 -1167 -400 -320 -209 -616
-513 -820 -842 -94 -152 -201 -383 -260 -564 -30 -91 -55 -172 -55 -181 0 -19
-8 -19 -126 6 -346 72 -827 -7 -1181 -196 -282 -150 -528 -380 -691 -647 -311
-508 -377 -1188 -176 -1788 194 -577 623 -1042 1161 -1259 256 -104 422 -135
703 -135 185 0 217 2 333 27 155 33 359 99 518 168 l119 51 87 -80 c302 -274
627 -471 981 -593 237 -81 440 -121 711 -139 589 -40 1171 123 1739 487 l83
53 97 -86 c303 -268 683 -442 1117 -511 153 -24 492 -24 625 0 471 86 872 311
1237 690 105 110 252 294 317 398 l29 46 171 -82 c307 -148 455 -194 648 -202
576 -24 1148 277 1523 802 448 627 600 1547 394 2380 -211 854 -779 1491
-1494 1675 -64 17 -127 30 -140 30 -13 0 -26 4 -29 9 -4 5 -15 60 -26 123
-221 1269 -1084 2289 -2175 2573 -257 67 -527 96 -715 78z"></path></g></svg><svg data-v-f027afe4="" data-v-c58b6816="" version="1.0" xmlns="http://www.w3.org/2000/svg" width="320px" height="150px" viewBox="0 0 1280.000000 855.000000" style="position: absolute;left: 409px;bottom: -3px;"><g data-v-f027afe4="" data-v-c58b6816="" transform="translate(0.000000,855.000000) scale(0.100000,-0.100000)" :fill="styleNubes.color" stroke="none"><path data-v-f027afe4="" data-v-c58b6816="" d="M8130 8543 c-19 -1 -84 -8 -145 -13 -873 -83 -1730 -711 -2261 -1654
-42 -76 -106 -201 -141 -279 -35 -78 -69 -141 -76 -141 -6 1 -87 32 -179 68
-299 118 -473 148 -736 128 -427 -33 -806 -163 -1167 -400 -320 -209 -616
-513 -820 -842 -94 -152 -201 -383 -260 -564 -30 -91 -55 -172 -55 -181 0 -19
-8 -19 -126 6 -346 72 -827 -7 -1181 -196 -282 -150 -528 -380 -691 -647 -311
-508 -377 -1188 -176 -1788 194 -577 623 -1042 1161 -1259 256 -104 422 -135
703 -135 185 0 217 2 333 27 155 33 359 99 518 168 l119 51 87 -80 c302 -274
627 -471 981 -593 237 -81 440 -121 711 -139 589 -40 1171 123 1739 487 l83
53 97 -86 c303 -268 683 -442 1117 -511 153 -24 492 -24 625 0 471 86 872 311
1237 690 105 110 252 294 317 398 l29 46 171 -82 c307 -148 455 -194 648 -202
576 -24 1148 277 1523 802 448 627 600 1547 394 2380 -211 854 -779 1491
-1494 1675 -64 17 -127 30 -140 30 -13 0 -26 4 -29 9 -4 5 -15 60 -26 123
-221 1269 -1084 2289 -2175 2573 -257 67 -527 96 -715 78z"></path></g></svg><svg data-v-f027afe4="" data-v-c58b6816="" version="1.0" xmlns="http://www.w3.org/2000/svg" width="320px" height="150px" viewBox="0 0 1280.000000 855.000000" style="position: absolute;left: 477px;bottom: -31px;"><g data-v-f027afe4="" data-v-c58b6816="" transform="translate(0.000000,855.000000) scale(0.100000,-0.100000)" :fill="styleNubes.color" stroke="none"><path data-v-f027afe4="" data-v-c58b6816="" d="M8130 8543 c-19 -1 -84 -8 -145 -13 -873 -83 -1730 -711 -2261 -1654
-42 -76 -106 -201 -141 -279 -35 -78 -69 -141 -76 -141 -6 1 -87 32 -179 68
-299 118 -473 148 -736 128 -427 -33 -806 -163 -1167 -400 -320 -209 -616
-513 -820 -842 -94 -152 -201 -383 -260 -564 -30 -91 -55 -172 -55 -181 0 -19
-8 -19 -126 6 -346 72 -827 -7 -1181 -196 -282 -150 -528 -380 -691 -647 -311
-508 -377 -1188 -176 -1788 194 -577 623 -1042 1161 -1259 256 -104 422 -135
703 -135 185 0 217 2 333 27 155 33 359 99 518 168 l119 51 87 -80 c302 -274
627 -471 981 -593 237 -81 440 -121 711 -139 589 -40 1171 123 1739 487 l83
53 97 -86 c303 -268 683 -442 1117 -511 153 -24 492 -24 625 0 471 86 872 311
1237 690 105 110 252 294 317 398 l29 46 171 -82 c307 -148 455 -194 648 -202
576 -24 1148 277 1523 802 448 627 600 1547 394 2380 -211 854 -779 1491
-1494 1675 -64 17 -127 30 -140 30 -13 0 -26 4 -29 9 -4 5 -15 60 -26 123
-221 1269 -1084 2289 -2175 2573 -257 67 -527 96 -715 78z"></path></g></svg><svg data-v-f027afe4="" data-v-c58b6816="" version="1.0" xmlns="http://www.w3.org/2000/svg" width="320px" height="150px" viewBox="0 0 1280.000000 855.000000" style="position: absolute;left: 622px;bottom: -19px;"><g data-v-f027afe4="" data-v-c58b6816="" transform="translate(0.000000,855.000000) scale(0.100000,-0.100000)" :fill="styleNubes.color" stroke="none"><path data-v-f027afe4="" data-v-c58b6816="" d="M8130 8543 c-19 -1 -84 -8 -145 -13 -873 -83 -1730 -711 -2261 -1654
-42 -76 -106 -201 -141 -279 -35 -78 -69 -141 -76 -141 -6 1 -87 32 -179 68
-299 118 -473 148 -736 128 -427 -33 -806 -163 -1167 -400 -320 -209 -616
-513 -820 -842 -94 -152 -201 -383 -260 -564 -30 -91 -55 -172 -55 -181 0 -19
-8 -19 -126 6 -346 72 -827 -7 -1181 -196 -282 -150 -528 -380 -691 -647 -311
-508 -377 -1188 -176 -1788 194 -577 623 -1042 1161 -1259 256 -104 422 -135
703 -135 185 0 217 2 333 27 155 33 359 99 518 168 l119 51 87 -80 c302 -274
627 -471 981 -593 237 -81 440 -121 711 -139 589 -40 1171 123 1739 487 l83
53 97 -86 c303 -268 683 -442 1117 -511 153 -24 492 -24 625 0 471 86 872 311
1237 690 105 110 252 294 317 398 l29 46 171 -82 c307 -148 455 -194 648 -202
576 -24 1148 277 1523 802 448 627 600 1547 394 2380 -211 854 -779 1491
-1494 1675 -64 17 -127 30 -140 30 -13 0 -26 4 -29 9 -4 5 -15 60 -26 123
-221 1269 -1084 2289 -2175 2573 -257 67 -527 96 -715 78z"></path></g></svg><svg data-v-f027afe4="" data-v-c58b6816="" version="1.0" xmlns="http://www.w3.org/2000/svg" width="320px" height="150px" viewBox="0 0 1280.000000 855.000000" style="position: absolute;left: 735px;bottom: -4px;"><g data-v-f027afe4="" data-v-c58b6816="" transform="translate(0.000000,855.000000) scale(0.100000,-0.100000)" :fill="styleNubes.color" stroke="none"><path data-v-f027afe4="" data-v-c58b6816="" d="M8130 8543 c-19 -1 -84 -8 -145 -13 -873 -83 -1730 -711 -2261 -1654
-42 -76 -106 -201 -141 -279 -35 -78 -69 -141 -76 -141 -6 1 -87 32 -179 68
-299 118 -473 148 -736 128 -427 -33 -806 -163 -1167 -400 -320 -209 -616
-513 -820 -842 -94 -152 -201 -383 -260 -564 -30 -91 -55 -172 -55 -181 0 -19
-8 -19 -126 6 -346 72 -827 -7 -1181 -196 -282 -150 -528 -380 -691 -647 -311
-508 -377 -1188 -176 -1788 194 -577 623 -1042 1161 -1259 256 -104 422 -135
703 -135 185 0 217 2 333 27 155 33 359 99 518 168 l119 51 87 -80 c302 -274
627 -471 981 -593 237 -81 440 -121 711 -139 589 -40 1171 123 1739 487 l83
53 97 -86 c303 -268 683 -442 1117 -511 153 -24 492 -24 625 0 471 86 872 311
1237 690 105 110 252 294 317 398 l29 46 171 -82 c307 -148 455 -194 648 -202
576 -24 1148 277 1523 802 448 627 600 1547 394 2380 -211 854 -779 1491
-1494 1675 -64 17 -127 30 -140 30 -13 0 -26 4 -29 9 -4 5 -15 60 -26 123
-221 1269 -1084 2289 -2175 2573 -257 67 -527 96 -715 78z"></path></g></svg><svg data-v-f027afe4="" data-v-c58b6816="" version="1.0" xmlns="http://www.w3.org/2000/svg" width="320px" height="150px" viewBox="0 0 1280.000000 855.000000" style="position: absolute;left: 899px;bottom: 6px;"><g data-v-f027afe4="" data-v-c58b6816="" transform="translate(0.000000,855.000000) scale(0.100000,-0.100000)" :fill="styleNubes.color" stroke="none"><path data-v-f027afe4="" data-v-c58b6816="" d="M8130 8543 c-19 -1 -84 -8 -145 -13 -873 -83 -1730 -711 -2261 -1654
-42 -76 -106 -201 -141 -279 -35 -78 -69 -141 -76 -141 -6 1 -87 32 -179 68
-299 118 -473 148 -736 128 -427 -33 -806 -163 -1167 -400 -320 -209 -616
-513 -820 -842 -94 -152 -201 -383 -260 -564 -30 -91 -55 -172 -55 -181 0 -19
-8 -19 -126 6 -346 72 -827 -7 -1181 -196 -282 -150 -528 -380 -691 -647 -311
-508 -377 -1188 -176 -1788 194 -577 623 -1042 1161 -1259 256 -104 422 -135
703 -135 185 0 217 2 333 27 155 33 359 99 518 168 l119 51 87 -80 c302 -274
627 -471 981 -593 237 -81 440 -121 711 -139 589 -40 1171 123 1739 487 l83
53 97 -86 c303 -268 683 -442 1117 -511 153 -24 492 -24 625 0 471 86 872 311
1237 690 105 110 252 294 317 398 l29 46 171 -82 c307 -148 455 -194 648 -202
576 -24 1148 277 1523 802 448 627 600 1547 394 2380 -211 854 -779 1491
-1494 1675 -64 17 -127 30 -140 30 -13 0 -26 4 -29 9 -4 5 -15 60 -26 123
-221 1269 -1084 2289 -2175 2573 -257 67 -527 96 -715 78z"></path></g></svg><svg data-v-f027afe4="" data-v-c58b6816="" version="1.0" xmlns="http://www.w3.org/2000/svg" width="320px" height="150px" viewBox="0 0 1280.000000 855.000000" style="position: absolute;left: 847px;bottom: -6px;"><g data-v-f027afe4="" data-v-c58b6816="" transform="translate(0.000000,855.000000) scale(0.100000,-0.100000)" :fill="styleNubes.color" stroke="none"><path data-v-f027afe4="" data-v-c58b6816="" d="M8130 8543 c-19 -1 -84 -8 -145 -13 -873 -83 -1730 -711 -2261 -1654
-42 -76 -106 -201 -141 -279 -35 -78 -69 -141 -76 -141 -6 1 -87 32 -179 68
-299 118 -473 148 -736 128 -427 -33 -806 -163 -1167 -400 -320 -209 -616
-513 -820 -842 -94 -152 -201 -383 -260 -564 -30 -91 -55 -172 -55 -181 0 -19
-8 -19 -126 6 -346 72 -827 -7 -1181 -196 -282 -150 -528 -380 -691 -647 -311
-508 -377 -1188 -176 -1788 194 -577 623 -1042 1161 -1259 256 -104 422 -135
703 -135 185 0 217 2 333 27 155 33 359 99 518 168 l119 51 87 -80 c302 -274
627 -471 981 -593 237 -81 440 -121 711 -139 589 -40 1171 123 1739 487 l83
53 97 -86 c303 -268 683 -442 1117 -511 153 -24 492 -24 625 0 471 86 872 311
1237 690 105 110 252 294 317 398 l29 46 171 -82 c307 -148 455 -194 648 -202
576 -24 1148 277 1523 802 448 627 600 1547 394 2380 -211 854 -779 1491
-1494 1675 -64 17 -127 30 -140 30 -13 0 -26 4 -29 9 -4 5 -15 60 -26 123
-221 1269 -1084 2289 -2175 2573 -257 67 -527 96 -715 78z"></path></g></svg><svg data-v-f027afe4="" data-v-c58b6816="" version="1.0" xmlns="http://www.w3.org/2000/svg" width="320px" height="150px" viewBox="0 0 1280.000000 855.000000" style="position: absolute; left: 879px; bottom: -17px;"><g data-v-f027afe4="" data-v-c58b6816="" transform="translate(0.000000,855.000000) scale(0.100000,-0.100000)" :fill="styleNubes.color" stroke="none"><path data-v-f027afe4="" data-v-c58b6816="" d="M8130 8543 c-19 -1 -84 -8 -145 -13 -873 -83 -1730 -711 -2261 -1654
-42 -76 -106 -201 -141 -279 -35 -78 -69 -141 -76 -141 -6 1 -87 32 -179 68
-299 118 -473 148 -736 128 -427 -33 -806 -163 -1167 -400 -320 -209 -616
-513 -820 -842 -94 -152 -201 -383 -260 -564 -30 -91 -55 -172 -55 -181 0 -19
-8 -19 -126 6 -346 72 -827 -7 -1181 -196 -282 -150 -528 -380 -691 -647 -311
-508 -377 -1188 -176 -1788 194 -577 623 -1042 1161 -1259 256 -104 422 -135
703 -135 185 0 217 2 333 27 155 33 359 99 518 168 l119 51 87 -80 c302 -274
627 -471 981 -593 237 -81 440 -121 711 -139 589 -40 1171 123 1739 487 l83
53 97 -86 c303 -268 683 -442 1117 -511 153 -24 492 -24 625 0 471 86 872 311
1237 690 105 110 252 294 317 398 l29 46 171 -82 c307 -148 455 -194 648 -202
576 -24 1148 277 1523 802 448 627 600 1547 394 2380 -211 854 -779 1491
-1494 1675 -64 17 -127 30 -140 30 -13 0 -26 4 -29 9 -4 5 -15 60 -26 123
-221 1269 -1084 2289 -2175 2573 -257 67 -527 96 -715 78z"></path></g></svg><svg data-v-f027afe4="" data-v-c58b6816="" version="1.0" xmlns="http://www.w3.org/2000/svg" width="320px" height="150px" viewBox="0 0 1280.000000 855.000000" style="position: absolute;left: 976px;bottom: 16px;"><g data-v-f027afe4="" data-v-c58b6816="" transform="translate(0.000000,855.000000) scale(0.100000,-0.100000)" :fill="styleNubes.color" stroke="none"><path data-v-f027afe4="" data-v-c58b6816="" d="M8130 8543 c-19 -1 -84 -8 -145 -13 -873 -83 -1730 -711 -2261 -1654
-42 -76 -106 -201 -141 -279 -35 -78 -69 -141 -76 -141 -6 1 -87 32 -179 68
-299 118 -473 148 -736 128 -427 -33 -806 -163 -1167 -400 -320 -209 -616
-513 -820 -842 -94 -152 -201 -383 -260 -564 -30 -91 -55 -172 -55 -181 0 -19
-8 -19 -126 6 -346 72 -827 -7 -1181 -196 -282 -150 -528 -380 -691 -647 -311
-508 -377 -1188 -176 -1788 194 -577 623 -1042 1161 -1259 256 -104 422 -135
703 -135 185 0 217 2 333 27 155 33 359 99 518 168 l119 51 87 -80 c302 -274
627 -471 981 -593 237 -81 440 -121 711 -139 589 -40 1171 123 1739 487 l83
53 97 -86 c303 -268 683 -442 1117 -511 153 -24 492 -24 625 0 471 86 872 311
1237 690 105 110 252 294 317 398 l29 46 171 -82 c307 -148 455 -194 648 -202
576 -24 1148 277 1523 802 448 627 600 1547 394 2380 -211 854 -779 1491
-1494 1675 -64 17 -127 30 -140 30 -13 0 -26 4 -29 9 -4 5 -15 60 -26 123
-221 1269 -1084 2289 -2175 2573 -257 67 -527 96 -715 78z"></path></g></svg><svg data-v-f027afe4="" data-v-c58b6816="" version="1.0" xmlns="http://www.w3.org/2000/svg" width="320px" height="150px" viewBox="0 0 1280.000000 855.000000" style="position: absolute;left: 1078px;bottom: -15px;"><g data-v-f027afe4="" data-v-c58b6816="" transform="translate(0.000000,855.000000) scale(0.100000,-0.100000)" :fill="styleNubes.color" stroke="none"><path data-v-f027afe4="" data-v-c58b6816="" d="M8130 8543 c-19 -1 -84 -8 -145 -13 -873 -83 -1730 -711 -2261 -1654
-42 -76 -106 -201 -141 -279 -35 -78 -69 -141 -76 -141 -6 1 -87 32 -179 68
-299 118 -473 148 -736 128 -427 -33 -806 -163 -1167 -400 -320 -209 -616
-513 -820 -842 -94 -152 -201 -383 -260 -564 -30 -91 -55 -172 -55 -181 0 -19
-8 -19 -126 6 -346 72 -827 -7 -1181 -196 -282 -150 -528 -380 -691 -647 -311
-508 -377 -1188 -176 -1788 194 -577 623 -1042 1161 -1259 256 -104 422 -135
703 -135 185 0 217 2 333 27 155 33 359 99 518 168 l119 51 87 -80 c302 -274
627 -471 981 -593 237 -81 440 -121 711 -139 589 -40 1171 123 1739 487 l83
53 97 -86 c303 -268 683 -442 1117 -511 153 -24 492 -24 625 0 471 86 872 311
1237 690 105 110 252 294 317 398 l29 46 171 -82 c307 -148 455 -194 648 -202
576 -24 1148 277 1523 802 448 627 600 1547 394 2380 -211 854 -779 1491
-1494 1675 -64 17 -127 30 -140 30 -13 0 -26 4 -29 9 -4 5 -15 60 -26 123
-221 1269 -1084 2289 -2175 2573 -257 67 -527 96 -715 78z"></path></g></svg><svg data-v-f027afe4="" data-v-c58b6816="" version="1.0" xmlns="http://www.w3.org/2000/svg" width="320px" height="150px" viewBox="0 0 1280.000000 855.000000" style="position: absolute; left: 1233px; bottom: 2px;"><g data-v-f027afe4="" data-v-c58b6816="" transform="translate(0.000000,855.000000) scale(0.100000,-0.100000)" :fill="styleNubes.color" stroke="none"><path data-v-f027afe4="" data-v-c58b6816="" d="M8130 8543 c-19 -1 -84 -8 -145 -13 -873 -83 -1730 -711 -2261 -1654
-42 -76 -106 -201 -141 -279 -35 -78 -69 -141 -76 -141 -6 1 -87 32 -179 68
-299 118 -473 148 -736 128 -427 -33 -806 -163 -1167 -400 -320 -209 -616
-513 -820 -842 -94 -152 -201 -383 -260 -564 -30 -91 -55 -172 -55 -181 0 -19
-8 -19 -126 6 -346 72 -827 -7 -1181 -196 -282 -150 -528 -380 -691 -647 -311
-508 -377 -1188 -176 -1788 194 -577 623 -1042 1161 -1259 256 -104 422 -135
703 -135 185 0 217 2 333 27 155 33 359 99 518 168 l119 51 87 -80 c302 -274
627 -471 981 -593 237 -81 440 -121 711 -139 589 -40 1171 123 1739 487 l83
53 97 -86 c303 -268 683 -442 1117 -511 153 -24 492 -24 625 0 471 86 872 311
1237 690 105 110 252 294 317 398 l29 46 171 -82 c307 -148 455 -194 648 -202
576 -24 1148 277 1523 802 448 627 600 1547 394 2380 -211 854 -779 1491
-1494 1675 -64 17 -127 30 -140 30 -13 0 -26 4 -29 9 -4 5 -15 60 -26 123
-221 1269 -1084 2289 -2175 2573 -257 67 -527 96 -715 78z"></path></g></svg><svg data-v-f027afe4="" data-v-c58b6816="" version="1.0" xmlns="http://www.w3.org/2000/svg" width="320px" height="150px" viewBox="0 0 1280.000000 855.000000" style="position: absolute; left: 1310px; bottom: -10px;"><g data-v-f027afe4="" data-v-c58b6816="" transform="translate(0.000000,855.000000) scale(0.100000,-0.100000)" :fill="styleNubes.color" stroke="none"><path data-v-f027afe4="" data-v-c58b6816="" d="M8130 8543 c-19 -1 -84 -8 -145 -13 -873 -83 -1730 -711 -2261 -1654
-42 -76 -106 -201 -141 -279 -35 -78 -69 -141 -76 -141 -6 1 -87 32 -179 68
-299 118 -473 148 -736 128 -427 -33 -806 -163 -1167 -400 -320 -209 -616
-513 -820 -842 -94 -152 -201 -383 -260 -564 -30 -91 -55 -172 -55 -181 0 -19
-8 -19 -126 6 -346 72 -827 -7 -1181 -196 -282 -150 -528 -380 -691 -647 -311
-508 -377 -1188 -176 -1788 194 -577 623 -1042 1161 -1259 256 -104 422 -135
703 -135 185 0 217 2 333 27 155 33 359 99 518 168 l119 51 87 -80 c302 -274
627 -471 981 -593 237 -81 440 -121 711 -139 589 -40 1171 123 1739 487 l83
53 97 -86 c303 -268 683 -442 1117 -511 153 -24 492 -24 625 0 471 86 872 311
1237 690 105 110 252 294 317 398 l29 46 171 -82 c307 -148 455 -194 648 -202
576 -24 1148 277 1523 802 448 627 600 1547 394 2380 -211 854 -779 1491
-1494 1675 -64 17 -127 30 -140 30 -13 0 -26 4 -29 9 -4 5 -15 60 -26 123
-221 1269 -1084 2289 -2175 2573 -257 67 -527 96 -715 78z"></path></g></svg><svg data-v-f027afe4="" data-v-c58b6816="" version="1.0" xmlns="http://www.w3.org/2000/svg" width="320px" height="150px" viewBox="0 0 1280.000000 855.000000" style="position: absolute; left: 1510px; bottom: -6px;"><g data-v-f027afe4="" data-v-c58b6816="" transform="translate(0.000000,855.000000) scale(0.100000,-0.100000)" :fill="styleNubes.color" stroke="none"><path data-v-f027afe4="" data-v-c58b6816="" d="M8130 8543 c-19 -1 -84 -8 -145 -13 -873 -83 -1730 -711 -2261 -1654
-42 -76 -106 -201 -141 -279 -35 -78 -69 -141 -76 -141 -6 1 -87 32 -179 68
-299 118 -473 148 -736 128 -427 -33 -806 -163 -1167 -400 -320 -209 -616
-513 -820 -842 -94 -152 -201 -383 -260 -564 -30 -91 -55 -172 -55 -181 0 -19
-8 -19 -126 6 -346 72 -827 -7 -1181 -196 -282 -150 -528 -380 -691 -647 -311
-508 -377 -1188 -176 -1788 194 -577 623 -1042 1161 -1259 256 -104 422 -135
703 -135 185 0 217 2 333 27 155 33 359 99 518 168 l119 51 87 -80 c302 -274
627 -471 981 -593 237 -81 440 -121 711 -139 589 -40 1171 123 1739 487 l83
53 97 -86 c303 -268 683 -442 1117 -511 153 -24 492 -24 625 0 471 86 872 311
1237 690 105 110 252 294 317 398 l29 46 171 -82 c307 -148 455 -194 648 -202
576 -24 1148 277 1523 802 448 627 600 1547 394 2380 -211 854 -779 1491
-1494 1675 -64 17 -127 30 -140 30 -13 0 -26 4 -29 9 -4 5 -15 60 -26 123
-221 1269 -1084 2289 -2175 2573 -257 67 -527 96 -715 78z"></path></g></svg><svg data-v-f027afe4="" data-v-c58b6816="" version="1.0" xmlns="http://www.w3.org/2000/svg" width="320px" height="150px" viewBox="0 0 1280.000000 855.000000" style="position: absolute; left: 1695px; bottom: -8px;"><g data-v-f027afe4="" data-v-c58b6816="" transform="translate(0.000000,855.000000) scale(0.100000,-0.100000)" :fill="styleNubes.color" stroke="none"><path data-v-f027afe4="" data-v-c58b6816="" d="M8130 8543 c-19 -1 -84 -8 -145 -13 -873 -83 -1730 -711 -2261 -1654
-42 -76 -106 -201 -141 -279 -35 -78 -69 -141 -76 -141 -6 1 -87 32 -179 68
-299 118 -473 148 -736 128 -427 -33 -806 -163 -1167 -400 -320 -209 -616
-513 -820 -842 -94 -152 -201 -383 -260 -564 -30 -91 -55 -172 -55 -181 0 -19
-8 -19 -126 6 -346 72 -827 -7 -1181 -196 -282 -150 -528 -380 -691 -647 -311
-508 -377 -1188 -176 -1788 194 -577 623 -1042 1161 -1259 256 -104 422 -135
703 -135 185 0 217 2 333 27 155 33 359 99 518 168 l119 51 87 -80 c302 -274
627 -471 981 -593 237 -81 440 -121 711 -139 589 -40 1171 123 1739 487 l83
53 97 -86 c303 -268 683 -442 1117 -511 153 -24 492 -24 625 0 471 86 872 311
1237 690 105 110 252 294 317 398 l29 46 171 -82 c307 -148 455 -194 648 -202
576 -24 1148 277 1523 802 448 627 600 1547 394 2380 -211 854 -779 1491
-1494 1675 -64 17 -127 30 -140 30 -13 0 -26 4 -29 9 -4 5 -15 60 -26 123
-221 1269 -1084 2289 -2175 2573 -257 67 -527 96 -715 78z"></path></g></svg><svg data-v-f027afe4="" data-v-c58b6816="" version="1.0" xmlns="http://www.w3.org/2000/svg" width="320px" height="150px" viewBox="0 0 1280.000000 855.000000" style="position: absolute; left: 1891px; bottom: 5px;"><g data-v-f027afe4="" data-v-c58b6816="" transform="translate(0.000000,855.000000) scale(0.100000,-0.100000)" :fill="styleNubes.color" stroke="none"><path data-v-f027afe4="" data-v-c58b6816="" d="M8130 8543 c-19 -1 -84 -8 -145 -13 -873 -83 -1730 -711 -2261 -1654
-42 -76 -106 -201 -141 -279 -35 -78 -69 -141 -76 -141 -6 1 -87 32 -179 68
-299 118 -473 148 -736 128 -427 -33 -806 -163 -1167 -400 -320 -209 -616
-513 -820 -842 -94 -152 -201 -383 -260 -564 -30 -91 -55 -172 -55 -181 0 -19
-8 -19 -126 6 -346 72 -827 -7 -1181 -196 -282 -150 -528 -380 -691 -647 -311
-508 -377 -1188 -176 -1788 194 -577 623 -1042 1161 -1259 256 -104 422 -135
703 -135 185 0 217 2 333 27 155 33 359 99 518 168 l119 51 87 -80 c302 -274
627 -471 981 -593 237 -81 440 -121 711 -139 589 -40 1171 123 1739 487 l83
53 97 -86 c303 -268 683 -442 1117 -511 153 -24 492 -24 625 0 471 86 872 311
1237 690 105 110 252 294 317 398 l29 46 171 -82 c307 -148 455 -194 648 -202
576 -24 1148 277 1523 802 448 627 600 1547 394 2380 -211 854 -779 1491
-1494 1675 -64 17 -127 30 -140 30 -13 0 -26 4 -29 9 -4 5 -15 60 -26 123
-221 1269 -1084 2289 -2175 2573 -257 67 -527 96 -715 78z"></path></g></svg><svg data-v-f027afe4="" data-v-c58b6816="" version="1.0" xmlns="http://www.w3.org/2000/svg" width="320px" height="150px" viewBox="0 0 1280.000000 855.000000" style="position: absolute; left: 1991px; bottom: -63px;"><g data-v-f027afe4="" data-v-c58b6816="" transform="translate(0.000000,855.000000) scale(0.100000,-0.100000)" :fill="styleNubes.color" stroke="none"><path data-v-f027afe4="" data-v-c58b6816="" d="M8130 8543 c-19 -1 -84 -8 -145 -13 -873 -83 -1730 -711 -2261 -1654
-42 -76 -106 -201 -141 -279 -35 -78 -69 -141 -76 -141 -6 1 -87 32 -179 68
-299 118 -473 148 -736 128 -427 -33 -806 -163 -1167 -400 -320 -209 -616
-513 -820 -842 -94 -152 -201 -383 -260 -564 -30 -91 -55 -172 -55 -181 0 -19
-8 -19 -126 6 -346 72 -827 -7 -1181 -196 -282 -150 -528 -380 -691 -647 -311
-508 -377 -1188 -176 -1788 194 -577 623 -1042 1161 -1259 256 -104 422 -135
703 -135 185 0 217 2 333 27 155 33 359 99 518 168 l119 51 87 -80 c302 -274
627 -471 981 -593 237 -81 440 -121 711 -139 589 -40 1171 123 1739 487 l83
53 97 -86 c303 -268 683 -442 1117 -511 153 -24 492 -24 625 0 471 86 872 311
1237 690 105 110 252 294 317 398 l29 46 171 -82 c307 -148 455 -194 648 -202
576 -24 1148 277 1523 802 448 627 600 1547 394 2380 -211 854 -779 1491
-1494 1675 -64 17 -127 30 -140 30 -13 0 -26 4 -29 9 -4 5 -15 60 -26 123
-221 1269 -1084 2289 -2175 2573 -257 67 -527 96 -715 78z"></path></g></svg><svg data-v-f027afe4="" data-v-c58b6816="" version="1.0" xmlns="http://www.w3.org/2000/svg" width="320px" height="150px" viewBox="0 0 1280.000000 855.000000" style="position: absolute; left: 2201px; bottom: -19px;"><g data-v-f027afe4="" data-v-c58b6816="" transform="translate(0.000000,855.000000) scale(0.100000,-0.100000)" :fill="styleNubes.color" stroke="none"><path data-v-f027afe4="" data-v-c58b6816="" d="M8130 8543 c-19 -1 -84 -8 -145 -13 -873 -83 -1730 -711 -2261 -1654
-42 -76 -106 -201 -141 -279 -35 -78 -69 -141 -76 -141 -6 1 -87 32 -179 68
-299 118 -473 148 -736 128 -427 -33 -806 -163 -1167 -400 -320 -209 -616
-513 -820 -842 -94 -152 -201 -383 -260 -564 -30 -91 -55 -172 -55 -181 0 -19
-8 -19 -126 6 -346 72 -827 -7 -1181 -196 -282 -150 -528 -380 -691 -647 -311
-508 -377 -1188 -176 -1788 194 -577 623 -1042 1161 -1259 256 -104 422 -135
703 -135 185 0 217 2 333 27 155 33 359 99 518 168 l119 51 87 -80 c302 -274
627 -471 981 -593 237 -81 440 -121 711 -139 589 -40 1171 123 1739 487 l83
53 97 -86 c303 -268 683 -442 1117 -511 153 -24 492 -24 625 0 471 86 872 311
1237 690 105 110 252 294 317 398 l29 46 171 -82 c307 -148 455 -194 648 -202
576 -24 1148 277 1523 802 448 627 600 1547 394 2380 -211 854 -779 1491
-1494 1675 -64 17 -127 30 -140 30 -13 0 -26 4 -29 9 -4 5 -15 60 -26 123
-221 1269 -1084 2289 -2175 2573 -257 67 -527 96 -715 78z"></path></g></svg><svg data-v-f027afe4="" data-v-c58b6816="" version="1.0" xmlns="http://www.w3.org/2000/svg" width="320px" height="150px" viewBox="0 0 1280.000000 855.000000" style="position: absolute; left: 2391px; bottom: -4px;"><g data-v-f027afe4="" data-v-c58b6816="" transform="translate(0.000000,855.000000) scale(0.100000,-0.100000)" :fill="styleNubes.color" stroke="none"><path data-v-f027afe4="" data-v-c58b6816="" d="M8130 8543 c-19 -1 -84 -8 -145 -13 -873 -83 -1730 -711 -2261 -1654
-42 -76 -106 -201 -141 -279 -35 -78 -69 -141 -76 -141 -6 1 -87 32 -179 68
-299 118 -473 148 -736 128 -427 -33 -806 -163 -1167 -400 -320 -209 -616
-513 -820 -842 -94 -152 -201 -383 -260 -564 -30 -91 -55 -172 -55 -181 0 -19
-8 -19 -126 6 -346 72 -827 -7 -1181 -196 -282 -150 -528 -380 -691 -647 -311
-508 -377 -1188 -176 -1788 194 -577 623 -1042 1161 -1259 256 -104 422 -135
703 -135 185 0 217 2 333 27 155 33 359 99 518 168 l119 51 87 -80 c302 -274
627 -471 981 -593 237 -81 440 -121 711 -139 589 -40 1171 123 1739 487 l83
53 97 -86 c303 -268 683 -442 1117 -511 153 -24 492 -24 625 0 471 86 872 311
1237 690 105 110 252 294 317 398 l29 46 171 -82 c307 -148 455 -194 648 -202
576 -24 1148 277 1523 802 448 627 600 1547 394 2380 -211 854 -779 1491
-1494 1675 -64 17 -127 30 -140 30 -13 0 -26 4 -29 9 -4 5 -15 60 -26 123
-221 1269 -1084 2289 -2175 2573 -257 67 -527 96 -715 78z"></path></g></svg><svg data-v-f027afe4="" data-v-c58b6816="" version="1.0" xmlns="http://www.w3.org/2000/svg" width="320px" height="150px" viewBox="0 0 1280.000000 855.000000" style="position: absolute; left: 2410px; bottom: 6px;"><g data-v-f027afe4="" data-v-c58b6816="" transform="translate(0.000000,855.000000) scale(0.100000,-0.100000)" :fill="styleNubes.color" stroke="none"><path data-v-f027afe4="" data-v-c58b6816="" d="M8130 8543 c-19 -1 -84 -8 -145 -13 -873 -83 -1730 -711 -2261 -1654
-42 -76 -106 -201 -141 -279 -35 -78 -69 -141 -76 -141 -6 1 -87 32 -179 68
-299 118 -473 148 -736 128 -427 -33 -806 -163 -1167 -400 -320 -209 -616
-513 -820 -842 -94 -152 -201 -383 -260 -564 -30 -91 -55 -172 -55 -181 0 -19
-8 -19 -126 6 -346 72 -827 -7 -1181 -196 -282 -150 -528 -380 -691 -647 -311
-508 -377 -1188 -176 -1788 194 -577 623 -1042 1161 -1259 256 -104 422 -135
703 -135 185 0 217 2 333 27 155 33 359 99 518 168 l119 51 87 -80 c302 -274
627 -471 981 -593 237 -81 440 -121 711 -139 589 -40 1171 123 1739 487 l83
53 97 -86 c303 -268 683 -442 1117 -511 153 -24 492 -24 625 0 471 86 872 311
1237 690 105 110 252 294 317 398 l29 46 171 -82 c307 -148 455 -194 648 -202
576 -24 1148 277 1523 802 448 627 600 1547 394 2380 -211 854 -779 1491
-1494 1675 -64 17 -127 30 -140 30 -13 0 -26 4 -29 9 -4 5 -15 60 -26 123
-221 1269 -1084 2289 -2175 2573 -257 67 -527 96 -715 78z"></path></g></svg><svg data-v-f027afe4="" data-v-c58b6816="" version="1.0" xmlns="http://www.w3.org/2000/svg" width="320px" height="150px" viewBox="0 0 1280.000000 855.000000" style="position: absolute; left: 2526px; bottom: -6px;"><g data-v-f027afe4="" data-v-c58b6816="" transform="translate(0.000000,855.000000) scale(0.100000,-0.100000)" :fill="styleNubes.color" stroke="none"><path data-v-f027afe4="" data-v-c58b6816="" d="M8130 8543 c-19 -1 -84 -8 -145 -13 -873 -83 -1730 -711 -2261 -1654
-42 -76 -106 -201 -141 -279 -35 -78 -69 -141 -76 -141 -6 1 -87 32 -179 68
-299 118 -473 148 -736 128 -427 -33 -806 -163 -1167 -400 -320 -209 -616
-513 -820 -842 -94 -152 -201 -383 -260 -564 -30 -91 -55 -172 -55 -181 0 -19
-8 -19 -126 6 -346 72 -827 -7 -1181 -196 -282 -150 -528 -380 -691 -647 -311
-508 -377 -1188 -176 -1788 194 -577 623 -1042 1161 -1259 256 -104 422 -135
703 -135 185 0 217 2 333 27 155 33 359 99 518 168 l119 51 87 -80 c302 -274
627 -471 981 -593 237 -81 440 -121 711 -139 589 -40 1171 123 1739 487 l83
53 97 -86 c303 -268 683 -442 1117 -511 153 -24 492 -24 625 0 471 86 872 311
1237 690 105 110 252 294 317 398 l29 46 171 -82 c307 -148 455 -194 648 -202
576 -24 1148 277 1523 802 448 627 600 1547 394 2380 -211 854 -779 1491
-1494 1675 -64 17 -127 30 -140 30 -13 0 -26 4 -29 9 -4 5 -15 60 -26 123
-221 1269 -1084 2289 -2175 2573 -257 67 -527 96 -715 78z"></path></g></svg><svg data-v-f027afe4="" data-v-c58b6816="" version="1.0" xmlns="http://www.w3.org/2000/svg" width="320px" height="150px" viewBox="0 0 1280.000000 855.000000" style="position: absolute; left: 2779px; bottom: -17px;"><g data-v-f027afe4="" data-v-c58b6816="" transform="translate(0.000000,855.000000) scale(0.100000,-0.100000)" :fill="styleNubes.color" stroke="none"><path data-v-f027afe4="" data-v-c58b6816="" d="M8130 8543 c-19 -1 -84 -8 -145 -13 -873 -83 -1730 -711 -2261 -1654
-42 -76 -106 -201 -141 -279 -35 -78 -69 -141 -76 -141 -6 1 -87 32 -179 68
-299 118 -473 148 -736 128 -427 -33 -806 -163 -1167 -400 -320 -209 -616
-513 -820 -842 -94 -152 -201 -383 -260 -564 -30 -91 -55 -172 -55 -181 0 -19
-8 -19 -126 6 -346 72 -827 -7 -1181 -196 -282 -150 -528 -380 -691 -647 -311
-508 -377 -1188 -176 -1788 194 -577 623 -1042 1161 -1259 256 -104 422 -135
703 -135 185 0 217 2 333 27 155 33 359 99 518 168 l119 51 87 -80 c302 -274
627 -471 981 -593 237 -81 440 -121 711 -139 589 -40 1171 123 1739 487 l83
53 97 -86 c303 -268 683 -442 1117 -511 153 -24 492 -24 625 0 471 86 872 311
1237 690 105 110 252 294 317 398 l29 46 171 -82 c307 -148 455 -194 648 -202
576 -24 1148 277 1523 802 448 627 600 1547 394 2380 -211 854 -779 1491
-1494 1675 -64 17 -127 30 -140 30 -13 0 -26 4 -29 9 -4 5 -15 60 -26 123
-221 1269 -1084 2289 -2175 2573 -257 67 -527 96 -715 78z"></path></g></svg><svg data-v-f027afe4="" data-v-c58b6816="" version="1.0" xmlns="http://www.w3.org/2000/svg" width="320px" height="150px" viewBox="0 0 1280.000000 855.000000" style="position: absolute; left: 2951px; bottom: 4px;"><g data-v-f027afe4="" data-v-c58b6816="" transform="translate(0.000000,855.000000) scale(0.100000,-0.100000)" :fill="styleNubes.color" stroke="none"><path data-v-f027afe4="" data-v-c58b6816="" d="M8130 8543 c-19 -1 -84 -8 -145 -13 -873 -83 -1730 -711 -2261 -1654
-42 -76 -106 -201 -141 -279 -35 -78 -69 -141 -76 -141 -6 1 -87 32 -179 68
-299 118 -473 148 -736 128 -427 -33 -806 -163 -1167 -400 -320 -209 -616
-513 -820 -842 -94 -152 -201 -383 -260 -564 -30 -91 -55 -172 -55 -181 0 -19
-8 -19 -126 6 -346 72 -827 -7 -1181 -196 -282 -150 -528 -380 -691 -647 -311
-508 -377 -1188 -176 -1788 194 -577 623 -1042 1161 -1259 256 -104 422 -135
703 -135 185 0 217 2 333 27 155 33 359 99 518 168 l119 51 87 -80 c302 -274
627 -471 981 -593 237 -81 440 -121 711 -139 589 -40 1171 123 1739 487 l83
53 97 -86 c303 -268 683 -442 1117 -511 153 -24 492 -24 625 0 471 86 872 311
1237 690 105 110 252 294 317 398 l29 46 171 -82 c307 -148 455 -194 648 -202
576 -24 1148 277 1523 802 448 627 600 1547 394 2380 -211 854 -779 1491
-1494 1675 -64 17 -127 30 -140 30 -13 0 -26 4 -29 9 -4 5 -15 60 -26 123
-221 1269 -1084 2289 -2175 2573 -257 67 -527 96 -715 78z"></path></g></svg></div>
    </div>
</template>


<script>
export default {
  props:{
    styleNubes: {
      default: ()=>({
        color: 'white'
      })
    }
  }
}
</script>

<style scoped>
/* .nubes{
  position: absolute;
  left: 0;
  bottom: -50px;
} */
</style>