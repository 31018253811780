<template>
    <section
      class="config cd-main-content pb-80 blog sec-bg2 motpath boxshadowcustom section_"
    >  
      <!-- HEADER -->
      <div class="pt-breadcrumb pt-breadcrumb-cart custom-background">
        <div class="bg-logo-banner" style="z-index: 0">
          <img src="images/isotipo.png" alt="ihosting" style="max-width: 100%" />
        </div>
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-8"></div>
            <div class="col-lg-4 ilustra-home">
              <div class="pt-breadcrumb-img text-right wow fadeInRight">
                <img src="images/about-us/Group-510-1.svg" class="img-fluid" alt="seozie-img" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- STEPS -->
      <div class="container">
        <transition name="slide-fade" tag="div">
          <div class="row">
            <div class="col-md-12 col-lg-8 pt-80" style="margin-top: -140px !important">
              <div class="wrap-blog">
                <div class="row">
                  <div class="col-md-12 col-lg-12">
                    <div class="container-steps-v" style="padding: 0">
  
                      <!-- REGISTRO -->
                      <transition name="slide-fade" tag="div" class="row">
                        <div style="width: 100%">
                          <registro
                          />
                        </div>
                      </transition>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </section>
  </template>
  
  <script>
    import { mapMutations } from "vuex";
    import registro from "@/components/cart/steps/registro"; // REGISTRO
    
    export default {
      name: "Registro",
      components: {
        registro
      },
      metaInfo: {
        title: "ihosting | Registro",
        htmlAttrs: {
          lang: "es",
          amp: true,
        },
        meta: [
          {
            name: "description",
            content: "Registra tu cuenta de ihosting hoy mismo y disfruta de un servicio de alta calidad y confiabilidad para tu sitio web. Queremos ayudarte a mejorar la productividad entregandote estabilidad por medio de nuestras soluciones de conectividad."
          },
          {
            name: "keywords",
            content: "VPN Empresarial, Punto-Punto, BYOIP",
          },
        ]
      },
    };
  </script>
  
  <style>
    .container-steps-v {
      min-height: 120vh !important;
    }
    
    @media (max-width: 991px) {
      .container-steps-v {
        min-height: auto !important;
      }
    }
  </style>
  