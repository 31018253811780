<template>
  <div id="hosting" class="overflow-custom-v">
    <!-- START home 3 REVOLUTION SLIDER 6.3.2 -->
    <p class="rs-p-wp-fix"></p>
    <rs-module-wrap
      id="rev_slider_2_1_wrapper"
      data-alias="home-2-1"
      data-source="gallery"
      style="background: transparent; padding: 0; margin: 0px auto; margin-top: 0; margin-bottom: 0"
    >
      <rs-module id="rev_slider_hosting" class="" data-version="6.3.2" style="overflow: visible !important">
        <rs-slides style="overflow: visible !important">
          <rs-slide data-key="rs-2" data-title="Slide" data-anim="ei:d;eo:d;s:600;r:0;t:fade;sl:0;">
            <img
              src="rev-slider/assets/transparent.png"
              alt="Slide"
              data-bg="c:#f5f9ff;"
              data-parallax="off"
              class="rev-slidebg"
              data-no-retina
            />

            <rs-layer
              id="slider-6-slide-6-layer-0"
              data-type="image"
              data-rsp_ch="on"
              data-xy="x:r,c,c,c;xo:-45px,2px,-1px,0;y:m,b,b,b;yo:115px,-50px,-100px,-80px;"
              data-text="w:normal;s:20,15,11,6;l:0,19,14,8;"
              data-dim="w:704px,421px,333px,307px;h:493px,294.988px,233px,215px;"
              data-frame_0="sX:0.9;sY:0.9;"
              data-frame_999="o:0;st:w;"
              style="z-index: 13; overflow: visible !important"
              ><img
                loading="lazy"
                src="images/about-us/hosting-1200.png"
                width="774"
                height="542"
                data-no-retina
              />
            </rs-layer>

            <rs-layer
              id="slider-6-slide-6-layer-1"
              data-type="text"
              data-color="#606c7a"
              data-rsp_ch="on"
              data-xy="x:l,c,c,c;xo:15px,0,0,0;y:m,t,t,t;yo:0,150px,113px,69px;"
              data-text="w:normal;s:18,16,0,4;l:34,32,0,6;a:left,center,center,center;"
              data-vbility="t,f,f,f"
              data-frame_0="x:50,39,29,17;"
              data-frame_1="st:900;sp:1000;"
              data-frame_999="o:0;st:w;"
              style="z-index: 9; font-family: Jost"
              >¡Tomate un descanso!, nuestros especialistas te ayudarán a encontrar <br />el plan que
              necesitas y sacar el máximo provecho a tu hosting.
            </rs-layer>

            <rs-layer
              id="slider-6-slide-6-layer-2"
              class="banner-text"
              data-type="text"
              data-color="#002450"
              data-rsp_ch="on"
              data-xy="x:l,c,c,c;xo:15px,0,0,0;y:m,t,t,t;yo:-120px,40px,40px,20px;"
              data-text="w:normal;s:42,47,42,29;l:55,53,50,42;fw:600;a:left,center,center,center;"
              data-dim="w:622px,499px,509px,485px;"
              data-frame_0="x:50,39,29,17;"
              data-frame_1="st:900;sp:1000;"
              data-frame_999="o:0;st:w;"
              style="z-index: 15; font-family: Jost"
            >
              <span>¿Sabes cuál es el plan de hosting correcto para tu negocio?</span>
            </rs-layer>

            <rs-layer
              id="slider-6-slide-6-layer-5"
              class="rs-pxl-2"
              data-type="image"
              data-rsp_ch="on"
              data-xy="xo:683px,310px,241px,126px;yo:112px,306px,200px,-1193px;"
              data-text="w:normal;s:20,15,11,6;l:0,19,14,8;"
              data-dim="w:287px,163.996px,111px,68px;h:255px,146px,99px,61px;"
              data-frame_999="o:0;st:w;"
              style="z-index: 14"
              data-vbility="t,f,t,f"
              ><img
                loading="lazy"
                src="images/about-us/cloud-slider.png"
                width="316"
                height="281"
                data-no-retina
              />
            </rs-layer>

            <router-link class="menu-item" to="/nosotros#contacto">
              <a
                id="slider-6-slide-6-layer-7"
                class="rs-layer rev-btn"
                target="_self"
                rel="nofollow"
                data-type="button"
                data-rsp_ch="on"
                data-xy="x:l,c,c,c;xo:15px,-80px,-80px,-70px;y:m,t,m,m;yo:88px,235px,-40px, -1000px;"
                data-text="w:normal;s:18,16,16,14;l:32,32,32,30;"
                data-dim="minh:0px,none,none,none;"
                data-padding="t:12,9,10,8;r:30,24,30,25;b:12,9,10,8;l:30,24,30,25;"
                data-border="bor:5px,5px,5px,5px;"
                data-frame_0="x:50,39,29,17;"
                data-frame_1="st:900;sp:1000;"
                data-frame_999="o:0;st:w;"
                data-vbility="t,f,f,f"
                data-frame_hover="c:#fff;bgc:var(--primary-color);bor:5px,5px,5px,5px;"
                style="z-index: 15; background-color: var(--dark-color); font-family: Jost"
                >Contáctanos
              </a>
            </router-link>

            <rs-layer
              id="slider-6-slide-6-layer-9"
              class="rs-pxl-2"
              data-type="image"
              data-rsp_ch="on"
              data-xy="xo:1045px,515px,385px,229px;yo:180px,310px,242px,-1199px;"
              data-text="w:normal;s:20,15,11,6;l:0,19,14,8;"
              data-dim="w:99px,77px,57px,35px;h:142px,111px,82px,50px;"
              data-frame_999="o:0;st:w;"
              style="z-index: 15"
              data-vbility="t,f,t,f"
              ><img
                loading="lazy"
                src="images/about-us/arrow-top-slider.png"
                width="99"
                height="142"
                data-no-retina
              />
            </rs-layer>
          </rs-slide>
        </rs-slides>
      </rs-module>
    </rs-module-wrap>
    <!-- END REVOLUTION SLIDER -->

    <!-- Service Slider Start -->
    <section class="pt-grey-background pt-bottom-padding margin-top-desktop fade-in-slow" style="z-index: 10; background: transparent; padding-bottom: 20px; overflow: hidden !important">
      <div class="container" style="background: transparent">
        <div class="row">
          <div class="col-12">
            <div class="pt-section-title-1 text-center mb-2">
              <p class="pt-section-description" style="margin-bottom: 15px">
                <b><b>Todos nuestros planes incluyen</b></b>
              </p>
            </div>
          </div>
        </div>
        <div class="row" :class="!loading ? 'sectionSlide_container_body_content_rowView' : ' sectionSlide_container_body_content_rowNoView' ">
            <SliderCardInfo  typeInfo="info" :items="cardServicesInfo" keySlide="section_sliderCardInfo"/>
        </div>
      </div>
    </section>
    <!-- Service Slider End -->

    <!-- Service Plan Start -->
    <section class="pt-5 pb-0 section_service">
      <div id="seleccionar-hosting" class="enclaje_section"></div>
      <div class="">
        <div class="container">
          <div class="">
            <div class="pt-section-title-1 text-center">
              <h2 class="pt-section-title">¿Qué <span class="imp-word">Necesitas?</span></h2>
              <p class="pt-section-description" id="escoge_tu_plan">
                Selecciona la categoría que más se acomode a tus necesidades o la de tu empresa, te invitamos
                a probar nuestros planes.
              </p>
            </div>
          </div>
        </div>
        <div>
          <div class="col-12 px-0">
            <div class="pt-tabs-1 wow animate__ animate__fadeInUpBig animated">
              <div
                class="nav nav-tabs nav-fill container"
                style="padding-left: 15px"
                id="nav-tab"
                role="tablist"
              >
              <a
                  class="pt-tabs nav-item nav-link"
                  :class="{ 'active show': tab == '#nav-home-5' }"
                  @click="tab = '#nav-home-5'"
                  id="nav-home-5"
                  data-toggle="tab"
                  href="#nav-5"
                  aria-controls="nav-home-5"
                >
                  <div class="pt-tabs-icon">
                    <i class="cont-img-icon-tab"><img src="images/ec-logo.png" class="img-icon-tab" /></i
                    ><span style="margin-top: 10px">Hosting Emprendedor</span>
                  </div>
                </a>
                <a
                  class="pt-tabs nav-item nav-link"
                  :class="{ 'active show': tab == '#nav-home-0' }"
                  @click="tab = '#nav-home-0'"
                  id="nav-home-0"
                  data-toggle="tab"
                  href="#nav-0"
                  aria-controls="nav-home-0"
                >
                  <div class="pt-tabs-icon">
                    <i class="cont-img-icon-tab"><img src="images/h-logo.png" class="img-icon-tab" /></i
                    ><span style="margin-top: 10px">Hosting Empresa Básico</span>
                  </div>
                </a>
                <a
                  class="pt-tabs nav-item nav-link"
                  :class="{ 'active show': tab == '#nav-home-1' }"
                  @click="tab = '#nav-home-1'"
                  id="nav-home-1"
                  data-toggle="tab"
                  href="#nav-1"
                  aria-controls="nav-home-1"
                >
                  <div class="pt-tabs-icon">
                    <i class="cont-img-icon-tab"><img src="images/hp-logo.png" class="img-icon-tab" /></i
                    ><span style="margin-top: 10px">Hosting Empresa Premium</span>
                  </div>
                </a>
                <a
                  class="pt-tabs nav-item nav-link"
                  :class="{ 'active show': tab == '#nav-home-3' }"
                  @click="tab = '#nav-home-3'"
                  id="nav-home-3"
                  data-toggle="tab"
                  href="#nav-3"
                  aria-controls="nav-home-3"
                >
                  <div class="pt-tabs-icon">
                    <i class="cont-img-icon-tab"><img src="images/wp-logo.png" class="img-icon-tab" /></i
                    ><span style="margin-top: 10px">Hosting Wordpress</span>
                  </div>
                </a>


                <a
                  class="pt-tabs nav-item nav-link"
                  :class="{ 'active show': tab == '#nav-home-2' }"
                  @click="tab = '#nav-home-2'"
                  id="nav-home-2"
                  data-toggle="tab"
                  href="#nav-2"
                  aria-controls="nav-home-2"
                >
                  <div class="pt-tabs-icon">
                    <i class="cont-img-icon-tab"><img src="images/elearning-logo.png" class="img-icon-tab" /></i
                    ><span style="margin-top: 10px">Hosting E-learning</span>
                  </div>
                </a>
              </div>
              <div class="tab-content text-left" id="nav-tabContent">

                <!-- PANEL TAB 1 HOSTING -->
                <div
                  class="tab-pane fade"
                  :class="{ 'active show': tab == '#nav-home-0' }"
                  id="nav-0"
                  role="tabpanel"
                  aria-labelledby="nav-home-0"
                >
                  <div class="container pb-5 mb-1">
                    <div class="row" :class="{ 'flex-row-reverse-column': resolucion() }">
                      <div class="col-lg-6 p-box-c-30" style="align-self: center; text-align: center">
                        <img
                          src="images/ilustraciones/005.png"
                          class="svg-mh-c ilustra-img"
                          alt="seo-image"
                        />
                      </div>
                      <div class="col-lg-6">
                        <div class="pt-tab-info" style="margin-bottom: 50px">
                          <h2>Hosting Empresa Básico</h2>
                          <p>
                            Requieres un servicio de hosting compartido estable con apoyo permanente de
                            nuestro equipo de soporte que te permita crecer sin dificultades a un precio muy
                            conveniente, estos planes son para ti.
                          </p>
                          <ul>
                            <li>La estabilidad y confianza que buscas para hacer crecer tu negocio.</li>
                            <li>Puedes crecer cuando lo requieras de manera inmediata y transparente.</li>
                            <li>
                              Asesoría y soporte técnico en tú idioma especializado en resolver tus
                              inquietudes.
                            </li>
                            <li>Simplicidad a través de un panel intuitivo y de fácil uso.</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Service Slider Start -->
                  <section class="pt-grey-background section_plans">
                    <div class="container">
                      <div class="row">
                        <div class="col-12">
                          <div class="pt-section-title-1 text-center" style="margin-bottom: 20px">
                            <h2 class="pt-section-title">
                              Planes Empresa <span class="imp-word">Hosting</span> Básico
                            </h2>
                            <p class="pt-section-description">
                              En nuestros planes hosting básico encontrarás apoyo permanente de nuestro equipo
                              de soporte que te permita crecer sin dificultades, revisa nuestros planes a
                              continuación.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="tab-content" id="advance-nav-tabContent">
                        <div
                          class="pt-advance-tab-content tab-pane fade fade-in show active"
                          id="advance-nav-0"
                          role="tabpanel"
                          aria-labelledby="advance-nav-home-0"
                        >
                          <div v-if="categories['Hosting Básico Anual']" class="row">
                            <SliderCardPlans :categorie="categories['Hosting Básico Anual']" :styleBoxCard="{width: '380px'}" :infoCategory="{category: dataHosting[0].categoria, subCategory: dataHosting[0].subcategoria }" keySlide="hosting_Hosting_Básico_Anual" :promoGlobal="{status: true, text: 'Prueba 5 días gratis'}" :sslGlobal="true"/>
                          </div>
                        </div>
<!-- 
                        <div
                          class="pt-advance-tab-content tab-pane fade fade-in"
                          id="advance-nav-1"
                          role="tabpanel"
                          aria-labelledby="advance-nav-home-1"
                        >
                          <div class="row">
                            <div class="col-12">
                              <div class="pt-fancy-box-slider pt-fancy-box-slider-1">
                                <div
                                  class="swiper-container swiper-container-nav-home-0 swiper-container-initialized swiper-container-horizontal"
                                  data-nav="false"
                                  data-pagination_type="bullets"
                                  data-desk_num="3"
                                  data-lap_num="2"
                                  data-tab_num="2"
                                  data-mob_num="1"
                                  data-mob_sm="1"
                                  data-autoplay="false"
                                  data-loop="false"
                                  data-margin="30"
                                >
                                  <div class="swiper-wrapper">
                                    <div
                                      v-for="(item, index) in categories['Hosting Básico Anual']"
                                      :key="item.id"
                                      class="swiper-slide"
                                    >
                                      <div
                                        class="pt-pricebox-1 pc-fbox-home text-center mt-0 shadow-card"
                                        :class="{ active: index % 2 == 0 }"
                                      >
                                        <div class="pt-pricebox-info">
                                          <div class="contbadage">
                                            <div
                                              class="badge plans"
                                              :class="[index % 2 === 0 ? 'bg-white' : 'bg-blue']"
                                            >
                                              Prueba 5 días gratis
                                            </div>
                                          </div>
                                          <span class="pt-price-title">
                                            <span v-if="type == 1">{{ item.nombre }} </span></span
                                          >
                                          <br />
                                          <span class="pt-price-duration fromer"
                                            ><em>{{ item.descripcion }}</em></span
                                          >

                                          <h2 class="price">
                                            <span
                                              v-if="item.moneda.nombre_corto == 'UF'"
                                              class="item-row-price"
                                              :data-node="true"
                                              >{{ changeSeparator((item.precio * form.cantidad).toFixed(2)) }}
                                            </span>
                                            <span
                                              v-else-if="item.moneda.nombre_corto == 'USD'"
                                              class="item-row-price"
                                              :data-node="true"
                                              >{{ changeSeparator((item.precio * form.cantidad).toFixed(2)) }}
                                            </span>

                                            <span v-else class="row-price" :data-node="true"
                                              ><span class="item-row-price">$</span>
                                              <span>{{ formatNumber(item.precio * form.cantidad) }}</span>
                                            </span>
                                          </h2>

                                          <p class="pt-price-duration">
                                            {{ item.moneda.nombre_corto }} / {{ item.periodicidad.nombre }}
                                          </p>
                                        </div>
                                        <ul class="pt-list-info pt-list-info-padding">
                                          <li
                                            v-for="(atribute, idx) in item.atributos"
                                            :key="idx"
                                            class="active"
                                          >
                                            <div style="flex-flow: row nowrap">
                                              <span style="margin-right: 25px">
                                                <div style="display: flex; flex-flow: row nowrap"></div>
                                                <span>{{ atribute.nombre.toUpperCase() }} </span>
                                              </span>

                                              <span style="float: right"
                                                ><span
                                                  v-if="
                                                    atribute.pivot.valor.toUpperCase() == 'ILIMITADOS' ||
                                                    atribute.pivot.valor.toUpperCase() == 'ILIMITADA' ||
                                                    atribute.pivot.valor.toUpperCase() == 'ILIMITADAS'
                                                  "
                                                  class="span-infinito-cart"
                                                  >∞</span
                                                >
                                                <span v-else>{{ atribute.pivot.valor.toUpperCase() }}</span>
                                              </span>
                                            </div>
                                          </li>
                                        </ul>

                                        <div class="pt-btn-container">
                                          <div class="pt-button-block">
                                            <a
                                              :href="
                                                '/cart?service=' +
                                                item.id +
                                                '&category=' +
                                                dataHosting[0].categoria +
                                                '&subcategory=' +
                                                dataHosting[0].subcategoria
                                              "
                                              style="z-index: 20; cursor: pointer"
                                              class="pt-button"
                                              :class="{ 'pt-button-change-color': index % 2 == 0 }"
                                            >
                                              <span class="text">Contratar</span>
                                            </a>
                                          </div>
                                        </div>

                                        <div class="pt-bg-effect polygon-new">
                                          <img src="images/polygon/Polygon-Luminary.svg" alt="gym-image" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="swiper-pagination swiper-pagination-dynamic"></div>
                                </div>
                              </div>
                              <div class="swiper-button-prev"></div>
                              <div class="swiper-button-next"></div>
                            </div>
                          </div>
                        </div> -->
                      </div>
                    </div>
                  </section>
                  <!-- Service Slider End -->

                  <!-- Offer start -->
                  <section class="pt-blue-background">
                    <div
                      class="pt-background-overley pt-opacity-1"
                      style="background-image: url(images/polygon/Polygon-Luminary.svg)"
                    ></div>
                    <div class="container">
                      <div class="row mx-0 my-0">
                        <div class="col-xl-8">
                          <div class="pt-section-title-1 pt-section-title-1-color-white mb-0">
                            <h2 class="pt-section-title text-white">
                              Certificado SSL <span class="imp-word">¡GRATIS!</span>
                            </h2>
                            <p class="pt-section-description text-white mb-0">
                              Se encuentra incluido el certificado SSL en todos los planes de hosting. Aún tienes dudas, te invitamos a contactarnos.
                            </p>
                          </div>
                        </div>
                        <div class="col-xl-4">
                          <div class="pt-btn-container text-right pt-btn-position">
                            <div class="pt-button-block">
                              <router-link class="menu-item" to="/nosotros#contacto">
                                <a class="pt-button pt-button-change-color" style="cursor: pointer">
                                  <span class="text">Contáctanos</span>
                                </a>
                              </router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <!-- offer end -->

                  <!-- Faq Start -->
                  <section>
                    <div class="container">
                      <div class="row">
                        <div class="col-xl-6 col-md-12">
                          <div class="pt-img-main fade-in mb-5 sticky-img">
                            <img src="images/about-us/Group-519-1.svg" alt="solution-img" class="img-fluid" />
                          </div>
                        </div>
                        <div class="col-xl-6 col-md-12 mt-3 mt-xl-0">
                          <div class="pt-section-title-1">
                            <h2 class="pt-section-title">
                              Preguntas <span class="imp-word">Frecuentes</span>
                            </h2>
                            <p class="pt-section-description">
                              Queremos que estés tranquilo, no tengas sorpresas y disfrutes tu servicio de
                              hosting compartido por lo que hemos dispuesto esta sección para despejar las
                              dudas que frecuentemente recibimos respecto al servicio. Si no aparece algo o
                              requieres profundizar en algún tema nuestro equipo siempre estará disponible
                              para resolver tus dudas.
                            </p>
                          </div>
                          <div class="pt-accordion-block">
                            <div class="pt-accordion-box 1">
                              <div class="pt-ad-title" style="">
                                <h4 class="ad-title-text">
                                  ¿Qué es un hosting básico?
                                  <i aria-hidden="true" class="fas fa-angle-down active"> </i>
                                  <i aria-hidden="true" class="fas fa-angle-up inactive"> </i>
                                </h4>
                              </div>
                              <div class="pt-accordion-details" style="display: none">
                                <p class="pt-detail-text">
                                  Es un servicio de alojamiento web, el cual incluye el hospedaje de tu página
                                  web y servicios de correos (ambos dos).
                                </p>
                              </div>
                            </div>
                            <div class="pt-accordion-box 2">
                              <div class="pt-ad-title">
                                <h4 class="ad-title-text">
                                  ¿Es compatible con Wordpress?
                                  <i aria-hidden="true" class="fas fa-angle-down active"> </i>
                                  <i aria-hidden="true" class="fas fa-angle-up inactive"> </i>
                                </h4>
                              </div>
                              <div class="pt-accordion-details" style="display: block">
                                <p class="pt-detail-text">
                                  Todos nuestros planes son 100% compatibles con Wordpress. Si requieres
                                  migración, contáctanos y nos pondremos en contacto a la brevedad.
                                </p>
                              </div>
                            </div>
                            <div class="pt-accordion-box 3">
                              <div class="pt-ad-title">
                                <h4 class="ad-title-text">
                                  ¿Qué es importante saber de mi plan?
                                  <i aria-hidden="true" class="fas fa-angle-down active"> </i>
                                  <i aria-hidden="true" class="fas fa-angle-up inactive"> </i>
                                </h4>
                              </div>
                              <div class="pt-accordion-details" style="display: none">
                                <span class="pt-detail-text">
                                  <p>
                                    Los planes de hosting son compartidos, por lo tanto, algunos parámetros se
                                    encuentran limitados con el único objetivo que todos posean un servicio
                                    estable y disponible en todo momento.
                                  </p>
                                  <br />
                                  <p>
                                    Los parámetros y límites de cada plan se encuentran especificados en todos
                                    nuestros planes.
                                  </p>
                                </span>
                              </div>
                            </div>
                            <div class="pt-accordion-box 4">
                              <div class="pt-ad-title">
                                <h4 class="ad-title-text">
                                  ¿Qué es un inode?
                                  <i aria-hidden="true" class="fas fa-angle-down active"> </i>
                                  <i aria-hidden="true" class="fas fa-angle-up inactive"> </i>
                                </h4>
                              </div>
                              <div class="pt-accordion-details" style="display: block">
                                <p class="pt-detail-text">
                                  Los inode son todos los archivos o carpetas alojados en el hosting, esto
                                  considera archivos de registro, logs, scripts, correos electrónicos, etc.
                                  <br /><br />
                                  En caso de que alcances el límite de inode, ya no podrás crear ningún
                                  archivo y/o carpeta en tu cuenta, esto podría traer problemas para tu sitio,
                                  para solucionarlo deberás eliminar archivos que no sean utilizados.
                                  <br /><br />
                                  A diferencia de otros proveedores de hosting nuestros planes de hosting
                                  compartido no tienen limitación de inodes, únicamente se limita por
                                  capacidad de almacenamiento según el plan contratado.
                                </p>
                              </div>
                            </div>
                            <div class="pt-accordion-box 5">
                              <div class="pt-ad-title">
                                <h4 class="ad-title-text">
                                  ¿Es posible actualizar mi plan?, ¿Pierdo información?
                                  <i aria-hidden="true" class="fas fa-angle-down active"> </i>
                                  <i aria-hidden="true" class="fas fa-angle-up inactive"> </i>
                                </h4>
                              </div>
                              <div class="pt-accordion-details" style="display: block">
                                <p class="pt-detail-text">
                                  Todos nuestros planes son escalables y no pierdes información al realizar
                                  este proceso.
                                </p>
                              </div>
                            </div>
                            <div class="pt-accordion-box 6">
                              <div class="pt-ad-title">
                                <h4 class="ad-title-text">
                                  ¿Existe la posibilidad de reembolso?
                                  <i aria-hidden="true" class="fas fa-angle-down active"> </i>
                                  <i aria-hidden="true" class="fas fa-angle-up inactive"> </i>
                                </h4>
                              </div>
                              <div class="pt-accordion-details" style="display: block">
                                <p class="pt-detail-text">
                                  Todos nuestros planes hosting básico tienen 5 días de prueba completamente
                                  gratuitos, al finalizar este periodo, se suspenderá automáticamente y se
                                  enviará un correo electrónico notificando el término del periodo de prueba
                                  junto con una nota de cobro en caso de querer continuar con el servicio.
                                  <br />
                                  En caso de no estar conforme, dentro de los primeros 10 días puedes
                                  solicitar el reembolso de lo pagado.
                                </p>
                              </div>
                            </div>
                            <div class="pt-accordion-box 7">
                              <div class="pt-ad-title">
                                <h4 class="ad-title-text">
                                  ¿Si olvido el pago mi servicio de hosting se suspende automáticamente?
                                  <i aria-hidden="true" class="fas fa-angle-down active"> </i>
                                  <i aria-hidden="true" class="fas fa-angle-up inactive"> </i>
                                </h4>
                              </div>
                              <div class="pt-accordion-details" style="display: block">
                                <p class="pt-detail-text">
                                  En caso que olvides pagar en la fecha de renovación tu servicio de hosting
                                  compartido cuentas con 5 días adicionales, posterior a esa fecha el servicio
                                  será suspendido de manera automática, sin embargo, si tienes algún problema
                                  para pagar en la fecha te invitamos a contactar a nuestro equipo de ventas
                                  para que pueda revisar tu caso y ayudarte para que no sufras una
                                  interrupción en el servicio.
                                </p>
                              </div>
                            </div>
                            <div class="pt-accordion-box 8">
                              <div class="pt-ad-title">
                                <h4 class="ad-title-text">
                                  ¿Cómo puedo contactarme con Soporte?
                                  <i aria-hidden="true" class="fas fa-angle-down active"> </i>
                                  <i aria-hidden="true" class="fas fa-angle-up inactive"> </i>
                                </h4>
                              </div>
                              <div class="pt-accordion-details" style="display: block">
                                <p class="pt-detail-text">
                                  En el momento que contratas ya podemos apoyarte en lo que requieras para
                                  comenzar a trabajar. <br />
                                  Para que puedas contactarnos en todo momento tenemos a tu disposición los
                                  siguientes canales de atención: asistencia a través de nuestra mesa de ayuda
                                  telefónica, soporte online y a través de ticket de soporte.
                                </p>
                              </div>
                            </div>
                            <div class="pt-accordion-box 9">
                              <div class="pt-ad-title">
                                <h4 class="ad-title-text">
                                  ¿Si deseo aumentar la capacidad de mi plan cómo debo solicitarlo?
                                  <i aria-hidden="true" class="fas fa-angle-down active"> </i>
                                  <i aria-hidden="true" class="fas fa-angle-up inactive"> </i>
                                </h4>
                              </div>
                              <div class="pt-accordion-details" style="display: block">
                                <p class="pt-detail-text">
                                  Puedes aumentar la capacidad de tu plan de hosting compartido cuando lo
                                  necesites y únicamente pagarás la diferencia que se pueda generar entre un
                                  plan y otro según los meses restantes de contrato.
                                </p>
                              </div>
                            </div>
                            <div class="pt-accordion-box 10">
                              <div class="pt-ad-title">
                                <h4 class="ad-title-text">
                                  ¿Qué sucede si olvido pagar mi hosting?
                                  <i aria-hidden="true" class="fas fa-angle-down active"> </i>
                                  <i aria-hidden="true" class="fas fa-angle-up inactive"> </i>
                                </h4>
                              </div>
                              <div class="pt-accordion-details" style="display: block">
                                <p class="pt-detail-text">
                                  Si olvidaste pagar tu hosting y han transcurrido menos de 60 días desde la
                                  fecha de renovación, no te preocupes, tu cuenta podrá ser recuperada con
                                  todos sus datos, pero posterior a esa fecha y de no haber sido acreditado el
                                  pago la cuenta será eliminada de manera definitiva de nuestros servidores
                                  por lo que de querer reactivar el servicio tendrias que tomar un nuevo
                                  contrato y comenzar desde 0.
                                </p>
                              </div>
                            </div>
                            <div class="pt-accordion-box 10">
                              <div class="pt-ad-title">
                                <h4 class="ad-title-text">
                                  Ya tengo un sitio web, ¿Puedo migrar a ihosting?
                                  <i aria-hidden="true" class="fas fa-angle-down active"> </i>
                                  <i aria-hidden="true" class="fas fa-angle-up inactive"> </i>
                                </h4>
                              </div>
                              <div class="pt-accordion-details" style="display: block">
                                <p class="pt-detail-text">
                                  Sí. Para tu comodidad, podemos realizar la migración gratuita de cuentas de
                                  cPanel que tengan hasta 10GB en archivos, correos electrónicos, base de
                                  datos y otros componentes. Si tu cuenta supera estos límites, puedes
                                  contactarte con nuestro equipo de ventas para que envíe una cotización.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <!-- Faq End -->

                  <div>
                    <div class="col-12">
                      <div class="pt-section-title-1 text-center mb-0">
                        <h2 class="pt-section-title">¿Tienes más <span class="imp-word">preguntas?</span></h2>
                      </div>
                    </div>
                    <div class="row" style="place-content: center">
                      <div class="pt-button-block mt-4">
                        <a href="/nosotros#contacto" class="pt-button"
                          ><span class="text">Contáctanos</span></a
                        >
                      </div>
                    </div>
                    <div class="col-md-12 mb-5" style="text-align: center">
                      <img src="images/ilustraciones/006.png" alt="soporte 27/7" />
                    </div>
                  </div>
                </div>

                <!-- PANEL TAB 2 HOSTING PREMIUM -->
                <div
                  class="tab-pane fade"
                  :class="{ 'active show': tab == '#nav-home-1' }"
                  id="nav-1"
                  role="tabpanel"
                  aria-labelledby="nav-home-1"
                >
                  <div class="container pb-5 mb-1">
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="pt-tab-info" style="margin-bottom: 50px">
                          <h2>Hosting Empresa Premium</h2>
                          <p>
                            La estabilidad y seguridad que buscas con recursos garantizados y apoyo permanente
                            de nuestro equipo de soporte.
                          </p>
                          <ul>
                            <li>Crece sin complicaciones o difíciles configuraciones.</li>
                            <li>Mayores recursos para tu sitio web como para tus correos electrónicos .</li>
                            <li>
                              Asesoría y soporte técnico en tú idioma especializado en resolver tus
                              inquietudes.
                            </li>
                            <li>Simplicidad a través de un panel intuitivo y de fácil uso.</li>
                          </ul>
                        </div>
                      </div>
                      <div class="col-lg-6 p-box-c-30" style="align-self: center; text-align: center">
                        <img
                          src="images/ilustraciones/004.png"
                          class="svg-mh-c ilustra-img"
                          alt="seo-image"
                        />
                      </div>
                    </div>
                  </div>

                  <!-- Service Slider Start -->
                  <section class="pt-grey-background section_plans">
                    <div class="container">
                      <div class="row">
                        <div class="col-12">
                          <div class="pt-section-title-1 text-center" style="margin-bottom: 20px">
                            <h2 class="pt-section-title">
                              Planes Empresa Hosting <span class="imp-word">Premium</span>
                            </h2>
                            <p class="pt-section-description" style="margin-bottom: 50px">
                              En nuestros planes hosting premium encontrarás la estabilidad y confianza más
                              recursos garantizados y apoyo permanente, revisa nuestros planes a continuación.
                            </p>

                            <div class="pt-tabs-1">
                              <div
                                class="nav nav-tabs"
                                id="nav-tab"
                                role="tablist"
                                style="
                                  z-index: 10;
                                  padding-bottom: 0px;
                                  flex-flow: row nowrap;
                                  justify-content: center;
                                  margin-right: 0px;
                                "
                              >
                                <a
                                  class="pt-tabs nav-item nav-link active show nav-item2 tabs-new tab-resp-v tab-resp"
                                  @click="refreshComponent('.swiper-container-nav-home-1')"
                                  id="advance-nav-home-2"
                                  data-toggle="tab"
                                  href="#advance-nav-2"
                                  role="tab"
                                  aria-controls="advance-nav-home-2"
                                  style="
                                    border: 1px solid var(--primary-color);
                                    height: 46px;
                                    max-width: 200px !important;
                                  "
                                >
                                  <div>
                                    <span style="display: block !important">Mensual</span>
                                  </div>
                                </a>

                                <a
                                  class="pt-tabs nav-item nav-link nav-item2 tabs-new tab-resp"
                                  @click="refreshComponent('.swiper-container-nav-home-1')"
                                  id="advance-nav-home-3"
                                  data-toggle="tab"
                                  href="#advance-nav-3"
                                  role="tab"
                                  aria-controls="advance-nav-home-3"
                                  style="
                                    border: 1px solid var(--primary-color);
                                    height: 46px;
                                    max-width: 200px !important;
                                  "
                                >
                                  <div>
                                    <span style="display: block !important">Anual</span>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tab-content" id="advance-nav-tabContent">
                        <div
                          class="pt-advance-tab-content tab-pane fade fade-in show active"
                          id="advance-nav-2"
                          role="tabpanel"
                          aria-labelledby="advance-nav-home-2"
                        >
                        <div v-if="categories['Hosting Básico Anual']" class="row">
                            <SliderCardPlans :categorie="categories['Hosting Premium Mensual']" :styleBoxCard="{width: '380px'}" :infoCategory="{category: dataHosting[1].categoria, subCategory: dataHosting[1].subcategoria }" keySlide="hosting_Hosting_Premium_Mensual" :promoGlobal="{status: true, text: 'Prueba 5 días gratis'}" :sslGlobal="true"/>
                        </div>
                          <!-- <div class="row">
                            <div class="col-12">
                              <div class="pt-fancy-box-slider pt-fancy-box-slider-1">
                                <div
                                  class="swiper-container swiper-container-nav-home-1 swiper-container-initialized swiper-container-horizontal"
                                  data-nav="false"
                                  data-pagination_type="bullets"
                                  data-desk_num="3"
                                  data-lap_num="2"
                                  data-tab_num="2"
                                  data-mob_num="1"
                                  data-mob_sm="1"
                                  data-autoplay="false"
                                  data-loop="false"
                                  data-margin="30"
                                >
                                  <div class="swiper-wrapper">
                                    <div
                                      v-for="(item, index) in categories['Hosting Premium Mensual']"
                                      :key="item.id"
                                      class="swiper-slide"
                                    >
                                      <div
                                        class="pt-pricebox-1 pc-fbox-home text-center mt-0 shadow-card"
                                        :class="{ active: index % 2 == 0 }"
                                      >
                                        <div class="pt-pricebox-info">
                                          <div class="contbadage">
                                            <div
                                              class="badge plans"
                                              :class="[index % 2 === 0 ? 'bg-white' : 'bg-blue']"
                                            >
                                              Prueba 5 días gratis
                                            </div>
                                          </div>
                                          <span class="pt-price-title">
                                            <span v-if="type == 1">{{ item.nombre }} </span></span
                                          >
                                          <br />
                                          <span class="pt-price-duration fromer"
                                            ><em>{{ item.descripcion }}</em></span
                                          >

                                          <h2 class="price">
                                            <span
                                              v-if="item.moneda.nombre_corto == 'UF'"
                                              class="item-row-price"
                                              :data-node="true"
                                              >{{ changeSeparator((item.precio * form.cantidad).toFixed(2)) }}
                                            </span>
                                            <span
                                              v-else-if="item.moneda.nombre_corto == 'USD'"
                                              class="item-row-price"
                                              :data-node="true"
                                              >{{ changeSeparator((item.precio * form.cantidad).toFixed(2)) }}
                                            </span>

                                            <span v-else class="row-price" :data-node="true"
                                              ><span class="item-row-price">$</span>
                                              <span>{{ formatNumber(item.precio * form.cantidad) }}</span>
                                            </span>
                                          </h2>

                                          <p class="pt-price-duration">
                                            {{ item.moneda.nombre_corto }} / {{ item.periodicidad.nombre }}
                                          </p>
                                        </div>
                                        <ul class="pt-list-info pt-list-info-padding">
                                          <li
                                            v-for="(atribute, idx) in item.atributos"
                                            :key="idx"
                                            class="active"
                                          >
                                            <div style="flex-flow: row nowrap">
                                              <span style="margin-right: 25px">
                                                <div style="display: flex; flex-flow: row nowrap"></div>
                                                <span>{{ atribute.nombre.toUpperCase() }} </span>
                                              </span>

                                              <span style="float: right"
                                                ><span
                                                  v-if="
                                                    atribute.pivot.valor.toUpperCase() == 'ILIMITADOS' ||
                                                    atribute.pivot.valor.toUpperCase() == 'ILIMITADA' ||
                                                    atribute.pivot.valor.toUpperCase() == 'ILIMITADAS'
                                                  "
                                                  class="span-infinito-cart"
                                                  >∞</span
                                                >
                                                <span v-else>{{ atribute.pivot.valor.toUpperCase() }}</span>
                                              </span>
                                            </div>
                                          </li>
                                        </ul>

                                        <div class="pt-btn-container">
                                          <div class="pt-button-block">
                                            <a
                                              :href="
                                                '/cart?service=' +
                                                item.id +
                                                '&category=' +
                                                dataHosting[1].categoria +
                                                '&subcategory=' +
                                                dataHosting[1].subcategoria
                                              "
                                              style="z-index: 20; cursor: pointer"
                                              class="pt-button"
                                              :class="{ 'pt-button-change-color': index % 2 == 0 }"
                                            >
                                              <span class="text">Contratar</span>
                                            </a>
                                          </div>
                                        </div>

                                        <div class="pt-bg-effect polygon-new">
                                          <img src="images/polygon/Polygon-Luminary.svg" alt="gym-image" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="swiper-pagination swiper-pagination-dynamic"></div>
                                </div>
                              </div>
                              <div class="swiper-button-prev"></div>
                              <div class="swiper-button-next"></div>
                            </div>
                          </div> -->
                        </div>

                        <div
                          class="pt-advance-tab-content tab-pane fade fade-in"
                          id="advance-nav-3"
                          role="tabpanel"
                          aria-labelledby="advance-nav-home-3"
                        >
                        <div v-if="categories['Hosting Básico Anual']" class="row">
                            <SliderCardPlans :categorie="categories['Hosting Premium Anual']" :styleBoxCard="{width: '380px'}" :infoCategory="{category: dataHosting[2].categoria, subCategory: dataHosting[2].subcategoria }" keySlide="hosting_Hosting_Premium_Anual" :promoGlobal="{status: true, text: 'Prueba 5 días gratis'}" :sslGlobal="true"/>
                        </div>
                          <!-- <div class="row">
                            <div class="col-12">
                              <div class="pt-fancy-box-slider pt-fancy-box-slider-1">
                                <div
                                  class="swiper-container swiper-container-nav-home-1 swiper-container-initialized swiper-container-horizontal"
                                  data-nav="false"
                                  data-pagination_type="bullets"
                                  data-desk_num="3"
                                  data-lap_num="2"
                                  data-tab_num="2"
                                  data-mob_num="1"
                                  data-mob_sm="1"
                                  data-autoplay="false"
                                  data-loop="false"
                                  data-margin="30"
                                >
                                  <div class="swiper-wrapper">
                                    <div
                                      v-for="(item, index) in categories['Hosting Premium Anual']"
                                      :key="item.id"
                                      class="swiper-slide"
                                    >
                                      <div
                                        class="pt-pricebox-1 pc-fbox-home text-center mt-0 shadow-card"
                                        :class="{ active: index % 2 == 0 }"
                                      >
                                        <div class="pt-pricebox-info">
                                          <div class="contbadage">
                                            <div
                                              class="badge plans"
                                              :class="[index % 2 === 0 ? 'bg-white' : 'bg-blue']"
                                            >
                                              Prueba 5 días gratis
                                            </div>
                                          </div>
                                          <span class="pt-price-title">
                                            <span v-if="type == 1">{{ item.nombre }} </span></span
                                          >
                                          <br />
                                          <span class="pt-price-duration fromer"
                                            ><em>{{ item.descripcion }}</em></span
                                          >

                                          <h2 class="price">
                                            <span
                                              v-if="item.moneda.nombre_corto == 'UF'"
                                              class="item-row-price"
                                              :data-node="true"
                                              >{{ changeSeparator((item.precio * form.cantidad).toFixed(2)) }}
                                            </span>
                                            <span
                                              v-else-if="item.moneda.nombre_corto == 'USD'"
                                              class="item-row-price"
                                              :data-node="true"
                                              >{{ changeSeparator((item.precio * form.cantidad).toFixed(2)) }}
                                            </span>

                                            <span v-else class="row-price" :data-node="true"
                                              ><span class="item-row-price">$</span>
                                              <span>{{ formatNumber(item.precio * form.cantidad) }}</span>
                                            </span>
                                          </h2>

                                          <p class="pt-price-duration">
                                            {{ item.moneda.nombre_corto }} / {{ item.periodicidad.nombre }}
                                          </p>
                                        </div>
                                        <ul class="pt-list-info pt-list-info-padding">
                                          <li
                                            v-for="(atribute, idx) in item.atributos"
                                            :key="idx"
                                            class="active"
                                          >
                                            <div style="flex-flow: row nowrap">
                                              <span style="margin-right: 25px">
                                                <div style="display: flex; flex-flow: row nowrap"></div>
                                                <span>{{ atribute.nombre.toUpperCase() }} </span>
                                              </span>

                                              <span style="float: right"
                                                ><span
                                                  v-if="
                                                    atribute.pivot.valor.toUpperCase() == 'ILIMITADOS' ||
                                                    atribute.pivot.valor.toUpperCase() == 'ILIMITADA' ||
                                                    atribute.pivot.valor.toUpperCase() == 'ILIMITADAS'
                                                  "
                                                  class="span-infinito-cart"
                                                  >∞</span
                                                >
                                                <span v-else>{{ atribute.pivot.valor.toUpperCase() }}</span>
                                              </span>
                                            </div>
                                          </li>
                                        </ul>

                                        <div class="pt-btn-container">
                                          <div class="pt-button-block">
                                            <a
                                              :href="
                                                '/cart?service=' +
                                                item.id +
                                                '&category=' +
                                                dataHosting[2].categoria +
                                                '&subcategory=' +
                                                dataHosting[2].subcategoria
                                              "
                                              style="z-index: 20; cursor: pointer"
                                              class="pt-button"
                                              :class="{ 'pt-button-change-color': index % 2 == 0 }"
                                            >
                                              <span class="text">Contratar</span>
                                            </a>
                                          </div>
                                        </div>

                                        <div class="pt-bg-effect polygon-new">
                                          <img src="images/polygon/Polygon-Luminary.svg" alt="gym-image" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="swiper-pagination swiper-pagination-dynamic"></div>
                                </div>
                              </div>
                              <div class="swiper-button-prev"></div>
                              <div class="swiper-button-next"></div>
                            </div>
                          </div> -->
                        </div>
                      </div>
                    </div>
                  </section>
                  <!-- Service Slider End -->

                  <!-- Offer start -->
                  <section class="pt-blue-background">
                    <div
                      class="pt-background-overley pt-opacity-1"
                      style="background-image: url(images/polygon/Polygon-Luminary.svg)"
                    ></div>
                    <div class="container">
                      <div class="row mx-0 my-0">
                        <div class="col-xl-8">
                          <div class="pt-section-title-1 pt-section-title-1-color-white mb-0">
                            <h2 class="pt-section-title text-white">
                              5 días de prueba sin <span class="imp-word">costo</span>
                            </h2>
                            <p class="pt-section-description text-white mb-0">
                              Queremos que siempre te encuentres 100% conforme con el servicio antes de pagar.
                            </p>
                          </div>
                        </div>
                        <div class="col-xl-4">
                          <div class="pt-btn-container text-right pt-btn-position">
                            <div class="pt-button-block">
                              <router-link class="menu-item" to="/nosotros#contacto">
                                <a class="pt-button pt-button-change-color" style="cursor: pointer">
                                  <span class="text">Contáctanos</span>
                                </a>
                              </router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <!-- offer end -->

                  <!-- Faq Start -->
                  <section>
                    <div class="container">
                      <div class="row">
                        <div class="col-xl-6 col-md-12">
                          <div class="pt-img-main fade-in mb-5 sticky-img">
                            <img
                              src="images/about-us/Group-515-1.svg"
                              alt="solution-img"
                              class="svg img-fluid"
                            />
                          </div>
                        </div>
                        <div class="col-xl-6 col-md-12 mt-3 mt-xl-0">
                          <div class="pt-section-title-1">
                            <h2 class="pt-section-title">
                              Preguntas <span class="imp-word">Frecuentes</span>
                            </h2>
                            <p class="pt-section-description">
                              Queremos que estés tranquilo, no tengas sorpresas y disfrutes tu servicio de
                              hosting compartido por lo que hemos dispuesto esta sección para despejar las
                              dudas que frecuentemente recibimos respecto al servicio. Si no aparece algo o
                              requieres profundizar en algún tema nuestro equipo siempre estará disponible
                              para resolver tus dudas.
                            </p>
                          </div>
                          <div class="pt-accordion-block">
                            <div class="pt-accordion-box 3">
                              <div class="pt-ad-title">
                                <h4 class="ad-title-text">
                                  ¿Qué es importante saber de mi plan?
                                  <i aria-hidden="true" class="fas fa-angle-down active"> </i>
                                  <i aria-hidden="true" class="fas fa-angle-up inactive"> </i>
                                </h4>
                              </div>
                              <div class="pt-accordion-details" style="display: none">
                                <span class="pt-detail-text">
                                  <p>
                                    Los planes de hosting son compartidos, por lo tanto, algunos parámetros se
                                    encuentran limitados con el único objetivo que todos posean un servicio
                                    estable y disponible en todo momento.
                                  </p>
                                  <br />
                                  <p>
                                    Los parámetros y límites de cada plan se encuentran especificados en todos
                                    nuestros planes.
                                  </p>
                                </span>
                              </div>
                            </div>
                            <div class="pt-accordion-box 4">
                              <div class="pt-ad-title">
                                <h4 class="ad-title-text">
                                  ¿Qué es un inode?
                                  <i aria-hidden="true" class="fas fa-angle-down active"> </i>
                                  <i aria-hidden="true" class="fas fa-angle-up inactive"> </i>
                                </h4>
                              </div>
                              <div class="pt-accordion-details" style="display: block">
                                <p class="pt-detail-text">
                                  Los inode son todos los archivos o carpetas alojados en el hosting, esto
                                  considera archivos de registro, logs, scripts, correos electrónicos, etc.
                                  <br /><br />
                                  En caso de que alcances el límite de inode, ya no podrás crear ningún
                                  archivo y/o carpeta en tu cuenta, esto podría traer problemas para tu sitio,
                                  para solucionarlo deberás eliminar archivos que no sean utilizados.
                                  <br /><br />
                                  A diferencia de otros proveedores de hosting nuestros planes de hosting
                                  compartido no tienen limitación de inodes, únicamente se limita por
                                  capacidad de almacenamiento según el plan contratado.
                                </p>
                              </div>
                            </div>
                            <div class="pt-accordion-box 5">
                              <div class="pt-ad-title">
                                <h4 class="ad-title-text">
                                  ¿Es posible actualizar mi plan?, ¿Pierdo información?
                                  <i aria-hidden="true" class="fas fa-angle-down active"> </i>
                                  <i aria-hidden="true" class="fas fa-angle-up inactive"> </i>
                                </h4>
                              </div>
                              <div class="pt-accordion-details" style="display: block">
                                <p class="pt-detail-text">
                                  Todos nuestros planes son escalables y no pierdes información al realizar
                                  este proceso.
                                </p>
                              </div>
                            </div>
                            <div class="pt-accordion-box 6">
                              <div class="pt-ad-title">
                                <h4 class="ad-title-text">
                                  ¿Existe la posibilidad de reembolso?
                                  <i aria-hidden="true" class="fas fa-angle-down active"> </i>
                                  <i aria-hidden="true" class="fas fa-angle-up inactive"> </i>
                                </h4>
                              </div>
                              <div class="pt-accordion-details" style="display: block">
                                <p class="pt-detail-text">
                                  Todos nuestros planes hosting básico tienen 5 días de prueba completamente
                                  gratuitos, al finalizar este periodo, se suspenderá automáticamente y se
                                  enviará un correo electrónico notificando el término del periodo de prueba
                                  junto con una nota de cobro en caso de querer continuar con el servicio.
                                  <br />
                                  En caso de no estar conforme, dentro de los primeros 10 días puedes
                                  solicitar el reembolso de lo pagado.
                                </p>
                              </div>
                            </div>
                            <div class="pt-accordion-box 7">
                              <div class="pt-ad-title">
                                <h4 class="ad-title-text">
                                  ¿Si olvido el pago mi servicio de hosting se suspende automáticamente?
                                  <i aria-hidden="true" class="fas fa-angle-down active"> </i>
                                  <i aria-hidden="true" class="fas fa-angle-up inactive"> </i>
                                </h4>
                              </div>
                              <div class="pt-accordion-details" style="display: block">
                                <p class="pt-detail-text">
                                  En caso que olvides pagar en la fecha de renovación tu servicio de hosting
                                  compartido cuentas con 5 días adicionales, posterior a esa fecha el servicio
                                  será suspendido de manera automática, sin embargo, si tienes algún problema
                                  para pagar en la fecha te invitamos a contactar a nuestro equipo de ventas
                                  para que pueda revisar tu caso y ayudarte para que no sufras una
                                  interrupción en el servicio.
                                </p>
                              </div>
                            </div>
                            <div class="pt-accordion-box 8">
                              <div class="pt-ad-title">
                                <h4 class="ad-title-text">
                                  ¿Cómo puedo contactarme con Soporte?
                                  <i aria-hidden="true" class="fas fa-angle-down active"> </i>
                                  <i aria-hidden="true" class="fas fa-angle-up inactive"> </i>
                                </h4>
                              </div>
                              <div class="pt-accordion-details" style="display: block">
                                <p class="pt-detail-text">
                                  En el momento que contratas ya podemos apoyarte en lo que requieras para
                                  comenzar a trabajar. <br />
                                  Para que puedas contactarnos en todo momento tenemos a tu disposición los
                                  siguientes canales de atención: asistencia a través de nuestra mesa de ayuda
                                  telefónica, soporte online y a través de ticket de soporte.
                                </p>
                              </div>
                            </div>
                            <div class="pt-accordion-box 9">
                              <div class="pt-ad-title">
                                <h4 class="ad-title-text">
                                  ¿Si deseo aumentar la capacidad de mi plan cómo debo solicitarlo?
                                  <i aria-hidden="true" class="fas fa-angle-down active"> </i>
                                  <i aria-hidden="true" class="fas fa-angle-up inactive"> </i>
                                </h4>
                              </div>
                              <div class="pt-accordion-details" style="display: block">
                                <p class="pt-detail-text">
                                  Puedes aumentar la capacidad de tu plan de hosting compartido cuando lo
                                  necesites y únicamente pagarás la diferencia que se pueda generar entre un
                                  plan y otro según los meses restantes de contrato.
                                </p>
                              </div>
                            </div>
                            <div class="pt-accordion-box 10">
                              <div class="pt-ad-title">
                                <h4 class="ad-title-text">
                                  ¿Qué sucede si olvido pagar mi hosting?
                                  <i aria-hidden="true" class="fas fa-angle-down active"> </i>
                                  <i aria-hidden="true" class="fas fa-angle-up inactive"> </i>
                                </h4>
                              </div>
                              <div class="pt-accordion-details" style="display: block">
                                <p class="pt-detail-text">
                                  Si olvidaste pagar tu hosting y han transcurrido menos de 60 días desde la
                                  fecha de renovación, no te preocupes, tu cuenta podrá ser recuperada con
                                  todos sus datos, pero posterior a esa fecha y de no haber sido acreditado el
                                  pago la cuenta será eliminada de manera definitiva de nuestros servidores
                                  por lo que de querer reactivar el servicio tendrias que tomar un nuevo
                                  contrato y comenzar desde 0.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <!-- Faq End -->
                </div>

                <!-- PANEL TAB 3 HOSTING MOODLE -->
                <div
                  class="tab-pane fade"
                  :class="{ 'active show': tab == '#nav-home-2' }"
                  id="nav-2"
                  role="tabpanel"
                  aria-labelledby="nav-home-2"
                >
                  <div class="container pb-5 mb-1">
                    <div class="row" :class="{ 'flex-row-reverse-column': resolucion() }">
                      <div class="col-lg-6 p-box-c-30" style="align-self: center; text-align: center">
                        <img
                          src="images/ilustraciones/003.png"
                          class="svg-mh-c ilustra-img"
                          alt="seo-image"
                        />
                      </div>
                      <div class="col-lg-6">
                        <div class="pt-tab-info" style="margin-bottom: 50px">
                          <h2>Hosting E-learning</h2>
                          <p>
                            Cuente con la tranquilidad que estará trabajando sobre una plataforma
                            administrada, optimizada con soporte especializado y seguridad permanente para que
                            solo tenga que enfocarse en el contenido de sus clases.
                          </p>
                          <ul>
                            <li>
                              Flexibilidad y crecimiento sin complicaciones o difíciles configuraciones.
                            </li>
                            <li>Asesoramiento permanente para que crezcas sin interrupciones.</li>
                            <li>
                              Infraestructura dedicada y optimizada para sacar máximo provecho a plataformas e-learning.
                            </li>
                            <li>
                              Puedes comenzar con pocos alumnos y crecer a cientos de miles de alumnos en línea.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Service Slider Start -->
                  <section class="pt-grey-background">
                    <div class="container">
                      <div class="row">
                        <div class="col-12">
                          <div class="pt-section-title-1 text-center">
                            <h2 class="pt-section-title">
                              Planes <span class="imp-word">Hosting </span>E-learning
                            </h2>
                            <p class="pt-section-description">
                              En nuestros planes hosting E-learning estará trabajando sobre una plataforma
                              optimizada, soporte especializado y seguridad permanente, para más Información
                              contáctenos a continuación.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="row" style="place-content: center">
                        <div class="pt-button-block mt-4">
                          <a href="/nosotros#contacto" class="pt-button"
                            ><span class="text">Contáctanos</span></a
                          >
                        </div>
                      </div>
                    </div>
                  </section>
                  <!-- Service Slider End -->

                  <!-- Offer start -->
                  <section class="pt-blue-background">
                    <div
                      class="pt-background-overley pt-opacity-1"
                      style="background-image: url(images/polygon/Polygon-Luminary.svg)"
                    ></div>
                    <div class="container">
                      <div class="row mx-0 my-0">
                        <div class="col-xl-8">
                          <div class="pt-section-title-1 pt-section-title-1-color-white mb-0">
                            <h2 class="pt-section-title text-white">
                              5 días de prueba sin <span class="imp-word">costo</span>
                            </h2>
                            <p class="pt-section-description text-white mb-0">
                              Queremos que siempre te encuentres 100% conforme con el servicio antes de pagar.
                            </p>
                          </div>
                        </div>
                        <div class="col-xl-4">
                          <div class="pt-btn-container text-right pt-btn-position">
                            <div class="pt-button-block">
                              <router-link class="menu-item" to="/nosotros#contacto">
                                <a class="pt-button pt-button-change-color" style="cursor: pointer">
                                  <span class="text">Contáctanos</span>
                                </a>
                              </router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <!-- offer end -->
                </div>

                <!-- PLANEL 4 HOSTING Wordpress -->
                <div
                  class="tab-pane fade"
                  :class="{ 'active show': tab == '#nav-home-3' }"
                  id="nav-3"
                  role="tabpanel"
                  aria-labelledby="nav-home-3"
                >
                  <div class="container pb-5 mb-1">
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="pt-tab-info" style="margin-bottom: 50px">
                          <h2>Hosting Wordpress</h2>
                          <p>
                            La tranquilidad que buscas en servidores exclusivos y optimizados para que solo
                            tenga que preocuparse de subir contenido y publicar sin demoras tu blog o sitio
                            web.
                          </p>
                          <ul>
                            <li>El CMS más utilizado en el mundo a un solo click.</li>
                            <li>Soporte técnico en tú idioma especializado en resolver tus inquietude.</li>
                            <li>Recursos optimizados para tu sitio basado en Wordpress.</li>
                            <li>Si sabes que en fechas determinadas vas a tener mayor cantidad de visitas puedes aumentar temporalmente los recursos de tu plan hasta 5 veces por el tiempo que necesites.</li>
                            <li>
                              Medidas de seguridad adicionales para que tu Wordpress no sufra vulneraciones.
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="col-lg-6 p-box-c-30" style="align-self: center; text-align: center">
                        <img
                          src="images/ilustraciones/002.png"
                          class="svg-mh-c ilustra-img"
                          alt="seo-image"
                        />
                      </div>
                    </div>
                  </div>

                  <!-- Service Slider Start -->
                  <section class="pt-grey-background section_plans">
                    <div class="container">
                      <div class="row">
                        <div class="col-12">
                          <div class="pt-section-title-1 text-center" style="margin-bottom: 20px">
                            <h2 class="pt-section-title">
                              Planes <span class="imp-word">Hosting </span>Wordpress
                            </h2>
                            <p class="pt-section-description">
                              En nuestros planes de hosting wordpress disfruta de la estabilidad que requieres
                              en servidores exclusivos para Wordpress solo preocúpate de subir contenido y
                              publicar tu blog o sitio web, revisa nuestros planes a continuación.
                            </p>

                            <div class="pt-tabs-1">
                              <div
                                class="nav nav-tabs"
                                id="nav-tab"
                                role="tablist"
                                style="
                                  z-index: 10;
                                  padding-bottom: 0px;
                                  flex-flow: row nowrap;
                                  justify-content: center;
                                  margin-right: 0px;
                                "
                              >
                                <a
                                  class="pt-tabs nav-item nav-link active show nav-item2 tabs-new tab-resp-v tab-resp"
                                  @click="refreshComponent('.swiper-container-nav-home-3')"
                                  id="advance-nav-home-4"
                                  data-toggle="tab"
                                  href="#advance-nav-4"
                                  role="tab"
                                  aria-controls="advance-nav-home-4"
                                  style="
                                    border: 1px solid var(--primary-color);
                                    height: 46px;
                                    max-width: 200px !important;
                                  "
                                >
                                  <div>
                                    <span style="display: block !important">Mensual</span>
                                  </div>
                                </a>

                                <a
                                  class="pt-tabs nav-item nav-link nav-item2 tabs-new tab-resp"
                                  @click="refreshComponent('.swiper-container-nav-home-3')"
                                  id="advance-nav-home-5"
                                  data-toggle="tab"
                                  href="#advance-nav-5"
                                  role="tab"
                                  aria-controls="advance-nav-home-5"
                                  style="
                                    border: 1px solid var(--primary-color);
                                    height: 46px;
                                    max-width: 200px !important;
                                  "
                                >
                                  <div>
                                    <span style="display: block !important">Anual</span>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tab-content" id="advance-nav-tabContent">
                        <div
                          class="pt-advance-tab-content tab-pane fade fade-in show active"
                          id="advance-nav-4"
                          role="tabpanel"
                          aria-labelledby="advance-nav-home-4"
                        >
                        <div v-if="categories['Hosting Básico Anual']" class="row">
                            <SliderCardPlans :categorie="categories['Hosting Wordpress Mensual']" :styleBoxCard="{width: '380px'}" :infoCategory="{category: dataHosting[4].categoria, subCategory: dataHosting[4].subcategoria }" keySlide="hosting_Hosting_Wordpress_Mensual" :promoGlobal="{status: true, text: 'Prueba 5 días gratis'}" :sslGlobal="true"/>
                        </div>
                          <!-- <div class="row">
                            <div class="col-12">
                              <div class="pt-fancy-box-slider pt-fancy-box-slider-1">
                                <div
                                  class="swiper-container swiper-container-nav-home-3 swiper-container-initialized swiper-container-horizontal"
                                  data-nav="false"
                                  data-pagination_type="bullets"
                                  data-desk_num="3"
                                  data-lap_num="2"
                                  data-tab_num="2"
                                  data-mob_num="1"
                                  data-mob_sm="1"
                                  data-autoplay="false"
                                  data-loop="false"
                                  data-margin="30"
                                >
                                  <div class="swiper-wrapper">
                                    <div
                                      v-for="item in categories['Hosting Wordpress Mensual']"
                                      :key="item.id"
                                      class="swiper-slide"
                                    >
                                      <div class="pt-pricebox-1 pc-fbox-home text-center mt-0 shadow-card">
                                        <div class="img-wp-hosting">
                                          <img src="images/wp-logo.png" alt="image8" style="width: 65px" />
                                        </div>
                                        <div class="pt-pricebox-info">
                                          <div class="contbadage">
                                            <div class="badge plans bg-blue">Prueba 5 días gratis</div>
                                          </div>
                                          <span class="pt-price-title">
                                            <span v-if="type == 1">{{ item.nombre }} </span></span
                                          >
                                          <br />
                                          <span class="pt-price-duration fromer"
                                            ><em>{{ item.descripcion }}</em></span
                                          >

                                          <h2 class="price">
                                            <span
                                              v-if="item.moneda.nombre_corto == 'UF'"
                                              class="item-row-price"
                                              :data-node="true"
                                              >{{ changeSeparator((item.precio * form.cantidad).toFixed(2)) }}
                                            </span>
                                            <span
                                              v-else-if="item.moneda.nombre_corto == 'USD'"
                                              class="item-row-price"
                                              :data-node="true"
                                              >{{ changeSeparator((item.precio * form.cantidad).toFixed(2)) }}
                                            </span>

                                            <span v-else class="row-price" :data-node="true"
                                              ><span class="item-row-price">$</span>
                                              <span>{{ formatNumber(item.precio * form.cantidad) }}</span>
                                            </span>
                                          </h2>

                                          <p class="pt-price-duration">
                                            {{ item.moneda.nombre_corto }} / {{ item.periodicidad.nombre }}
                                          </p>
                                        </div>
                                        <ul class="pt-list-info pt-list-info-padding">
                                          <li
                                            v-for="(atribute, idx) in item.atributos"
                                            :key="idx"
                                            class="active"
                                          >
                                            <div style="flex-flow: row nowrap">
                                              <span style="margin-right: 25px">
                                                <div style="display: flex; flex-flow: row nowrap"></div>
                                                <span>{{ atribute.nombre.toUpperCase() }} </span>
                                              </span>

                                              <span style="float: right"
                                                ><span
                                                  v-if="
                                                    atribute.pivot.valor.toUpperCase() == 'ILIMITADOS' ||
                                                    atribute.pivot.valor.toUpperCase() == 'ILIMITADA' ||
                                                    atribute.pivot.valor.toUpperCase() == 'ILIMITADAS'
                                                  "
                                                  class="span-infinito-cart"
                                                  >∞</span
                                                >
                                                <span v-else>{{ atribute.pivot.valor.toUpperCase() }}</span>
                                              </span>
                                            </div>
                                          </li>
                                        </ul>

                                        <div class="pt-btn-container">
                                          <div class="pt-button-block">
                                            <a
                                              :href="
                                                '/cart?service=' +
                                                item.id +
                                                '&category=' +
                                                dataHosting[4].categoria +
                                                '&subcategory=' +
                                                dataHosting[4].subcategoria
                                              "
                                              style="z-index: 20; cursor: pointer"
                                              class="pt-button"
                                            >
                                              <span class="text">Contratar</span>
                                            </a>
                                          </div>
                                        </div>

                                        <div class="pt-bg-effect polygon-new">
                                          <img src="images/polygon/Polygon-Luminary.svg" alt="gym-image" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="swiper-pagination swiper-pagination-dynamic"></div>
                                </div>
                              </div>
                              <div class="swiper-button-prev"></div>
                              <div class="swiper-button-next"></div>
                            </div>
                          </div> -->
                        </div>

                        <div
                          class="pt-advance-tab-content tab-pane fade fade-in"
                          id="advance-nav-5"
                          role="tabpanel"
                          aria-labelledby="advance-nav-home-5"
                        >
                        <div v-if="categories['Hosting Básico Anual']" class="row">
                            <SliderCardPlans :categorie="categories['Hosting Wordpress Anual']" :styleBoxCard="{width: '380px'}" :infoCategory="{category: dataHosting[4].categoria, subCategory: dataHosting[4].subcategoria }" keySlide="hosting_Hosting_Wordpress_Anual" :promoGlobal="{status: true, text: 'Prueba 5 días gratis'}" :sslGlobal="true"/>
                        </div>
                          <!-- <div class="row">
                            <div class="col-12">
                              <div class="pt-fancy-box-slider pt-fancy-box-slider-1">
                                <div
                                  class="swiper-container swiper-container-nav-home-3 swiper-container-initialized swiper-container-horizontal"
                                  data-nav="false"
                                  data-pagination_type="bullets"
                                  data-desk_num="3"
                                  data-lap_num="2"
                                  data-tab_num="2"
                                  data-mob_num="1"
                                  data-mob_sm="1"
                                  data-autoplay="false"
                                  data-loop="false"
                                  data-margin="30"
                                >
                                  <div class="swiper-wrapper">
                                    <div
                                      v-for="item in categories['Hosting Wordpress Anual']"
                                      :key="item.id"
                                      class="swiper-slide"
                                    >
                                      <div class="pt-pricebox-1 pc-fbox-home text-center mt-0 shadow-card">
                                        <div class="img-wp-hosting">
                                          <img src="images/wp-logo.png" alt="image8" style="width: 65px" />
                                        </div>
                                        <div class="pt-pricebox-info">
                                          <div class="contbadage">
                                            <div class="badge plans bg-blue">Prueba 5 días gratis</div>
                                          </div>
                                          <span class="pt-price-title">
                                            <span v-if="type == 1">{{ item.nombre }} </span></span
                                          >
                                          <br />
                                          <span class="pt-price-duration fromer"
                                            ><em>{{ item.descripcion }}</em></span
                                          >

                                          <h2 class="price">
                                            <span
                                              v-if="item.moneda.nombre_corto == 'UF'"
                                              class="item-row-price"
                                              :data-node="true"
                                              >{{ changeSeparator((item.precio * form.cantidad).toFixed(2)) }}
                                            </span>
                                            <span
                                              v-else-if="item.moneda.nombre_corto == 'USD'"
                                              class="item-row-price"
                                              :data-node="true"
                                              >{{ changeSeparator((item.precio * form.cantidad).toFixed(2)) }}
                                            </span>

                                            <span v-else class="row-price" :data-node="true"
                                              ><span class="item-row-price">$</span>
                                              <span>{{ formatNumber(item.precio * form.cantidad) }}</span>
                                            </span>
                                          </h2>

                                          <p class="pt-price-duration">
                                            {{ item.moneda.nombre_corto }} / {{ item.periodicidad.nombre }}
                                          </p>
                                        </div>
                                        <ul class="pt-list-info pt-list-info-padding">
                                          <li
                                            v-for="(atribute, idx) in item.atributos"
                                            :key="idx"
                                            class="active"
                                          >
                                            <div style="flex-flow: row nowrap">
                                              <span style="margin-right: 25px">
                                                <div style="display: flex; flex-flow: row nowrap"></div>
                                                <span>{{ atribute.nombre.toUpperCase() }} </span>
                                              </span>

                                              <span style="float: right"
                                                ><span
                                                  v-if="
                                                    atribute.pivot.valor.toUpperCase() == 'ILIMITADOS' ||
                                                    atribute.pivot.valor.toUpperCase() == 'ILIMITADA' ||
                                                    atribute.pivot.valor.toUpperCase() == 'ILIMITADAS'
                                                  "
                                                  class="span-infinito-cart"
                                                  >∞</span
                                                >
                                                <span v-else>{{ atribute.pivot.valor.toUpperCase() }}</span>
                                              </span>
                                            </div>
                                          </li>
                                        </ul>

                                        <div class="pt-btn-container">
                                          <div class="pt-button-block">
                                            <a
                                              :href="
                                                '/cart?service=' +
                                                item.id +
                                                '&category=' +
                                                dataHosting[4].categoria +
                                                '&subcategory=' +
                                                dataHosting[4].subcategoria
                                              "
                                              style="z-index: 20; cursor: pointer"
                                              class="pt-button"
                                            >
                                              <span class="text">Contratar</span>
                                            </a>
                                          </div>
                                        </div>

                                        <div class="pt-bg-effect polygon-new">
                                          <img src="images/polygon/Polygon-Luminary.svg" alt="gym-image" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="swiper-pagination swiper-pagination-dynamic"></div>
                                </div>
                              </div>
                              <div class="swiper-button-prev"></div>
                              <div class="swiper-button-next"></div>
                            </div>
                          </div> -->
                        </div>
                      </div>
                    </div>
                  </section>
                  <!-- Service Slider End -->

                  <!-- Offer start -->
                  <section class="pt-blue-background">
                    <div
                      class="pt-background-overley pt-opacity-1"
                      style="background-image: url(images/polygon/Polygon-Luminary.svg)"
                    ></div>
                    <div class="container">
                      <div class="row mx-0 my-0">
                        <div class="col-xl-8">
                          <div class="pt-section-title-1 pt-section-title-1-color-white mb-0">
                            <h2 class="pt-section-title text-white">
                              5 días de prueba sin <span class="imp-word">costo</span>
                            </h2>
                            <p class="pt-section-description text-white mb-0">
                              Queremos que siempre te encuentres 100% conforme con el servicio antes de pagar.
                            </p>
                          </div>
                        </div>
                        <div class="col-xl-4">
                          <div class="pt-btn-container text-right pt-btn-position">
                            <div class="pt-button-block">
                              <router-link class="menu-item" to="/nosotros#contacto">
                                <a class="pt-button pt-button-change-color" style="cursor: pointer">
                                  <span class="text">Contáctanos</span>
                                </a>
                              </router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <!-- offer end -->

                  <!-- Faq Start -->
                  <section>
                    <div class="container">
                      <div class="row">
                        <div class="col-xl-6 col-md-12">
                          <div class="pt-img-main fade-in mb-5 sticky-img">
                            <img
                              src="images/about-us/Group-31.svg"
                              alt="solution-img"
                              class="svg img-fluid"
                            />
                          </div>
                        </div>
                        <div class="col-xl-6 col-md-12 mt-3 mt-xl-0">
                          <div class="pt-section-title-1">
                            <h2 class="pt-section-title">
                              Preguntas <span class="imp-word">Frecuentes</span>
                            </h2>
                            <p class="pt-section-description">
                              Queremos que estés tranquilo, no tengas sorpresas y disfrutes tu servicio de
                              hosting compartido por lo que hemos dispuesto esta sección para despejar las
                              dudas que frecuentemente recibimos respecto al servicio. Si no aparece algo o
                              requieres profundizar en algún tema nuestro equipo siempre estará disponible
                              para resolver tus dudas.
                            </p>
                          </div>
                          <div class="pt-accordion-block">
                            <div class="pt-accordion-box 3">
                              <div class="pt-ad-title">
                                <h4 class="ad-title-text">
                                  ¿Qué es importante saber de mi plan?
                                  <i aria-hidden="true" class="fas fa-angle-down active"> </i>
                                  <i aria-hidden="true" class="fas fa-angle-up inactive"> </i>
                                </h4>
                              </div>
                              <div class="pt-accordion-details" style="display: none">
                                <span class="pt-detail-text">
                                  <p>
                                    Los planes de hosting son compartidos, por lo tanto, algunos parámetros se
                                    encuentran limitados con el único objetivo que todos posean un servicio
                                    estable y disponible en todo momento.
                                  </p>
                                  <br />
                                  <p>
                                    Los parámetros y límites de cada plan se encuentran especificados en todos
                                    nuestros planes.
                                  </p>
                                </span>
                              </div>
                            </div>
                            <div class="pt-accordion-box 4">
                              <div class="pt-ad-title">
                                <h4 class="ad-title-text">
                                  ¿Qué es un inode?
                                  <i aria-hidden="true" class="fas fa-angle-down active"> </i>
                                  <i aria-hidden="true" class="fas fa-angle-up inactive"> </i>
                                </h4>
                              </div>
                              <div class="pt-accordion-details" style="display: block">
                                <p class="pt-detail-text">
                                  Los inode son todos los archivos o carpetas alojados en el hosting, esto
                                  considera archivos de registro, logs, scripts, correos electrónicos, etc.
                                  <br /><br />
                                  En caso de que alcances el límite de inode, ya no podrás crear ningún
                                  archivo y/o carpeta en tu cuenta, esto podría traer problemas para tu sitio,
                                  para solucionarlo deberás eliminar archivos que no sean utilizados.
                                  <br /><br />
                                  A diferencia de otros proveedores de hosting nuestros planes de hosting
                                  compartido no tienen limitación de inodes, únicamente se limita por
                                  capacidad de almacenamiento según el plan contratado.
                                </p>
                              </div>
                            </div>
                            <div class="pt-accordion-box 5">
                              <div class="pt-ad-title">
                                <h4 class="ad-title-text">
                                  ¿Es posible actualizar mi plan?, ¿Pierdo información?
                                  <i aria-hidden="true" class="fas fa-angle-down active"> </i>
                                  <i aria-hidden="true" class="fas fa-angle-up inactive"> </i>
                                </h4>
                              </div>
                              <div class="pt-accordion-details" style="display: block">
                                <p class="pt-detail-text">
                                  Todos nuestros planes son escalables y no pierdes información al realizar
                                  este proceso.
                                </p>
                              </div>
                            </div>
                            <div class="pt-accordion-box 6">
                              <div class="pt-ad-title">
                                <h4 class="ad-title-text">
                                  ¿Existe la posibilidad de reembolso?
                                  <i aria-hidden="true" class="fas fa-angle-down active"> </i>
                                  <i aria-hidden="true" class="fas fa-angle-up inactive"> </i>
                                </h4>
                              </div>
                              <div class="pt-accordion-details" style="display: block">
                                <p class="pt-detail-text">
                                  Todos nuestros planes hosting básico tienen 5 días de prueba completamente
                                  gratuitos, al finalizar este periodo, se suspenderá automáticamente y se
                                  enviará un correo electrónico notificando el término del periodo de prueba
                                  junto con una nota de cobro en caso de querer continuar con el servicio.
                                  <br />
                                  En caso de no estar conforme, dentro de los primeros 10 días puedes
                                  solicitar el reembolso de lo pagado.
                                </p>
                              </div>
                            </div>
                            <div class="pt-accordion-box 7">
                              <div class="pt-ad-title">
                                <h4 class="ad-title-text">
                                  ¿Si olvido el pago mi servicio de hosting se suspende automáticamente?
                                  <i aria-hidden="true" class="fas fa-angle-down active"> </i>
                                  <i aria-hidden="true" class="fas fa-angle-up inactive"> </i>
                                </h4>
                              </div>
                              <div class="pt-accordion-details" style="display: block">
                                <p class="pt-detail-text">
                                  En caso que olvides pagar en la fecha de renovación tu servicio de hosting
                                  compartido cuentas con 5 días adicionales, posterior a esa fecha el servicio
                                  será suspendido de manera automática, sin embargo, si tienes algún problema
                                  para pagar en la fecha te invitamos a contactar a nuestro equipo de ventas
                                  para que pueda revisar tu caso y ayudarte para que no sufras una
                                  interrupción en el servicio.
                                </p>
                              </div>
                            </div>
                            <div class="pt-accordion-box 8">
                              <div class="pt-ad-title">
                                <h4 class="ad-title-text">
                                  ¿Cómo puedo contactarme con Soporte?
                                  <i aria-hidden="true" class="fas fa-angle-down active"> </i>
                                  <i aria-hidden="true" class="fas fa-angle-up inactive"> </i>
                                </h4>
                              </div>
                              <div class="pt-accordion-details" style="display: block">
                                <p class="pt-detail-text">
                                  En el momento que contratas ya podemos apoyarte en lo que requieras para
                                  comenzar a trabajar. <br />
                                  Para que puedas contactarnos en todo momento tenemos a tu disposición los
                                  siguientes canales de atención: asistencia a través de nuestra mesa de ayuda
                                  telefónica, soporte online y a través de ticket de soporte.
                                </p>
                              </div>
                            </div>
                            <div class="pt-accordion-box 9">
                              <div class="pt-ad-title">
                                <h4 class="ad-title-text">
                                  ¿Si deseo aumentar la capacidad de mi plan cómo debo solicitarlo?
                                  <i aria-hidden="true" class="fas fa-angle-down active"> </i>
                                  <i aria-hidden="true" class="fas fa-angle-up inactive"> </i>
                                </h4>
                              </div>
                              <div class="pt-accordion-details" style="display: block">
                                <p class="pt-detail-text">
                                  Puedes aumentar la capacidad de tu plan de hosting compartido cuando lo
                                  necesites y únicamente pagarás la diferencia que se pueda generar entre un
                                  plan y otro según los meses restantes de contrato.
                                </p>
                              </div>
                            </div>
                            <div class="pt-accordion-box 10">
                              <div class="pt-ad-title">
                                <h4 class="ad-title-text">
                                  ¿Qué sucede si olvido pagar mi hosting?
                                  <i aria-hidden="true" class="fas fa-angle-down active"> </i>
                                  <i aria-hidden="true" class="fas fa-angle-up inactive"> </i>
                                </h4>
                              </div>
                              <div class="pt-accordion-details" style="display: block">
                                <p class="pt-detail-text">
                                  Si olvidaste pagar tu hosting y han transcurrido menos de 60 días desde la
                                  fecha de renovación, no te preocupes, tu cuenta podrá ser recuperada con
                                  todos sus datos, pero posterior a esa fecha y de no haber sido acreditado el
                                  pago la cuenta será eliminada de manera definitiva de nuestros servidores
                                  por lo que de querer reactivar el servicio tendrias que tomar un nuevo
                                  contrato y comenzar desde 0.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <!-- Faq End -->
                </div>

                <!-- PLANEL 6 HOSTING Emprendedor -->
                <div
                  class="tab-pane fade"
                  :class="{ 'active show': tab == '#nav-home-5' }"
                  id="nav-5"
                  role="tabpanel"
                  aria-labelledby="nav-home-5"
                >
                  <div class="container pb-5 mb-1">
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="pt-tab-info" style="margin-bottom: 50px">
                          <h2>Hosting Emprendedor</h2>
                          <p>
                            Ideal para emprendedores que desean establecer su presencia en línea de manera rápida y a bajo costo.
                          </p>
                          <ul>
                            <li>Múltiples canales de comunicación: Contaras con soporte a través de ticket, chat Online o WhatsApp lo que le permitira obtener respuestas rápidas y directas a sus preguntas o problemas.</li>
                            <li>Correos electrónicos con tu dominio: Te permite crear direcciones de correo personalizadas para su negocio, aumentando la profesionalidad de su marca</li>
                            <li>Economía y accesibilidad: Es una opción económica, ideal para emprendedores con presupuestos ajustados</li>
                            <li>Escalabilidad: Puedes actualizar tu plan de hosting fácilmente para adaptarse al aumento de tráfico y demanda.</li>
                            <li>
                              Compatibilidad con lenguajes:  Es compatible con una amplia gama de lenguajes de programación, lo que permite a los emprendedores desarrollar sus sitios utilizando la tecnología preferida.
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="col-lg-6 p-box-c-30" style="align-self: center; text-align: center">
                        <img
                          src="images/ilustraciones/001.png"
                          style="width: 100%"
                          class="svg-mh-c"
                          alt="seo-image"
                        />
                      </div>
                    </div>
                  </div>

                  <!-- Service Slider Start -->
                  <section class="pt-grey-background section_plans">
                    <div class="container">
                      <div class="row">
                        <div class="col-12">
                          <div class="pt-section-title-1 text-center" style="margin-bottom: 20px">
                            <h2 class="pt-section-title">
                              Planes <span class="imp-word">Hosting </span>Emprendedores
                            </h2>
                            <p class="pt-section-description">
                              ¡No esperes más para darle vida a tu negocio en línea! Revisa nuestros planes a continuación y descubre cómo podemos ayudarte en tu emprendimiento.
                            </p>


                          </div>
                        </div>
                      </div>
                      <div class="tab-content" id="advance-nav-tabContent">
                        <div
                          class="pt-advance-tab-content tab-pane fade fade-in show active"
                          id="advance-nav-5"
                          role="tabpanel"
                          aria-labelledby="advance-nav-home-5"
                        >
                        <div v-if="categories['Hosting Básico Anual']" class="row">
                            <SliderCardPlans :categorie="categories['Emprendedor']" :styleBoxCard="{width: '380px'}"  :infoCategory="{category: dataHosting[4].categoria, subCategory: dataHosting[5].subcategoria }" keySlide="hosting_Hosting_Wordpress_Anual" :promoGlobal="{status: true, text: 'Prueba 5 días gratis'}" :sslGlobal="true"/>
                        </div>
                        
                          <!-- <div class="row">
                            <div class="col-12">
                              <div class="pt-fancy-box-slider pt-fancy-box-slider-1">
                                <div
                                  class="swiper-container swiper-container-nav-home-5 swiper-container-initialized swiper-container-horizontal"
                                  data-nav="false"
                                  data-pagination_type="bullets"
                                  data-desk_num="3"
                                  data-lap_num="2"
                                  data-tab_num="2"
                                  data-mob_num="1"
                                  data-mob_sm="1"
                                  data-autoplay="false"
                                  data-loop="false"
                                  data-margin="30"
                                >
                                  <div class="swiper-wrapper">
                                    <div
                                      v-for="item in categories['Emprendedor']"
                                      :key="item.id"
                                      class="swiper-slide"
                                    >
                                      <div class="pt-pricebox-1 pc-fbox-home text-center mt-0 shadow-card">
                                        <div class="pt-pricebox-info">
                                          <span class="pt-price-title">
                                            <span v-if="type == 1">{{ item.nombre }} </span></span
                                          >
                                          <br />
                                          <span class="pt-price-duration fromer"
                                            ><em>{{ item.descripcion }}</em></span
                                          >

                                          <h2 class="price">
                                            <span
                                              v-if="item.moneda.nombre_corto == 'UF'"
                                              class="item-row-price"
                                              :data-node="true"
                                              >{{ changeSeparator((item.precio * form.cantidad).toFixed(2)) }}
                                            </span>
                                            <span
                                              v-else-if="item.moneda.nombre_corto == 'USD'"
                                              class="item-row-price"
                                              :data-node="true"
                                              >{{ changeSeparator((item.precio * form.cantidad).toFixed(2)) }}
                                            </span>

                                            <span v-else class="row-price" :data-node="true"
                                              ><span class="item-row-price">$</span>
                                              <span>{{ formatNumber(item.precio * form.cantidad) }}</span>
                                            </span>
                                          </h2>

                                          <p class="pt-price-duration">
                                            {{ item.moneda.nombre_corto }} / {{ item.periodicidad.nombre }}
                                          </p>
                                        </div>
                                        <ul class="pt-list-info pt-list-info-padding">
                                          <li
                                            v-for="(atribute, idx) in item.atributos"
                                            :key="idx"
                                            class="active"
                                          >
                                            <div style="flex-flow: row nowrap">
                                              <span style="margin-right: 25px">
                                                <div style="display: flex; flex-flow: row nowrap"></div>
                                                <span>{{ atribute.nombre.toUpperCase() }} </span>
                                              </span>

                                              <span style="float: right"
                                                ><span
                                                  v-if="
                                                    atribute.pivot.valor.toUpperCase() == 'ILIMITADOS' ||
                                                    atribute.pivot.valor.toUpperCase() == 'ILIMITADA' ||
                                                    atribute.pivot.valor.toUpperCase() == 'ILIMITADAS'
                                                  "
                                                  class="span-infinito-cart"
                                                  >∞</span
                                                >
                                                <span v-else>{{ atribute.pivot.valor.toUpperCase() }}</span>
                                              </span>
                                            </div>
                                          </li>
                                        </ul>

                                        <div class="pt-btn-container">
                                          <div class="pt-button-block">
                                            <a
                                              :href="
                                                '/cart?service=' +
                                                item.id +
                                                '&category=' +
                                                dataHosting[4].categoria +
                                                '&subcategory=' +
                                                dataHosting[5].subcategoria
                                              "
                                              style="z-index: 20; cursor: pointer"
                                              class="pt-button"
                                            >
                                              <span class="text">Contratar</span>
                                            </a>
                                          </div>
                                        </div>

                                        <div class="pt-bg-effect polygon-new">
                                          <img src="images/polygon/Polygon-Luminary.svg" alt="gym-image" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="swiper-pagination swiper-pagination-dynamic"></div>
                                </div>
                              </div>
                              <div class="swiper-button-prev"></div>
                              <div class="swiper-button-next"></div>
                            </div>
                          </div> -->
                        </div>

                      </div>
                    </div>
                  </section>
                  <!-- Service Slider End -->

                  <!-- Offer start -->
                  <section class="pt-blue-background">
                    <div
                      class="pt-background-overley pt-opacity-1"
                      style="background-image: url(images/polygon/Polygon-Luminary.svg)"
                    ></div>
                    <div class="container">
                      <div class="row mx-0 my-0">
                        <div class="col-xl-8">
                          <div class="pt-section-title-1 pt-section-title-1-color-white mb-0">
                            <h2 class="pt-section-title text-white">
                              5 días de prueba sin <span class="imp-word">costo</span>
                            </h2>
                            <p class="pt-section-description text-white mb-0">
                              Queremos que siempre te encuentres 100% conforme con el servicio antes de pagar.
                            </p>
                          </div>
                        </div>
                        <div class="col-xl-4">
                          <div class="pt-btn-container text-right pt-btn-position">
                            <div class="pt-button-block">
                              <router-link class="menu-item" to="/nosotros#contacto">
                                <a class="pt-button pt-button-change-color" style="cursor: pointer">
                                  <span class="text">Contáctanos</span>
                                </a>
                              </router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <!-- offer end -->

                  <!-- Faq Start -->
                  <section>
                    <div class="container">
                      <div class="row">
                        <div class="col-xl-6 col-md-12">
                          <div class="pt-img-main fade-in mb-5 sticky-img">
                            <img
                              src="images/about-us/Group-31.svg"
                              alt="solution-img"
                              class="svg img-fluid"
                            />
                          </div>
                        </div>
                        <div class="col-xl-6 col-md-12 mt-3 mt-xl-0">
                          <div class="pt-section-title-1">
                            <h2 class="pt-section-title">
                              Preguntas <span class="imp-word">Frecuentes</span>
                            </h2>
                            <p class="pt-section-description">
                              Queremos que estés tranquilo, no tengas sorpresas y disfrutes tu servicio de
                              hosting compartido por lo que hemos dispuesto esta sección para despejar las
                              dudas que frecuentemente recibimos respecto al servicio. Si no aparece algo o
                              requieres profundizar en algún tema nuestro equipo siempre estará disponible
                              para resolver tus dudas.
                            </p>
                          </div>
                          <div class="pt-accordion-block">
                            <div class="pt-accordion-box 3">
                              <div class="pt-ad-title">
                                <h4 class="ad-title-text">
                                  ¿Qué es importante saber de mi plan?
                                  <i aria-hidden="true" class="fas fa-angle-down active"> </i>
                                  <i aria-hidden="true" class="fas fa-angle-up inactive"> </i>
                                </h4>
                              </div>
                              <div class="pt-accordion-details" style="display: none">
                                <span class="pt-detail-text">
                                  <p>
                                    Los planes de hosting son compartidos, por lo tanto, algunos parámetros se
                                    encuentran limitados con el único objetivo que todos posean un servicio
                                    estable y disponible en todo momento.
                                  </p>
                                  <br />
                                  <p>
                                    Los parámetros y límites de cada plan se encuentran especificados en todos
                                    nuestros planes.
                                  </p>
                                </span>
                              </div>
                            </div>
                            <div class="pt-accordion-box 4">
                              <div class="pt-ad-title">
                                <h4 class="ad-title-text">
                                  ¿Qué es un inode?
                                  <i aria-hidden="true" class="fas fa-angle-down active"> </i>
                                  <i aria-hidden="true" class="fas fa-angle-up inactive"> </i>
                                </h4>
                              </div>
                              <div class="pt-accordion-details" style="display: block">
                                <p class="pt-detail-text">
                                  Los inode son todos los archivos o carpetas alojados en el hosting, esto
                                  considera archivos de registro, logs, scripts, correos electrónicos, etc.
                                  <br /><br />
                                  En caso de que alcances el límite de inode, ya no podrás crear ningún
                                  archivo y/o carpeta en tu cuenta, esto podría traer problemas para tu sitio,
                                  para solucionarlo deberás eliminar archivos que no sean utilizados.
                                  <br /><br />
                                  A diferencia de otros proveedores de hosting nuestros planes de hosting
                                  compartido no tienen limitación de inodes, únicamente se limita por
                                  capacidad de almacenamiento según el plan contratado.
                                </p>
                              </div>
                            </div>
                            <div class="pt-accordion-box 5">
                              <div class="pt-ad-title">
                                <h4 class="ad-title-text">
                                  ¿Es posible actualizar mi plan?, ¿Pierdo información?
                                  <i aria-hidden="true" class="fas fa-angle-down active"> </i>
                                  <i aria-hidden="true" class="fas fa-angle-up inactive"> </i>
                                </h4>
                              </div>
                              <div class="pt-accordion-details" style="display: block">
                                <p class="pt-detail-text">
                                  Todos nuestros planes son escalables y no pierdes información al realizar
                                  este proceso.
                                </p>
                              </div>
                            </div>
                            <div class="pt-accordion-box 6">
                              <div class="pt-ad-title">
                                <h4 class="ad-title-text">
                                  ¿Existe la posibilidad de reembolso?
                                  <i aria-hidden="true" class="fas fa-angle-down active"> </i>
                                  <i aria-hidden="true" class="fas fa-angle-up inactive"> </i>
                                </h4>
                              </div>
                              <div class="pt-accordion-details" style="display: block">
                                <p class="pt-detail-text">
                                  Todos nuestros planes hosting básico tienen 5 días de prueba completamente
                                  gratuitos, al finalizar este periodo, se suspenderá automáticamente y se
                                  enviará un correo electrónico notificando el término del periodo de prueba
                                  junto con una nota de cobro en caso de querer continuar con el servicio.
                                  <br />
                                  En caso de no estar conforme, dentro de los primeros 10 días puedes
                                  solicitar el reembolso de lo pagado.
                                </p>
                              </div>
                            </div>
                            <div class="pt-accordion-box 7">
                              <div class="pt-ad-title">
                                <h4 class="ad-title-text">
                                  ¿Si olvido el pago mi servicio de hosting se suspende automáticamente?
                                  <i aria-hidden="true" class="fas fa-angle-down active"> </i>
                                  <i aria-hidden="true" class="fas fa-angle-up inactive"> </i>
                                </h4>
                              </div>
                              <div class="pt-accordion-details" style="display: block">
                                <p class="pt-detail-text">
                                  En caso que olvides pagar en la fecha de renovación tu servicio de hosting
                                  compartido cuentas con 5 días adicionales, posterior a esa fecha el servicio
                                  será suspendido de manera automática, sin embargo, si tienes algún problema
                                  para pagar en la fecha te invitamos a contactar a nuestro equipo de ventas
                                  para que pueda revisar tu caso y ayudarte para que no sufras una
                                  interrupción en el servicio.
                                </p>
                              </div>
                            </div>
                            <div class="pt-accordion-box 8">
                              <div class="pt-ad-title">
                                <h4 class="ad-title-text">
                                  ¿Cómo puedo contactarme con Soporte?
                                  <i aria-hidden="true" class="fas fa-angle-down active"> </i>
                                  <i aria-hidden="true" class="fas fa-angle-up inactive"> </i>
                                </h4>
                              </div>
                              <div class="pt-accordion-details" style="display: block">
                                <p class="pt-detail-text">
                                  En el momento que contratas ya podemos apoyarte en lo que requieras para
                                  comenzar a trabajar. <br />
                                  Para que puedas contactarnos en todo momento tenemos a tu disposición los
                                  siguientes canales de atención: asistencia a través de nuestra mesa de ayuda
                                  telefónica, soporte online y a través de ticket de soporte.
                                </p>
                              </div>
                            </div>
                            <div class="pt-accordion-box 9">
                              <div class="pt-ad-title">
                                <h4 class="ad-title-text">
                                  ¿Si deseo aumentar la capacidad de mi plan cómo debo solicitarlo?
                                  <i aria-hidden="true" class="fas fa-angle-down active"> </i>
                                  <i aria-hidden="true" class="fas fa-angle-up inactive"> </i>
                                </h4>
                              </div>
                              <div class="pt-accordion-details" style="display: block">
                                <p class="pt-detail-text">
                                  Puedes aumentar la capacidad de tu plan de hosting compartido cuando lo
                                  necesites y únicamente pagarás la diferencia que se pueda generar entre un
                                  plan y otro según los meses restantes de contrato.
                                </p>
                              </div>
                            </div>
                            <div class="pt-accordion-box 10">
                              <div class="pt-ad-title">
                                <h4 class="ad-title-text">
                                  ¿Qué sucede si olvido pagar mi hosting?
                                  <i aria-hidden="true" class="fas fa-angle-down active"> </i>
                                  <i aria-hidden="true" class="fas fa-angle-up inactive"> </i>
                                </h4>
                              </div>
                              <div class="pt-accordion-details" style="display: block">
                                <p class="pt-detail-text">
                                  Si olvidaste pagar tu hosting y han transcurrido menos de 60 días desde la
                                  fecha de renovación, no te preocupes, tu cuenta podrá ser recuperada con
                                  todos sus datos, pero posterior a esa fecha y de no haber sido acreditado el
                                  pago la cuenta será eliminada de manera definitiva de nuestros servidores
                                  por lo que de querer reactivar el servicio tendrias que tomar un nuevo
                                  contrato y comenzar desde 0.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <!-- Faq End -->
                </div>



              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Service Plan End -->

    <!-- Award Slider start -->
    <section style="padding: 40px 0px 80px 0px" class="boxshadowcustom">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="pt-section-title-1 text-center">
              <h2 class="pt-section-title">Tecno<span class="imp-word">logías</span></h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="pt-award">
              <!-- Slider main container -->
              <div
                class="swiper-container swiper-container-fast-static swiper-container-initialized swiper-container-horizontal"
                data-nav="false"
                data-pagination_type="bullets"
                data-desk_num="5"
                data-lap_num="3"
                data-tab_num="2"
                data-mob_num="1"
                data-mob_sm="1"
                data-autoplay="true"
                data-loop="true"
                data-margin="60"
              >
                <!-- Additional required wrapper -->
                <div class="swiper-wrapper">
                  <!-- Slides -->
                  <div class="swiper-slide">
                    <div class="pt-awardbox-1">
                      <a href="https://www.cloudflare.com/" title="Cloudflare" target="_blank"
                        ><img src="img/logos/cloudflare.svg" alt="Cloudflare"
                      /></a>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="pt-awardbox-1">
                      <a href="https://www.cloudlinux.com/" title="Cloudlinux" target="_blank"
                        ><img src="img/logos/cloudlinux.svg" alt="CloudLinux"
                      /></a>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="pt-awardbox-1">
                      <a href="https://www.cpanel.com/" title="cPanel" target="_blank"
                        ><img src="img/logos/cpanel.svg" alt="cPanel"
                      /></a>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="pt-awardbox-1">
                      <a href="https://git-scm.com/" title="GIT" target="_blank"
                        ><img src="img/logos/git.svg" alt="GIT"
                      /></a>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="pt-awardbox-1">
                      <a href="https://nodejs.org/" title="NodeJS" target="_blank"
                        ><img src="img/logos/nodejs.svg" alt="NodeJS"
                      /></a>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="pt-awardbox-1">
                      <a href="https://www.python.org/" title="Python" target="_blank"
                        ><img src="img/logos/python.svg" alt="Python"
                      /></a>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="pt-awardbox-1">
                      <a href="https://www.php.com/" title="Php" target="_blank"
                        ><img src="img/logos/php.svg" alt="PHP"
                      /></a>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="pt-awardbox-1">
                      <a href="https://magento.com/" title="Magento" target="_blank"
                        ><img src="img/logos/magento.svg" alt="Magento"
                      /></a>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="pt-awardbox-1">
                      <a href="https://www.prestashop.com/es" title="Prestashop" target="_blank"
                        ><img src="img/logos/prestashop.svg" alt="Prestashop"
                      /></a>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="pt-awardbox-1">
                      <a href="https://wordpress.com/" title="WordPress" target="_blank"
                        ><img src="img/logos/wordpress.svg" alt="WordPress"
                      /></a>
                    </div>
                  </div>
                </div>
                <!-- If we need pagination -->
                <div class="swiper-pagination swiper-pagination-static"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Award Slider end -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SliderCardInfo from "@/components/web/sliders/SliderCardInfo.vue";
import SliderCardPlans from "@/components/web/sliders/SliderCardPlans.vue";
import {
  formatNumber,
  changeSeparator,
  swiper_component,
  swiper_component_fast,
  swiper_component_fast_static,
  revinit_revslider21,
  accordion,
  resolucion,
  resolucion2,
  swiper_component_target,
  toArray,
} from "@/helpers";

export default {
  metaInfo: {
        title: "ihosting | Hosting",
        htmlAttrs: {
          lang: "es",
          amp: true,
        },
        meta: [
          {
            name: "description",
            content: "Servicios de hosting en Chile enfocado a pequeñas y medianas empresas, nuestros especialistas te ayudarán a encontrar el plan que necesitas y sacar el máximo provecho a tu hosting."
          },
          {
            name: "keywords",
            content:"hosting, wordpress, moodle",
          },
        ]
      },
  components: {
    SliderCardInfo,
    SliderCardPlans
  },


  data: () => ({
    tab: "#nav-home-5",

    loading: false,
    type: 1,
    form: {
      cantidad: 1,
    },
    categories: {},
    timeout: 0,
    cardServicesInfo: [
      { icon: false, img: 'images/icons/cpanel.svg', title: 'Base de Datos Remota', info: ' Puedes acceder remotamente y cuando necesites a tu base de datos ya sea através de tu computador, entorno nube o dónde requieras.'},
      { icon: true, iconClass: 'flaticon-010-lock', title: 'Seguridad', info: 'Nuestros planes incluyen poderosas herramientas que permitirán tener tu sitio seguro de vulnerabilidades, bots y brechas de seguridad a través de imunify360.'},
      { icon: false, img: 'images/icons/support.svg', title: 'Soporte', info: ' Siempre que requieras asistencia estaremos disponibles para ayudarte a resolver tus inquietudes en el menor tiempo posible.'},
      { icon: true, iconClass: 'flaticon-009-web-browser', title: 'Sitio Seguro', info: ' En todos nuestros planes de hosting compartido obtendrás de manera gratuita un certificado SSL el cual te permitirá entregar seguridad a los visitantes que ingresen a tu sitio.'},
      { icon: false, img: 'images/icons/rocket.svg', title: 'Rapidez', info: 'Tu sitio web abrirá en todas partes del mundo de manera rápida y con protección DDoS solo debes habilitar de forma totalmente gratuita cloudflare desde cPanel.'},
      { icon: false, img: 'images/wp-logo.png', title: 'Aplicaciones', info: 'Desata todo el potencial de WordPress con WP Toolkit Premium, diseñado para facilitar, optimizar y potenciar tu experiencia con WordPress, ¡incluido en todos los planes!'},
  
      { icon: true, iconClass: 'flaticon-006-server', title: 'Respaldos', info: 'Si existiese un problema en el servidor, una catástrofe o borraste de manera accidental tu sitio siempre contaremos con la última copia disponible para ti.'},
      { icon: true, iconClass: 'flaticon-003-monitor-screen', title: 'Migración', info: 'Estas aburrido de tu proveedor actual, solo debes solicitar a nuestro equipo de soporte que evalúe la migración de su sitio. Sujeta a restricciones y factibilidad técnica.'},
  
    ]
  }),
  watch: {
    $route() {
      if (this.$route.query.id) {
        this.tab = "#" + this.$route.query.id;
        setTimeout(() => {
          this.swiper_component_target(".swiper-container-" + this.$route.query.id);
          this.swiper_component_fast();
        }, 100);
      }
    },
    tab(val) {
      setTimeout(() => {
        this.swiper_component_target(".swiper-container-" + val.substring(1));
      }, 50);
    },
  },

  created() {},
  mounted() {
    this.initComponent();
  },

  computed: {
    ...mapGetters(["getServices_", "getData"]),
    data_() {
      return this.getData?.web;
    },
    dataHosting() {
      return this.getData?.web.hosting;
    },
  },
  methods: {
    ...mapActions(["getServices"]),
    formatNumber,
    changeSeparator,
    swiper_component,
    swiper_component_fast,
    swiper_component_fast_static,
    revinit_revslider21,
    resolucion,
    resolucion2,
    accordion,
    swiper_component_target,
    toArray,
    retryInit() {
      setTimeout(() => {
        if (this.getData) {
          this.swiper_component();
          this.swiper_component_target(".swiper-container-nav-home-0");
        } else {
          this.retryInit();
        }
      }, 100);
    },
    refreshComponent(target) {
      setTimeout(() => {
        this.swiper_component_target(target);
      }, 190);
    },
    categoriesSort(service){
        return service.sort( (a,b)=> a.precio - b.precio) ;
    },
    initComponent() {
      setTimeout(() => {
        if (this.getData) {
          this.revinit_revslider21("#rev_slider_hosting");
          this.loading = true;
          // console.log("this.data: ", this.data_.hosting);
          this.data_.hosting.forEach((hosting, index) => {
            this.getServices(hosting.subcategoria).then((res) => {
              let data = [];
              // console.log("res: ", res);
              res.forEach((item) => {
                let i = this.data_.hosting[index]["servicios"].includes(item.id);
                if (i) {
                  data.push(item);
                }
              });
              // Funcion que ordena los servicios de su categoria de menor a mayor por su precio
              let categoriasOrdenada = this.categoriesSort(data);
              this.$set(this.categories, hosting.nombre, categoriasOrdenada);
              // console.log("categories: ", this.categories);
            });
          });

          this.swiper_component_fast_static();
          if (this.$route.query.id) {
            this.tab = "#" + this.$route.query.id;
            this.retryTwo();
          } else {
            this.retryOne();
          }
          this.accordion();
        } else {
          this.initComponent();
        }
      }, 100);
    },
    retryOne() {
      setTimeout(() => {
        if (this.getData && this.toArray(this.categories).length == 5) {
          setTimeout(() => {
            this.swiper_component_target(".swiper-container-nav-home-0");
          }, 150);
        } else {
          this.retryOne();
        }
      }, 100);
    },
    retryTwo() {
      setTimeout(() => {
        if (this.getData && this.toArray(this.categories).length == 5) {
          setTimeout(() => {
            this.swiper_component_target(".swiper-container-" + this.$route.query.id);
          }, 150);
        } else {
          this.retryTwo();
        }
      }, 100);
    },
  },
};
</script>
<style scoped>



.section_plans{
  padding: 130px 0px 70px !important;
}

/* title section plan */
.tab-content{
  margin-top: 40px !important;
}


/* card price */
.pt-pricebox-1 {
  min-height: 770px;
}
/* boton price */
.pt-pricebox-1 .pt-btn-container {
    margin: 0 auto;
    position: absolute;
    bottom: 35px;
    right: 10px;
    left: 10px;
}


/* padre text aributos */
.pt-list-info div{
  display: flex;
    justify-content: space-between;
    width: 100%;
}

/* text atributos price */
.pt-pricebox-1 .pt-list-info li {
  font-size: 13px;
}



.swiper-container{
  overflow: hidden !important;
}
</style>
