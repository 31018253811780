<template>
    <div class="section">
        <div class="container" :class="{'container_widthFull': configSwiper.perView.slidesPerViewThree == 4}">
         <div class="card__container swiper" :class="{card__container_loading: loading }">
            <div class="card__content" :class="`card__content_${keySlide}`">
               <div  class="swiper-wrapper">
                  <article v-for="(item, index) in categories['Microsoft Office 365 Mensual']" :key="item.id" class="card__article swiper-slide">
                    <div
                            class="pt-pricebox-1 pc-fbox-home text-center mt-0 shadow-card  cardsMicrosoft"
                            :class="{ active: index % 2 == 0 }"
                          >
                            <div class="pt-pricebox-info" style="margin-bottom: 10px !important;">
                              <div class="contbadage"></div>
                              <span class="pt-price-title">
                                <span
                                  v-if="item.nombre.toUpperCase() == 'MICROSOFT 365 EMPRESA ESTÁNDAR'"
                                  style="font-size: 17px"
                                  >{{ item.nombre }} </span
                                ><span v-else>{{ item.nombre }}</span></span
                              >
                              <br />
                              <span class="pt-price-duration fromer"
                                ><em>{{ item.descripcion }}</em></span
                              >

                              <h2 class="price">
                                <!-- div: {{ item.precio / 12 * 2 }} -->
                                <span
                                  v-if="item.moneda.nombre_corto == 'UF'"
                                  class="item-row-price"
                                  :data-node="true"
                                  >{{item.moneda.nombre_corto}} {{ changeSeparator( ( item.precio / 12 * form2[index].cantidad ).toFixed(2) ) }}
                                </span>
                                <span
                                  v-else-if="item.moneda.nombre_corto == 'USD'"
                                  class="item-row-price"
                                  :data-node="true"
                                  >{{item.moneda.nombre_corto}} {{ changeSeparator((item.precio / 12 * form2[index].cantidad).toFixed(2)) }}
                                </span>

                                <span v-else class="row-price" :data-node="true"
                                  ><span class="item-row-price">$</span>
                                  <span>{{ formatNumber(item.precio / 12 * form2[index].cantidad) }}</span>
                                </span>
                              </h2>

                              <!-- <p class="pt-price-duration">
                                {{ item.moneda.nombre_corto }} / {{ item.periodicidad.nombre }}
                              </p> -->
                              <p  v-if="item.moneda.nombre_corto == 'UF'" class="pt-price-duration">
                                Mes / Contrato anual {{ changeSeparator((item.precio * form2[index].cantidad).toFixed(2)) }}
                              </p>
                              <p  v-else-if="item.moneda.nombre_corto == 'USD'" class="pt-price-duration">
                                Mes / Contrato anual {{ changeSeparator((item.precio * form2[index].cantidad).toFixed(2)) }}
                              </p>
                              <p  v-else class="pt-price-duration">
                                Mes / Contrato anual {{ formatNumber(item.precio * form2[index].cantidad) }}
                              </p>
                            </div>
                            <ul class="pt-list-info pt-list-info-padding">
                              <li v-for="(atribute, idx) in item.atributos" :key="idx" class="active">
                                <div v-if="atribute.nombre != 'Activación'" style="flex-flow: row nowrap">
                                  <div
                                    v-if="
                                      atribute.nombre.toUpperCase() != 'APLICACIONES OFFICE' &&
                                      atribute.nombre.toUpperCase() != 'CUENTAS'
                                    "
                                  >
                                    <span style="margin-right: 25px">
                                      <div style="display: flex; flex-flow: row nowrap"></div>
                                      <span>{{ atribute.nombre.toUpperCase() }} </span>
                                    </span>

                                    <span style="float: right"
                                      ><span
                                        v-if="
                                          atribute.pivot.valor.toUpperCase() == 'ILIMITADOS' ||
                                          atribute.pivot.valor.toUpperCase() == 'ILIMITADA' ||
                                          atribute.pivot.valor.toUpperCase() == 'ILIMITADAS'
                                        "
                                        class="span-infinito-cart"
                                        >∞</span
                                      >
                                      <span v-else>{{ atribute.pivot.valor.toUpperCase() }}</span>
                                    </span>
                                  </div>
                                </div>
                              </li>
                            </ul>

                            <hr class="my-3" />

                            <div>
                              <!-- title if appsServices exist-->
                              <h3
                                style="font-size: 16px"
                                :class="[index % 2 == 0 ? 'label-b-white' : 'label-b-blue']"
                              >
                              <!-- {{ codeValid(item.codigo, -3, 'webInstalable') ? 'APLICACIONES OFFICE INCLUIDAS' : "VERSIONES WEB OFFICE INCLUIDAS" }} -->
                              {{ item.codigo == 'M365EB-A-SNT' ? "VERSIONES WEB OFFICE INCLUIDAS" : "APLICACIONES OFFICE INCLUIDAS" }}
                              </h3>                           
                              <div style="min-height:58px">
                                <!-- si instalables es si y web no -->
                                <img v-if=" item.codigo == 'M365EE-A-NSB' || item.codigo == 'M365EE-M' || item.codigo == 'M365EP-A-NSA' || item.codigo == 'M365EP-M' || item.codigo == 'M365EB-A-NSB' || item.codigo == 'AM365-A' || item.codigo == 'AM365-A-NSO' "
                                  src="images/microsoft/imagen_3.svg"
                                  alt="microsoft office 365 paquete ihosting completo"
                                />
                                <!-- si web es si e instalable no -->
                                <img v-else-if=" item.codigo == 'M365EB-A-SNT' || item.codigo == 'M365EB-A-SNE' || item.codigo == 'M365EB-A-SNO' || item.codigo == 'M365EB-A-SNA' || item.codigo == 'M365EB-A-SNB' "
                                  src="images/microsoft/imagen_5.svg"
                                  alt="microsoft office 365 paquete ihosting basico"
                                />
                                <p v-else style="margin: 5px auto;font-size: 16px; color: #194960; font-weight: 500;">Ninguna</p>
                            </div>
                            </div>

                            <hr class="my-3" />

                            <div>
                              <h3
                                style="font-size: 16px"
                                :class="[index % 2 == 0 ? 'label-b-white' : 'label-b-blue']"
                              >
                                SERVICIOS INCLUIDOS
                              </h3>
                              <div style="min-height:58px">
                                <img v-if="item.codigo == 'M365EB-A-NSA' || item.codigo == 'M365EB-A-SNA' || item.codigo ==  'M365EP-A-NSA' "
                                src="images/microsoft/imagen_1.svg"
                                alt="microsoft paquete servicio completo"
                                />
                                <img v-else-if="item.codigo == 'M365EB-A-SNB' || item.codigo == 'M365EB-M' ||  item.codigo ==  'M365EE-A-NSB' || item.codigo ==  'M365EE-M' "
                                src="images/microsoft/imagen_0.svg"
                                alt="microsoft paquete servicio basico"
                              />
                              <img v-else-if="item.codigo == 'M365EB-A-NSE' || item.codigo == 'M365EB-A-SNE' || item.codigo == 'EOM365-A'  || item.codigo == 'EOM365-A-NNE' || item.codigo == 'EOM365-A-NNE'"
                                src="images/microsoft/imagen_4.svg"
                                alt="microsoft paquete servicio exchange"
                              />
                              <img v-else-if="item.codigo == 'M365EB-A-NSO' || item.codigo == 'M365EB-A-SNO' || item.codigo == 'M365AP-A-NSO' || item.codigo == 'AM365-A' || item.codigo == 'M365ODB01A' || item.codigo == 'AM365-A-NSO' "
                                src="images/microsoft/imagen_2.svg"
                                alt="microsoft paquete servicio onedrive"
                              />
                              <img v-else-if="item.codigo == 'M365EB-A-SNT' || item.codigo == 'M365EB-M' "
                                src="images/microsoft/teams.svg"
                                alt="microsoft paquete servicio teams"
                              />
                              </div>
                            </div>

                            <div class="pt-btn-container" style="position: relative">
                              <div class="pt-button-block">
                                <div class="row">
                                  <div
                                    class="col-12 px-0 mb-2"
                                    style="height: 83px; z-index: 10"
                                    :data-node="true"
                                  >
                                    <div style="text-align: center; text-align: -webkit-center">
                                      <label class="vue-input" :data-node="true" style="margin-bottom: 0rem"
                                        ><b
                                          :data-node="true"
                                          :class="[index % 2 == 0 ? 'label-b-white' : 'label-b-blue']"
                                          >Cuentas</b
                                        ></label
                                      >
                                      <div style="width: 85px; position: relative; margin: auto;" :data-node="true">
                                        <input
                                          v-model="form2[index].cantidad"
                                          type="number"
                                          onkeypress="if((event.charCode < 48 || event.charCode > 57) || this.value.length > 1) return false"
                                          @keydown="checkNumber($event, form2[index].cantidad)"
                                          min="1"
                                          max="99"
                                          maxlength="3"
                                          name="cantidad"
                                          id="cantidad_"
                                          class="input_num input-num-serv"
                                          :data-node="true"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-12 px-0 cardService_button">
                                    <a
                                      :href="
                                        '/cart?service=' +
                                        item.id +
                                        '&category=' +
                                        dataServicios[6].categoria +
                                        '&subcategory=' +
                                        dataServicios[6].subcategoria +
                                        '&cantidad=' +
                                        form2[index].cantidad
                                      "
                                      style="z-index: 20; cursor: pointer"
                                      class="pt-button"
                                      :class="{ 'pt-button-change-color': index % 2 == 0 }"
                                    >
                                      <span class="text"><i class="fas fa-shopping-cart"></i> Contratar</span>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="pt-bg-effect polygon-new">
                              <img src="images/polygon/Polygon-Luminary.svg" alt="gym-image" />
                            </div>
                          </div>  
                </article>   
  
               </div>
            </div>
            <!-- Navigation buttons -->
            <div class="swiper-button-next" :class="`swiper-button-next_${keySlide}`">
               <i class="ri-arrow-right-s-line"></i>
            </div>
            
            <div class="swiper-button-prev" :class="`swiper-button-prev_${keySlide}`">
               <i class="ri-arrow-left-s-line"></i> 
            </div>

            <!-- Pagination -->
            <!-- <div class="swiper-pagination" :class="`swiper-pagination_${keySlide}`"></div> -->
         </div>
         <div class="container_loading" v-if="loading">
          <Loading/>
         </div>
        </div>
    </div>
</template>

<script>
import Loading from "@/components/web/Loading.vue";
import { changeSeparator } from "@/helpers";
import { mapGetters, mapActions } from "vuex";
import {swiperCreate} from "@/helpers";

export default{
    components:{
        Loading
    },
    data: ()=>({
            categories: {},
            loading: true,
            form2: [{ cantidad: 1 }, { cantidad: 1 }, { cantidad: 1 }, { cantidad: 1 }, { cantidad: 1 }],
            form3: [{ cantidad: 1 }, { cantidad: 1 }, { cantidad: 1 }, { cantidad: 1 }],
            configSwiper: {
                loop: false,
                autoplay: false,
                time: 800,
                perView: {'slidesPerViewTwo': 2, 'slidesPerViewThree': 3, viewDynamic: true},
            },
            keySlide: 'microsoft',

        }),
    mounted() {
            setTimeout(() => {
            this.initComponent();
        }, 2000);
    },
    computed: {
        ...mapGetters(["getServices_", "getData"]),
        data_() {
        return this.getData?.web;
        },
        dataServicios() {
        return this.getData?.web.servicios;
        },
    },
    methods: {
        // helpers:
            changeSeparator,
            swiperCreate,

        // actions:
            ...mapActions(["getServices"]),
          
        // methods
            initComponent() {
                if (this.getData != null) {
                this.loading = true;
                //ADD service microsof adicional:
                this.data_.servicios['6']["servicios"].push(818);
                this.data_.servicios.forEach((servicio, index) => {
                    this.getServices(servicio.subcategoria).then((res) => {

                    let data = [];
                    res.forEach((item) => {
                        let i = this.data_.servicios[index]["servicios"].includes(item.id);
                        if (i) {
                        if(this.data_.servicios['6']["servicios"] && item.id == 818){
                            data.unshift(item);
                        }else{
                            data.push(item);
                        }
                        }
                    });

                    // Funcion que ordena los servicios de su categoria de menor a mayor por su precio
                    let categoriasOrdenada = this.categoriesSort(data);
                    this.$set(this.categories, servicio.nombre, categoriasOrdenada);
                    });
                });
                // Despues de que se hayan cargado todas las siete peticiones que se hicieron en el foreach deberia ejecutarse el swiperCreate
                this.getServices(this.getData.web.categorias?.servicios).then(() => {
                 // refresh
                // console.log("ya cargaron todos los datos");
                this.swiperCreate(this.keySlide, this.configSwiper.loop, this.configSwiper.autoplay, this.configSwiper.time, this.configSwiper.perView);
          });
          setTimeout(()=>{
                this.loading = false;
          }, 1000)
      } else {
          this.initComponent();
        }
            },
                  // Ordena los servicios/categorias por el precio de menor a mayor
      categoriesSort(service){
        return service.sort( (a,b)=> a.precio - b.precio) ;
       },
        
    },
}


</script>

<style scoped>



.section{
    padding: 40px 0px;
    max-width: 100%;
}

/* loading */
.container_loading{
  width: 100%;
  height: 100%;
  min-height: 725px;

  padding: 100PX 20PX;
  display: flex;
  justify-content: center;
}





/* Swiper class */
.swiper-button-prev:after,
.swiper-button-next:after {
content: "";
}

.swiper-button-prev,
.swiper-button-next {
width: initial;
height: initial;
font-size: 1.4rem;
color: white;
background: var(--primary-color);
box-shadow:1px 1px 8px 0px rgb(4 70 151 / 88%);
border-radius: 40px;
height: 45px;
width:45px;
display: flex;
align-items: center;
justify-content: center;
text-align: center;
top: 428px !important;
/* padding: 2px; */
/* display: none; */
}



.swiper-button-prev {
left: 6px;
}

.swiper-button-next {
right: 6px;
}
.swiper-pagination{
margin-top: 10px;
transform: translateX(-50%) translateY(10px) !important;
}

.swiper-pagination-bullet {
background-color: hsl(212, 32%, 40%);
opacity: 1;
z-index: 10;
}

.swiper-pagination-bullet-active {
background-color: var(--second-color);
}


/* card */
/* style base */
.card__article{
    max-width: 405px;
    margin-right: 32px;
}


  /* cards microsoft */
.cardsMicrosoft{
  min-height: 915px;
  max-width: 405px;
  min-width: 98%;
}
.cardsMicrosoft, .cardService{
  max-width: 405px;
}
@media(max-width: 700px){
  .cardsMicrosoft, .cardService{
    max-width: 550px;
  }
}

@media (max-width: 600px){
    .swiper-button-prev,
    .swiper-button-next {
        top: 250px !important;
    }
}
</style>